import Container from "components/container";
import ContentContainer from "components/content-container";
import OurPlatform from "components/our-platform";
import OwnnYourData from "components/own-your-data";
import PageHeader from "components/page-header";
import React from "react";
import Text from "components/text";
import about1 from "assets/images/about1.png";
import about2 from "assets/images/about2.png";
import aboutUsImg from "assets/images/about-us-header.png";
import styles from "./about.module.css";

const AboutUs = () => {
  const header =
    "Our Founders Struggled Hands on with Procurement on Job Sites";
  const body = ` Koncurent is the answer to every PM's struggle with procurement. As founder's
  we've felt this struggle on a daily basis, so we decided to do something about it. Many jobs
  lose time and money without procurement logs. Koncurent is here to maximize efficiency, and
  profits.`;
  return (
    <div>
      <PageHeader pageTitle="About Us" imageUrl={aboutUsImg} />

      <Container>
        <ContentContainer className={`mt-4 px-5 py-3 mb-3`}>
          <div className={`d-flex flex-column align-items-start pt-4`}>
            <Text className={`${styles.title}`} dontWrap={false}>
              {header}
            </Text>
            <Text className={`${styles.body} my-2`} dontWrap={false}>
              {body}
            </Text>
            <img src={about1} alt="content-img-1" className={`w-100 my-2`} />
          </div>
          {/* 
          {[1, 2].map((x, index) => (
            <div
              className={`row ${
                index % 2 == 0 ? "flex-row" : " flex-row-reverse"
              } align-items-center my-5`}
              key={`about-us-${x}`}
            >
              <div className="col-md-6">
                <Text className={`${styles.title}`} dontWrap={false}>
                  {header}
                </Text>
                <Text className={`${styles.body} my-2`} dontWrap={false}>
                  {body}
                </Text>
              </div>
              <div className="col-md-6">
                <img src={about2} alt="content-img-2" className={`w-100`} />
              </div>
            </div>
          ))} */}

          {/* <Text className={`${styles.body} my-5`} dontWrap={false}>
            {body}
          </Text> */}
        </ContentContainer>
      </Container>

      {/* <div className="text-center my-4">
        <Text className={`${styles.header}`} dontWrap={false}>
          Explore our construction software solutions for owners.
        </Text>
        <Text className={`${styles.subHeader}`} dontWrap={false}>
          Take control of construction outcomes, minimise risk and protect
          profits with Procore's top-rated construction management platform.
        </Text>
      </div> */}

      {/* <Container>
        <div className="d-flex justify-content-between flex-md-nowrap flex-wrap mt-3 py-2 mb-3">
          {[1, 2, 3, 4].map((x, index) => (
            <ContentContainer className="mx-2 px-3 py-3 mt-2">
              <OwnnYourData
                hasColor
                title="Own Your Data"
                body="Stay connected to the actionable insights you need to make smart management Stay connected to the actionable insights you need to make smart management"
              />
            </ContentContainer>
          ))}
        </div>
      </Container> */}

      <OurPlatform />
    </div>
  );
};

export default AboutUs;
