import React from "react";
import Styles from "./button.module.css";
import ButtonLoader from "./ButtonLoader";

// import { Oval } from "react-loader-spinner"
const ButtonComponent = ({
  type = "submit",
  className = "",
  style = {},
  onClickAction = () => {},
  isLoading = false,
  title,
  disabled = false,
  ...prop
}) => {
  return (
    <button
      type={type}
      className={`${
        Styles.button
      } d-flex justify-content-center py-2 ${className} ${
        isLoading ? "d-flex justify-content-center" : ""
      }`}
      style={{ ...style }}
      onClick={() => {
        onClickAction();
      }}
      disabled={disabled}
      {...prop}
    >
      {isLoading ? <ButtonLoader /> : title}
    </button>
  );
};

export default ButtonComponent;
