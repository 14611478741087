import { React, useState, useEffect } from "react";
import Styles from "./select.module.css";

const SelectComponent = ({
  id = "",
  className = "",
  inputClassName = "",
  style = {},
  options = [],
  placeholder,
  label = "",
  required = false,
  clearSelection = false,
  handleChange = (e) => {},
  handleBlur = () => {},
  errors = "",
  touched,
  value,
  ...prop
}) => {
  return (
    <>
      {label && (
        <div className="d-flex align-items-start">
          <label htmlFor={id} className={`${Styles.label}`}>
            {label}
          </label>
          {required && (
            <label htmlFor={id} className={`${Styles.star} mx-1`}>
              *
            </label>
          )}
        </div>
      )}
      <div className={` ${className} w-100 mb-3`}>
        <select
          className={`${Styles.input} ${className} ${inputClassName} py-2`}
          placeholder={placeholder}
          id={id}
          style={{
            ...style,
            borderColor: errors ? "#e93c3c" : "#C8C8C8",
          }}
          value={clearSelection ? "" : value}
          defaultValue={""}
          {...prop}
        >
          <option value={""} disabled hidden>
            {placeholder}
          </option>
          {options.map((item, index) => {
            return (
              <option
                key={item.value + " " + index}
                value={item.value}
                disabled={item.isDisabled || false}
              >
                {item.label}
              </option>
            );
          })}
        </select>
        {errors && <div className={Styles.error_msg}>{errors}</div>}
      </div>
    </>
  );
};

export default SelectComponent;
