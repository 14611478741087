import { Controller, useForm } from "react-hook-form";
import { Menu, MenuItem, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";

import ActionDots from "assets/images/action-dots.svg";
import ButtonComponent from "components/button-component";
import { ContactServices } from "apis/Services/ContactService";
import { Edit_Contact_Schema } from "schemas/contactsSchemas";
import GrowingAlert from "components/growing-alert";
import InputComponent from "components/input-component";
import SelectComponent from "components/select-component";
import { AutoComplete, Checkbox } from "antd";
import styles from "./contacts.module.css";
import { useSelector } from "react-redux";
import { useState } from "react";

const ContactRow = ({
  item,
  menuKey,
  refreshContacts = () => {},
  company_id,
  project_id,
  accessLevel,
  companiesList,
  companyAssignablePermissions,
  projectAssignablePermissions,
  checkedRows,
  setCheckedRows,
  toggleRowCheck,
  resendInvitations,
  deleteContacts,
  editContacts,
}) => {
  // const { companyPermissions, projectPermissions } = useSelector(
  //   (state) => state.permission
  // );

  // console.log(accessLevel, "accessLevel");
  const [isLoading, setisLoading] = useState(false);
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    values: {
      name: item?.["First Name"] + " " + item?.["Last Name"],
      Phone: item?.["Phone"],
      company: item?.["Company"],
      "Company Address": item?.["Company Address"],
      // "Email Address": item?.["Email Address"],
      "Company Permissions": item?.["Company Permissions"],
      "Project Permissions": item?.["Project Permissions"],
    },
    resolver: Edit_Contact_Schema,
  });

  const [EditMode, setEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //   actions' functions
  const HandleEditClicked = () => {
    handleClose();
    setEditMode(true);
  };

  const confirmEdit = (v) => {
    setisLoading(true);
    let firstName = v.name.split(" ")[0];
    let lastName = v.name.split(" ").slice(1).join(" ");

    let editDetails = [
      {
        firstName: firstName,
        lastName: lastName,
        inviteeEmail: item["Email Address"],
        inviteePhoneNumber: v["Phone"],
        inviteeCompany: v.company || item?.["Company"],
        inviteeCompanyAddress: v?.["Company Address"],
        inviteeCompanyPermission: v?.["Company Permissions"],
        inviteeProjectPermission: v?.["Project Permissions"],
      },
    ];

    editContacts(editDetails);
    setEditMode(false);
  };

  const handleDeleteClicked = () => {
    let inviteeEmail = [item["Email Address"]];

    deleteContacts(inviteeEmail);
    handleClose();
  };

  const resendInvitation = () => {
    let invitationDetails = [
      {
        firstName: item["First Name"],
        lastName: item?.["Last Name"],
        inviteeEmail: item?.["Email Address"],
        inviteePhoneNumber: item?.Phone,
        inviteeCompany: item?.Company,
        inviteeCompanyAddress: item?.["Company Address"],
        inviteeProjectPermission: item?.["Project Permissions"],
        inviteeCompanyPermission: item?.["Company Permissions"],
      },
    ];

    resendInvitations(invitationDetails);
    handleClose();
  };

  let email = localStorage.getItem("email");

  const contactData = (
    <TableRow>
      <TableCell width={50}>
        <Checkbox
          checked={checkedRows.includes(item?.["Email Address"])}
          onChange={() => toggleRowCheck(item)}
        />
      </TableCell>
      <TableCell>{item?.["First Name"] + " " + item?.["Last Name"]}</TableCell>
      <TableCell>{item?.["Email Address"]}</TableCell>
      <TableCell>{item?.Phone}</TableCell>
      <TableCell>{item?.Company}</TableCell>
      <TableCell>{item?.["Company Address"]}</TableCell>
      <TableCell>
        {item?.["Company Permissions"] == "Default"
          ? "Apply Permission Template Later"
          : item?.["Company Permissions"]}
      </TableCell>
      <TableCell>
        {item?.["Project Permissions"] == "Default"
          ? "Apply Permission Template Later"
          : item?.["Project Permissions"]}
      </TableCell>
      <TableCell>
        <GrowingAlert
          type={item.Status == "Not Invited" ? "pending" : item.Status}
          text={item.Status}
        />
      </TableCell>
      <TableCell>
        <ButtonComponent
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" + menuKey : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="bg-white"
          title={<img src={ActionDots} alt="dots" />}
        />

        <Menu
          id={"demo-positioned-menu" + menuKey}
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={
            accessLevel == 1 ||
            (item?.["Company Permissions"] == "Account Owner" &&
              email !== item?.["Email Address"]) ||
            (item?.["Project Permissions"] == "Account Owner" &&
              email !== item?.["Email Address"])
              ? null
              : open
          }
          // open={
          //   accessLevel == 1 ||
          //   item?.["Company Permissions"] == "Account Owner" ||
          //   item?.["Project Permissions"] == "Account Owner"
          //     ? null
          //     : open
          // }
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {accessLevel == 2 ? (
            <>
              {item.Status == "Not Invited" ? (
                <>
                  <MenuItem onClick={resendInvitation}>
                    Send Invitation
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={resendInvitation}>
                    Resend Invitation
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={HandleEditClicked}>Edit Contact Info</MenuItem>
            </>
          ) : (
            <>
              {item.Status == "Not Invited" ? (
                <>
                  <MenuItem onClick={resendInvitation}>
                    Send Invitation
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={resendInvitation}>
                    Resend Invitation
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={HandleEditClicked}>Edit Contact Info</MenuItem>
              <MenuItem onClick={handleDeleteClicked}>Delete Contact</MenuItem>
            </>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );

  return !EditMode ? (
    <>{contactData}</>
  ) : (
    <EditRow
      item={item}
      onsubmit={confirmEdit}
      handleEdit={() => setEditMode(false)}
      accessLevel={accessLevel}
      companiesList={companiesList}
      companyAssignablePermissions={companyAssignablePermissions}
      projectAssignablePermissions={projectAssignablePermissions}
    />
  );
};

const EditRow = ({
  item,
  onsubmit = () => {},
  handleEdit = () => {},
  accessLevel,
  companiesList,
  companyAssignablePermissions,
  projectAssignablePermissions,
}) => {
  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
  } = useForm({
    values: {
      name: item?.["First Name"] + " " + item?.["Last Name"],
      Phone: item?.["Phone"],
      company: item?.["Company"],
      "Company Address": item?.["Company Address"],
      // "Email Address": item?.["Email Address"],
      "Company Permissions": item?.["Company Permissions"],
      "Project Permissions": item?.["Project Permissions"],
    },
    mode: "all",
    // resolver: Edit_Contact_Schema,
  });

  const handleChange = (value) => {
    // console.log(`selected ${value}`);
    // console.log(companiesList);
  };
  // console.log(companiesList, "LIST?");

  const currentCompanyPermission = item?.["Company Permissions"];
  const currentProjectPermission = item?.["Project Permissions"];

  return (
    <TableRow>
      {/* <TableCell>{item?.companyID}</TableCell> */}
      <TableCell>{/* CHECK BOX PLACEMENT */}</TableCell>
      <TableCell>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <InputComponent
              label=" "
              // errors={errors.name}
              placeholder="Name"
              normalError={true}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>{item?.["Email Address"]}</TableCell>
      <TableCell>
        <Controller
          name="Phone"
          control={control}
          // rules={{ required: true }}
          render={({ field }) => (
            <InputComponent
              label=" "
              // errors={errors?.["Phone"]}
              placeholder="Phone"
              normalError={true}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name="company"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            // <InputComponent
            //   label=" "
            //   placeholder="company"
            //   normalError={true}
            //   {...field}
            // />
            <AutoComplete
              className="w-100"
              styles={{
                width: "300",
              }}
              size="large"
              optionFontSize="12"
              options={companiesList}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder="Company Name"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              onSelect={handleChange}
              {...field}
            />
          )}
        />
      </TableCell>
      {/* <TableCell>{item?.Company}</TableCell> */}
      <TableCell>
        <Controller
          name="Company Address"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <InputComponent
              label=""
              // errors={errors?.["Company Address"]}
              placeholder="Company Address"
              normalError={true}
              {...field}
            />
          )}
        />
      </TableCell>
      {/* <TableCell>{item?.["Company Address"]}</TableCell> */}
      <TableCell>
        <Controller
          name="Company Permissions"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectComponent
              label=" "
              // errors={errors?.["Company Permissions"]}
              placeholder="Company Permissions"
              disabled={accessLevel < "2"}
              {...field}
              options={[
                // Include the current permission value in the options if it's not already included
                ...(currentCompanyPermission
                  ? companyAssignablePermissions?.some(
                      (e) => e === currentCompanyPermission
                    )
                    ? [] // If currentCompanyPermission is already in the list, don't add it again
                    : [
                        {
                          label:
                            currentCompanyPermission === "Default"
                              ? "Apply Permission Template Later"
                              : currentCompanyPermission,
                          value: currentCompanyPermission,
                        },
                      ]
                  : []),
                ...(field.value === "Account Owner"
                  ? [{ label: "Account Owner", value: "Account Owner" }]
                  : companyAssignablePermissions?.map((e) => ({
                      label:
                        e === "Default" ? "Apply Permission Template Later" : e,
                      value: e,
                    }))),
              ]}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name="Project Permissions"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectComponent
              label=" "
              // errors={errors?.["Project Permissions"]}
              placeholder="Project Permissions"
              // disabled={field.value === "Account Owner"}
              options={[
                // Include the current permission value in the options if it's not already included
                ...(currentProjectPermission
                  ? projectAssignablePermissions?.some(
                      (e) => e === currentProjectPermission
                    )
                    ? [] // If currentProjectPermission is already in the list, don't add it again
                    : [
                        {
                          label:
                            currentProjectPermission === "Default"
                              ? "Apply Permission Template Later"
                              : currentProjectPermission,
                          value: currentProjectPermission,
                        },
                      ]
                  : []),
                ...(field.value === "Account Owner"
                  ? [{ label: "Account Owner", value: "Account Owner" }]
                  : projectAssignablePermissions?.map((e) => ({
                      label:
                        e === "Default" ? "Apply Permission Template Later" : e,
                      value: e,
                    }))),
              ]}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <GrowingAlert
          type={item.Status == "Not Invited" ? "pending" : item.Status}
          text={item.Status}
        />
      </TableCell>
      <TableCell>
        <div className="d-flex  w-100 flex-wrap">
          <ButtonComponent
            title={"Confirm"}
            className="px-2 mt-2"
            type="submit"
            disabled={!isValid || !isDirty}
            onClickAction={handleSubmit(onsubmit)}
          />
          <ButtonComponent
            title={"Cancel"}
            className={`${styles.danger} px-2 mt-2`}
            type=" "
            // disabled={isLoadin}
            onClickAction={handleEdit}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ContactRow;
