import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyPermissions: [],
  projectPermissions: [],
  userAccessLevel: null,
  companyAccessLevel: null,
  projectAccessLevel: null,
  reportAccessLevel: null,
};

export const PermissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    saveCompanyPermissions: (state, action) => {
      state.companyPermissions = action.payload;
    },
    saveProjectPermissions: (state, action) => {
      state.projectPermissions = action.payload;
    },
    saveCompanyAccessLevel: (state, action) => {
      state.companyAccessLevel = action.payload;
    },
    saveProjectAccessLevel: (state, action) => {
      state.projectAccessLevel = action.payload;
    },
    saveReportAccessLevel: (state, action) => {
      state.reportAccessLevel = action.payload;
    },
    saveUserAccessLevel: (state, action) => {
      state.userAccessLevel = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveCompanyPermissions,
  saveProjectPermissions,
  reloadProjects,
  saveCompanyAccessLevel,
  saveProjectAccessLevel,
  saveUserAccessLevel,
  saveReportAccessLevel,
} = PermissionSlice.actions;

export default PermissionSlice.reducer;
