import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ButtonComponent from "components/button-component";
import { Modal } from "antd";
import NotificationTable from "components/notification-table";
import SideMenu from "components/side-menu";
import Text from "components/text";
import bellIcon from "assets/images/bell-icon.png";
import logo from "assets/images/logo.svg";
import logoLanding from "assets/images/Koncurent-02.png";
import profileImg from "assets/images/person-image.png";
import styles from "./nav.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "apis/Services/AuthService";
import { setUnsavedChangesModal } from "redux/project-reducer";

const NavbarComponent = () => {
  const [IsExpanded, setIsExpanded] = useState(false);
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [shownTabs, setshownTabs] = useState([]);
  const [activeTab, setactiveTab] = useState({});
  const [autologin, setautologin] = useState(false);
  const { projectDetails } = useSelector((state) => state.projectDetails);
  const { unsavedChanges } = useSelector((state) => state.projectDetails);
  let projectName = projectDetails.projectInfo;
  let accessLevel = projectDetails?.usersInfo?.[0]?.accessLevel;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { company_id, id } = useParams();

  const checkUserAuth = () => {
    setautologin(false);
    const rememberMe = localStorage.getItem("remember");
    const loginEmail = localStorage.getItem("email");
    const refreshCode = localStorage.getItem("refreshToken");
    const company_id = localStorage.getItem("company_id");
    const token = localStorage.getItem("token");
    let data = {
      loginEmail,
      access_token: token,
      refresh_token: refreshCode,
      company_id,
    };
    if (token && rememberMe == "true") {
      AuthService.refreshToken(data)
        .then((res) => {
          localStorage.setItem("token", res.access_token);
          localStorage.setItem("refreshToken", res.refresh_token);
          localStorage.setItem("lastRequestTime", new Date().getTime());
          setautologin(true);
        })
        .catch((err) => {
          console.log(err, "errrrr");
        });
    } else {
      // console.log("Autologin Declined");
    }
  };

  const navTabs = {
    home: [
      // { id: 1, name: "Home", value: "home", to: "/home" },
      // { id: 2, name: "About Us", value: "about-us", to: "/about-us" },
      // {
      //   id: 3,
      //   name: "Support",
      //   value: "KoncurentSupport",
      //   to: "/KoncurentSupport",
      // },
    ],
    category: [
      {
        id: 7,
        name: "Company Admin",
        value: "company-admin",
        to: `/main/company/${
          location?.pathname?.split("/")?.[3]
        }/company-overview`,
      },
      {
        id: 6,
        name: "Company Reports",
        value: "company-reports",
        to: `/main/company/${
          location?.pathname?.split("/")?.[3]
        }/company-reports`,
      },
      {
        id: 5,
        name: "Projects",
        value: "projects",
        to: `/main/company/${location?.pathname?.split("/")?.[3]}/projects`,
      },
      {
        id: 4,
        name: "Permissions",
        value: "permission",
        to: `/main/company/${
          location?.pathname?.split("/")?.[3]
        }/user-permission`,
      },
    ],
    projectPages: [
      {
        id: 4,
        name: "Admin",
        value: "create-project",
        to: `/main/company/${
          location?.pathname?.split("/")?.[3]
        }/create-project/${location?.pathname?.split("/")?.[5]}`,
      },
      {
        id: 5,
        name: "Dashboard",
        value: "projects",
        to: `/main/company/${location?.pathname?.split("/")?.[3]}/projects/${
          location?.pathname?.split("/")?.[5]
        }`,
      },
      {
        id: 6,
        name: "Directory",
        value: "directory",
        to: `/main/company/${location?.pathname?.split("/")?.[3]}/projects/${
          location?.pathname?.split("/")?.[5]
        }/directory`,
      },
      {
        id: 7,
        name: "Reports",
        value: "reports",
        to: `/main/company/${location?.pathname?.split("/")?.[3]}/projects/${
          location?.pathname?.split("/")?.[5]
        }/canned-reports`,
      },
    ],
  };

  const handleNavTabs = () => {
    const tabs = [
      "company-admin",
      "company-reports",
      "company-high-lead-time",
      "company-subcontractor-health",
      "company-item-deficiencies",
      "company-design-team-review-time",
      "company-submittal-issuing-review",
      "company-subcontract-signing",
      "company-upcoming-deliveries",
      "company-planned-issue-date",
      "company-overview",
      "company-schedule",
      "company-dashboard",
      "projects",
      "permissions",
      "user-permission",
      "company-permission",
      "Project-permission",
    ];
    const category = location.pathname.split("/");
    if (location.pathname.includes("main")) {
      if (tabs.includes(category[category.length - 1])) {
        setshownTabs(navTabs.category);
      } else {
        setshownTabs(navTabs.projectPages);
      }
    } else if (location.pathname.includes("handle-auth-data")) {
      setshownTabs([]);
    } else {
      setshownTabs(navTabs.home);
      checkUserAuth();
    }
  };

  const handleActiveTab = () => {
    const findActive = shownTabs?.filter((item) =>
      location.pathname.includes(item?.value)
    );
    if (findActive.length) {
      if (findActive.length > 1) {
        setactiveTab(findActive[1]);
      } else {
        setactiveTab(findActive[0]);
      }
    }
  };

  useEffect(() => {
    handleActiveTab();
  }, [shownTabs]);

  useEffect(() => {
    handleNavTabs();
  }, [location.pathname]);

  const handleLinkClicked = (x) => {
    const url = localStorage.getItem("url");

    if (autologin && x == "/login") {
      window.location.href = url;
    } else {
      if (unsavedChanges) {
        dispatch(setUnsavedChangesModal(x));
      } else {
        navigate(x);
        setIsExpanded(false);
      }
    }
  };
  if (
    ["/login", "/forget-password", "/verify-code", "/resetPassword"].includes(
      location.pathname
    )
  ) {
    return null;
  }

  const handleLogoClick = () => {
    let path;
    let pathRegex =
      /^(\/about(\/.*)?|\/home(\/.*)?|\/about-us(\/.*)?|\/handle-auth-data(\/.*)?|\/KoncurentSupport(\/.*)?|\/contact-us(\/.*)?|\/resetPassword(\/.*)?|\/)$/.test(
        location.pathname
      );
    if (pathRegex) {
      path = "/";
    } else {
      path = `/main/company/${companyId}/projects`;
    }
    if (unsavedChanges) {
      dispatch(setUnsavedChangesModal(path));
      return;
    }
    navigate(path);
  };

  let project_id = location?.pathname?.split("/")?.[5];

  const companyId = localStorage.getItem("company_id");

  if (localStorage.getItem("token")) {
    let targetPath = "/";
  } else {
    let targetPath = `/main/company/${companyId}/projects`;
  }

  return (
    <>
      <Navbar
        expand="lg"
        className={`${styles.navbar} px-2 d-flex align-items-center ${
          location.pathname.includes("/main") ||
          location.pathname.includes("/handle-auth-data")
            ? `${styles.simpleShadow} bg-white`
            : "bg-black"
        }`}
        expanded={IsExpanded}
      >
        {/* logo */}
        <Navbar.Brand>
          {unsavedChanges ? (
            <div className="pointer" onClick={handleLogoClick}>
              {/* <Link onClick={handleLogoClick}> */}
              <img src={logo} alt="koncurent-logo" />
              {/* </Link> */}
            </div>
          ) : (
            <>
              <Link
                to={
                  localStorage.getItem("token")
                    ? `/main/company/${companyId}/projects`
                    : "/"
                }
              >
                {location.pathname.includes("/main") ||
                location.pathname.includes("/handle-auth-data") ? (
                  <img src={logo} alt="koncurent-logo" />
                ) : (
                  <img src={logoLanding} width={180} alt="koncurent-logo" />
                )}
              </Link>
            </>
          )}
        </Navbar.Brand>
        <Navbar.Toggle
          className={`${
            !location.pathname.includes("/main")
              ? `${styles.navbarToggleCustom}`
              : ""
          }`}
          aria-controls="basic-navbar-nav"
          onClick={() => setIsExpanded(!IsExpanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`d-flex  justify-content-between w-100`}>
            <div className="d-flex gap-2 flex-lg-row flex-column">
              {shownTabs.map((item, index) => (
                <Link
                  key={"navLink" + item.value + index}
                  to={item.to}
                  className={`
                    mx-1
                    ${
                      location.pathname.includes("/main")
                        ? `${styles.navLink}`
                        : `${styles.navLinkLanding}`
                    }
                    ${
                      activeTab.value == item.value &&
                      location.pathname.includes(activeTab.value) &&
                      `${
                        location.pathname.includes("/main")
                          ? `${styles.activeLink}`
                          : `${styles.activeLinkLanding}`
                      }`
                    }
                  `}
                  onClick={() => handleLinkClicked(item.to)}
                >
                  {item.name}
                </Link>
              ))}
            </div>

            {location.pathname.includes("/main") ? (
              <LoginContent
                openSideMenu={setIsSideMenuVisible}
                accessLevel={accessLevel}
              />
            ) : location.pathname.includes("handle-auth-data") ||
              location.pathname.includes("registering-new-client") ? (
              <></>
            ) : (
              <div
                className={`d-flex align-items-center ${styles.actionsContainer} `}
              >
                <ButtonComponent
                  title={"Login"}
                  className={`${styles.btn}`}
                  onClickAction={() => handleLinkClicked("/login")}
                />
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* SIDE MENU */}
      {isSideMenuVisible && (
        <SideMenu
          show={isSideMenuVisible}
          setShow={setIsSideMenuVisible}
          isRight={true}
          title={projectName}
          isSmall={false}
        >
          <NotificationTable company_id={companyId} project_id={project_id} />
        </SideMenu>
      )}
    </>
  );
};

export default NavbarComponent;

const LoginContent = ({ openSideMenu, accessLevel }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const openNotificationSideModal = () => {
    openSideMenu(true);
  };

  const [names, setNames] = useState([]);
  const [firstCharacters, setFirstCharacters] = useState([]);

  useEffect(() => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    const company_id = localStorage.getItem("company_id");
    let data = {
      company_id: company_id,
      loginEmail,
      code,
    };
    // if (!names.length) {
    ActiveUsersService.UserInfo(data)
      .then((res) => {
        // console.log(res, "res");
        setNames(res);
        setFirstCharacters(res.firstName[0] + res.lastName[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const [shouldBellshow, setshouldBellshow] = useState(false);
  const handleShowBell = () => {
    const path = location.pathname;
    const isNan = isNaN(parseFloat(path.split("/").at(-1)));
    if (path.includes("projects") && !isNan) {
      setshouldBellshow(true);
    } else {
      setshouldBellshow(false);
    }
  };
  useEffect(() => {
    handleShowBell();
  }, [location.pathname]);

  return (
    <div className="d-flex align-items-center gap-3 px-2">
      {shouldBellshow && (
        <img
          src={bellIcon}
          alt="bell-icon"
          style={{
            cursor:
              accessLevel < 3 || accessLevel == undefined
                ? "not-allowed"
                : "pointer",
          }}
          onClick={
            accessLevel < 3 || accessLevel == undefined
              ? null
              : openNotificationSideModal
          }
        />
      )}

      <div onClick={showModal} className={`${styles.letterContainer}`}>
        <Text className={`${styles.firstLetters}`}>{firstCharacters}</Text>
      </div>

      <Modal
        // title="Basic Modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        // centered={true}
      >
        <div className="d-flex flex-column mt-4 justify-content-start">
          <div className="mb-4">
            <div className="d-flex align-items-center justify-content-center my-2">
              <Text className="mx-2">{`${names.firstName} ${names.lastName}`}</Text>
            </div>
            <div className="d-flex align-items-center justify-content-center my-2">
              <h6 className="mx-2">{names.loginEmail}</h6>
            </div>
          </div>
          <div className="w-100 text-center d-flex justify-content-center">
            <ButtonComponent
              title={"Logout"}
              style={{ paddingInline: 25, width: "30%" }}
              onClickAction={handleLogout}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
