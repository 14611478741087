export const SettingsEndPoints = {
  getProcurementLogSettings: {
    url: (company, project) =>
      `/rest/v1/getProcurementLogSettings/${company}/${project}`,
    method: `GET`,
  },

  setProcurementLogSettings: {
    url: (company, project) =>
      `/rest/v1/setProcurementLogSettings/${company}/${project}`,
    method: "POST",
  },

  updateProcurementLogSettings: {
    url: (company, project) =>
      `/rest/v1/updateProcurementLogSettings/${company}/${project}`,
    method: "PUT",
  },
};
