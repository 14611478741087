import React, { memo, useEffect } from "react";

import OnlyDesktop from "components/multi-view-wrapper/OnlyDesktop";
import OnlyMobile from "components/multi-view-wrapper/OnlyMobile";
import ProjectRow from "./project-row";
import Text from "components/text";
import { projectsAccessLevel } from "redux/project-reducer";
import styles from "./table.module.css";
import { useDispatch } from "react-redux";

const ProjectTable = ({ data = [] }) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(projectsAccessLevel(data?.accessLevel));
  // }, []);
  return (
    <div className={`${styles.tableContainer} mt-2 px-2`}>
      <table className={`${styles.table} table`}>
        <thead className={`${styles.tableHead}`}>
          <tr>
            <th scope="col">
              <Text className={`${styles.tableHead}`}>Project Number</Text>
            </th>
            <th scope="col">
              <Text className={`${styles.tableHead}`}>Project Name</Text>
            </th>
            <th scope="col">
              <Text className={`${styles.tableHead}`}>Project Type</Text>
            </th>
            <th scope="col">
              <Text className={`${styles.tableHead}`}>Start Date</Text>
            </th>
            <th scope="col">
              <Text className={`${styles.tableHead}`}>End Date</Text>
            </th>
            <th scope="col">
              <OnlyDesktop>
                <Text className={`${styles.tableHead} ${styles.mediumCells}`}>
                  Project Progress
                </Text>
              </OnlyDesktop>
              <OnlyMobile>
                <Text className={`${styles.tableHead} ${styles.bigCells} `}>
                  Project Progress
                </Text>
              </OnlyMobile>
            </th>
            <th scope="col" colSpan={2}>
              <OnlyDesktop>
                <Text className={`${styles.tableHead} ${styles.mediumCells}`}>
                  Alerting
                </Text>
              </OnlyDesktop>
              <OnlyMobile>
                <Text className={`${styles.tableHead} ${styles.bigCells}`}>
                  Alerting
                </Text>
              </OnlyMobile>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data?.map((project, index) => (
              <ProjectRow
                project={{ ...project, index }}
                key={"projectTable" + index}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(ProjectTable);
