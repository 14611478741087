import { DeletedItemsService } from "apis/Services/DeletedItemsService";
import Container from "components/container";
import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./deleted.module.css";
import LoadingComponent from "components/loading-component";
import { toast } from "react-hot-toast";
import { Alert, Space, Button, Table, Dropdown, Modal, Input } from "antd";
import {
  DownOutlined,
  SyncOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Text from "components/text";

const { confirm } = Modal;

function DeletedItems() {
  const { company_id, id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [isTableLoading, setisTableLoading] = useState(false);
  const [accessLevel, setAccessLevel] = useState();
  const [data, setData] = useState();
  const [projectName, setProjectName] = useState();
  const [viewName, setViewName] = useState();
  const [alignedData, setAlignedData] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  function RestoreItems(selectedRowKeys) {
    setisTableLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company_id: company_id,
      id: id,
      code,
      loginEmail: email,
      entryID: selectedRowKeys,
    };
    DeletedItemsService.restoreProcurementLogDeletedEntries(ReqObj)
      .then((res) => {
        getDeletedItems();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Failed to restore the selected item(s). Please try again later."
        );
        setisTableLoading(false);
      })
      .finally(() => {});
  }

  function DeleteItems(selectedRowKeys) {
    setisTableLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company_id: company_id,
      id: id,
      code,
      loginEmail: email,
      entryID: selectedRowKeys,
    };
    DeletedItemsService.deleteProcurementLogDeletedEntries(ReqObj)
      .then((res) => {
        getDeletedItems();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Failed to delete the selected item(s). Please try again later."
        );
        setisTableLoading(false);
      })
      .finally(() => {
        // setisTableLoading(false);
      });
  }

  function getDeletedItems() {
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company_id: company_id,
      id: id,
      code,
      loginEmail: email,
    };
    DeletedItemsService.getProcurementLogDeletedEntries(ReqObj)
      .then((res) => {
        setAccessLevel(parseInt(res.accessLevel));
        setData(res.content);
        setProjectName(res.projectName);
        setViewName(res.viewsInfo?.lastUsedView);
        setAlignedData(res.viewsInfo?.alignedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setisLoading(false);
        setisTableLoading(false);
      });
  }

  useEffect(() => {
    setisLoading(true);
    getDeletedItems();
  }, []);

  // columns to ignore because we will add manually
  const columnsToIgnore = [
    "specNumber",
    "specTitle",
    "specRevision",
    "deletedTimestamp",
    "daysTillPermanentDeletion",
  ];

  // Filter and map the data
  let columns = Object.keys(alignedData)
    .filter(
      (key) =>
        alignedData[key].hidden === "false" && !columnsToIgnore.includes(key)
    )
    .sort((a, b) => alignedData[a].index - alignedData[b].index)
    .map((key) => ({
      title: alignedData[key].label,
      dataIndex: key,
      align: "center",
      width: 150,
    }));

  columns.unshift(
    {
      title: "Item Name",
      dataIndex: "specTitle",
      align: "left",
      width: 200,
      fixed: "left",
    },
    {
      title: "Submittal Number",
      dataIndex: "submittalNumber",
      align: "center",
      width: 150,
      fixed: "left",
    },
    {
      title: "Submittal Revison",
      dataIndex: "submittalRevison",
      align: "center",
      width: 150,
      fixed: "left",
    }
  );

  columns.push(
    {
      title: "Deleted Timestamp",
      dataIndex: "deletedTimestamp",
      align: "center",
      width: 150,
    },
    {
      title: "Days Till Deletion",
      dataIndex: "daysTillPermanentDeletion",
      align: "center",
      width: 150,
    }
  );

  let dataSource = [];

  if (!isLoading) {
    data.forEach((item) => {
      dataSource = [
        ...dataSource,
        {
          key: `${item?.entryID}`,
          specTitle: `${item?.specTitle}`,
          submittalNumber: `${item?.specNumber}`,
          submittalRevison: `${item?.specRevision}`,
          specType: `${item?.specType}`,
          submittalResponse: `${item?.specResponse}`,
          responsibleContractor: `${item?.responsibleContractor}`,
          issueContract: `${item?.issueContract}`,
          contractIssuedRelease: `${item?.contractIssuedRelease}`,
          reviewTime: `${item?.submittalPrepTime}`,
          submittalRequired: `${item?.submittalRequired}`,
          submittalIssued: `${item?.submittalIssued}`,
          approvalTime: `${item?.approvalTime}`,
          submittalApprovalDue: `${item?.submittalApprovalDue}`,
          submittalReturned: `${item?.submittalReturned}`,
          leadTime: `${item?.leadTime}`,
          scheduleID: `${item?.scheduleID}`,
          dateRequiredonSite: `${item?.dateRequiredOnSite}`,
          projectedDeliveryDate: `${item?.projectedDeliveryDate}`,
          subCommittedDeliveryDate: `${item?.subCommittedDeliveryDate}`,
          actualDeliveryDate: `${item?.actualDeliveryDate}`,
          deletedTimestamp: `${item?.deletedTimestamp}`,
          custom1: `${item?.custom1 || ""}`,
          custom2: `${item?.custom2 || ""}`,
          custom3: `${item?.custom3 || ""}`,
          custom4: `${item?.custom4 || ""}`,
          custom5: `${item?.custom5 || ""}`,
          custom6: `${item?.custom6 || ""}`,
          custom7: `${item?.custom7 || ""}`,
          custom8: `${item?.custom8 || ""}`,
          custom9: `${item?.custom9 || ""}`,
          custom10: `${item?.custom10 || ""}`,
          custom11: `${item?.custom11 || ""}`,
          custom12: `${item?.custom12 || ""}`,
          custom13: `${item?.custom13 || ""}`,
          custom14: `${item?.custom14 || ""}`,
          custom15: `${item?.custom15 || ""}`,
          custom16: `${item?.custom16 || ""}`,
          custom17: `${item?.custom17 || ""}`,
          custom18: `${item?.custom18 || ""}`,
          custom19: `${item?.custom19 || ""}`,
          custom20: `${item?.custom20 || ""}`,
          custom21: `${item?.custom21 || ""}`,
          custom22: `${item?.custom22 || ""}`,
          custom23: `${item?.custom23 || ""}`,
          custom24: `${item?.custom24 || ""}`,
          custom25: `${item?.custom25 || ""}`,
          daysTillPermanentDeletion: `${item?.daysTillPermanentDeletion}`,
        },
      ];
    });
  }

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure you want to delete the selected item(s)?",
      icon: <ExclamationCircleFilled />,
      content: (
        <div style={{ fontSize: "16px" }}>
          <p>
            This action is irreversible and the item(s) will be permanently
            deleted.
          </p>
        </div>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      width: 600,
      onOk() {
        DeleteItems(selectedRowKeys);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  function handleMenuClick(e) {
    if (e.key === "1") {
      //RESTORE
      RestoreItems(selectedRowKeys);
    }
    if (e.key === "2") {
      //DELETE
      showDeleteConfirm();
    }
  }

  const items = [
    {
      label: "Restore selected item(s)",
      key: "1",
      icon: <SyncOutlined />,
      action: "restore",
    },
    {
      label: "Delete selected item(s)",
      key: "2",
      icon: <DeleteOutlined />,
      action: "delete",
      disabled: accessLevel < 3,
      danger: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = dataSource.filter(
    (item) =>
      item.specTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.submittalNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container className="mt-3">
      {isLoading ? (
        <LoadingComponent />
      ) : accessLevel !== 3 ? (
        <div style={{ textAlign: "center" }}>
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : (
        <>
          <Space
            direction="vertical"
            style={{ width: "100%", marginTop: "5px" }}
          >
            <Alert
              message="Important Notice"
              description="The table below displays previously deleted items from the dashboard. Please note that all deleted items will be automatically deleted in 30 days. It is recommended to review and restore any necessary items before the automatic deletion occurs."
              type="info"
              showIcon
            />
          </Space>

          <div className=" bg-white rounded-4 p-3 my-4">
            <div className=" d-flex flex-row justify-content-between mb-3">
              <div>
                <Text className={`${styles.header}`}>{projectName}</Text>
                <Text className=" fs-6">View Name: {viewName}</Text>
              </div>
              <div></div>
            </div>

            <div className=" d-flex justify-content-between">
              <Input
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ width: "200px" }}
                suffix={<SearchOutlined style={{ opacity: "50%" }} />}
              />

              <div>
                <Dropdown
                  menu={menuProps}
                  disabled={!hasSelected}
                  placement="bottomLeft"
                >
                  <Button className={`${styles.antBtn}`}>
                    Actions
                    <DownOutlined style={{ fontSize: "16px" }} />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="row">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              // dataSource={dataSource}
              dataSource={filteredData}
              pagination={{
                position: ["bottomCenter"],
                defaultPageSize: 20,
              }}
              size="small"
              loading={
                isTableLoading ? { indicator: <LoadingComponent /> } : false
              }
              scroll={{ x: 500, y: "70vh" }}
            />
          </div>
        </>
      )}
    </Container>
  );
}

export default DeletedItems;
