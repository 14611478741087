export const AuthEndPoints = {
  login: {
    url: `/rest/v1/login`,
    method: "POST",
  },
  sendVerificationCode: {
    url: "/rest/v1/troubleLoggingIn",
    method: "POST",
  },
  resetPssword: {
    url: "/rest/v1/resetPassword",
    method: "POST",
  },

  refreshTokenCode: {
    url: (company) => `/rest/v1/refreshToken/${company}`,
    method: `POST`,
  },
  requestDemo:{
    url:"/rest/v1/requestDemo",
    method:"POST"
  }
};
