import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";

import { AuthService } from "apis/Services/AuthService";
import ButtonComponent from "components/button-component";
import Container from "components/container";
import ContentContainer from "components/content-container";
import FileInput from "components/file-input";
import InputComponent from "components/input-component";
import OurPlatform from "components/our-platform";
import PageHeader from "components/page-header";
import SelectComponent from "components/select-component";
import Text from "components/text";
import contactUsImg from "assets/images/contact-us-image.png";
import styles from "./contact.module.css";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router";

import instagramLogo from "assets/images/instagram-logo.svg";
import linkedInLogo from "assets/images/linkedin-logo.svg";
import youtubeLogo from "assets/images/youtube-icon.svg";
import HeroImage from "assets/images/contact-us.svg";

const ContactUs = () => {
  // const head = `Letter from Procore Founder, President, and CEO Tooey Courtemanche`;
  // const subHead = `God. Creepeth wherein kind night it seed green third thing living god you may lesser man let creeping you're saw for. Place above whales. Void sea void. Over together. Tree fifth. Lesser creature second good.
  // `;
  const [loader, setloader] = useState(false);
  const location = useLocation();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
  });
  const requestText = `Please enter the details of your request. A member of our support staff will respond as soon as possible.`;

  const handleRequest = (values) => {
    setloader(true);
    AuthService.requestDemo(values)
      .then((res) => {
        toast.success("The request has been sent successfuly!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      })
      .finally(() => {
        reset({ firstName: "", lastName: "", emailAddress: "", text: "" });
        setloader(false);
      });
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className=" bg-black">
      {/* <PageHeader pageTitle="Contact Us" imageUrl={contactUsImg} /> */}

      {/* <div className=" p-5">
        <h3 className=" display-3 fw-bold text-white pt-5">CONTACT US</h3>
      </div> */}

      <div
        className={`bg-black d-flex flex-column flex-lg-row-reverse ${styles.container}`}
      >
        <div className={` d-lg-flex flex-lg-column ${styles.contactSection}`}>
          <div className=" d-lg-flex flex-lg-column align-items-start p-5">
            <h3 className="display-4 fw-bold text-white">CONTACT US</h3>
            <p className="text-white d-lg-flex opacity-75 w-75">
              We value your feedback and inquiries. Whether you have a question,
              need support, or want to learn more about our services, please
              don't hesitate to reach out. Our team is here to assist you and
              will respond to your request as soon as possible.
            </p>
          </div>
          <div className=" d-flex justify-content-center h-100">
            {/* <img
              src={HeroImage}
              className={`${styles.responsiveHero} d-none d-lg-block`}
              alt="koncurent-logo"
            /> */}
          </div>

          <div className=" d-flex flex-row w-100 px-5 mt-lg-5">
            <div className=" d-flex justify-content-center align-items-end me-4">
              <h5 className=" fw-bold text-white">GET SOCIAL</h5>
            </div>
            <div className=" d-flex justify-content-center align-items-center gap-2">
              <a
                href="https://www.linkedin.com/company/koncurent"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedInLogo} width={50} alt="Linkedin-logo" />
              </a>

              <a
                href="https://www.instagram.com/koncurenttech/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagramLogo} width={35} alt="Instagram-logo" />
              </a>

              <a
                href="https://www.youtube.com/watch?v=FcNVZG3kpbc"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={youtubeLogo}
                  width={50}
                  className=" ms-2"
                  alt="Youtube-logo"
                />
              </a>
            </div>
          </div>

          <div className=" d-flex justify-content-start px-5 text-white">
            <footer>
              <p className="opacity-50">© {currentYear} All rights reserved.</p>
            </footer>
          </div>
        </div>
        <div className={`bg-white p-3 p-lg-0 w-100 ${styles.container}`}>
          {/* <div className="text-center">
            <Text className={`${styles.header}`} dontWrap={false}>
              {head}
            </Text>
            <Text className={`${styles.subHeader} my-2`} dontWrap={false}>
              {subHead}
            </Text>
          </div> */}
          <div className="row justify-content-center mt-5">
            <div className="col-md-6">
              <Text className={`${styles.header}`}>Your Request</Text>
            </div>
          </div>
          <form
            className="pb-4 row justify-content-center pt-2"
            onSubmit={handleSubmit(handleRequest)}
          >
            <div className="d-flex flex-column col-md-6 gap-3 my-3">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field, fieldState }) => (
                  <div>
                    <InputComponent
                      label="First Name"
                      required
                      className=" border border-2 border-dark rounded-0 p-3"
                      placeholder="Enter First Name"
                      errors={errors?.firstName}
                      {...field}
                      {...fieldState}
                    />
                  </div>
                )}
              />
              {errors && errors?.firstName && (
                <Text fontSize={14} color={"red"}>
                  {errors.firstName.message}
                </Text>
              )}
              <Controller
                name="lastName"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <InputComponent
                    className=" border border-2 border-dark rounded-0 p-3"
                    label="Last Name"
                    required
                    placeholder="Enter Last Name"
                    // errors={errors.name}
                    {...field}
                  />
                )}
              />
              {errors && errors?.lastName && (
                <Text fontSize={14} color={"red"}>
                  {errors.lastName.message}
                </Text>
              )}
              <Controller
                name="emailAddress"
                control={control}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <InputComponent
                    className=" border border-2 border-dark rounded-0 p-3"
                    label="E-mail"
                    required
                    placeholder="Enter E-mail"
                    errors={errors?.["Email Address"]}
                    {...field}
                  />
                )}
              />
              {errors && errors?.["Email Address"] && (
                <Text fontSize={14} color={"red"}>
                  {errors["Email Address"].message}
                </Text>
              )}
              <Controller
                name="text"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <InputComponent
                    className=" border border-2 border-dark rounded-0 p-3"
                    label="How can we help?"
                    required
                    textarea
                    inputClassName={`${styles.textarea}`}
                    placeholder="How can we help?"
                    {...field}
                  />
                )}
              />
              <div className="justify-content-start">
                <div className="col-md-4">
                  <ButtonComponent
                    title={"Submit"}
                    disabled={!isValid || loader}
                    className={`bg-black text-white rounded-0 ${styles.buttonStyling}`}
                    isLoading={loader}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {location.pathname.includes("/contact-us") ? null : (
        <OurPlatform></OurPlatform>
      )}
    </div>
  );
};

export default ContactUs;
