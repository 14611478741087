import AuthReducer from "./auth-reducer";
import { configureStore } from "@reduxjs/toolkit";
import permissionReducer from "./permission-reducer";
import projectReducer from "./project-reducer";

export const store = configureStore({
  reducer: {
    permission: permissionReducer,
    projectDetails: projectReducer,
    Auth: AuthReducer,
  },
});
