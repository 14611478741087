import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "apis/Services/AuthService";
import AuthWrapper from "components/auth-page-wrapper";
import ButtonComponent from "components/button-component";
import InputComponent from "components/input-component";
import React, { useEffect } from "react";
import Text from "components/text";
import styles from "./login.module.css";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const navigate = useNavigate();
  const [loginError, setloginError] = useState(null);
  const [showPassword, setshowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderLogin, setrenderLogin] = useState(false);
  const [redirectionURL, setredirectionURL] = useState(null);
  let schema = yup.object().shape({
    loginEmail: yup
      .string()
      .required("This field is required")
      .email("Please enter a valid email"),
    loginPassword: yup.string().required("This field is required"),
  });

  useEffect(() => {
    console.count("Counter");
    const email = localStorage.getItem("email");
    const url = localStorage.getItem("url");
    const lastLoggedDate = localStorage.getItem("lastRequestTime");
    if (email && (url || redirectionURL) && lastLoggedDate && renderLogin) {
      window.location.replace(url || redirectionURL);
    } else {
      console.log("Error on UseEffect");
    }
  }, [renderLogin]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      loginEmail: "",
      loginPassword: "",
      remember: false,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (v) => {
    await AuthService.post_login(v)
      .then((res) => {
        setLoading(true);
        localStorage.setItem("email", v.loginEmail);
        localStorage.setItem("lastRequestTime", new Date().getTime());
        setredirectionURL(res.url);
        if (v.remember) {
          localStorage.setItem("url", res.url);
          localStorage.setItem("remember", "true");
        } else {
          localStorage.removeItem("remember");
        }
        setrenderLogin(true);
        // setTimeout(() => {
        //   window.location.replace(res.url);
        // }, 500);
      })
      .catch((err) => {
        console.log(err, "err");
        setloginError(true);
        setLoading(false);
      });
  };

  // setTimeout(() => {
  //   setLoading(false);
  // }, 900);

  const switchShowPassword = () => {
    setshowPassword(!showPassword);
  };

  return (
    <AuthWrapper>
      {/* <Spin indicator={indicator} spinning={loader}> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize={38} className="mb-4 fw-bold text-wrap">
          Welcome to Koncurent
        </Text>
        <div className="d-flex flex-column mt-3">
          <Controller
            name="loginEmail"
            control={control}
            render={({ field }) => (
              <InputComponent
                label="Email"
                errors={errors.loginEmail}
                placeholder="Enter your email"
                {...field}
              />
            )}
          />
          {errors?.loginEmail && (
            <Text className={styles.errorText}>
              {errors.loginEmail.message}
            </Text>
          )}
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <Controller
                name="loginPassword"
                control={control}
                render={({ field }) => (
                  <InputComponent
                    label="Password"
                    errors={errors.loginPassword}
                    className={"my-2"}
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="pointer p-3 pt-4 mt-3" onClick={switchShowPassword}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </div>
          {errors?.loginPassword && (
            <Text className={styles.errorText}>
              {errors.loginPassword.message}
            </Text>
          )}

          <div className="d-flex justify-content-between w-100 flex-wrap flex-md-nowrap my-2 ">
            <div className="d-flex align-items-center">
              <Controller
                name="remember"
                control={control}
                render={({ field }) => (
                  <InputComponent
                    type="checkbox"
                    className="checkbox"
                    {...field}
                  />
                )}
              />
              <Text className={`${styles.remember} mx-1`}>Remember Me</Text>
            </div>
            <div className="d-flex  gap-1">
              <Text className={`${styles.forgetPass}`}>
                Forgot your password
              </Text>
              <Text
                className={`${styles.link} pointer`}
                onClickAction={() => navigate("/forget-password")}
              >
                Click here.
              </Text>
            </div>
          </div>
          {loginError && (
            <Text className={styles.errorText}>
              You have entered an invalid username or password
            </Text>
          )}
          <ButtonComponent
            isLoading={loading}
            title={"Login"}
            className={`mt-3`}
            disabled={loading}
          />
        </div>
      </form>
      {/* </Spin> */}
    </AuthWrapper>
  );
};

export default Login;
