import React from "react";
import ReactLoading from "react-loading";

const ButtonLoader = () => {
  return (
    <ReactLoading
      type={"spin"}
      color={"var(--clr-current)"}
      height={25}
      width={25}
    />
  );
};

export default React.memo(ButtonLoader);
