import { Network } from "../Network";
import { PermissionsEndPoints } from "apis/EndPoints/PermissionsEndPoints";

export class PermissionServices {
  static getProjectPermissionsTemplate(data = {}) {
    return Network.fetch(
      PermissionsEndPoints.getProjectPermissions.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: PermissionsEndPoints.getProjectPermissions.method,
      }
    );
  }

  static setProjectPermissionsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.setProjectPermissions.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        body: JSON.stringify(data),
        method: PermissionsEndPoints.setProjectPermissions.method,
      }
    );
  }
  static deleteProjectPermissionsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.deleteProjectPermissions.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        body: JSON.stringify(data),
        method: PermissionsEndPoints.deleteProjectPermissions.method,
      }
    );
  }
  static updateProjectPermissionsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.updateProjectPermissions.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        body: JSON.stringify(data),
        method: PermissionsEndPoints.updateProjectPermissions.method,
      }
    );
  }

  static getCompanyPermissionsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.getCompanyPermissions.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: PermissionsEndPoints.getCompanyPermissions.method,
      }
    );
  }

  static setCompanyPermissionsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.setCompanyPermissions.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        body: JSON.stringify(data),
        method: PermissionsEndPoints.setCompanyPermissions.method,
      }
    );
  }

  static deleteCompanyPermissionsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.deleteCompanyPermissions.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        body: JSON.stringify(data),
        method: PermissionsEndPoints.deleteCompanyPermissions.method,
      }
    );
  }

  static updateCompanyPermissionsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.updateCompanyPermissions.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        body: JSON.stringify(data),
        method: PermissionsEndPoints.updateCompanyPermissions.method,
      }
    );
  }

  static readCompanyContactsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.readCompanyContacts.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: PermissionsEndPoints.readCompanyContacts.method,
      }
    );
  }
  static removeCompanyContactsTemplate(data) {
    return Network.fetch(
      PermissionsEndPoints.removeCompanyContacts.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        body: JSON.stringify(data),
        method: PermissionsEndPoints.removeCompanyContacts.method,
      }
    );
  }
  static editUserPermission(data, company_id) {
    return Network.fetch(
      PermissionsEndPoints.editUserPermission.url(company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        body: JSON.stringify(data),
        method: PermissionsEndPoints.editUserPermission.method,
      }
    );
  }
}
