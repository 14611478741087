export const DeletedItemsEndPoints = {
  getProcurementLogDeletedEntries: {
    url: (company, project) =>
      `/rest/v1/getProcurementLogDeletedEntries/${company}/${project}`,
    method: `GET`,
  },

  restoreProcurementLogDeletedEntries: {
    url: (company, project) =>
      `/rest/v1/restoreProcurementLogDeletedEntries/${company}/${project}`,
    method: "POST",
  },

  deleteProcurementLogDeletedEntries: {
    url: (company, project) =>
      `/rest/v1/deleteProcurementLogDeletedEntries/${company}/${project}`,
    method: "DELETE",
  },
};
