import React, { useState } from "react";
import { Modal } from "antd";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Select, Slider, Tooltip } from "antd";
import ButtonComponent from "components/button-component";
import Container from "components/container";

const ReportFilters = ({
  pageName,
  returnedData,
  maxLeadTime,
  subcontractorFilterList,
  dropDownResponsesList,
  editedData,
}) => {
  const { control, handleSubmit, formState, reset } = useForm();
  const watchFilteredColumns = useWatch({
    control,
    name: "filteredColumns",
    defaultValue: [],
  });

  const ApplyFilters = (v) => {
    if (!v.filteredColumns) {
      v.filteredColumns = [];
    }
    if (!v.filteredResponses) {
      v.filteredResponses = [];
    }
    if (!v.filteredCompanies) {
      v.filteredCompanies = "";
    }
    if (!v.sortBy) {
      if (
        pageName === "itemDeficienciesReport" ||
        pageName === "highLeadTimeReport" ||
        pageName === "companyItemDeficienciesReport" ||
        pageName === "companyHighLeadTimeReport"
      ) {
        v.sortBy = "Descending";
      } else {
        v.sortBy = "Ascending";
      }
    }
    if (!v.range) {
      v.range = [];
    }
    if (!v.status) {
      v.status = "";
    }
    if (!v.filterCombination) {
      v.filterCombination = "and";
    }
    returnedData(v);
  };

  function handleChange(v) {
    // return console.log("test");
  }

  const [showModal, setShowModal] = useState(false);

  const handleResetFilters = (v) => {
    if (editedData.length > 0) {
      setShowModal(true);
    } else {
      reset();
      handleSubmit(ApplyFilters)();
    }
  };

  const handleSubmitFilters = () => {
    if (editedData.length > 0) {
      setShowModal(true);
    } else {
      handleSubmit(ApplyFilters)();
    }
  };
  const handleConfirm = () => {
    setShowModal(false);
    reset();
    handleSubmit(ApplyFilters)();
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <div className="row mb-4 mt-4 py-2 mx-0 bg-white rounded-3">
        <div className="col-sm-12 col-md-12">
          <form className="col-12 w-100" onSubmit={handleSubmit(ApplyFilters)}>
            <div className="row justify-content-lg-start justify-content-md-center gap-2">
              <div className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center">
                <Controller
                  name="sortBy"
                  control={control}
                  render={({ field }) => (
                    <div className="d-flex align-items-center gap-sm-1 gap-md-4 gap-lg-4 gap-xl-1 w-100">
                      <label className="w-25 text-nowrap">Sort by:</label>
                      <Tooltip
                        title={
                          pageName === "highLeadTimeReport" ||
                          pageName === "companyHighLeadTimeReport"
                            ? "Based on the 'Lead Time' column"
                            : pageName === "dateRequiredOnSiteReport" ||
                              pageName === "companyDateRequiredOnSiteReport"
                            ? "Based on the 'Days Till Required' column"
                            : pageName === "plannedIssueDateReport" ||
                              pageName === "companyPlannedIssueDateReport"
                            ? "Based on the 'Submittal Number' column"
                            : "Based on the 'Notes' column"
                        }
                      >
                        <Select
                          className="w-100"
                          defaultValue={
                            pageName === "itemDeficienciesReport" ||
                            pageName === "highLeadTimeReport" ||
                            pageName === "companyItemDeficienciesReport" ||
                            pageName === "companyHighLeadTimeReport"
                              ? "Descending"
                              : "Ascending"
                          }
                          style={{
                            width: "100%",
                          }}
                          onChange={handleChange}
                          options={[
                            {
                              value: "Ascending",
                              label: "Ascending",
                            },
                            {
                              value: "Descending",
                              label: "Descending",
                            },
                          ]}
                          {...field}
                        />
                      </Tooltip>
                    </div>
                  )}
                />
              </div>

              {pageName === "subcontractorHealthReport" ||
              pageName === "companySubcontractorHealthReport" ||
              pageName === "plannedIssueDateReport" ||
              pageName === "companyPlannedIssueDateReport" ||
              pageName === "dateRequiredOnSiteReport" ||
              pageName === "companyDateRequiredOnSiteReport" ? (
                <>
                  <div className="col-lg-2 col-md-4 col-sm-12 d-flex align-items-center">
                    <Controller
                      name="filteredCompanies"
                      control={control}
                      render={({ field }) => (
                        <div className="d-flex align-items-center gap-sm-1 gap-md-4 gap-lg-4 gap-xl-1 w-100">
                          <label className="w-25 ">Filter:</label>
                          <Select
                            style={{
                              width: "100%",
                            }}
                            onChange={handleChange}
                            mode={
                              pageName === "plannedIssueDateReport" ||
                              pageName === "companyPlannedIssueDateReport"
                                ? "multiple"
                                : undefined
                            }
                            maxTagCount={
                              pageName === "plannedIssueDateReport" ||
                              pageName === "companyPlannedIssueDateReport"
                                ? "responsive"
                                : undefined
                            }
                            placeholder="Select Company"
                            options={subcontractorFilterList.map((key) => ({
                              value: key,
                              label: key,
                            }))}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              {pageName === "subcontractorHealthReport" ||
              pageName === "companyCubcontractorHealthReport" ||
              pageName === "timeDifferenceReport" ||
              pageName === "companyTimeDifferenceReport" ? (
                <div className="col-lg-2 col-md-4 col-sm-12 d-flex align-items-center">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <div className="d-flex align-items-center gap-sm-1 gap-md-4 gap-lg-4 gap-xl-1 w-100">
                        <label className="w-25 ">Status:</label>
                        <Select
                          style={{
                            width: "100%",
                          }}
                          onChange={handleChange}
                          placeholder="Select Status"
                          options={[
                            {
                              value: "onTime",
                              label: "On Time",
                            },
                            {
                              value: "late",
                              label: "Late",
                            },
                            {
                              value: "early",
                              label: "Early",
                            },
                            {
                              value: "notYetDelivered",
                              label: "Not Yet Delivered",
                            },
                            {
                              value: "unknown",
                              label: "Calculation Not Possible",
                            },
                          ]}
                          {...field}
                        />
                      </div>
                    )}
                  />
                </div>
              ) : (
                <></>
              )}

              {(pageName === "highLeadTimeReport" ||
                pageName === "companyHighLeadTimeReport") && (
                <div className="col-lg-2 col-md-4 col-sm-12 mx-lg-3 mx-xl-0 d-flex align-items-center">
                  <Controller
                    name="range"
                    control={control}
                    render={({ field }) => (
                      <div className="d-flex align-items-center gap-sm-1 gap-md-4 gap-lg-4 gap-xl-1 w-100">
                        <label className="w-25">Range:</label>
                        <Slider
                          className="w-100"
                          style={{ width: "100%" }}
                          range={{ draggableTrack: true }}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          max={maxLeadTime}
                          defaultValue={[0, 900]}
                          {...field}
                        />
                      </div>
                    )}
                  />
                </div>
              )}
              {(pageName === "plannedIssueDateReport" ||
                pageName === "companyPlannedIssueDateReport") && (
                <>
                  <div className="col-lg-2 col-md-4 col-sm-12 d-flex align-items-center">
                    <Controller
                      name="filteredResponses"
                      control={control}
                      render={({ field }) => (
                        <div className="d-flex align-items-center gap-sm-1 gap-md-4 gap-5 gap-xl-4 w-100">
                          <label className="w-25">Response:</label>
                          <Select
                            style={{
                              width: "100%",
                            }}
                            onChange={handleChange}
                            mode="multiple"
                            placeholder="Select Responses"
                            maxTagCount="responsive"
                            options={[
                              { value: "", label: "No response" },
                              ...dropDownResponsesList.map((key) => ({
                                value: key,
                                label: key,
                              })),
                            ]}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </div>
                </>
              )}
              {(pageName === "itemDeficienciesReport" ||
                pageName === "companyItemDeficienciesReport") && (
                <>
                  <div className="col-lg-3 col-md-6 col-sm-12 d-flex align-items-center">
                    <Controller
                      name="filteredColumns"
                      control={control}
                      render={({ field }) => (
                        <div className="d-flex align-items-center gap-sm-1 gap-md-4 gap-lg-4 gap-xl-1 w-100">
                          <label className="w-25">Filters:</label>
                          <Select
                            className="w-100"
                            style={{ width: "100%" }}
                            mode="multiple"
                            onChange={handleChange}
                            placeholder="Select Columns"
                            maxTagCount="responsive"
                            options={[
                              { value: "specTitle", label: "Item Name" },
                              {
                                value: "specNumber",
                                label: "Submittal Number",
                              },
                              {
                                value: "specRevision",
                                label: "Submittal Revision",
                              },
                              {
                                value: "specType",
                                label: "Submittal Type",
                              },
                              {
                                value: "specResponse",
                                label: "Submittal Response",
                              },
                              {
                                value: "responsibleContractor",
                                label: "Responsible Contractor",
                              },
                              {
                                value: "issueContract",
                                label: "Issue Contract",
                              },
                              {
                                value: "contractIssuedRelease",
                                label: "Contract Issued Release",
                              },
                              {
                                value: "submittalPrepTime",
                                label: "Review Time",
                              },
                              {
                                value: "submittalRequired",
                                label: "Submittal Required",
                              },
                              {
                                value: "submittalIssued",
                                label: "Submittal Issued",
                              },
                              { value: "approvalTime", label: "Approval Time" },
                              {
                                value: "submittalApprovalDue",
                                label: "Submittal Approval Due",
                              },
                              {
                                value: "submittalReturned",
                                label: "Submittal Returned",
                              },
                              { value: "leadTime", label: "Lead Time" },
                              { value: "scheduleID", label: "Schedule ID" },
                              {
                                value: "dateRequiredOnSite",
                                label: "Date Required On Site",
                              },
                              {
                                value: "projectedDeliveryDate",
                                label: "Projected Delivery Date",
                              },
                              {
                                value: "subCommittedDeliveryDate",
                                label: "Sub Committed Delivery Date",
                              },
                              {
                                value: "actualDeliveryDate",
                                label: "Actual Delivery Date",
                              },
                            ]}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div
                    className={`col-lg-2 col-md-6 col-sm-12 d-flex me-md-5 align-items-center ${
                      watchFilteredColumns.length < 2 ? "d-none" : ""
                    }`}
                  >
                    <Controller
                      name="filterCombination"
                      control={control}
                      render={({ field }) => (
                        <div className="d-flex align-items-center justify-content-start gap-sm-1 gap-md-4 gap-lg-4 gap-xl-1 w-100">
                          <Select
                            className="w-100"
                            defaultValue="and"
                            style={{
                              width: "100%",
                            }}
                            disabled={watchFilteredColumns.length < 2}
                            onChange={handleChange}
                            options={[
                              {
                                value: "and",
                                label: "All Fields (AND)",
                              },
                              {
                                value: "or",
                                label: "Any Fields (OR)",
                              },
                            ]}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </div>
                </>
              )}

              <div className="col-lg-3 col-md-10 col-sm-12 ms-md-5 ms-xl-3 ms-xxl-0 d-flex gap-2 justify-content-lg-start justify-content-md-center justify-content-center align-items-center">
                <ButtonComponent
                  type="button"
                  className="w-auto px-3 ms-xl-3 ms-xxl-0"
                  onClickAction={handleSubmitFilters}
                  title="Apply Filters"
                  disabled={!formState.isDirty}
                />

                {formState.isDirty && (
                  <button
                    type="button"
                    className="btn btn-light rounded-3"
                    onClick={handleResetFilters}
                  >
                    Reset Filters
                  </button>
                )}
              </div>
              <Modal
                title="Unsaved Changes"
                open={showModal}
                onOk={handleConfirm}
                onCancel={handleCancel}
                okText="Proceed"
                cancelText="Cancel"
              >
                You have unsaved changes. Are you sure you want to proceed?
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default ReportFilters;
