import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useState } from "react";

import AboutUs from "pages/about-us";
import CompanyPermissions from "pages/company-permissions";
import ContactUs from "pages/contact-us";
import CreateProject from "pages/create-project";
import Footer from "components/footer";
import ForgetPassword from "pages/forget-password";
import HandleAuthData from "pages/handle-auth-data";
import Home from "pages/home";
import KoncurentSupport from "pages/Koncurent-Support";
import Login from "pages/login";
import NavbarComponent from "components/navbar";
import Permissions from "pages/permissions";
import ProjectContacts from "pages/project-contacts";
import Reports from "pages/canned-reports";
import ReportsDetails from "pages/reports-details";
import ProjectDetails from "pages/project-details";
import ProjectPermissions from "pages/project-permissions";
import Projects from "pages/projects";
import ResetPassword from "pages/reset-password";
import UserPermissionTable from "pages/user-permission";
import VerifyCode from "pages/verify-code";
import RegisterClient from "pages/registering-new-client";
import DeletedItems from "pages/deleted-items";
import HiddenItems from "pages/hidden-items";
import CompanyAdmin from "pages/company-admin";
import CompanyOverview from "pages/company-overview";
import CompanySchedule from "pages/company-schedule";
import CompanyReports from "pages/company-reports";
import CompanyDashboard from "pages/company-dashboard";
import CompanyReportsDetails from "pages/company-reports-details";
import ProjectReports from "pages/project-reports";
import ProjectCustomReport from "pages/project-custom-reports";
import CustomReportsDetails from "pages/custom-reports-details";

const Router = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <NavbarComponent />
        <Routes>
          {/* main routes */}
          <Route
            exact
            path="/main/company/:company_id/company-admin"
            element={<CompanyAdmin />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/company-reports"
            element={<CompanyReports />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/company-reports/:reportName"
            element={<CompanyReportsDetails />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects"
            element={<Projects />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/permissions"
            element={<Permissions />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/create-project/:id"
            element={<CreateProject />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id"
            element={<ProjectDetails />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id/directory"
            element={<ProjectContacts />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id/deleted-items"
            element={<DeletedItems />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id/hidden-items"
            element={<HiddenItems />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id/project-reports"
            element={<ProjectReports />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id/canned-reports"
            element={<Reports />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id/canned-reports/:reportName"
            element={<ReportsDetails />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id/project-custom-reports"
            element={<ProjectCustomReport />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/projects/:id/project-custom-reports/:reportName"
            element={<CustomReportsDetails />}
          ></Route>

          {/*  */}

          <Route
            exact
            path="/main/company/:company_id/company-overview"
            element={<CompanyOverview />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/company-schedule"
            element={<CompanySchedule />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/company-dashboard"
            element={<CompanyDashboard />}
          ></Route>

          {/*  */}

          {/*  */}

          <Route
            exact
            path="/main/company/:company_id/user-permission"
            element={<UserPermissionTable />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/company-permission"
            element={<CompanyPermissions />}
          ></Route>
          <Route
            exact
            path="/main/company/:company_id/Project-permission"
            element={<ProjectPermissions />}
          ></Route>

          {/*  */}

          {/* auth routes */}
          <Route exact path="/login" element={<Login />}></Route>
          <Route
            exact
            path="/handle-auth-data/:company_id/:company_name/:access_token/:refresh_token/:email"
            element={<HandleAuthData />}
          ></Route>
          <Route
            exact
            path="/forget-password"
            element={<ForgetPassword />}
          ></Route>
          <Route exact path="/verify-code" element={<VerifyCode />}></Route>
          <Route
            exact
            path="/resetPassword/:code"
            element={<ResetPassword />}
          ></Route>
          {/* intro routes */}
          <Route
            exact
            path="/KoncurentSupport"
            element={<KoncurentSupport />}
          ></Route>
          <Route
            exact
            path="/registering-new-client"
            element={<RegisterClient />}
          ></Route>
          <Route exact path="/about-us" element={<AboutUs />}></Route>
          <Route exact path="/contact-us" element={<ContactUs />}></Route>
          <Route exact path="/home" element={<Home />}></Route>
          <Route exact path="/" element={<Home />}></Route>
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
};

export default Router;
