import { Network } from "../Network";
import { RegisterEndPoints } from "apis/EndPoints/RegisterEndPoints";

export class RegisterServices {
  static getRegisteredClients(data = {}) {
    return Network.fetch(RegisterEndPoints.getRegisteredClients.url(), {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: data?.password,
        From: data?.username,
      },
      method: RegisterEndPoints.getRegisteredClients.method,
    });
  }

  static registerNewClient(username, password, data) {
    return Network.fetch(RegisterEndPoints.registerNewClient.url(), {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: password,
        From: username,
      },
      body: JSON.stringify(data),
      method: RegisterEndPoints.registerNewClient.method,
    });
  }

  static updateRegisteredClient(username, password, data) {
    return Network.fetch(RegisterEndPoints.updateRegisteredClient.url(), {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: password,
        From: username,
      },
      body: JSON.stringify(data),
      method: RegisterEndPoints.updateRegisteredClient.method,
    });
  }

  static deleteRegisteredClient(username, password, data) {
    return Network.fetch(RegisterEndPoints.deleteRegisteredClient.url(), {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: password,
        From: username,
      },
      body: JSON.stringify(data),
      method: RegisterEndPoints.deleteRegisteredClient.method,
    });
  }
}
