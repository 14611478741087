import React from "react";
import Text from "components/text";
import styles from "./alert.module.css";
import draftIcon from "assets/images/draft-alert-icon.svg";
import pendingIcon from "assets/images/pending-alert-icon.svg";
import overdueIcon from "assets/images/overdue-alert-icon.svg";
import completedIcon from "assets/images/completed-alert-icon.svg";
import { Tooltip } from "antd";
const GrowingAlert = ({ number = "", type = "draft", text = "", page }) => {
  const alertIcons = {
    draft: draftIcon,
    pending: pendingIcon,
    overdue: overdueIcon,
    completed: completedIcon,
    activated: completedIcon,
  };

  const alertTooltip = {
    draft: "Items with no submittal issued",
    pending: "Items due within the next 14 days",
    overdue: "Items that are due today or earlier",
    completed: "Items with an actual delivery day",
  };
  return (
    <Tooltip title={alertTooltip[type.toLowerCase()]}>
      <div
        className={`d-flex 
      ${page === "dashboard" ? styles.containerDashboard : styles.container}
      ${text ? styles.noGrow : ""} 
      align-items-center `}
      >
        <img
          src={alertIcons[type.toLowerCase()]}
          alt="alert-icon"
          className={`${styles.icon}`}
        />
        <Text className={`${styles[type.toLowerCase()]}`}>
          {number + " " + (text || type)}
        </Text>
      </div>
    </Tooltip>
  );
};

export default GrowingAlert;
