import { ApiUrl } from "./Domains";
import { AuthService } from "./Services/AuthService";
import { useParams } from "react-router-dom";

export class Network {
  constructor() {
    this.bearer = "";
  }

  static async fetch(url, init, addAuth) {
    // let requestedUrl = url.includes("http") ? url : ApiUrl + `/${init?.locale || "en"}/api` + url;
    let requestedUrl = ApiUrl + url;

    const response = await fetch(requestedUrl, {
      mode: "cors",
      ...init,
      headers: Network.getHeaders(init.headers, addAuth),
    });

    let lastRequestTime = localStorage.getItem("lastRequestTime");
    let token = localStorage.getItem("token");
    const isRefresh = url.includes("refreshToken");

    let currentTime = new Date().getTime();
    if (lastRequestTime && currentTime - lastRequestTime < 3600000) {
      // console.log("Entered");
      // console.log(1);
      // Token exists and last request was made within the last 15 minutes, do nothing
    } else if (token) {
      if (
        (response.status === 401 && !isRefresh) ||
        (response.status === 400 && !isRefresh) ||
        (response.status === 405 && !isRefresh)
      ) {
        let code = token;
        let loginEmail = localStorage.getItem("email");
        let refreshCode = localStorage.getItem("refreshToken");
        let company_id = localStorage.getItem("company_id");
        let data = {
          loginEmail,
          access_token: code,
          refresh_token: refreshCode,
          company_id,
        };
        AuthService.refreshToken(data)
          .then((res) => {
            localStorage.setItem("token", res.access_token);
            localStorage.setItem("refreshToken", res.refresh_token);
            localStorage.setItem("lastRequestTime", new Date().getTime());

            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      // Token doesn't exist in local storage, do nothing
    }

    let promise;
    if (![204, 201, 200].includes(response.status)) {
      let promise = response.json().then((data) => {
        return Promise.reject(data);
      });
      return promise.catch((error) => {
        return Promise.reject(error);
      });
    } else if (response.status == 204) promise = Promise.resolve({});
    else promise = response.json();

    return promise;
  }

  // ////
  static getHeaders(originalHeaders, auth) {
    let headers = {};
    if (auth) {
      this.bearer = localStorage.getItem("bearer");
      headers.Authorization = `Token ${this.bearer}`;
    }
    headers = {
      ...headers,
      ...originalHeaders,
      // "content-type": "application/json",
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
    };

    return headers;
  }
}
