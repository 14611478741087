import React from "react";
import Text from "components/text";
import styles from "./header.module.css";
const PageHeader = ({ imageUrl = "", pageTitle = "" }) => {
  return (
    <div
      className={`${styles.background}`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <Text className={`${styles.pageTitle}`}>{pageTitle}</Text>
    </div>
  );
};

export default PageHeader;
