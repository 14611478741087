import { Controller, useForm } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";

import {
  Menu,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  DownOutlined,
  DeleteOutlined,
  FormOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import ButtonComponent from "components/button-component";
import CloseIcon from "@mui/icons-material/Close";
import ContactRow from "./contact-row";
import { ContactServices } from "apis/Services/ContactService";
import Container from "components/container";
import ContentContainer from "components/content-container";
import InputComponent from "components/input-component";
import LoadingComponent from "components/loading-component";
import ProjectHeader from "components/project-header";
import {
  Button,
  AutoComplete,
  Modal as AntModal,
  Checkbox,
  Dropdown,
  Input,
  Select,
} from "antd";
import SearchIcon from "assets/images/search-icon.svg";
import SelectComponent from "components/select-component";
import SeparateLine from "components/separate-line";
import Text from "components/text";
import UseGetCompanyPermissions from "hooks/request-hooks/use-get-company-permissions";
import UseGetProjectPermissions from "hooks/request-hooks/use-get-project-permissions";
import styles from "./contacts.module.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ProjectContacts = () => {
  const { company_id, id } = useParams();
  const { control, reset, formState, getValues, setValue } = useForm({
    defaultValues: {
      "company name": "",
      "Company Address": "",
      "Company Permissions": undefined,
      "Project Permissions": undefined,
    },
  });

  const [isLoading, setisLoading] = useState(false);
  const [tableContent, settableContent] = useState(null);
  const [AddContactModalVisible, setAddContactModalVisible] = useState(false);
  const [accessLevel, setAccessLevel] = useState("");
  const [ActiveUsers, setActiveUsers] = useState([]);
  const [ActiveUsersIcon, setActiveUsersIcon] = useState([]);
  const [directoryFilter, setDirectoryFilter] = useState("");
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [companyAssignablePermissions, setCompanyAssignablePermissions] =
    useState([]);
  const [projectAssignablePermissions, setProjectAssignablePermissions] =
    useState([]);

  const getContacts = () => {
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    setisLoading(true);
    ContactServices.getProjectsContactPage({
      company_id,
      id,
      code,
      loginEmail: email,
    })
      .then((res) => {
        settableContent(res);
        console.log(res);
        setAccessLevel(res?.accessLevel);

        const companiesWithAddress = Object.entries(res?.content).map(
          ([key, value]) => ({
            label: key,
            value: key,
            address: value[0]?.["Company Address"],
          })
        );
        setCompaniesList(companiesWithAddress);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const readAssignablePermissionsTemplate = () => {
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    // setisLoading(true);
    ContactServices.readUserSpecificCompanyAndProjectAssignablePermissionsTemplates(
      {
        company_id,
        project_id: id,
        code,
        loginEmail: email,
      }
    )
      .then((res) => {
        console.log(res, "Assignable Permissions");
        setCompanyAssignablePermissions(res?.companyAssignablePermissions);
        setProjectAssignablePermissions(res?.projectAssignablePermissions);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setisLoading(false);
      });
  };

  const syncContacts = () => {
    // console.log("SYNCING");
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    setisLoading(true);
    ContactServices.resyncContacts({
      company_id,
      project_id: id,
      code,
      loginEmail: email,
    })
      .then((res) => {
        settableContent(res);
        console.log(res);
        setAccessLevel(res?.accessLevel);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setisLoading(false));
  };

  const getActiveUsers = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      project_id: id,
    };
    ActiveUsersService.getUsers(data)
      .then((res) => {
        console.log(res, "ActiveUsers");
        setActiveUsers(res);
        setActiveUsersIcon(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addActiveUser = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      project_id: id,
    };
    ActiveUsersService.AddUser(data)
      .then((res) => {})
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => getActiveUsers());
  };
  const removeUser = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      project_id: id,
    };
    ActiveUsersService.RemoveUser(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getContacts();
    readAssignablePermissionsTemplate();
  }, []);

  const showModal = () => {
    setIsSyncModalOpen(true);
  };
  const handleOk = () => {
    syncContacts();
    setIsSyncModalOpen(false);
  };
  const handleCancel = () => {
    setIsSyncModalOpen(false);
  };

  const filteredArr = (companyName, searchText) => {
    if (directoryFilter !== "") {
      return tableContent?.content?.[companyName]?.filter(
        (item) =>
          item["First Name"]
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          item["Last Name"]
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          (
            item["First Name"].toLocaleLowerCase() +
            " " +
            item["Last Name"].toLocaleLowerCase()
          ).includes(searchText.toLocaleLowerCase()) ||
          item["Phone"]
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          item["Email Address"]
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          item["Company"]
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          item["Status"]
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          item["Company Permissions"]
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          item["Project Permissions"]
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
    } else {
      return tableContent?.content?.[companyName];
    }
  };

  const [allExpanded, setAllExpanded] = useState(false);
  const [openPanel, setOpenPanel] = useState({});

  const handlePanelToggle = (companyName) => {
    setOpenPanel({
      ...openPanel,
      [companyName]: !openPanel[companyName],
    });
  };

  const [checkedRows, setCheckedRows] = useState([]);

  const toggleRowCheck = (item) => {
    let email = item?.["Email Address"];
    setCheckedRows((prevState) => {
      if (prevState.includes(email)) {
        // Remove entryID if it is already checked
        return prevState.filter((id) => id !== email);
      } else {
        // Add entryID if it is not checked
        return [...prevState, email];
      }
    });
    console.log(checkedRows, "CHECKED ROWS");
  };

  const handleSelectAll = (companyName) => {
    const emails = filteredArr(companyName, directoryFilter).map(
      (item) => item["Email Address"]
    );

    setCheckedRows((prevState) => {
      // Check if all items are already checked
      const allChecked = emails.every((email) => prevState.includes(email));

      if (allChecked) {
        // If all items are checked, uncheck all
        return prevState.filter((email) => !emails.includes(email));
      } else {
        // If not all items are checked, check all
        return [...new Set([...prevState, ...emails])];
      }
    });
  };

  const editContacts = (editDetails) => {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    let requestObj = {
      code: code,
      loginEmail: email,
      company_id,
      id: id,
      editDetails,
    };

    ContactServices.EditContact(requestObj)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        getContacts();
      });
  };

  const resendInvitations = (invitationDetails) => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");

    let requestObj = {
      company_id,
      project_id: id,
      code,
      loginEmail,
      invitationDetails,
    };
    ContactServices.resendInvitation(requestObj)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        getContacts();
        setCheckedRows([]);
      });
  };

  const deleteContacts = (inviteeEmail) => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let requestObj = {
      company_id,
      project_id: id,
      code,
      loginEmail,
      inviteeEmail,
    };
    ContactServices.removeContact(requestObj)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        getContacts();
        setCheckedRows([]);
      });
  };

  const handleBulkEdit = () => {
    const formData = getValues();

    const editDetails = [];

    Object.keys(tableContent?.content || {}).forEach((companyName) => {
      const contacts = tableContent.content[companyName];
      contacts.forEach((item) => {
        if (checkedRows.includes(item["Email Address"])) {
          editDetails.push({
            firstName: item["First Name"],
            lastName: item["Last Name"],
            inviteeEmail: item["Email Address"],
            inviteePhoneNumber: item.Phone,
            inviteeCompany:
              formData["company name"] === ""
                ? item?.Company
                : formData["company name"],
            inviteeCompanyAddress:
              formData["Company Address"] === ""
                ? item["Company Address"]
                : formData["Company Address"],
            inviteeProjectPermission:
              formData["Project Permissions"] === undefined
                ? item["Project Permissions"]
                : formData["Project Permissions"],
            inviteeCompanyPermission:
              formData["Company Permissions"] === undefined
                ? item["Company Permissions"]
                : formData["Company Permissions"],
          });
        }
      });
    });

    editContacts(editDetails);
    reset();
    setIsBulkEditModalOpen(false);
  };

  function handleBulkMenuClick(e) {
    if (e.key === "1") {
      setIsBulkEditModalOpen(true);
    }
    if (e.key === "2") {
      //Invite selected rows
      AntModal.confirm({
        title: "Are you sure you want to invite selected contact(s)?",
        okText: "Send Invitations",
        okButtonProps: {
          type: "primary",
        },
        cancelText: "Cancel",
        onOk() {
          const invitationDetails = [];

          Object.keys(tableContent?.content || {}).forEach((companyName) => {
            const contacts = tableContent.content[companyName];
            contacts.forEach((item) => {
              if (checkedRows.includes(item["Email Address"])) {
                invitationDetails.push({
                  firstName: item["First Name"],
                  lastName: item["Last Name"],
                  inviteeEmail: item["Email Address"],
                  inviteePhoneNumber: item.Phone,
                  inviteeCompany: item.Company,
                  inviteeCompanyAddress: item["Company Address"],
                  inviteeProjectPermission: item["Project Permissions"],
                  inviteeCompanyPermission: item["Company Permissions"],
                });
              }
            });
          });
          resendInvitations(invitationDetails);
        },
        onCancel() {
          // console.log("Delete action cancelled");
        },
      });
    }

    if (e.key === "3") {
      //Delete selected rows
      AntModal.confirm({
        title: "Are you sure you want to delete selected contact(s)?",
        // content: "This action is permanent and cannot be undone.",
        okText: "Delete",
        okButtonProps: {
          type: "primary",
          danger: true,
        },
        cancelText: "Cancel",
        onOk() {
          deleteContacts(checkedRows);
        },
        onCancel() {
          // console.log("Delete action cancelled");
        },
      });
    }
  }

  const bulkitems = [
    {
      label: "Bulk Edit",
      icon: <FormOutlined style={{ fontSize: "16px" }} />,
      key: "1",
      // disabled: unsavedChanges || projectsAccessLevelValue < 2,
    },
    {
      label: "Bulk Invite",
      icon: <SendOutlined style={{ fontSize: "16px" }} />,
      key: "2",
    },
    {
      label: "Bulk Delete",
      icon: <DeleteOutlined style={{ fontSize: "16px" }} />,
      key: "3",
      danger: true,
    },
  ];

  const bulkMenuProps = {
    items: bulkitems,
    onClick: handleBulkMenuClick,
  };

  return (
    <>
      <ProjectHeader
        ActiveUsers={ActiveUsers}
        ActiveUsersIcon={ActiveUsersIcon}
        isActiveShown={false}
      />

      <Container className="mt-4">
        <div className="row">
          <div className="col-md-6">
            <Text className={`${styles.header}`}>Project Directory</Text>
          </div>

          <div className="col-md-6 d-flex gap-3 justify-content-md-end">
            <ButtonComponent
              className="w-md-auto w-100 px-3 "
              disabled={accessLevel < 2}
              onClickAction={showModal}
              title={
                <div className="d-flex gap-1">
                  <SyncIcon fontSize="small" color="#fff" />
                  <Text className={`${styles.btnText}`}>Sync with Procore</Text>
                </div>
              }
            />
            <ButtonComponent
              className="w-md-auto w-100 px-3 "
              onClickAction={() => setAddContactModalVisible(true)}
              disabled={accessLevel < 2}
              title={
                <div className="d-flex gap-1">
                  <AddIcon fontSize="small" color="#fff" />
                  <Text className={`${styles.btnText}`}>Create Contact</Text>
                </div>
              }
            />
          </div>
        </div>

        <AntModal
          title="Sync Contacts From Procore"
          open={isSyncModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          By syncing with Procore you will bring in all users including ones
          previously removed. No invites will be sent out.
        </AntModal>

        <AntModal
          title={<h3>Bulk Edit</h3>}
          open={isBulkEditModalOpen}
          centered
          onOk={handleBulkEdit}
          okText="Save Changes"
          okButtonProps={{ disabled: !formState.isDirty }}
          onCancel={() => {
            setIsBulkEditModalOpen(false);
            reset();
          }}
          width={800}
        >
          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-3">
              <div className=" d-flex flex-column w-100">
                <b>Company Name</b>
                <Controller
                  name="company name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <AutoComplete
                      optionFontSize="12"
                      options={companiesList}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      placeholder="Company Name"
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      {...field}
                    />
                  )}
                />
              </div>

              <div className="w-100">
                <b>Company Address</b>
                <Controller
                  name="Company Address"
                  control={control}
                  render={({ field }) => (
                    <Input placeholder="Company Address" {...field} />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-row gap-3">
              <div className=" d-flex flex-column w-100">
                <b>Company Permissions</b>
                <Controller
                  name="Company Permissions"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Company Permissions"
                      placement="bottomLeft"
                      allowClear
                      options={companyAssignablePermissions?.map((e) => ({
                        label:
                          e === "Default"
                            ? "Apply Permission Template Later"
                            : e,
                        value: e,
                      }))}
                      value={field.value || ""}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className=" d-flex flex-column w-100">
                <b>Project Permissions</b>
                <Controller
                  name="Project Permissions"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Project Permissions"
                      placement="bottomLeft"
                      allowClear
                      options={projectAssignablePermissions?.map((e) => ({
                        label:
                          e === "Default"
                            ? "Apply Permission Template Later"
                            : e,
                        value: e,
                      }))}
                      value={field.value || ""}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </AntModal>

        {/* table part */}
        <ContentContainer className="mt-3 mb-2 py-3">
          <div className="d-flex align-items-center justify-content-between flex-wrap px-3 gap-2 mb-3">
            <InputComponent
              Icon={<img src={SearchIcon} alt="search icon" />}
              placeholder="Search"
              className="w-md-auto"
              havemargin={false}
              onChange={(e) => setDirectoryFilter(e)}
              inputIconContainerClass="align-items-center"
            />

            <div className=" d-flex flex-row gap-2">
              <Dropdown
                menu={bulkMenuProps}
                placement="bottomLeft"
                disabled={
                  isLoading || checkedRows.length === 0 || accessLevel < 2
                }
              >
                <Button className={`${styles.antBtn}`}>
                  Bulk Actions
                  <DownOutlined style={{ fontSize: "14px" }} />
                </Button>
              </Dropdown>
              {!allExpanded ? (
                <Button
                  onClick={() => {
                    setAllExpanded(true);
                    setOpenPanel(
                      Object.keys(tableContent.content).reduce((acc, key) => {
                        acc[key] = true;
                        return acc;
                      }, {})
                    );
                  }}
                >
                  Expand All
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setAllExpanded(false);
                    setOpenPanel({});
                  }}
                >
                  Collapse All
                </Button>
              )}
            </div>
          </div>

          <SeparateLine />

          <div className="row">
            {isLoading && <LoadingComponent />}
            {!isLoading &&
              (accessLevel == 0 ? (
                <div style={{ textAlign: "center" }}>
                  <Text>
                    You do not have the necessary permissions to access this
                    page.
                  </Text>
                </div>
              ) : (
                <TableContainer className={styles.tableContainer}>
                  <Table>
                    {/* <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Company Permissions</TableCell>
                        <TableCell>Project Permissions</TableCell>
                        <TableCell>Invitation Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead> */}

                    <TableBody className={styles.tbody}>
                      {tableContent &&
                        Object.keys(tableContent?.content).map(
                          (companyName, index) => {
                            // HEADER CHECK BOX LOGIC
                            const allEmails = filteredArr(
                              companyName,
                              directoryFilter
                            ).map((item) => item["Email Address"]);
                            const allChecked = allEmails.every((email) =>
                              checkedRows.includes(email)
                            );
                            const someChecked = allEmails.some((email) =>
                              checkedRows.includes(email)
                            );

                            return (
                              <React.Fragment
                                key={"company" + companyName + index}
                              >
                                {filteredArr(companyName, directoryFilter)
                                  .length > 0 && (
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        cursor: "pointer",
                                        userSelect: "none",
                                      }}
                                      onClick={() =>
                                        handlePanelToggle(companyName)
                                      }
                                    >
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                      >
                                        {openPanel[companyName] ? (
                                          <KeyboardArrowUp />
                                        ) : (
                                          <KeyboardArrowDown />
                                        )}
                                      </IconButton>
                                      <strong>{companyName}</strong>
                                    </TableCell>
                                  </TableRow>
                                )}
                                <TableRow>
                                  <Collapse
                                    in={openPanel[companyName]}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Table>
                                      {filteredArr(companyName, directoryFilter)
                                        .length > 0 && (
                                        <TableHead>
                                          <TableRow>
                                            <TableCell width={50}>
                                              <Checkbox
                                                className="align-self-center"
                                                indeterminate={
                                                  someChecked && !allChecked
                                                }
                                                checked={allChecked}
                                                onChange={() =>
                                                  handleSelectAll(companyName)
                                                }
                                              />
                                            </TableCell>

                                            <TableCell width={200}>
                                              Name
                                            </TableCell>
                                            <TableCell width={200}>
                                              Email
                                            </TableCell>
                                            <TableCell width={200}>
                                              Phone
                                            </TableCell>
                                            <TableCell width={200}>
                                              Company
                                            </TableCell>
                                            <TableCell width={200}>
                                              Address
                                            </TableCell>
                                            <TableCell width={200}>
                                              Company Permissions
                                            </TableCell>
                                            <TableCell width={200}>
                                              Project Permissions
                                            </TableCell>
                                            <TableCell width={100}>
                                              Invitation Status
                                            </TableCell>
                                            <TableCell width={50}>
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                      )}
                                      <TableBody>
                                        {filteredArr(
                                          companyName,
                                          directoryFilter
                                        ).map((item, index) => (
                                          <ContactRow
                                            item={item}
                                            menuKey={
                                              "contact" +
                                              index +
                                              JSON.stringify(item)
                                            }
                                            key={
                                              "contact" +
                                              index +
                                              JSON.stringify(item)
                                            }
                                            refreshContacts={getContacts}
                                            company_id={company_id}
                                            project_id={id}
                                            accessLevel={accessLevel}
                                            companiesList={companiesList}
                                            companyAssignablePermissions={
                                              companyAssignablePermissions
                                            }
                                            projectAssignablePermissions={
                                              projectAssignablePermissions
                                            }
                                            checkedRows={checkedRows}
                                            setCheckedRows={setCheckedRows}
                                            toggleRowCheck={toggleRowCheck}
                                            resendInvitations={
                                              resendInvitations
                                            }
                                            deleteContacts={deleteContacts}
                                            editContacts={editContacts}
                                          />
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Collapse>
                                </TableRow>
                              </React.Fragment>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ))}
          </div>
        </ContentContainer>
      </Container>

      <AddContactModal
        show={AddContactModalVisible}
        setShow={setAddContactModalVisible}
        company_id={company_id}
        project_id={id}
        companiesList={companiesList}
        setCompaniesList={setCompaniesList}
        refreshContacts={getContacts}
        companyAssignablePermissions={companyAssignablePermissions}
        projectAssignablePermissions={projectAssignablePermissions}
      />
    </>
  );
};

export default ProjectContacts;

const AddContactModal = ({
  show,
  setShow = () => {},
  company_id,
  project_id,
  companiesList,
  setCompaniesList,
  refreshContacts = () => {},
  companyAssignablePermissions,
  projectAssignablePermissions,
}) => {
  const {
    control,
    reset,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  // const { companyPermissions, projectPermissions } = useSelector(
  //   (state) => state.permission
  // );

  const handleClose = () => {
    reset({
      firstName: "",
      lastName: "",
      inviteeEmail: "",
      inviteePhoneNumber: "",
      inviteeCompany: "",
      NewCompany: "",
      inviteeCompanyAddress: "",
      inviteeCompanyPermission: "",
      inviteeProjectPermission: "",
    });
    setShow(false);
  };

  const AddContact = (v) => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");

    let requestObj = {
      code,
      loginEmail,
      company_id,
      project_id,
      firstName: v["firstName"],
      lastName: v["lastName"],
      inviteeEmail: v["Email Address"],
      inviteePhoneNumber: v["Phone"] || "",
      inviteeCompany: v["Company"],
      inviteeCompanyAddress: v["Company Address"],
      inviteeCompanyPermission: v?.["Company Permissions"],
      inviteeProjectPermission: v?.["Project Permissions"],
    };
    ContactServices.AddContact(requestObj)
      .then((res) => {
        // console.log(res)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        refreshContacts();
        handleClose();
      });
  };

  const handleChange = (value) => {
    // console.log(`selected ${value}`);
    console.log(companiesList);
  };

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "1000",
          position: "absolute",
        }}
      >
        <div className={` ${styles.modalContainer}`}>
          {/* header */}
          <div className="d-flex justify-content-between alignn-items-center p-3">
            <div className="d-flex flex-column">
              <Text className={`${styles.modalHeader}`}>
                Create New Contact
              </Text>
            </div>
            <CloseIcon
              fontSize="medium"
              className="pointer"
              sx={{ color: "var(--clr-midGrey)" }}
              onClick={() => handleClose()}
            />
          </div>

          <SeparateLine />

          <div className={` ${styles.margins}`}>
            <form className="pb-4 pt-2" onSubmit={handleSubmit(AddContact)}>
              <div className="d-flex w-100 gap-3 my-3">
                <div className="w-50">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field, fieldState }) => (
                      <div>
                        <InputComponent
                          label="First Name"
                          required
                          placeholder="Enter First Name"
                          errors={errors?.firstName}
                          {...field}
                          {...fieldState}
                        />
                      </div>
                    )}
                  />
                  {errors && errors?.firstName && (
                    <Text fontSize={14} color={"red"}>
                      {errors.firstName.message}
                    </Text>
                  )}
                </div>

                <div className="w-50">
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <InputComponent
                        label="Last Name"
                        required
                        placeholder="Enter Last Name"
                        // errors={errors.name}
                        {...field}
                      />
                    )}
                  />
                  {errors && errors?.lastName && (
                    <Text fontSize={14} color={"red"}>
                      {errors.lastName.message}
                    </Text>
                  )}
                </div>
              </div>
              <div className="d-flex  w-100 gap-3  my-3">
                <div className="w-50">
                  <Controller
                    name="Email Address"
                    control={control}
                    rules={{
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        label="E-mail"
                        required
                        placeholder="Enter E-mail"
                        errors={errors?.["Email Address"]}
                        {...field}
                      />
                    )}
                  />
                  {errors && errors?.["Email Address"] && (
                    <Text fontSize={14} color={"red"}>
                      {errors["Email Address"].message}
                    </Text>
                  )}
                </div>
                <div className="w-50">
                  <Controller
                    name="Phone"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (
                          value !== undefined &&
                          value !== null &&
                          value.trim().length > 0
                        ) {
                          if (!/^\+?\d{10}$/.test(value)) {
                            return "Phone number must be exactly 10 digits";
                          }
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        label="Phone"
                        // required
                        type="number"
                        keyboardType="number-pad"
                        placeholder="Enter Phone"
                        // handleChange={handleErrors}
                        // errors={errors.name}
                        {...field}
                      />
                    )}
                  />
                  {errors && errors?.["Phone"] && (
                    <Text fontSize={14} color={"red"}>
                      {errors["Phone"].message}
                    </Text>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className="w-50">
                  <Controller
                    name="Company Permissions"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <SelectComponent
                        label="Company Permissions Template"
                        required
                        // errors={errors?.["Company Permissions"]}
                        placeholder="Company Permissions Template"
                        {...field}
                        // options={companyPermissions?.content?.map((e) => ({
                        //   label:
                        //     e?.["Template Name"] === "Default"
                        //       ? "Apply Permission Template Later"
                        //       : e?.["Template Name"],
                        //   value:
                        //     e?.["Template Name"] === "Default"
                        //       ? "Apply Permission Template Later"
                        //       : e?.["Template Name"],
                        // }))}
                        options={companyAssignablePermissions?.map((e) => ({
                          label:
                            e === "Default"
                              ? "Apply Permission Template Later"
                              : e,
                          value: e,
                        }))}
                      />
                    )}
                  />
                  {errors && errors?.["Company Permissions"] && (
                    <Text fontSize={14} color={"red"}>
                      {errors["Company Permissions"].message}
                    </Text>
                  )}
                </div>
                <div className="w-50">
                  <Controller
                    name="Project Permissions"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <SelectComponent
                        label="Project Permission Template"
                        // errors={errors?.["Project Permissions"]}
                        required
                        placeholder="Project Permission Template"
                        // options={projectPermissions?.content?.map((e) => ({
                        //   label:
                        //     e?.["Template Name"] === "Default"
                        //       ? "Apply Permission Template Later"
                        //       : e?.["Template Name"],
                        //   value:
                        //     e?.["Template Name"] === "Default"
                        //       ? "Apply Permission Template Later"
                        //       : e?.["Template Name"],
                        // }))}
                        options={projectAssignablePermissions?.map((e) => ({
                          label:
                            e === "Default"
                              ? "Apply Permission Template Later"
                              : e,
                          value: e,
                        }))}
                        {...field}
                      />
                    )}
                  />
                  {errors && errors?.["Project Permissions"] && (
                    <Text fontSize={14} color={"red"}>
                      {errors["Project Permissions"].message}
                    </Text>
                  )}
                </div>
              </div>

              <div className="d-flex w-100 gap-3 mb-3">
                <div className="w-50">
                  <Controller
                    name="Company"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <div className={styles.companyName}>
                        <div>
                          <label className={styles.label}>
                            Company Name <span className={styles.star}>*</span>
                          </label>
                        </div>
                        <div>
                          <AutoComplete
                            className="w-100"
                            options={companiesList}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            placeholder="Company Name"
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={handleChange}
                            {...field}
                          />
                        </div>
                      </div>
                    )}
                  />
                  {errors && errors?.Company && (
                    <Text fontSize={14} color={"red"}>
                      {errors.Company.message}
                    </Text>
                  )}
                </div>
                <div className="w-50">
                  <Controller
                    name="Company Address"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <InputComponent
                        label="Company Address"
                        required
                        placeholder="Enter Company Address"
                        {...field}
                      />
                    )}
                  />
                  {errors && errors?.["Company Address"] && (
                    <Text fontSize={14} color={"red"}>
                      {errors["Company Address"].message}
                    </Text>
                  )}
                </div>
              </div>
              {/* btn */}
              <div className="row">
                <div className="col-md-4">
                  <ButtonComponent title={"Add Contact"} disabled={!isValid} />
                </div>
                <div className="col-md-4">
                  <ButtonComponent
                    title={"Cancel"}
                    onClickAction={() => handleClose()}
                    type=" "
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
