import React, { useEffect, useState } from "react";

import ButtonComponent from "components/button-component";
import InputComponent from "components/input-component";
import Text from "components/text";

export default function MultiInputsProjectDates({
  onChange,
  errors,
  Data,
  accessLevel,
  saveFlag,
  setIsFieldsDirty,
}) {
  const [inputs, setinputs] = useState(Data);

  useEffect(() => {
    onChange(inputs);
  }, [inputs, onChange]);

  const onIncreaseInput = () => {
    setIsFieldsDirty(true);
    setinputs((prevState) => [
      ...prevState,
      { holidayName: "", startDate: "", finishDate: "" },
    ]);
  };
  const removeinput = (index) => {
    setinputs((prevState) => {
      let newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
    setIsFieldsDirty(true);
  };

  const onChangeInput = ({ index, value, fieldName }) => {
    setinputs((prevState) => {
      const newState = [...prevState];
      newState[index][fieldName] = value;
      return newState;
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-4 my-2">
          <InputComponent value="Name" disabled />
        </div>
        <div className="col-3 my-2">
          <InputComponent value="START" disabled />
        </div>
        <div className="col-3 my-2">
          <InputComponent value="FINISH" disabled />
        </div>
      </div>
      {inputs?.map((item, index) => (
        <div key={index + ""} className="row d-flex align-items-center">
          <div className="col-4">
            <InputComponent
              value={item?.holidayName}
              onChange={(value) =>
                onChangeInput({
                  index,
                  fieldName: "holidayName",
                  value,
                })
              }
            />
          </div>
          <div className="col-3 my-1">
            <InputComponent
              value={item?.startDate}
              type="date"
              isDate={true}
              onChange={(value) =>
                onChangeInput({
                  index,
                  fieldName: "startDate",
                  value,
                })
              }
            />
          </div>
          <div className="col-3 my-1">
            <InputComponent
              value={item?.finishDate}
              type="date"
              isDate={true}
              onChange={(value) =>
                onChangeInput({
                  index,
                  fieldName: "finishDate",
                  value,
                })
              }
            />
          </div>
          {inputs.length !== 1 && (
            <div className="col-1 mt-1 ">
              <ButtonComponent
                type="button"
                title="-"
                onClickAction={() => removeinput(index)}
                className={"bg-danger"}
                disabled={accessLevel <= 2 ? true : false}
              />
            </div>
          )}
          {index === inputs?.length - 1 && (
            <>
              <div className="col-md-1 mt-1 ">
                <ButtonComponent
                  disabled={saveFlag && accessLevel > 1 ? false : true}
                  title="+"
                  onClickAction={onIncreaseInput}
                  // className={"w-md-100 w-auto"}
                />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
