import { SettingsEndPoints } from "apis/EndPoints/SettingsEndPoints";
import { Network } from "../Network";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class SettingsService {
  static getProcurementLogSettings(data = {}) {
    return Network.fetch(
      SettingsEndPoints.getProcurementLogSettings.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: SettingsEndPoints.getProcurementLogSettings.method,
      }
    );
  }

  static setProcurementLogSettings(data = {}, updatedData = {}) {
    return Network.fetch(
      SettingsEndPoints.setProcurementLogSettings.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: SettingsEndPoints.setProcurementLogSettings.method,
        body: JSON.stringify(updatedData),
      }
    );
  }

  static updateProcurementLogSettings(data = {}, updatedData = {}) {
    return Network.fetch(
      SettingsEndPoints.updateProcurementLogSettings.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: SettingsEndPoints.updateProcurementLogSettings.method,
        body: JSON.stringify(updatedData),
      }
    );
  }
}
