import ButtonComponent from "components/button-component";
import Container from "components/container";
import { Link } from "react-router-dom";
import React from "react";
import Text from "components/text";
import styles from "./platform.module.css";

const OurPlatform = () => {
  return (
    <div className={`${styles.container} text-center py-4`}>
      <Container>
        <Text className={`${styles.header}`} dontWrap={false}>
          Our Platform
        </Text>
        <Text className={`${styles.subHeader} my-1`} dontWrap={false}>
          See Koncurent in action when you book a personal walkthrough with one
          of our product experts.
        </Text>
        <div className="row justify-content-center">
          <div className="col-md-2">
            <Link to={"/contact-us"}>
              <ButtonComponent
                title={"Request a Demo"}
                className={`${styles.btn} mt-3`}
              />
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OurPlatform;
