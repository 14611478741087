export const CompanyReportsEndPoints = {
  getReportDetails: {
    url: (
      company,
      firstColumnName,
      secondColumnName,
      minValue,
      maxValue,
      sortBy,
      status,
      filteredColumns,
      filterCombination,
      subcontractorFilter,
      reportRequestName
    ) => `/rest/v1/${reportRequestName}/${company}`,
    method: `POST`,
  },

  getCompanyReportAccessLevel: {
    url: (company) => `/rest/v1/companyReportPageAccessLevel/${company}`,
    method: `GET`,
  },

  modifyIssueDateReport: {
    url: (company, project) => `/rest/v1/modifyIssueDateReport/${company}`,
    method: `POST`,
  },
};
