import React, { useState, useEffect } from "react";
import Container from "components/container";
import { RegisterServices } from "apis/Services/RegisterService";
import Modal from "antd/es/modal/Modal";
import { Button } from "antd";
import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import moment from "moment";

// login compnent
export const RegisterForm = ({
  username,
  password,
  isLoading,
  isError,
  setUsername,
  setPassword,
  handleSubmit,
}) => {
  return (
    <div className="row justify-content-center mx-auto w-50">
      <div className="col-md-6">
        <form onSubmit={handleSubmit} method="POST">
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              Username
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={isLoading}
              autoComplete="off"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              autoComplete="off"
              required
            />
          </div>
          {isError && !isLoading && (
            <span className="text-danger">invalid Username or Password!</span>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary w-100"
          >
            {isLoading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

// editing a cell in the table row
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  // const inputNode = inputType === "date" ? <Input type="date" /> : <Input />;

  // const inputNode =
  //   inputType === "date" ? <Input placeholder="MM-DD-YYYY" /> : <Input />;

  // Define a validator function to check if the input is a valid date
  const dateValidator = (_, value) => {
    if (value && !moment(value, "MM-DD-YYYY", true).isValid()) {
      return Promise.reject(new Error(`Invalid date for ${title}!`));
    }
    return Promise.resolve();
  };

  let rules = [
    {
      required: true,
      message: `Please Input ${title}!`,
    },
    // {
    //   validator: dateValidator,
    // },
  ];

  // Apply date validator if dataIndex is 'dateRegistered' or 'expirationDate'
  if (dataIndex === "dateRegistered" || dataIndex === "expirationDate") {
    rules.push({
      validator: dateValidator,
    });
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules}
        >
          {inputType === "date" ? <Input /> : <Input />}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

//Create a client form
export const CreateNewClient = ({ handleClientSubmit }) => {
  const [isExpirationDateValid, setIsExpirationDateValid] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    companyName: "",
    projectName: "",
    companyAddress: "",
    companyID: "",
    projectID: "",
    registrationDate: "",
    expirationDate: "",
  });

  const [expirationOption, setExpirationOption] = useState("");

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Handle expiration option selection
    if (name === "expirationOption") {
      handleExpirationOption(value);
    }

    // If changing the registrationDate field and an expiration option is selected, update expiration date
    if (name === "registrationDate" && expirationOption !== "SetManually") {
      updateExpirationDate(value);
    }

    // If changing the expirationDate field, perform validation
    if (name === "expirationDate") {
      validateExpirationDate(value);
    }
  };

  // Update expiration date based on registration date and selected option
  const updateExpirationDate = (registrationDate) => {
    const momentRegistrationDate = moment(registrationDate, "YYYY-MM-DD");
    let expirationDate;

    switch (expirationOption) {
      case "1Year":
        expirationDate = momentRegistrationDate
          .clone()
          .add(1, "year")
          .format("YYYY-MM-DD");
        break;
      case "2Years":
        expirationDate = momentRegistrationDate
          .clone()
          .add(2, "years")
          .format("YYYY-MM-DD");
        break;
      case "3Years":
        expirationDate = momentRegistrationDate
          .clone()
          .add(3, "years")
          .format("YYYY-MM-DD");
        break;
      default:
        expirationDate = "";
    }

    setFormData((prevData) => ({
      ...prevData,
      expirationDate,
    }));
    validateExpirationDate(expirationDate); // Validate the new expiration date
  };

  // Handle expiration option selection
  const handleExpirationOption = (value) => {
    setExpirationOption(value);
    const registrationDate = moment(formData.registrationDate, "YYYY-MM-DD");
    let expirationDate;
    switch (value) {
      case "1Year":
        expirationDate = registrationDate
          .clone()
          .add(1, "year")
          .format("YYYY-MM-DD");
        break;
      case "2Years":
        expirationDate = registrationDate
          .clone()
          .add(2, "years")
          .format("YYYY-MM-DD");
        break;
      case "3Years":
        expirationDate = registrationDate
          .clone()
          .add(3, "years")
          .format("YYYY-MM-DD");
        break;
      case "SetManually":
        expirationDate = "";
        break;
      default:
        expirationDate = "";
    }
    setFormData((prevData) => ({
      ...prevData,
      expirationDate,
    }));
    validateExpirationDate(expirationDate); // Validate the new expiration date
  };

  // Validate expiration date
  const validateExpirationDate = (date) => {
    const registrationDate = moment(formData.registrationDate, "YYYY-MM-DD");
    const expirationDate = moment(date, "YYYY-MM-DD");
    if (expirationDate.isBefore(registrationDate)) {
      setIsExpirationDateValid(false);
    } else {
      setIsExpirationDateValid(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Format the date values before submitting
    const formattedFormData = {
      ...formData,
      registrationDate: formatDate(formData.registrationDate),
      expirationDate: formatDate(formData.expirationDate),
    };

    handleClientSubmit(formattedFormData);

    // Reset the form fields
    // Reset the form fields
    setFormData({
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      companyName: "",
      projectName: "",
      companyAddress: "",
      companyID: "",
      projectID: "",
      registrationDate: "",
      expirationDate: "",
    });
    setExpirationOption("SetManually");
    setIsExpirationDateValid(true); // Reset validation state
  };

  // Function to format date to MM-DD-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <form onSubmit={handleSubmit} className="row g-3">
      <h2 className="mb-4">Create New Client</h2>

      {/* First Name and Last Name */}
      <div className="col-md-6">
        <label htmlFor="firstName" className="form-label">
          First Name:
        </label>
        <input
          type="text"
          className="form-control"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="col-md-6">
        <label htmlFor="lastName" className="form-label">
          Last Name:
        </label>
        <input
          type="text"
          className="form-control"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>

      {/* Phone Number and Email */}
      <div className="col-md-6">
        <label htmlFor="phoneNumber" className="form-label">
          Phone Number:
        </label>
        <input
          type="text"
          className="form-control"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
      </div>
      <div className="col-md-6">
        <label htmlFor="email" className="form-label">
          Email:
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      {/* Company Name and Company Address */}
      <div className="col-md-6">
        <label htmlFor="companyName" className="form-label">
          Company Name:
        </label>
        <input
          type="text"
          className="form-control"
          id="companyName"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          required
        />
      </div>

      <div className="col-md-6">
        <label htmlFor="projectName" className="form-label">
          Project Name:
        </label>
        <input
          type="text"
          className="form-control"
          id="projectName"
          name="projectName"
          value={formData.projectName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="col-md-6">
        <label htmlFor="companyAddress" className="form-label">
          Company Address:
        </label>
        <input
          type="text"
          className="form-control"
          id="companyAddress"
          name="companyAddress"
          value={formData.companyAddress}
          onChange={handleChange}
          required
        />
      </div>

      {/* Company ID and Project ID */}
      <div className="col-md-6">
        <label htmlFor="companyID" className="form-label">
          Company ID:
        </label>
        <input
          type="text"
          className="form-control"
          id="companyID"
          name="companyID"
          value={formData.companyID}
          onChange={handleChange}
          required
        />
      </div>
      <div className="col-md-6">
        <label htmlFor="projectID" className="form-label">
          Project ID:
        </label>
        <input
          type="text"
          className="form-control"
          id="projectID"
          name="projectID"
          value={formData.projectID}
          onChange={handleChange}
          required
        />
      </div>

      {/* Registration Date */}
      <div className="col-md-6">
        <label htmlFor="registrationDate" className="form-label">
          Registration Date:
        </label>
        <input
          type="date"
          className="form-control"
          id="registrationDate"
          name="registrationDate"
          value={formData.registrationDate}
          onChange={handleChange}
          required
        />
      </div>

      {/* Expiration Option Select */}
      <div className="col-md-6">
        <label htmlFor="expirationOption" className="form-label">
          Expiration Option:
        </label>
        <select
          className="form-select"
          id="expirationOption"
          name="expirationOption"
          value={expirationOption}
          onChange={handleChange}
          disabled={!formData.registrationDate}
        >
          <option value="SetManually">Set Date Manually</option>
          <option value="1Year">1 Year</option>
          <option value="2Years">2 Years</option>
          <option value="3Years">3 Years</option>
        </select>
      </div>

      {/* Expiration Date */}
      <div className="col-md-6">
        <label htmlFor="expirationDate" className="form-label">
          Expiration Date:
        </label>
        <input
          type="date"
          className="form-control"
          id="expirationDate"
          name="expirationDate"
          value={formData.expirationDate}
          onChange={handleChange}
          required={expirationOption === "SetManually"}
          disabled={
            expirationOption !== "SetManually" && !formData.registrationDate
          }
        />
        {!isExpirationDateValid && (
          <span className="text-danger">
            Expiration Date should be after Registration Date
          </span>
        )}
      </div>
      {/* Submit Button */}
      <div className="col-12">
        <button
          className="btn btn-primary w-100"
          disabled={!isExpirationDateValid || !formData.expirationDate}
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

// where all actions are defined
export const ConfirmForm = ({
  username,
  password,
  isLoading,
  isError,
  setUsername,
  setPassword,
  handleActions,
}) => {
  return (
    <div className="row justify-content-center w-100">
      <div className="col-md-6">
        <form onSubmit={handleActions} method="POST">
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              Username
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={isLoading}
              autoComplete="off"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              autoComplete="off"
              required
            />
          </div>
          {isError && !isLoading && (
            <span className="text-danger">Invalid username or password!</span>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary w-100"
          >
            {isLoading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

// Responsible for displaying all components
function RegisterClient() {
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [data, setData] = useState();
  const [newClientData, setNewClientData] = useState(null);
  const [actionType, setActionType] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateClientModalOpen, setIsCreateClientModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [updatedData, setUpdatedData] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showCreateClientModal = () => {
    setIsCreateClientModalOpen(true);
  };
  const handleCancel = () => {
    setIsCreateClientModalOpen(false);
  };

  // gets clients and reloads table
  async function getClients() {
    setIsLoading(true);
    let ReqObj = {
      username,
      password,
    };
    try {
      const res = await RegisterServices.getRegisteredClients(ReqObj);
      const keyedData = res.map((item, index) => ({
        ...item,
        key: item.companyID + index + 7 + item.projectID,
      }));

      setData(keyedData);

      // setData(keyedData);
      setIsAuthenticated(true);
      setIsError(false);
      setIsModalOpen(false);
    } catch (err) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsTableLoading(false);
    }
  }

  // create new clients
  async function registerNewClient(newClientData) {
    setIsLoading(true);
    setIsTableLoading(true);
    let ReqObj = {
      clientEmail: newClientData.email,
      clientFirstName: newClientData.firstName,
      clientLastName: newClientData.lastName,
      clientPhoneNumber: newClientData.phoneNumber,
      companyName: newClientData.companyName,
      projectName: newClientData.projectName,
      companyAddress: newClientData.companyAddress,
      companyID: newClientData.companyID,
      projectID: newClientData.projectID,
      registrationDate: newClientData.registrationDate,
      expirationDate: newClientData.expirationDate,
    };
    try {
      const res = await RegisterServices.registerNewClient(
        username,
        password,
        ReqObj
      );
      setIsAuthenticated(true);
      setIsError(false);
      setIsModalOpen(false);
    } catch (err) {
      setIsAuthenticated(false);
      setUsername("");
      setPassword("");
    } finally {
      getClients();
      setIsLoading(false);
      newClientData(null);
      // setUsername("");
      // setPassword("");
    }
  }

  // update existing clients
  async function updateRegisteredClient(newClientData) {
    setIsLoading(true);
    setIsTableLoading(true);
    let ReqObj = {
      clientEmail: newClientData.registeredEmail,
      clientFirstName: newClientData.firstName,
      clientLastName: newClientData.lastName,
      clientPhoneNumber: newClientData.phoneNumber,
      companyName: newClientData.companyName,
      projectName: newClientData.projectName,
      companyAddress: newClientData.companyAddress,
      companyID: newClientData.companyID,
      projectID: newClientData.projectID,
      registrationDate: newClientData.dateRegistered,
      expirationDate: newClientData.expirationDate,
    };
    try {
      const res = await RegisterServices.updateRegisteredClient(
        username,
        password,
        ReqObj
      );
      setIsAuthenticated(true);
      setIsError(false);
      setIsModalOpen(false);
    } catch (err) {
      setIsAuthenticated(false);
      setUsername("");
      setPassword("");
    } finally {
      getClients();
      setIsLoading(false);
      setIsTableLoading(false);
      newClientData(null);
      // setUsername("");
      // setPassword("");
    }
  }

  // delete client
  async function deleteRegisteredClient(newClientData) {
    setIsLoading(true);
    setIsTableLoading(true);
    let ReqObj = {
      clientEmail: newClientData.registeredEmail,
      clientFirstName: newClientData.firstName,
      clientLastName: newClientData.lastName,
      clientPhoneNumber: newClientData.phoneNumber,
      companyName: newClientData.companyName,
      projectName: newClientData.projectName,
      companyAddress: newClientData.companyAddress,
      companyID: newClientData.companyID,
      projectID: newClientData.projectID,
      registrationDate: newClientData.dateRegistered,
      expirationDate: newClientData.expirationDate,
    };
    try {
      const res = await RegisterServices.deleteRegisteredClient(
        username,
        password,
        ReqObj
      );
      setIsAuthenticated(true);
      setIsError(false);
      setIsModalOpen(false);
    } catch (err) {
      setIsAuthenticated(false);
      setUsername("");
      setPassword("");
    } finally {
      getClients();
      setIsLoading(false);
      newClientData(null);
      // setUsername("");
      // setPassword("");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    getClients();
    // setUsername("");
    // setPassword("");
    setIsError(false);
  };

  // Handles submission of client creation form
  const handleClientSubmit = (formData) => {
    setNewClientData(formData);
    setIsCreateClientModalOpen(false);
    registerNewClient(formData);
    // setActionType("createNewClient");
    // showModal();
  };

  const handleEditSubmit = (formData) => {
    setNewClientData(formData);
    updateRegisteredClient(formData);

    // USED TO SHOW AUTHENTICATION MODAL BELOW

    // setActionType("editClient");
    // showModal();
  };

  const handleDelete = (formData) => {
    setNewClientData(formData);
    // deleteRegisteredClient(formData);
    setActionType("deleteClient");
    showModal();
  };

  const handleActions = (e) => {
    e.preventDefault();
    setIsModalOpen(false);

    // add CHECK if actionType is create new client
    if (actionType === "createNewClient") {
      registerNewClient(newClientData);
    } else if (actionType === "editClient") {
      updateRegisteredClient(newClientData);
    } else if (actionType === "deleteClient") {
      deleteRegisteredClient(newClientData);
    }
  };

  // TABLE FUNCTIONS
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      // name: "",
      // age: "",
      // address: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => record.key === item.key);

      record.companyName = row.companyName;
      record.projectName = row.projectName;
      record.dateRegistered = row.dateRegistered;
      record.expirationDate = row.expirationDate;

      // Compare the dates before submitting
      const dateRegistered = moment(record.dateRegistered);
      const expirationDate = moment(record.expirationDate);

      if (
        expirationDate.isValid() &&
        dateRegistered.isValid() &&
        expirationDate.isBefore(dateRegistered)
      ) {
        // Show error message to the user
        alert("Expiration Date should be after Registration Date");
        return;
      }

      handleEditSubmit(record);
      // console.log(record, "HERE?");

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setUpdatedData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setUpdatedData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteClient = async (record) => {
    const newData = [...data];
    const index = newData.findIndex((item) => record.key === item.key);

    handleDelete(record);

    // if (index !== -1) {
    //   newData.splice(index, 1);
    //   setData(newData);
    // }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      editable: false,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      editable: false,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      editable: false,
    },
    {
      title: "Address",
      dataIndex: "companyAddress",
      editable: false,
    },
    {
      title: "Email",
      dataIndex: "registeredEmail",
      editable: false,
    },
    {
      title: "Company ID",
      dataIndex: "companyID",
      editable: false,
    },
    {
      title: "Project ID",
      dataIndex: "projectID",
      editable: false,
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      editable: true,
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      editable: true,
    },
    {
      title: "Date Registered",
      dataIndex: "dateRegistered",
      editable: true,
    },
    {
      title: "Expiration Date",
      dataIndex: "expirationDate",
      editable: true,
    },

    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ""}
              style={{
                marginLeft: 10,
                color: editingKey === "" ? "red" : "#BBBBBB",
              }}
              onClick={() => deleteClient(record)}
            >
              Delete
            </Typography.Link>
          </span>
        );
      },
      width: "10%",
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "expirationDate" || "dateRegistered"
            ? "date"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Container className="mt-4">
      {!isAuthenticated && (
        <RegisterForm
          username={username}
          password={password}
          isLoading={isLoading}
          isError={isError}
          setUsername={setUsername}
          setPassword={setPassword}
          handleSubmit={handleSubmit}
        />
      )}

      {isAuthenticated && (
        <>
          <div className=" d-flex flex-row justify-content-end">
            <Button
              className="ms-2 align-self-end mb-4"
              onClick={showCreateClientModal}
              type="primary"
            >
              + New Client
            </Button>
          </div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
              loading={isTableLoading}
              pagination={{
                onChange: cancel,
                defaultPageSize: "20",
              }}
            />
          </Form>

          <Modal open={isModalOpen} footer={null} closable={false}>
            <ConfirmForm
              username={username}
              password={password}
              isLoading={isLoading}
              isError={isError}
              setUsername={setUsername}
              setPassword={setPassword}
              handleActions={handleActions}
            />
          </Modal>

          <Modal
            open={isCreateClientModalOpen}
            onCancel={handleCancel}
            footer={null}
          >
            <CreateNewClient handleClientSubmit={handleClientSubmit} />
          </Modal>
        </>
      )}
    </Container>
  );
}

export default RegisterClient;
