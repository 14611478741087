import ActiveProfiles from "components/profiles";
import ActiveUsersAvatar from "components/profiles/ActiveUsersAvatar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ColoredListIcon from "assets/images/sub-division-list-colored-icon.svg";
import ListIcon from "assets/images/sub-division-list-icon.svg";
import React from "react";
import Text from "components/text";
import styles from "./div.module.css";
import { useSelector } from "react-redux";

const SubDivisionItemHeader = ({
  collapsed,
  setcollapsed,
  name,
  length,
  handleCollapse,
  checkedRows,
  setCheckedRows,
}) => {
  const { projectDetails } = useSelector((state) => state.projectDetails);
  const subDivisionProgress = projectDetails?.subdivisionsProgress?.[`${name}`];
  return (
    <div className="row ">
      <div className={`col-3 p-1`}>
        <div className={`${styles.blueBorder} ${styles.divisionHeader}`}>
          <Text className={`${styles.subDivisonName}`}>{name}</Text>
        </div>
      </div>

      <div
        onClick={() => {
          handleCollapse(name);
        }}
        className={`col-3 p-1 pointer`}
      >
        <div
          className={`d-flex align-items-center justify-content-between ${styles.divisionHeader}`}
        >
          <ActiveProfiles />

          <div
            className="d-flex align-items-center pointer"
            // onClick={() => setcollapsed((prev) => !prev)}
          >
            <ArrowForwardIosIcon
              fontSize="var(--fs-18)"
              className={`
              ${
                collapsed.includes(name) ? styles.activeArrow : styles.nonActive
              } 
              ${styles.arrow}
              `}
            />
            <img
              src={collapsed.includes(name) ? ColoredListIcon : ListIcon}
              alt="List-Icon"
            />
            <Text
              className={`${styles.numberOfItems} 
            ${collapsed.includes(name) ? styles.colored : ""}`}
            >
              {length}
            </Text>
          </div>
        </div>
      </div>

      <div className={`d-flex align-items-center col-6 p-1`}>
        <div
          className={`${styles.divisionHeader} d-flex align-items-center justify-content-end pe-1`}
        >
          <div
            className="d-flex align-items-center gap-2"
            style={{ fontSize: "var(--fs-12)" }}
          >
            <span
              style={{
                color: "gray",
                backgroundColor: "#f0f0f0",
                padding: "0.2rem 0.5rem",
                borderRadius: "5px",
              }}
            >
              {subDivisionProgress?.draft} draft
            </span>
            <span
              style={{
                color: "#EC9700",
                backgroundColor: "#fff4e6",
                padding: "0.2rem 0.5rem",
                borderRadius: "5px",
              }}
            >
              {subDivisionProgress?.approaching} pending
            </span>
            <span
              style={{
                color: "#E60200",
                backgroundColor: "#ffe6e6",
                padding: "0.2rem 0.5rem",
                borderRadius: "5px",
              }}
            >
              {subDivisionProgress?.overdue} overdue
            </span>
            <span
              style={{
                color: "#0195D9",
                backgroundColor: "#e6f0ff",
                padding: "0.2rem 0.5rem",
                borderRadius: "5px",
              }}
            >
              {subDivisionProgress?.completed} completed
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubDivisionItemHeader;
