import { Button, Input, Modal, Select, Table } from "antd";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "components/button-component";
import CloseIcon from "@mui/icons-material/Close";
import Container from "components/container";
import ContentContainer from "components/content-container";
import { EditOutlined } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import InputComponent from "components/input-component";
import { PermissionServices } from "apis/Services/PermissionsService";
import SearchIcon from "assets/images/search-icon.svg";
import SeparateLine from "components/separate-line";
import Text from "components/text";
import styles from "./project-reports.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/loading-component";
import permissionsLevels from "constants/permissionsLevels";

const tabs = [
  {
    id: 1,
    text: "Canned Reports",
    value: "canned-reports",
    path: "canned-reports",
  },
  // {
  //   id: 2,
  //   text: "Custom Reports",
  //   value: "project-custom-reports",
  //   path: "project-custom-reports",
  // },
];
const ProjectReports = ({ pageType }) => {
  const [selectedTab, setselectedTab] = useState(tabs[0]);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { company_id, id } = useParams();

  return (
    <>
      <div className={`d-flex flex-column nav-rest`}>
        <div className="d-flex justify-content-center px-3 ">
          <SeparateLine />
        </div>
        <Row
          className={`align-items-center justify-content-between gap-4 py-2 px-2`}
        >
          <Col>
            <div className={`d-flex align-items-center gap-4 py-2 px-2`}>
              <div className="d-flex gap-2 align-items-center py-1">
                <Text
                  className={`main-nav-title`}
                  fontFamily={"var(--ff-bold)"}
                >
                  Reports
                </Text>
              </div>
              <div className="d-flex align-items-center flex-wrap gap-3">
                {tabs.map((item, index) => (
                  <Link
                    key={index}
                    className="pointer"
                    to={`/main/company/${company_id}/projects/${id}/${item.path}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Text
                      fontSize={14}
                      // color={selectedTab.id == item.id ? "#0196DB" : "black"}
                      color={
                        location.pathname.includes(item.path)
                          ? "#0196DB"
                          : "black"
                      }
                      fontFamily={"var(--ff-bold)"}
                    >
                      {item.text}
                    </Text>
                  </Link>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProjectReports;
