import { ContactsEndPoints } from "apis/EndPoints/ContactsEndPoints";
import { Network } from "../Network";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class ContactServices {
  static getProjectsContactPage(data = {}) {
    return Network.fetch(
      ContactsEndPoints.getProjectsContactPage.url(data?.company_id, data.id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ContactsEndPoints.getProjectsContactPage.method,
      }
    );
  }

  static AddContact(data = {}) {
    return Network.fetch(
      ContactsEndPoints.AddContact.url(data?.company_id, data.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ContactsEndPoints.AddContact.method,
        body: JSON.stringify(data),
      }
    );
  }

  static EditContact(data = {}) {
    return Network.fetch(
      ContactsEndPoints.EditContact.url(data?.company_id, data.id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ContactsEndPoints.EditContact.method,
        body: JSON.stringify(data?.editDetails),
      }
    );
  }

  static removeContact(data = {}) {
    return Network.fetch(
      ContactsEndPoints.removeContact.url(data?.company_id, data.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ContactsEndPoints.removeContact.method,
        body: JSON.stringify(data),
      }
    );
  }

  static resyncContacts(data = {}) {
    return Network.fetch(
      ContactsEndPoints.resyncContacts.url(data?.company_id, data.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ContactsEndPoints.resyncContacts.method,
      }
    );
  }

  static readUserSpecificCompanyAndProjectAssignablePermissionsTemplates(
    data = {}
  ) {
    return Network.fetch(
      ContactsEndPoints.readUserSpecificCompanyAndProjectAssignablePermissionsTemplates.url(
        data?.company_id,
        data?.project_id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method:
          ContactsEndPoints
            .readUserSpecificCompanyAndProjectAssignablePermissionsTemplates
            .method,
      }
    );
  }

  static resendInvitation(data = {}) {
    return Network.fetch(
      ContactsEndPoints.resendInvitation.url(data?.company_id, data.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ContactsEndPoints.resendInvitation.method,
        body: JSON.stringify(data?.invitationDetails),
      }
    );
  }
}
