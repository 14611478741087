import { PermissionServices } from "apis/Services/PermissionsService";
import React from "react";
import { saveCompanyPermissions } from "redux/permission-reducer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const UseGetCompanyPermissions = ({ company_id = "", reload = 0 } = {}) => {
  const [companyPermission, setcompanyPermission] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      code,
      loginEmail,
    };
    PermissionServices.getCompanyPermissionsTemplate(data)
      .then((res) => {
        if (typeof res == "object") {
          dispatch(saveCompanyPermissions(res));
          setcompanyPermission(res);
        }
      })
      .catch((err) => console.log(err));
  }, [reload]);
  return companyPermission;
};

export default UseGetCompanyPermissions;
