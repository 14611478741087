import { AuthEndPoints } from "../EndPoints/AuthEndPoints";
import { Network } from "../Network";

export class AuthService {
  static post_login(data) {
    return Network.fetch(AuthEndPoints.login.url, {
      method: AuthEndPoints.login.method,
      body: JSON.stringify(data),
    });
  }

  static sendVerificationCodeLink(data) {
    return Network.fetch(AuthEndPoints.sendVerificationCode.url, {
      method: AuthEndPoints.sendVerificationCode.method,
      body: JSON.stringify(data),
    });
  }

  static resetPassword(data) {
    return Network.fetch(AuthEndPoints.resetPssword.url, {
      method: AuthEndPoints.resetPssword.method,
      body: JSON.stringify(data),
    });
  }

  static refreshToken(data) {
    return Network.fetch(AuthEndPoints.refreshTokenCode.url(data.company_id), {
      body: JSON.stringify(data),
      method: AuthEndPoints.refreshTokenCode.method,
    });
  }
  static requestDemo(data) {
    return Network.fetch(AuthEndPoints.requestDemo.url, {
      body: JSON.stringify(data),
      method: AuthEndPoints.requestDemo.method,
    });
  }
}
