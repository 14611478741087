import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const CREATE_PROJECT_SCHEMA = yupResolver(
  yup.object().shape({
    projectNumber: yup.string().required("This field is required"),
    // workdays: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       name: yup.string().required(),
    //       startDate: yup.string().required(),
    //       endDate: yup.string().required(),
    //     })
    //   )
    //   .compact((v) => !v.checked)
    //   .min(0),
  })
);
