import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Divider, Tooltip } from "antd";
import React, { useState } from "react";

import PersonsModal from "components/project-details-components/PersonsModal";
import Text from "components/text";
import styles from "./profiles.module.css";

const ActiveUsersAvatar = ({ ActiveUsers, ActiveUsersIcon }) => {
  const [IsPersonsModalVisible, setIsPersonsModalVisible] = useState(false);
  // const [ActiveUsersIcon, setActiveUsersIcon] = useState([]);

  const handlePersonsModalVisibility = () => {
    setIsPersonsModalVisible((prev) => !prev);
  };


  const firstCharacters = [];
  if (ActiveUsersIcon) {
    for (const pair of ActiveUsersIcon) {
      for (const name of pair) {
        const firstChar = name.charAt(0);
        firstCharacters.push(firstChar);
      }
    }
  }
  const groupedCharacters = [];
  for (let i = 0; i < firstCharacters.length; i += 2) {
    const combinedCharacters = firstCharacters.slice(i, i + 2).join("");
    groupedCharacters.push(combinedCharacters);
  }

  return (
    <>
      {/* className={`${styles.header}`} */}
      <div
        style={{
          width: "100%",
          display: "flex",
          textAlign: "center",
          justifyContent: "end",
        }}
      >
        <p
          onClick={handlePersonsModalVisibility}
          className={`${styles.header}`}
        >
          Active
        </p>
      </div>
      <Avatar.Group>
        {groupedCharacters.map((chr, index) => {
          const backgroundColor = index % 2 === 0 ? "#f56a00" : "#f56a00";

          return <Avatar style={{ backgroundColor }}>{chr}</Avatar>;
        })}
        {/* <Avatar style={{ backgroundColor: "#87d068" }}>r</Avatar>
        <Avatar style={{ backgroundColor: "#f56a00" }}>q</Avatar>
        <Avatar style={{ backgroundColor: "#87d068" }}>f</Avatar>
        <Avatar style={{ backgroundColor: "#f56a00" }}>s</Avatar>
        <Avatar style={{ backgroundColor: "#87d068" }}>z</Avatar> */}
      </Avatar.Group>

      <PersonsModal
        isVisible={IsPersonsModalVisible}
        closeModal={setIsPersonsModalVisible}
        ActiveUsers={ActiveUsers}
      />
    </>
  );
};

export default ActiveUsersAvatar;
