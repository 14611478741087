import React, { useState } from "react";
import { Modal } from "antd";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Select, InputNumber, Tooltip, DatePicker, Space } from "antd";
import ButtonComponent from "components/button-component";
import Container from "components/container";
import moment from "moment";

const CustomReportFilters = ({ returnedData, columnsUsed, alignedData }) => {
  const { control, handleSubmit, formState, reset } = useForm();

  const [filterType, setFilterType] = useState("");
  const [specifiedColumn, setSpecifiedColumn] = useState();
  const [sortBy, setSortBy] = useState();
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [applyFilterFlag, setApplyFilterFlag] = useState(false);
  const [resetFilterFlag, setResetFilterFlag] = useState(false);

  function handleSpecifiedColumn(v) {
    setSpecifiedColumn(v);
    setFilterType(alignedData[v]?.type);

    setApplyFilterFlag(true);
  }

  function handleSortChange(v) {
    setSortBy(v);
    setApplyFilterFlag(true);
  }

  function handleMinValueChange(v) {
    setMinValue(v.toString());
    setApplyFilterFlag(true);
  }
  function handleMaxValueChange(v) {
    setMaxValue(v.toString());
    setApplyFilterFlag(true);
  }

  function handleMinDate(date, dateString) {
    if (dateString) {
      setMinValue(dateString);
      setApplyFilterFlag(true);
    }
  }

  function handleMaxDate(date, dateString) {
    if (dateString) {
      setMaxValue(dateString);
      setApplyFilterFlag(true);
    }
  }
  const [showModal, setShowModal] = useState(false);

  const handleResetFilters = (v) => {
    setSpecifiedColumn();
    setMaxValue("");
    setMinValue("");
    setSortBy();
    setFilterType("");

    let data = {
      specifiedColumn: "",
      sortBy: "",
      maxValue: "",
      minValue: "",
    };
    returnedData(data);

    setApplyFilterFlag(false);
    setResetFilterFlag(false);
  };

  const handleSubmitFilters = () => {
    let data = {
      specifiedColumn: specifiedColumn,
      sortBy: sortBy ? sortBy : "Ascending",
      maxValue: maxValue,
      minValue: minValue,
    };
    // handleSubmit(ApplyFilters)();
    returnedData(data);
    setApplyFilterFlag(false);
    setResetFilterFlag(true);
  };

  const handleConfirm = () => {
    setShowModal(false);
    reset();
    // handleSubmit(ApplyFilters)();
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <div className=" d-flex flex-row align-items-center mb-4 mt-4 bg-white rounded-3">
        <div className=" d-flex flex-row w-100">
          <form
            className="d-flex flex-row w-100"
            // onSubmit={handleSubmit(ApplyFilters)}
          >
            <div className=" mx-2 d-flex flex-row align-items-center gap-2">
              <div className="">
                <Controller
                  name="specifiedColumn"
                  control={control}
                  render={({ field }) => (
                    <div className="d-flex flex-row gap-2 align-items-center">
                      <div>
                        <label className="text-nowrap">Filter:</label>
                      </div>
                      <div className="w-100">
                        <Select
                          style={{ width: "200px" }}
                          placeholder="Choose a Column"
                          value={specifiedColumn}
                          onChange={handleSpecifiedColumn}
                          options={columnsUsed.map((item) => ({
                            value: item,
                            label: alignedData[item]?.label || item,
                          }))}
                          //   {...field}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>

              <div className="">
                <Controller
                  name="sortBy"
                  control={control}
                  render={({ field }) => (
                    <div className="d-flex flex-row gap-2 align-items-center">
                      <div>
                        <label className="text-nowrap">Sort by:</label>
                      </div>

                      <div>
                        <Select
                          value={sortBy}
                          placeholder="Ascending"
                          style={{ width: "150px" }}
                          onChange={handleSortChange}
                          options={[
                            {
                              value: "Ascending",
                              label: "Ascending",
                            },
                            {
                              value: "Descending",
                              label: "Descending",
                            },
                          ]}
                          //   {...field}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>

              <div className=" d-flex flex-row">
                {filterType === "date" ? (
                  <div className=" d-flex gap-2 align-items-center">
                    <label>Start Date: </label>
                    <DatePicker
                      format="MM-DD-YYYY"
                      onChange={handleMinDate}
                      style={{ width: "150px" }}
                    />

                    <label>End Date: </label>
                    <DatePicker
                      format="MM-DD-YYYY"
                      onChange={handleMaxDate}
                      style={{ width: "150px" }}
                    />
                  </div>
                ) : filterType === "int" ? (
                  <div className=" d-flex flex-row align-items-center gap-2">
                    <label>Min:</label>
                    <InputNumber
                      style={{
                        textAlignLast: "center",
                        width: "50px",
                      }}
                      placeholder="1"
                      type="number"
                      value={minValue}
                      controls={false}
                      onChange={handleMinValueChange}
                    />

                    <label>Max:</label>
                    <InputNumber
                      style={{
                        textAlignLast: "center",
                        width: "50px",
                      }}
                      type="number"
                      value={maxValue}
                      placeholder="100"
                      controls={false}
                      onChange={handleMaxValueChange}
                    />
                  </div>
                ) : filterType === "string" ? (
                  <></>
                ) : (
                  <></>
                )}
              </div>

              <div className=" d-flex flex-row gap-2">
                <div className=" py-2">
                  <ButtonComponent
                    type="button"
                    className=" px-3 "
                    onClickAction={handleSubmitFilters}
                    title="Apply Filters"
                    disabled={!applyFilterFlag}
                  />
                </div>

                {(resetFilterFlag || applyFilterFlag) && (
                  <div className=" py-2">
                    <button
                      type="button"
                      className=" btn btn-light rounded-3"
                      onClick={handleResetFilters}
                    >
                      Reset Filters
                    </button>
                  </div>
                )}
              </div>
            </div>
            <Modal
              title="Unsaved Changes"
              open={showModal}
              onOk={handleConfirm}
              onCancel={handleCancel}
              okText="Proceed"
              cancelText="Cancel"
            >
              You have unsaved changes. Are you sure you want to proceed?
            </Modal>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CustomReportFilters;
