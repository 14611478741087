import { Button, Tooltip, Table, Modal, Menu, Alert } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import styles from "./company-dashboard.module.css";
import moment from "moment";
import { DeleteFilled } from "@ant-design/icons";
import { CompanyAdminServices } from "apis/Services/CompanyAdminService";

export default function DashboardViews({
  viewsList,
  selectedViewName,
  setSelectedViewName,
  setCreateFlag,
  readCompanySchedule,
  handleEdit,
}) {
  let code = localStorage.getItem("token");
  let loginEmail = localStorage.getItem("email");
  let Auth = {
    code,
    loginEmail,
  };

  const { company_id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedViewColumns, setSelectedViewColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const standardViews = viewsList
    .filter((view) => view.viewCreatedBy === "Koncurent")
    .map((view, index) => ({
      key: view.viewName,
      label: view.viewName,
    }));

  const myViews = viewsList
    .filter((view) => view.viewCreatedBy !== "Koncurent")
    .map((view, index) => ({
      key: view.viewName,
      label: view.viewName,
    }));

  const viewDetailsColumn = [
    {
      title: "Column Name",
      dataIndex: "label",
      key: "label",
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => <div>{text}</div>,
    },
  ];

  const handleMenuSelect = ({ key }) => {
    const selectedView = viewsList.find((view, index) => view.viewName === key);
    setSelectedViewName(selectedView);
    if (selectedView) {
      const columnInfo = Object.keys(selectedView?.columnsInfo)
        .filter((colKey) => !["entryID", "specSection"].includes(colKey)) // hide entryID and specSection from the table
        .map((key) => {
          const col = selectedView.columnsInfo[key];
          return {
            key: key,
            label: col.label,
            description: col.description,
            hidden: col.hidden,
          };
        })
        .filter((col) => col.hidden !== "true"); // remove hidden items

      setSelectedViewColumns(columnInfo);
    }
  };

  const items = [
    {
      key: "1",
      label: "Standard Views",
      children: standardViews,
    },
    {
      key: "2",
      label: "My Views",
      children: myViews,
    },
  ];

  // HOME TABLE

  const deleteProcurementLogsViews = (value) => {
    setIsLoading(true);
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      viewName: value,
    };
    CompanyAdminServices.deleteProcurementLogsViews(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = (item) => {
    if (item.createdBy !== "Koncurent") {
      Modal.confirm({
        title: "Are you sure you want to delete this view?",
        content: "This action is permanent and cannot be undone.",
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        onOk() {
          deleteProcurementLogsViews(item.view);
          readCompanySchedule();
        },
        onCancel() {
          // console.log("Delete action cancelled");
        },
      });
    }
  };

  const dataSource = viewsList.map((view, index) => ({
    key: index.toString(),

    view: view.viewName,
    description: view.viewDescription,
    projects: view.viewProjects,
    projectsCount: `${view.viewProjects.length} projects selected`,
    createdBy: view.viewCreatedBy,
    dateCreated: view.viewDateCreated
      ? moment(view.viewDateCreated).format("MM/DD/YY")
      : "",
  }));

  const columns = [
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <div style={{ cursor: "pointer" }}>
          {record.view === "Koncurent Standard View" ||
          record.view === "Blank View" ? (
            <Tooltip>{record.view}</Tooltip>
          ) : (
            <Tooltip title="Edit" onClick={() => handleEdit(record)}>
              {record.view}
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Projects",
      dataIndex: "projectsCount",
      key: "projectsCount",
      render: (text, record) => (
        <div>
          <b>{record.projectsCount}</b>
          <div className=" d-flex flex-column">
            {record.projects.slice(0, 10).map((project, index) => (
              <small key={index}>{project}</small>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
    },
    {
      title: "Actions",
      align: "center",
      key: "actions",
      render: (text, record) => (
        <Tooltip title="Delete">
          <DeleteFilled
            onClick={() => handleDelete(record)}
            style={{
              color: record.createdBy === "Koncurent" ? "gray" : "red",
              cursor:
                record.createdBy === "Koncurent" ? "not-allowed" : "pointer",
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <div className="my-4 d-flex justify-content-between">
        <Text className={`${styles.title} mb-2`} dontWrap={false}>
          Dashboard Views
        </Text>
        <Button
          type="primary"
          onClick={() => {
            const defaultView = viewsList.find(
              (view) => view.viewName === "Koncurent Standard View"
            );
            handleMenuSelect({
              key: defaultView ? defaultView.viewName : "",
            });
            setModalOpen(true);
          }}
        >
          New Dashboard View
        </Button>
      </div>

      <div className=" pb-3">
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={isLoading ? { indicator: <LoadingComponent /> } : false}
        />
      </div>

      <Modal
        title="New Dashboard View"
        centered
        open={modalOpen}
        onOk={() => {
          setModalOpen(false);
          setCreateFlag(true);
        }}
        onCancel={() => setModalOpen(false)}
        okText="Create"
        width={900}
      >
        <div>
          <Alert
            className=" my-3"
            message="To create a new view, first select a view from either Standard Views or My Views. Once you've chosen a view, you can adjust and configure the columns as needed."
            type="info"
          />
          <div className=" d-flex">
            <div>
              <Menu
                style={{
                  width: 256,
                  height: 500,
                  overflowY: "scroll",
                }}
                defaultSelectedKeys={["1", "Koncurent Standard View"]}
                defaultOpenKeys={["1", "2"]}
                mode="inline"
                items={items}
                onSelect={handleMenuSelect}
              />
            </div>
            <div className=" w-100 ms-3">
              <h3>{selectedViewName.viewName}</h3>
              <p>{selectedViewName.viewDescription}</p>
              <Table
                style={{
                  maxHeight: 500,
                }}
                scroll={{ y: 400 }}
                bordered
                dataSource={selectedViewColumns}
                columns={viewDetailsColumn}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
