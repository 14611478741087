import React from "react";
import Styles from "./components.module.css";

const Container = ({ children, style = {}, className = "" }) => {
  return (
    <div
      style={{ ...containerStyle, ...style }}
      className={`${className} ${Styles.container}`}
    >
      {children}
    </div>
  );
};

export default Container;

const containerStyle = {
  width: "98vw",
  // maxWidth: 1920,
  marginInline: "auto",
};
