import { Accordion } from "react-bootstrap";
import Container from "components/container";
import ContentContainer from "components/content-container";
import React from "react";
import styles from"./styles.module.css";

function KoncurentSupport() {
  const items = [
    {
      key: "1",
      label: "Privacy Policy",
      children: (
        <p>
          Privacy Policy At Koncurent, we take your privacy seriously. This
          Privacy Policy explains our practices regarding the collection, use,
          and disclosure of information that we receive from users of our
          website and services.
          <br /> <br /> 1. Information We Collect We collect information that you
          provide to us, such as your name, email address, postal address, and
          phone number when you register for our services, make a purchase, or
          otherwise communicate with us. We may also collect information about
          your use of our website and services, such as your IP address, browser
          type, and device ID.
          <br /> <br /> 2. How We Use Your Information We use your information to
          provide our services to you, to communicate with you about our
          services, and to improve our website and services. We may also use
          your information to send you marketing communications about our
          services, unless you opt out of such communications.
          <br /> <br /> 3. Sharing Your Information We do not sell or rent your
          personal information to third parties. We may share your information
          with our third-party service providers who help us to provide our
          services or process payments. We may also share your information if
          required by law or to protect our rights or the rights of others.
          <br /> <br /> 4. Security We take reasonable measures to protect your
          information from unauthorized access, use, or disclosure. However, no
          method of transmission over the Internet or electronic storage is 100%
          secure, and we cannot guarantee the absolute security of your
          information.
          <br /> <br /> 5. Your Choices You may choose to opt out of receiving
          marketing communications from us at any time by following the
          instructions in the communications. You may also access, correct, or
          delete your personal information by contacting us.
          <br /> <br /> 6. Children's Privacy Our website and services are not intended
          for use by children under the age of 13. We do not knowingly collect
          personal information from children under 13. If we become aware that
          we have collected personal information from a child under 13, we will
          take steps to delete the information as soon as possible.
          <br /> <br /> 7. Changes to This Privacy Policy We may update this Privacy
          Policy from time to time. If we make material changes to this Privacy
          Policy, we will notify you by email or by posting a notice on our
          website.
          <br /> <br /> 8. Contact Us If you have any questions or concerns about our
          Privacy Policy, please contact us.
        </p>
      ),
    },
    {
      key: "2",
      label: "Procore Integration",
      children: (
        <p>
          {" "}
          Koncurent processes submittals and Specifications data from procore,
          and transforms them into a ready to use procurement log that can keep
          projects on track. Using Koncurent enables you to keep track of your
          project items, and the team members assigned to those items in order
          to never miss a deadline, with an early warning system put in place
          that will notify you when an item is approaching its due data.
          Starting a new project, or integrating an already built project has
          never been easier, as Koncurent is continuously syncing with procore
          to make sure your projects are always up to date.
        </p>
      ),
    },
    {
      key: "3",
      label: "How do you get started with Koncurent?",
      children: (
        <p>
          {" "}
          Please reach out to us at sales@koncurent.com with any questions you
          have.
        </p>
      ),
    },
  ];
  return (
    <div>
      <Container>
        <ContentContainer>
          <div className={` ${styles.Con}`}>
          <Accordion defaultActiveKey="1">
            {items.map((item) => (
              <Accordion.Item eventKey={item.key}>
                <Accordion.Header>{item.label}</Accordion.Header>
                <Accordion.Body>{item.children}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          </div>
        </ContentContainer>
      </Container>
    </div>
  );
}

export default KoncurentSupport;
