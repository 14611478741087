import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ProjectHeader from "components/project-header";
import styles from "./reports-details.module.css";
import Container from "components/container";
import ButtonComponent from "components/button-component";
import { Table, Tooltip, DatePicker, Button } from "antd";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import ReportFilters from "components/report-filters";
import { ReportServices } from "apis/Services/ReportService";
import ExportReports from "components/export-reports";
import dayjs from "dayjs";

export default function ReportsDetails() {
  const { company_id, id, reportName } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [editedData, setEditedData] = useState([]);

  let reportPageTitle = "";
  let firstColumnName = "";
  let secondColumnName = "";

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    setEditingKey(record.key);
  };

  const handleDateChange = (value, record) => {
    //UPDATE VISUALLY BEFORE SAVING
    const updatedDataSource = dataSource.map((item) => {
      if (item.key === record.key) {
        const updatedItem = {
          ...item,
          plannedIssueDate: value ? value.format("MM-DD-YYYY") : null,
        };
        const existingEditedIndex = editedData.findIndex(
          (entry) => entry.entryID === updatedItem.key
        );

        //Check if entryId exists
        if (existingEditedIndex !== -1) {
          const updatedEditedData = [...editedData];
          updatedEditedData[existingEditedIndex] = {
            entryID: updatedItem.key,
            plannedIssuedDate: updatedItem.plannedIssueDate,
          };
          setEditedData(updatedEditedData);
        } else {
          setEditedData([
            ...editedData,
            {
              entryID: updatedItem.key,
              plannedIssuedDate: updatedItem.plannedIssueDate,
            },
          ]);
        }
        return updatedItem;
      }
      return item;
    });
    //Update rendered Data
    setEditingKey("");
    setDataSource(updatedDataSource);
  };

  const save = (key) => {
    // Save logic here
    setEditingKey("");
  };
  const dateFormat = "MM-DD-YYYY";

  // let columns = [
  //   {
  //     title: "Item Name",
  //     dataIndex: "itemName",
  //     key: "itemName",
  //     align: "left",
  //     width: 400,
  //     fixed: "left",
  //   },
  //   {
  //     title: "Submittal Number",
  //     dataIndex: "submittalNumber",
  //     key: "submittalNumber",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Submittal Revison",
  //     dataIndex: "submittalRevison",
  //     key: "submittalRevison",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Submittal Response",
  //     dataIndex: "submittalResponse",
  //     key: "submittalResponse",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Responsible Contractor",
  //     dataIndex: "responsibleContractor",
  //     key: "responsibleContractor",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Issue Contract",
  //     dataIndex: "issueContract",
  //     key: "issueContract",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Contract Issued Release",
  //     dataIndex: "contractIssuedRelease",
  //     key: "contractIssuedRelease",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Review Time",
  //     dataIndex: "reviewTime",
  //     key: "reviewTime",
  //     align: "center",
  //     width: 100,
  //   },
  //   {
  //     title: "Submittal Required",
  //     dataIndex: "submittalRequired",
  //     key: "submittalRequired",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Submittal Issued",
  //     dataIndex: "submittalIssued",
  //     key: "submittalIssued",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Approval Time",
  //     dataIndex: "approvalTime",
  //     key: "approvalTime",
  //     align: "center",
  //     width: 100,
  //   },
  //   {
  //     title: "Submittal Approval Due",
  //     dataIndex: "submittalApprovalDue",
  //     key: "submittalApprovalDue",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Submittal Returned",
  //     dataIndex: "submittalReturned",
  //     key: "submittalReturned",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Lead Time",
  //     dataIndex: "leadTime",
  //     key: "leadTime",
  //     align: "center",
  //     width: 100,
  //   },
  //   {
  //     title: "Schedule ID",
  //     dataIndex: "scheduleID",
  //     key: "scheduleID",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Date Required on Site",
  //     dataIndex: "dateRequiredonSite",
  //     key: "dateRequiredonSite",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Projected Delivery Date",
  //     dataIndex: "projectedDeliveryDate",
  //     key: "projectedDeliveryDate",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Sub Committed Delivery Date",
  //     dataIndex: "subCommittedDeliveryDate",
  //     key: "subCommittedDeliveryDate",
  //     align: "center",
  //     width: 150,
  //   },
  //   {
  //     title: "Actual Delivery Date",
  //     dataIndex: "actualDeliveryDate",
  //     key: "Actual Delivery Date",
  //     align: "center",
  //     width: 150,
  //   },
  // ];
  let columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      align: "left",
      width: 400,
      fixed: "left",
    },
    {
      title: "Submittal Number",
      dataIndex: "submittalNumber",
      key: "submittalNumber",
      align: "center",
      width: 150,
      fixed: "left",
    },
    {
      title: "Submittal Revison",
      dataIndex: "submittalRevison",
      key: "submittalRevison",
      align: "center",
      width: 150,
      fixed: "left",
    },
    {
      title: "Submittal Type",
      dataIndex: "submittalType",
      key: "submittalType",
      align: "center",
      width: 150,
    },
  ];

  let itemDefeciencesColumns = [
    {
      title: "Submittal Response",
      dataIndex: "submittalResponse",
      key: "submittalResponse",
      align: "center",
      width: 150,
    },
    {
      title: "Responsible Contractor",
      dataIndex: "responsibleContractor",
      key: "responsibleContractor",
      align: "center",
      width: 150,
    },
    {
      title: "Issue Contract",
      dataIndex: "issueContract",
      key: "issueContract",
      align: "center",
      width: 150,
    },
    {
      title: "Contract Issued Release",
      dataIndex: "contractIssuedRelease",
      key: "contractIssuedRelease",
      align: "center",
      width: 150,
    },
    {
      title: "Review Time",
      dataIndex: "reviewTime",
      key: "reviewTime",
      align: "center",
      width: 100,
    },
    {
      title: "Submittal Required",
      dataIndex: "submittalRequired",
      key: "submittalRequired",
      align: "center",
      width: 150,
    },
    {
      title: "Submittal Issued",
      dataIndex: "submittalIssued",
      key: "submittalIssued",
      align: "center",
      width: 150,
    },
    {
      title: "Approval Time",
      dataIndex: "approvalTime",
      key: "approvalTime",
      align: "center",
      width: 100,
    },
    {
      title: "Submittal Approval Due",
      dataIndex: "submittalApprovalDue",
      key: "submittalApprovalDue",
      align: "center",
      width: 150,
    },
    {
      title: "Submittal Returned",
      dataIndex: "submittalReturned",
      key: "submittalReturned",
      align: "center",
      width: 150,
    },
    {
      title: "Lead Time",
      dataIndex: "leadTime",
      key: "leadTime",
      align: "center",
      width: 100,
    },
    {
      title: "Schedule ID",
      dataIndex: "scheduleID",
      key: "scheduleID",
      align: "center",
      width: 150,
    },
    {
      title: "Date Required on Site",
      dataIndex: "dateRequiredonSite",
      key: "dateRequiredonSite",
      align: "center",
      width: 150,
    },
    {
      title: "Projected Delivery Date",
      dataIndex: "projectedDeliveryDate",
      key: "projectedDeliveryDate",
      align: "center",
      width: 150,
    },
    {
      title: "Sub Committed Delivery Date",
      dataIndex: "subCommittedDeliveryDate",
      key: "subCommittedDeliveryDate",
      align: "center",
      width: 150,
    },
    {
      title: "Actual Delivery Date",
      dataIndex: "actualDeliveryDate",
      key: "actualDeliveryDate",
      align: "center",
      width: 150,
    },
  ];

  let reportRequestName;

  switch (reportName) {
    case "high-lead-time":
      reportRequestName = "highLeadTimeReport";
      reportPageTitle = "High Lead Time Report";
      columns = [
        ...columns,
        {
          title: "Lead Time",
          dataIndex: "leadTime",
          key: "leadTime",
          align: "center",
          width: 100,
        },
      ];
      break;
    case "subcontractor-health":
      reportRequestName = "subcontractorHealthReport";
      reportPageTitle = "Subcontractor Health Report";
      columns = [
        ...columns,
        {
          title: "Responsible Contractor",
          dataIndex: "responsibleContractor",
          key: "responsibleContractor",
          align: "center",
          fixed: "left",
          width: 200,
        },
        {
          title: "Date Required on Site",
          dataIndex: "dateRequiredonSite",
          key: "dateRequiredonSite",
          align: "center",
          width: 200,
        },
        {
          title: "Sub Committed Delivery Date",
          dataIndex: "subCommittedDeliveryDate",
          key: "subCommittedDeliveryDate",
          align: "center",
          width: 150,
        },
        {
          title: "Actual Delivery Date",
          dataIndex: "actualDeliveryDate",
          key: "actualDeliveryDate",
          align: "center",
          width: 150,
        },
        {
          title: (
            <>
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line", maxWidth: "500px" }}
                title={`• Actual Delivery Date - Subcontractor Committed Date 
                • ⁠If Subcontractor Committed Date = 0
                • ⁠Then Actual Delivery Date - Required on Site`}
              >
                Status
              </Tooltip>
            </>
          ),
          dataIndex: "status",
          key: "status",
          align: "center",
          width: 400,
        },
      ];
      break;
    case "item-deficiencies":
      reportRequestName = "itemDeficienciesReport";
      reportPageTitle = "Item Deficiencies Report";
      columns = [
        ...columns,
        ...itemDefeciencesColumns,
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          align: "center",
          width: 200,
        },
      ];
      break;
    case "design-team-review-time":
      reportRequestName = "timeDifferenceReport";
      reportPageTitle = "Design Team Review Time Report";
      firstColumnName = "submittalReturned";
      secondColumnName = "submittalApprovalDue";
      columns = [
        ...columns,
        {
          title: "Submittal Approval Due",
          dataIndex: "submittalApprovalDue",
          key: "submittalApprovalDue",
          align: "center",
          width: 150,
        },
        {
          title: "Submittal Returned",
          dataIndex: "submittalReturned",
          key: "submittalReturned",
          align: "center",
          width: 150,
        },
        {
          title: (
            <>
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line", maxWidth: "500px" }}
                title={`Submittal Returned - Submittal Approval Due`}
              >
                Status
              </Tooltip>
            </>
          ),
          dataIndex: "status",
          key: "status",
          align: "center",
          width: 400,
        },
      ];
      break;
    case "submittal-issuing-review":
      reportRequestName = "timeDifferenceReport";
      reportPageTitle = "Submittal Issuance Review Report";
      firstColumnName = "submittalIssued";
      secondColumnName = "submittalRequired";
      columns = [
        ...columns,
        {
          title: "Submittal Required",
          dataIndex: "submittalRequired",
          key: "submittalRequired",
          align: "center",
          width: 150,
        },
        {
          title: "Submittal Issued",
          dataIndex: "submittalIssued",
          key: "submittalIssued",
          align: "center",
          width: 150,
        },
        {
          title: (
            <>
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line", maxWidth: "500px" }}
                title={`Submittal Issued - Submittal Required`}
              >
                Status
              </Tooltip>
            </>
          ),
          dataIndex: "status",
          key: "status",
          align: "center",
          width: 400,
        },
      ];
      break;
    case "subcontract-signing":
      reportRequestName = "timeDifferenceReport";
      reportPageTitle = "Subcontracts Issued Report";
      firstColumnName = "contractIssuedRelease";
      secondColumnName = "issueContract";
      columns = [
        ...columns,
        {
          title: "Issue Contract",
          dataIndex: "issueContract",
          key: "issueContract",
          align: "center",
          width: 150,
        },
        {
          title: "Contract Issued Release",
          dataIndex: "contractIssuedRelease",
          key: "contractIssuedRelease",
          align: "center",
          width: 200,
        },
        {
          title: (
            <>
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line", maxWidth: "500px" }}
                title={`Contract Issued Release	- Issue Contract`}
              >
                Status
              </Tooltip>
            </>
          ),
          dataIndex: "status",
          key: "status",
          align: "center",
          width: 400,
        },
      ];
      break;
    case "upcoming-deliveries":
      reportRequestName = "dateRequiredOnSiteReport";
      reportPageTitle = "Upcoming Deliveries Report";
      columns = [
        ...columns,
        {
          title: "Submittal Response",
          dataIndex: "submittalResponse",
          key: "submittalResponse",
          align: "center",
          width: 150,
        },
        {
          title: "Responsible Contractor",
          dataIndex: "responsibleContractor",
          key: "responsibleContractor",
          align: "center",
          width: 150,
        },
        {
          title: "Submittal Returned",
          dataIndex: "submittalReturned",
          key: "submittalReturned",
          align: "center",
          width: 150,
        },
        {
          title: "Lead Time",
          dataIndex: "leadTime",
          key: "leadTime",
          align: "center",
          width: 100,
        },
        {
          title: "Schedule ID",
          dataIndex: "scheduleID",
          key: "scheduleID",
          align: "center",
          width: 150,
        },
        {
          title: "Date Required on Site",
          dataIndex: "dateRequiredonSite",
          key: "dateRequiredonSite",
          align: "center",
          width: 150,
        },
        {
          title: "Projected Delivery Date",
          dataIndex: "projectedDeliveryDate",
          key: "projectedDeliveryDate",
          align: "center",
          width: 150,
        },
        {
          title: "Sub Committed Delivery Date",
          dataIndex: "subCommittedDeliveryDate",
          key: "subCommittedDeliveryDate",
          align: "center",
          width: 150,
        },
        {
          title: "Days Till Required",
          dataIndex: "daysTillRequired",
          key: "daysTillRequired",
          align: "center",
          width: 150,
        },
      ];
      break;
    case "planned-issue-date":
      reportRequestName = "plannedIssueDateReport";
      reportPageTitle = "Planned Submittal Issue Date Report";
      columns = [
        ...columns,
        {
          title: "Responsible Contractor",
          dataIndex: "responsibleContractor",
          key: "responsibleContractor",
          align: "center",
          width: 150,
        },
        {
          title: "Planned Issue Date",
          dataIndex: "plannedIssueDate",
          key: "plannedIssueDate",
          align: "center",
          width: 150,
          render: (text, record) => {
            const editable = isEditing(record);
            return editable ? (
              <DatePicker
                defaultValue={text ? dayjs(text, dateFormat) : null}
                format={dateFormat}
                style={{ width: "100%" }}
                onChange={(value) => handleDateChange(value, record)}
              />
            ) : (
              <Tooltip title={text ? "Click to Edit" : "MM-DD-YYYY"}>
                <div
                  style={{
                    cursor: "pointer",
                    color: text ? "#03A0E3" : "#FFFFFF",
                  }}
                  onClick={() => {
                    edit(record);
                  }}
                >
                  {text || "Set Date"}
                </div>
              </Tooltip>
            );
          },
        },
        {
          title: "Submittal Response",
          dataIndex: "submittalResponse",
          key: "submittalResponse",
          align: "center",
          width: 150,
        },
        {
          title: "Submittal Returned",
          dataIndex: "submittalReturned",
          key: "submittalReturned",
          align: "center",
          width: 150,
        },
        {
          title: "Lead Time",
          dataIndex: "leadTime",
          key: "leadTime",
          align: "center",
          width: 100,
        },
        {
          title: "Date Required on Site",
          dataIndex: "dateRequiredonSite",
          key: "dateRequiredonSite",
          align: "center",
          width: 150,
        },
      ];
      break;
    case "user-sessions":
      reportRequestName = "";
      reportPageTitle = "User Sessions Report";
      break;
    default:
      reportRequestName = "";
      break;
  }

  const [data, setData] = useState();

  const [applyFilters, setApplyFilters] = useState(false);
  const [sortBy, setSortBy] = useState(
    reportRequestName === "itemDeficienciesReport" ||
      reportRequestName === "highLeadTimeReport"
      ? "Descending"
      : "Ascending"
  );
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [status, setStatus] = useState("");
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filterCombination, setFilterCombination] = useState("and");
  const [maxLeadTime, setMaxLeadTime] = useState("");

  // initial state array only for plannedIssueDate
  const [subcontractorFilter, setSubcontractorFilter] = useState(
    reportRequestName === "plannedIssueDateReport" ? [] : ""
  );
  const [subcontractorFilterList, setSubcontractorFilterList] = useState([]);
  const [responseFilter, setResponseFilter] = useState([]);
  const [dropDownResponsesList, setDropDownResponsesList] = useState([]);

  function getData() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      firstColumnName,
      secondColumnName,
      minValue,
      maxValue,
      sortBy,
      status,
      filteredColumns,
      filterCombination,
      subcontractorFilter,
      reportRequestName,
      responseFilter,
    };
    ReportServices.getReportDetails(ReqObj)
      .then((res) => {
        setData(res.content);
        setAccessLevel(res.accessLevel);
        setMaxLeadTime(res.maxLeadTime);
        setProjectName(res.projectName);
        setProjectAddress(res.projectAddress);
        setSubcontractorFilterList(res.dropDownCompanyNames);
        setDropDownResponsesList(res.dropDownResponses);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      })
      .finally(() => {
        setisLoading(false);
        setEditedData([]);
      });
  }

  useEffect(() => {
    getData();
  }, [applyFilters]);

  function saveChanges() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      editedData: editedData,
    };
    ReportServices.modifyIssueDateReport(ReqObj)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Update dataSource using setDataSource
  useEffect(() => {
    if (!isLoading) {
      const updatedDataSource = data.map((item) => ({
        key: `${item?.entryID}`,
        itemName: `${item?.specTitle}`,
        submittalNumber: `${item?.specNumber}`,
        submittalRevison: `${item?.specRevision}`,
        submittalType: `${item?.specType == null ? "" : item?.specType}`,
        submittalResponse: `${item?.specResponse}`,
        responsibleContractor: `${item?.responsibleContractor}`,
        issueContract: `${item?.issueContract}`,
        contractIssuedRelease: `${item?.contractIssuedRelease}`,
        reviewTime: `${item?.submittalPrepTime}`,
        submittalRequired: `${item?.submittalRequired}`,
        submittalIssued: `${item?.submittalIssued}`,
        approvalTime: `${item?.approvalTime}`,
        submittalApprovalDue: `${item?.submittalApprovalDue}`,
        submittalReturned: `${item?.submittalReturned}`,
        leadTime: `${item?.leadTime}`,
        scheduleID: `${item?.scheduleID}`,
        dateRequiredonSite: `${item?.dateRequiredOnSite}`,
        projectedDeliveryDate: `${item?.projectedDeliveryDate}`,
        subCommittedDeliveryDate: `${item?.subCommittedDeliveryDate}`,
        actualDeliveryDate: `${item?.actualDeliveryDate}`,
        status: `${item?.notes}`,
        result: `${item?.result}`,
        daysTillRequired: `${item?.daysTillRequired}`,
        plannedIssueDate: `${item?.plannedIssueDate}`,
      }));
      setDataSource(updatedDataSource);
    }
  }, [data, isLoading]);

  const returnedData = (data) => {
    if (data?.range.length < 1) {
      setMinValue("");
      setMaxValue("");
    } else {
      setMinValue(data?.range[0]);
      setMaxValue(data?.range[1]);
    }
    setFilteredColumns(data?.filteredColumns);
    setStatus(data?.status);
    setSortBy(data?.sortBy);
    setFilterCombination(data?.filterCombination);
    setSubcontractorFilter(data?.filteredCompanies);
    setResponseFilter(data?.filteredResponses);
    setApplyFilters(!applyFilters);
  };

  return (
    <>
      <Container>
        {accessLevel > 1 && (
          <>
            <div className="row mt-4">
              <div className="col-md-6">
                <Text className={`${styles.header}`}>{reportPageTitle}</Text>
              </div>
              <div className="col-md-6 d-flex  justify-content-sm-center justify-content-md-end gap-3">
                {reportRequestName === "plannedIssueDateReport" && (
                  <div className="align-self-center">
                    <Button
                      size="default"
                      onClick={saveChanges}
                      disabled={editedData.length === 0 || isLoading}
                    >
                      Save Changes
                    </Button>
                  </div>
                )}
                <div className=" align-self-center">
                  <ExportReports
                    data={dataSource}
                    columns={columns}
                    accessLevel={accessLevel}
                    editedData={editedData}
                    isLoading={isLoading}
                    reportTitle={reportPageTitle}
                    projectName={projectName}
                    projAddress={projectAddress}
                  />
                </div>
              </div>
            </div>
            <ReportFilters
              pageName={reportRequestName}
              returnedData={returnedData}
              maxLeadTime={maxLeadTime}
              subcontractorFilterList={subcontractorFilterList}
              dropDownResponsesList={dropDownResponsesList}
              editedData={editedData}
            />
          </>
        )}
        <div className="row mt-4">
          {isLoading ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <>
              {accessLevel < 2 ? (
                <div className="row">
                  <div className="text-center">
                    <Text>
                      You do not have the necessary permissions to access this
                      page.
                    </Text>
                  </div>
                </div>
              ) : (
                <>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ x: 500, y: "70vh" }}
                    bordered
                    pagination={{
                      position: ["bottomCenter"],
                      defaultPageSize: 20,
                    }}
                    size="small"
                  />
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
}
