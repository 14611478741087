import { PermissionServices } from "apis/Services/PermissionsService";
import React from "react";
import { saveProjectPermissions } from "redux/permission-reducer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const UseGetProjectPermissions = ({ company_id, project_id = "" } = {}) => {
  const [projectPermissions, setprojectPermissions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    PermissionServices.getProjectPermissionsTemplate({
      company_id,
      project_id,
      code,
      loginEmail,
    })
      .then((res) => {
        if (typeof res == "object") {
          setprojectPermissions(res);
          dispatch(saveProjectPermissions(res));
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return projectPermissions;
};

export default UseGetProjectPermissions;
