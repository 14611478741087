import { Button, Modal, Select, Space, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Container from "components/container";
import ContentContainer from "components/content-container";
import LoadingComponent from "components/loading-component";
import { PermissionServices } from "apis/Services/PermissionsService";
import Permissions from "pages/permissions";
import { ProjectServices } from "apis/Services/ProjectService";
import UseGetProjectPermissions from "hooks/request-hooks/use-get-project-permissions";
import { saveProjectAccessLevel } from "redux/permission-reducer";
import tableData from "../../components/permission-table/tableData";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import permissionsLevels from "constants/permissionsLevels";
import Text from "components/text";

const ProjectPermissions = ({
  type = "company_permission",
  showPermissionsTable,
}) => {
  const { company_id } = useParams();
  const project_permissions = UseGetProjectPermissions({ company_id });
  const [togglePermissions, settogglePermissions] = useState(true);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [ContactsPagePermission, setContactsPagePermission] = useState("");
  const [procurementlogpagePermission, setProcurementlogpagePermission] =
    useState("");
  const [adminPagePermission, setadminPagePermission] = useState("");
  const [reportsPagePermission, setReportsPagePermission] = useState("");
  const [templateName, settemplateName] = useState("");
  const [templateNameFilter, setTemplateNameFilter] = useState("");
  const [assignedTemplates, setAssignedTemplates] = useState([]);
  const [permissionTemplatesNames, setPermissionTemplatesNames] = useState([]);
  const [editingTemplateName, setEditingTemplateName] = useState(false);
  const [loader, setloader] = useState(true);
  const [NewProjectTemplateName, setNewProjectTemplateName] = useState("");
  const [defaultPermissions, setdefaultPermissions] = useState({});
  const [CheckedPermissions, setCheckedPermissions] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(false);
  const loaderr = {
    spinning: loader,
    indicator: <LoadingComponent />,
  };
  const { projectAccessLevel } = useSelector((state) => state.permission);
  const dispatch = useDispatch();
  // console.log(CheckedPermissions);

  useEffect(() => {
    setCheckedPermissions({
      subCommitedDate: +defaultPermissions.Sub_Committed_Permission || 0,
      LTpermission: +defaultPermissions.LT_permission || 0,
      viewTable: +defaultPermissions.view_only || 0,
      SubRequiredpermission: +defaultPermissions.Sub_Required_permission || 0,
    });
  }, [defaultPermissions]);
  useEffect(() => {
    setTimeout(() => {
      setloader(false);
    }, 1500);
  }, []);

  useEffect(() => {
    dispatch(saveProjectAccessLevel(project_permissions.accessLevel));
    setPermissionTemplatesNames(project_permissions?.permissionTemplatesNames);
  }, [project_permissions]);

  const TableData = {
    BasicData: project_permissions?.content
      ?.map((item, index) => {
        let ProcurementlogpageeValue;
        let ContactsPageeValue;
        let AdminPageeValue;
        let ReportsPageeValue;
        // console.log(item, "kkkk");
        switch (item["Procurement log page"]) {
          case "0":
            ProcurementlogpageeValue = "default";
            break;
          case "1":
            ProcurementlogpageeValue = "readonly";
            break;
          case "2":
            ProcurementlogpageeValue = "standard";
            break;
          case "3":
            ProcurementlogpageeValue = "Admin";
            break;
          default:
            ProcurementlogpageeValue = "";
            break;
        }

        switch (item["Contacts page"]) {
          case "0":
            ContactsPageeValue = "default";
            break;
          case "1":
            ContactsPageeValue = "readonly";
            break;
          case "2":
            ContactsPageeValue = "standard";
            break;
          case "3":
            ContactsPageeValue = "Admin";
            break;
          default:
            ContactsPageeValue = "";
            break;
        }
        switch (item["Admin page"]) {
          case "0":
            AdminPageeValue = "default";
            break;
          case "1":
            AdminPageeValue = "readonly";
            break;
          case "2":
            AdminPageeValue = "standard";
            break;
          case "3":
            AdminPageeValue = "Admin";
            break;
          default:
            AdminPageeValue = "";
            break;
        }
        switch (item["Reports page"]) {
          case "0":
            ReportsPageeValue = "default";
            break;
          case "1":
            ReportsPageeValue = "readonly";
            break;
          case "2":
            ReportsPageeValue = "standard";
            break;
          case "3":
            ReportsPageeValue = "Admin";
            break;
          default:
            ReportsPageeValue = "";
            break;
        }

        return {
          key: item["Template Name"],
          templateName: item["Template Name"],
          ContactsPage: ContactsPageeValue || "default",
          Procurementlogpage: ProcurementlogpageeValue || "default",
          AdminPage: AdminPageeValue || "default",
          ReportsPage: ReportsPageeValue || "default",
          index: index,
          Sub_Committed_Permission:
            item["Sub Committed Delivery Date Granular Permission"],
          view_only: item["View Only Assigned Entries Granular Permission"],
          LT_permission: item["Lead Time Granular Permission"],
          Sub_Required_permission:
            item[
              "Grey Out Submittal Required Date For Subs Granular Permission"
            ],
          assignedTemplates: item?.["Assigned Templates"],
        };
      })
      .filter((item) => item.templateName !== "Default"),

    BasicColumns: [
      {
        title: "Actions",
        dataIndex: "actions",
        render: (_, record) => {
          if (projectAccessLevel == 1) {
            return null;
          } else if (projectAccessLevel == 2) {
            return (
              <Space size="small">
                <Button
                  type="link"
                  onClick={() => handleEdit(record)}
                  icon={<EditOutlined />}
                />
              </Space>
            );
          } else {
            return (
              <Space size="small">
                <Button
                  type="link"
                  onClick={() => handleEdit(record)}
                  icon={<EditOutlined />}
                />
                <Button
                  type="link"
                  onClick={() => {
                    handleDeleteProject(record);
                  }}
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                />
              </Space>
            );
          }
        },
        width: 50,
      },
      {
        title: "Template Name",
        dataIndex: "templateName",
      },
      // {
      //   title: "ContactsPage",
      //   dataIndex: "ContactsPage",
      // },
      // {
      //   title: "Procurementlogpage",
      //   dataIndex: "Procurementlogpage",
      // },
    ],
  };

  const handleDeleteProject = (key) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this item?",
      onOk() {
        let code = localStorage.getItem("token");
        let loginEmail = localStorage.getItem("email");
        let data = {
          company_id,
          loginEmail,
          code,
          permissionTemplateNameToDelete: key.templateName,
        };
        console.log(data, "data");
        PermissionServices.deleteProjectPermissionsTemplate(data)
          .then((res) => {
            console.log(res);
            setTimeout(() => {
              window.location.reload();
            }, 600);
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              "User(s) already assigned to this permission template!"
            );
          });
      },
    });
  };

  const handleCheckboxChange = (e, record, value) => {
    if (value == "readonly" && record.name == "Dashboard") setIsReadOnly(true);
    else if (value !== "readonly" && record.name == "Dashboard")
      setIsReadOnly(false);
    const tempObj = {
      name: record.name,
      value: value,
    };

    const updatedPermissions = selectedPermissions.map((permission) => {
      if (permission.name === tempObj.name) {
        return tempObj;
      }
      return permission;
    });

    const isNewPermission = selectedPermissions.every(
      (permission) => permission.name !== tempObj.name
    );

    if (isNewPermission) {
      setSelectedPermissions([...updatedPermissions, tempObj]);
    } else {
      setSelectedPermissions(updatedPermissions);
    }
  };

  const handleEdit = (key) => {
    console.log(key, "!!!!!!");

    const initialValues = [
      { name: "Admin", value: key.AdminPage },
      { name: "Dashboard", value: key.Procurementlogpage },
      { name: "Directory", value: key.ContactsPage },
      { name: "Reports", value: key.ReportsPage },
    ];
    setSelectedPermissions(initialValues);
    settogglePermissions(false);
    settemplateName(key.templateName);
    setAssignedTemplates(key.assignedTemplates);
    setdefaultPermissions(key);
  };

  const permissionsCheck = {
    permissions_collection_column: [
      Table.EXPAND_COLUMN,

      {
        title: "Page Name",
        dataIndex: "name",
      },
      {
        title: "No Visibility",
        dataIndex: "Default",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            checked={handleCheckedCells(record, "default")}
            onChange={(e) => handleCheckboxChange(e, record, "default")}
          />
        ),
      },
      {
        title: "Read Only",
        dataIndex: "read_only",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.readonly}
            checked={handleCheckedCells(record, "readonly")}
            onChange={(e) => handleCheckboxChange(e, record, "readonly")}
          />
        ),
      },
      {
        title: "Standard",
        dataIndex: "standard",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.standard}
            checked={handleCheckedCells(record, "standard")}
            onChange={(e) => handleCheckboxChange(e, record, "standard")}
          />
        ),
      },
      {
        title: "Admin",
        dataIndex: "Admin",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            checked={handleCheckedCells(record, "admin")}
            onChange={(e) => handleCheckboxChange(e, record, "admin")}
          />
        ),
      },
    ],

    permissions_collection_data: [
      {
        key: 1,
        name: "Admin",
      },
      {
        key: 2,
        name: "Dashboard",
      },
      {
        key: 3,
        name: "Directory",
      },
      {
        key: 4,
        name: "Reports",
      },
    ],
  };

  useEffect(() => {
    console.log(selectedPermissions, "selected");
    selectedPermissions.map((Permission) => {
      if (Permission.name === "Admin") {
        setadminPagePermission(Permission.value);
      } else if (Permission.name === "Dashboard") {
        setProcurementlogpagePermission(Permission.value);
      } else if (Permission.name === "Directory") {
        setContactsPagePermission(Permission.value);
      } else if (Permission.name === "Reports") {
        setReportsPagePermission(Permission.value);
      }
    });
    // if (procurementlogpagePermission) {
    //   setIsReadOnly(true);
    // }
  }, [selectedPermissions]);

  const handleColumnSelection = (column) => {
    // console.log(column, "column");
    if (column.dataIndex == "read_only") {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
    console.log(permissionsCheck, "permissionsCheck");
    const tempArr = [];
    permissionsCheck.permissions_collection_data.forEach((item) => {
      tempArr.push({
        name: item.name,
        value: column.dataIndex.toLowerCase().replace("_", ""),
      });
    });
    // console.log(tempArr, "aaa");
    // const revertedSelection = tempArr.reverse();
    setSelectedPermissions(tempArr);
  };
  const handleCheckedCells = (record, name) => {
    const isReadOnlyIncluded = selectedPermissions.some(
      (e) => e.name == "Dashboard" && e.value == "readonly"
    );
    const element = selectedPermissions.find((e) => e.value == name);
    if (
      (element?.name == "Dashboard" && element?.value == "readonly") ||
      isReadOnlyIncluded
    ) {
      setIsReadOnly(true);
    } else if (element?.name == "Dashboard" && element?.value !== "readonly") {
      setIsReadOnly(false);
    }
    return selectedPermissions[record.key - 1]?.value.toLowerCase() == name;
  };

  const saveEditedPermissions = () => {
    let contactsPageValue = permissionsLevels[ContactsPagePermission];
    let procurementlogpageValue =
      permissionsLevels[procurementlogpagePermission];
    let adminPageValue = permissionsLevels[adminPagePermission];
    let reportPageValue = permissionsLevels[reportsPagePermission];

    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");

    let data = {
      company_id,
      loginEmail,
      code,
      drawingsPageToEdit: "",
      submittalsPageToEdit: "",
      commitmentsPageToEdit: "",
      specificationsPageToEdit: "",
      adminPageToEdit: adminPageValue,
      reportsPageToEdit: reportPageValue,
      contactsPageToEdit: contactsPageValue,
      procurementLogPageToEdit: procurementlogpageValue,
      permissionTemplateNameToEdit: templateName,
      newPermissionTemplateNameToEdit: NewProjectTemplateName || templateName,
      leadTimeGranularPermissionToEdit: isReadOnly
        ? CheckedPermissions.LTpermission
        : "0",
      subCommittedDeliveryDateGranularPermissionToEdit: isReadOnly
        ? CheckedPermissions.subCommitedDate
        : "0",
      viewOnlyAssignedEntriesGranularPermissionToEdit: isReadOnly
        ? CheckedPermissions.viewTable
        : "0",
      greyOutSubRequiredDateGranularPermissionsToEdit: isReadOnly
        ? CheckedPermissions.SubRequiredpermission
        : "0",
      leadTimeGranularPermission: "",
      subCommittedDeliveryDateGranularPermission: "",
      viewOnlyAssignedEntriesGranularPermission: "",
      greyOutSubRequiredDateGranularPermissions: "",
      assignedTemplates: assignedTemplates,
    };
    console.log(data, "data ");

    PermissionServices.updateProjectPermissionsTemplate(data)
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          // window.location.reload();
          settogglePermissions(true);
        }, 600);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong, please try again");
      })
      .finally(() =>
        setTimeout(() => {
          window.location.reload();
          settogglePermissions(true);
        }, 600)
      );
  };
  const filteredData = TableData?.BasicData?.filter((item) =>
    item.templateName.toLowerCase().includes(templateNameFilter.toLowerCase())
  );
  // console.log(isReadOnly, "isReadOnly");

  return (
    <>
      <Permissions
        togglePermissionsTable={togglePermissions}
        pageType="Project Permissions"
        templateName={templateName}
        templateNameFilter={templateNameFilter}
        setTemplateNameFilter={setTemplateNameFilter}
        setNewProjectTemplateName={setNewProjectTemplateName}
        assignedTemplates={assignedTemplates}
        setAssignedTemplates={setAssignedTemplates}
        permissionTemplatesNames={permissionTemplatesNames}
        editingTemplateName={editingTemplateName}
        setEditingTemplateName={setEditingTemplateName}
      />

      {projectAccessLevel == 0 ? (
        <div className="pt-5 text-center">
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : (
        <Container>
          <ContentContainer className="P-3">
            <div className="my-2">
              {togglePermissions ? (
                <Table
                  columns={TableData.BasicColumns}
                  // dataSource={TableData.BasicData}
                  loading={loaderr}
                  dataSource={filteredData}
                  bordered
                  pagination={{
                    pageSize: 10,
                    // current: currentPage,
                    // showSizeChanger: true,
                    // position: ["bottomLeft"],
                  }}
                  scroll={{
                    x: "max-content",
                  }}
                />
              ) : (
                <div className="expandableTable">
                  <Table
                    columns={permissionsCheck.permissions_collection_column}
                    dataSource={permissionsCheck.permissions_collection_data}
                    pagination={false}
                    loading={loaderr}
                    // className="expandableTable"
                    expandable={
                      isReadOnly
                        ? {
                            expandedRowRender: (record) => (
                              <>
                                <div className="allowCon">
                                  <input
                                    type="checkbox"
                                    name={record.key}
                                    checked={
                                      +CheckedPermissions.subCommitedDate
                                    }
                                    onChange={
                                      (e) =>
                                        setCheckedPermissions({
                                          ...CheckedPermissions,
                                          subCommitedDate: e.target.checked
                                            ? "3"
                                            : "0",
                                        })
                                      // handleCheckboxChange(e, record, "default")
                                    }
                                  />
                                  <p
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    Allow edit access to sub committed date cell
                                  </p>
                                </div>
                                <div className="allowCon">
                                  <input
                                    type="checkbox"
                                    name={record.key}
                                    // checked={handleCheckedCells(record, "default")}
                                    checked={+CheckedPermissions.LTpermission}
                                    onChange={
                                      (e) =>
                                        setCheckedPermissions({
                                          ...CheckedPermissions,
                                          LTpermission: e.target.checked
                                            ? "3"
                                            : "0",
                                        })
                                      // handleCheckboxChange(e, record, "default")
                                    }
                                  />
                                  <p
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    Allow edit access to lead time cell
                                  </p>
                                </div>
                                <div className="allowCon">
                                  <input
                                    type="checkbox"
                                    name={record.key}
                                    // checked={handleCheckedCells(record, "default")}
                                    checked={+CheckedPermissions.viewTable}
                                    onChange={
                                      (e) =>
                                        setCheckedPermissions({
                                          ...CheckedPermissions,
                                          viewTable: e.target.checked
                                            ? "3"
                                            : "0",
                                        })
                                      // handleCheckboxChange(e, record, "default")
                                    }
                                  />
                                  <p
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    ⁠Allow user to view only assigned line items
                                  </p>
                                </div>
                                <div className="allowCon">
                                  <input
                                    type="checkbox"
                                    name={record.key}
                                    // checked={handleCheckedCells(record, "default")}
                                    checked={
                                      +CheckedPermissions.SubRequiredpermission
                                    }
                                    onChange={
                                      (e) =>
                                        setCheckedPermissions({
                                          ...CheckedPermissions,
                                          SubRequiredpermission: e.target
                                            .checked
                                            ? "3"
                                            : "0",
                                        })
                                      // handleCheckboxChange(e, record, "default")
                                    }
                                  />
                                  <p
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    Hide submittal required date cell
                                  </p>
                                </div>
                              </>
                            ),
                          }
                        : false
                    }
                  />

                  <Button
                    type="primary"
                    onClick={() => {
                      saveEditedPermissions();
                    }}
                    style={{ margin: "10px" }}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      // saveEditedPermissions();
                      setIsReadOnly(false);
                      settogglePermissions(true);
                      setEditingTemplateName(false);
                      // window.location.reload();
                    }}
                    style={{ margin: "10px" }}
                  >
                    Discard
                  </Button>
                </div>
              )}
            </div>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default ProjectPermissions;
