import Text from "components/text";
import React from "react";
import styles from "./profiles.module.css";

const ActiveProfiles = ({ haveTitle = false, title = "Active" }) => {
  const backGrounds = [
    "var(--clr-orange)",
    "var(--clr-violet)",
    "var(--clr-aqua)",
    "var(--clr-dearBlue)",
  ];

  return (
    <div className="d-flex gap-2 align-items-center">
      {haveTitle && <Text className={`${styles.header}`}>{title}</Text>}
      {/* <div className="d-flex align-items-center">
        {["TM", "AH", "OT", "MS"].map((item, index) => (
          <div
            className={`${styles.user} ${index !== 0 ? styles.negativeMargin : ""
              }`}
            style={{ backgroundColor: backGrounds[index] }}
            key={index}
          >
            {item}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ActiveProfiles;
