import { Carousel } from "react-bootstrap";
import React from "react";
import Text from "components/text";
import styles from "./slider.module.css";

const AuthSlider = () => {
  const sliderData = [
    {
      img: require("assets/images/login-hero.webp"),
      // header: `Create Division 1`,
      // body: "Browse or search the Content Library to find published collections of text strings, images and icons. Not finding what you need? No problem.",
    },
    {
      img: require("assets/images/login-hero-one.webp"),
      // header: `Create Division 1`,
      // body: "Browse or search the Content Library to find published collections of text strings, images and icons. Not finding what you need? No problem.",
    },
    {
      img: require("assets/images/login-hero-three.webp"),
      // header: `Create Division 1`,
      // body: "Browse or search the Content Library to find published collections of text strings, images and icons. Not finding what you need? No problem.",
    },
  ];
  return (
    <Carousel className={`${styles.carousel} w-100`}>
      {sliderData.map((item, index) => {
        return (
          <Carousel.Item key={item?.header + " " + index}>
            <img
              className={`${styles.sliderImg} d-none d-md-block w-100`}
              src={item?.img}
              alt={item?.header}
            />
            {/* <Carousel.Caption className="text-center">
              <Text className={`${styles.header}`}>{item.header}</Text>
              <Text className={`${styles.body}`} dontWrap={false}>
                {item.body}
              </Text>
            </Carousel.Caption> */}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default AuthSlider;
