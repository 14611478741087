import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CompanyReportServices } from "apis/Services/CompanyReportService";
import { saveReportAccessLevel } from "redux/permission-reducer";
import { useDispatch, useSelector } from "react-redux";
import ProjectHeader from "components/project-header";
import styles from "./reports.module.css";
import Container from "components/container";
import Text from "components/text";
import { Table } from "antd";
import LoadingComponent from "components/loading-component";
import { Tag } from "antd";
import { Link } from "react-router-dom";

export default function CompanyReports() {
  const { company_id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();

  const { reportAccessLevel } = useSelector((state) => state.permission);
  const dispatch = useDispatch();

  useEffect(() => {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      code,
      loginEmail: email,
    };
    CompanyReportServices.getCompanyReportAccessLevel(ReqObj)
      .then((res) => {
        setAccessLevel(res.accessLevel);
        dispatch(saveReportAccessLevel(res.accessLevel));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setisLoading(false));
  }, []);

  const dataSource = [
    {
      key: "1",
      Report: (
        <Link
          className={
            accessLevel > 1
              ? `${styles.linkStyle}`
              : `${styles.linkStyleNoPermission}`
          }
          to={
            accessLevel > 1
              ? accessLevel > 1
                ? "company-high-lead-time"
                : "#"
              : "#"
          }
        >
          High Lead Time
        </Link>
      ),
      Description: "All line items sorted from highest to lowest lead time",
    },
    {
      key: "2",
      // Report: "Subcontractor Health Report",
      Report: (
        <Link
          className={
            accessLevel > 1
              ? `${styles.linkStyle}`
              : `${styles.linkStyleNoPermission}`
          }
          to={
            accessLevel > 1
              ? accessLevel > 1
                ? "company-subcontractor-health"
                : "#"
              : "#"
          }
        >
          Subcontractor Health
        </Link>
      ),
      Description:
        "A comparison showing if subs deliver on time, late, or early",
    },
    {
      key: "3",
      Report: (
        <Link
          className={
            accessLevel > 1
              ? `${styles.linkStyle}`
              : `${styles.linkStyleNoPermission}`
          }
          to={accessLevel > 1 ? "company-item-deficiencies" : "#"}
        >
          Item Deficiencies
        </Link>
      ),
      Description: "All line items with missing fields",
    },
    {
      key: "4",
      Report: (
        <Link
          className={
            accessLevel > 1
              ? `${styles.linkStyle}`
              : `${styles.linkStyleNoPermission}`
          }
          to={accessLevel > 1 ? "company-design-team-review-time" : "#"}
        >
          Design Team Review Time
        </Link>
      ),
      Description:
        "A comparison showing if the design team returns submittals on time, late, or early",
    },
    {
      key: "5",
      Report: (
        <Link
          className={
            accessLevel > 1
              ? `${styles.linkStyle}`
              : `${styles.linkStyleNoPermission}`
          }
          to={accessLevel > 1 ? "company-submittal-issuing-review" : "#"}
        >
          Submittal Issuance Review
        </Link>
      ),
      Description:
        "A comparison showing if submittals are issued on time, late, or early",
    },
    {
      key: "6",
      Report: (
        <Link
          className={
            accessLevel > 1
              ? `${styles.linkStyle}`
              : `${styles.linkStyleNoPermission}`
          }
          to={accessLevel > 1 ? "company-subcontract-signing" : "#"}
        >
          Subcontracts Issued
        </Link>
      ),
      Description:
        "A comparison showing if commitments are issued on time, late, or early",
    },
    {
      key: "7",
      Report: (
        <Link
          className={
            accessLevel > 1
              ? `${styles.linkStyle}`
              : `${styles.linkStyleNoPermission}`
          }
          to={accessLevel > 1 ? "company-upcoming-deliveries" : "#"}
        >
          Upcoming Deliveries
        </Link>
      ),
      Description: "Items due on site within 30 days",
    },
    // {
    //   key: "8",
    //   Report: (
    //     <Link
    //       className={
    //         accessLevel > 1
    //           ? `${styles.linkStyle}`
    //           : `${styles.linkStyleNoPermission}`
    //       }
    //       to={accessLevel > 1 ? "company-planned-issue-date" : "#"}
    //     >
    //       Planned Submittal Issue Date
    //     </Link>
    //   ),
    //   Description:
    //     "Editable report to provide Architect with planned dates for submittal issuance",
    // },
    // {
    //   key: "9",
    //   Report: (
    //     <Link
    //       className={`${styles.linkStyleNoPermission}`}
    //       to={accessLevel > 1 ? "#" : "#"}
    //     >
    //       User Sessions <Tag>Available Soon</Tag>
    //     </Link>
    //   ),
    //   Description: "A complete history of each user’s actions on this project",
    // },
  ];

  const columns = [
    {
      title: "Report",
      dataIndex: "Report",
      key: "Report",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
  ];

  return (
    <>
      {/* <ProjectHeader
        ActiveUsers={ActiveUsers}
        ActiveUsersIcon={ActiveUsersIcon}
        isActiveShown={false}
      /> */}

      <Container className="mt-4">
        {isLoading && (
          <>
            <div className="row d-flex justify-content-center">
              <LoadingComponent />
            </div>
          </>
        )}

        {!isLoading && accessLevel === "0" && (
          <div className="row">
            <div className="text-center">
              <Text>
                You do not have the necessary permissions to access this page.
              </Text>
            </div>
          </div>
        )}
        {!isLoading && accessLevel > 0 && (
          <>
            <div className="row">
              <div className="col-md-12">
                <Text className={`${styles.header}`}>Canned Reports</Text>
              </div>
            </div>
            <div className="row mt-4">
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                bordered
                disabled
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
}
