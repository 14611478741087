import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import { ReportServices } from "apis/Services/ReportService";
import { saveReportAccessLevel } from "redux/permission-reducer";
import { useDispatch, useSelector } from "react-redux";
import ProjectHeader from "components/project-header";
import styles from "./reports.module.css";
import Container from "components/container";
import Text from "components/text";
import { Button, Tooltip, Table, Modal, Menu, Alert } from "antd";
import LoadingComponent from "components/loading-component";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import ProjectReports from "pages/project-reports";
import { DeleteFilled } from "@ant-design/icons";
import moment from "moment";

export default function ProjectCustomReport() {
  const { company_id, id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();

  const { reportAccessLevel } = useSelector((state) => state.permission);
  const dispatch = useDispatch();

  const [customReportsInfo, setCustomReportsInfo] = useState([]);
  const [alignedData, setAlignedData] = useState([]);

  useEffect(() => {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
    };
    ReportServices.readCustomReports(ReqObj)
      .then((res) => {
        setAccessLevel(parseInt(res.userData.accessLevel));
        dispatch(saveReportAccessLevel(parseInt(res.userData.accessLevel)));
        setCustomReportsInfo(res.reportsInfo);
        setAlignedData(res.alignedData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setisLoading(false));
  }, []);

  const handleEdit = (item) => {
    // const selectedView = viewsList.find(
    //   (view, index) => view.viewName === item.view
    // );
  };

  const handleView = (item) => {
    // const selectedView = viewsList.find(
    //   (view, index) => view.viewName === item.view
    // );
  };

  const handleDelete = (item) => {
    // const selectedView = viewsList.find(
    //   (view, index) => view.viewName === item.view
    // );
  };

  const dataSource = customReportsInfo?.map((report, index) => ({
    key: index.toString(),
    report: report.reportName,
    columnsUsed: report.columnsUsed,
    createdBy: report.createdBy,
    dateCreated: report.createdAt
      ? moment(report.createdAt).format("MM/DD/YY")
      : "",
  }));

  const columns = [
    {
      title: "Report",
      dataIndex: "report",
      key: "report",
      render: (text, record) => (
        <div style={{ cursor: "pointer" }}>
          <Tooltip title="View">
            <Link
              to={`${record.report}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {text}
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Columns Used",
      dataIndex: "columnsUsed",
      key: "columnsUsed",
      render: (text, record) => (
        <div>
          <div className=" d-flex flex-column">
            {record.columnsUsed.slice(0, 10).map((item, index) => (
              <small key={index}>{alignedData[item]?.label}</small>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
    },
    {
      title: "Actions",
      align: "center",
      key: "actions",
      render: (text, record) => (
        <Tooltip title="Delete">
          <DeleteFilled
            // onClick={() => handleDelete(record)}
            style={{
              color: "red",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <ProjectReports pageType="project-custom-reports" />

      <Container className="mt-4">
        {isLoading && (
          <>
            <div className="row d-flex justify-content-center">
              <LoadingComponent />
            </div>
          </>
        )}

        {!isLoading && accessLevel === "0" && (
          <div className="row">
            <div className="text-center">
              <Text>
                You do not have the necessary permissions to access this page.
              </Text>
            </div>
          </div>
        )}
        {!isLoading && accessLevel > 0 && (
          <>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between">
                <Text className={`${styles.header}`}>Custom Reports</Text>

                <Button type="primary">Create Report</Button>
              </div>
            </div>
            <div className="row mt-4">
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                bordered
                loading={
                  isLoading ? { indicator: <LoadingComponent /> } : false
                }
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
}
