import React from 'react';
import ReactLoading from 'react-loading';

const LoadingComponent = () => {
    return (
        <div className='my-3 py-3 d-flex align-items-center justify-content-center '>
              <ReactLoading type={"spin"} color={"var(--clr-current)"} height={30} width={30} />
        </div>
    );
}

export default LoadingComponent;
