import { Dropdown, Button, Tooltip, Radio, Select, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Container from "components/container";
import ContentContainer from "components/content-container";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/loading-component";
import MultiInputsProjectDates from "components/multi-input-project-dates";
import { WEEK_DAYS } from "mockups/WEEK_DAYS";
import Text from "components/text";
import CompanyAdmin from "pages/company-admin";
import { CompanyAdminServices } from "apis/Services/CompanyAdminService";
import styles from "./company-schedule.module.css";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import { ProjectServices } from "apis/Services/ProjectService";

export default function CompanySchedule({ type = "company_schedule" }) {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    // defaultValues: {},
  });

  let code = localStorage.getItem("token");
  let loginEmail = localStorage.getItem("email");
  let Auth = {
    code,
    loginEmail,
  };

  const { company_id } = useParams();
  const [selectedWeekdays, setSelectedWeekdays] = useState({});
  const [weekendsError, setWeekendsError] = useState();
  const [multiInputsData, setMultiInputsData] = useState({});
  const [accessLevelValue, setAccessLevelValue] = useState({});
  const [selectedProjectsList, setSelectedProjectsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [saveFlag, setSaveFlag] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [isFieldsDirty, setIsFieldsDirty] = useState(false);

  //Radio Button
  const [radioValue, setRadioValue] = useState(1);
  const [projectsList, setProjectsList] = useState([]);

  const handleMultiInputsChange = (data) => {
    setMultiInputsData(data);
  };
  useEffect(() => {
    let isValid = true;

    // Iterate over the keys of the multiInputsData object
    for (const key in multiInputsData) {
      if (multiInputsData.hasOwnProperty(key)) {
        const { holidayName, startDate, finishDate } = multiInputsData[key];
        if (!holidayName || !startDate || !finishDate) {
          isValid = false;
          break;
        }

        const startDateMoment = moment(startDate, "MM-DD-YYYY");
        const endDateMoment = moment(finishDate, "MM-DD-YYYY");

        if (
          !startDateMoment.isValid() ||
          !endDateMoment.isValid() ||
          endDateMoment.isBefore(startDateMoment)
        ) {
          isValid = false;
          break;
        }
      }
    }
    setSaveFlag(isValid);
    setDateFlag(!isValid);
  }, [multiInputsData]);

  const handleWeekdayToggle = (day) => {
    const updatedWeekdays = { ...selectedWeekdays };
    updatedWeekdays[day] = updatedWeekdays[day] === "1" ? "0" : "1";

    setIsFieldsDirty(true);
    setSelectedWeekdays(updatedWeekdays);
    setWeekendsError(areAllWeekdaysUnchecked(updatedWeekdays));
  };

  const readCompanySchedule = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
    };
    CompanyAdminServices.companyAdminPage(data)
      .then((res) => {
        // console.log(res, "res");
        setAccessLevelValue(parseInt(res.accessLevel));
        setProjectsList(res.projectsInfoArray);

        const nonWorkingDaysData = Object.values(res.nonWorkingDays).map(
          (day) => ({
            holidayName: day["holidayName"],
            startDate: day["startDate"],
            finishDate: day["finishDate"],
          })
        );

        setMultiInputsData(res.nonWorkingDays);
        setSelectedWeekdays(res.workingDays);
        handleMultiInputsChange(nonWorkingDaysData);
        reset({
          nonWorkingDays: nonWorkingDaysData,
          workingDays: res.workingDays,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setIsFieldsDirty(true);
  };

  const onMenuClick = (e) => {
    if (e.key === "1") {
      SaveChanges("workingDaysOnly");
    } else if (e.key === "2") {
      SaveChanges("nonWorkingDaysOnly");
    }
  };
  const items = [
    {
      key: "1",
      label: "Save Workings Days Only",
    },
    {
      key: "2",
      label: "Save Non-Workings Days Only",
    },
  ];

  useEffect(() => {
    readCompanySchedule();
  }, []);

  const areAllWeekdaysUnchecked = (updatedWeekdays) => {
    return Object.values(updatedWeekdays).every((value) => value !== "1");
  };

  const SaveChanges = (type) => {
    const toastId = toast.loading("Saving changes");
    const allDaysUnchecked = Object.values(selectedWeekdays).every(
      (day) => day === "0"
    );

    if (allDaysUnchecked) {
      setWeekendsError(true);
      toast.error("Please select Working Days and try again");
      return;
    }

    const formData = {
      ...getValues(),
      workingDays: selectedWeekdays,
      nonWorkingDays: multiInputsData,
    };

    const WorkingDaysData = {
      ...Auth,
      applyToAllProjects: radioValue === 1 ? "Yes" : "No",
      selectedProjects:
        radioValue === 1
          ? projectsList.map((project) => project.projectID)
          : radioValue === 3
          ? [...selectedProjectsList]
          : [],
      saturday: selectedWeekdays["Saturday"],
      sunday: selectedWeekdays["Sunday"],
      monday: selectedWeekdays["Monday"],
      tuesday: selectedWeekdays["Tuesday"],
      wednesday: selectedWeekdays["Wednesday"],
      thursday: selectedWeekdays["Thursday"],
      friday: selectedWeekdays["Friday"],
    };

    const holidayName = [];
    const start = [];
    const end = [];

    for (const key in multiInputsData) {
      if (multiInputsData.hasOwnProperty(key)) {
        const item = multiInputsData[key];

        //To prevent saving empty data
        if (
          item.holidayName !== "" &&
          item.startDate !== "" &&
          item.finishDate !== ""
        ) {
          holidayName.push(item.holidayName);
          start.push(item.startDate);
          end.push(item.finishDate);
        }
      }
    }

    const NonWorkingDaysData = {
      ...Auth,
      applyToAllProjects: radioValue === 1 ? "Yes" : "No",
      selectedProjects:
        radioValue === 1
          ? projectsList.map((project) => project.projectID)
          : radioValue === 3
          ? [...selectedProjectsList]
          : [],
      holidayName,
      start,
      end,
    };

    let promises = [];

    if (type === "saveAll" || type === "workingDaysOnly") {
      promises.push(
        CompanyAdminServices.updateCompanyWorkingDays(
          WorkingDaysData,
          company_id
        )
      );
    }

    if (type === "saveAll" || type === "nonWorkingDaysOnly") {
      promises.push(
        CompanyAdminServices.updateCompanyNonWorkingDays(
          NonWorkingDaysData,
          company_id
        )
      );
    }

    let calculateProjectsList =
      radioValue === 1
        ? projectsList.map((project) => project.projectID)
        : radioValue === 3
        ? [...selectedProjectsList]
        : [];

    Promise.all(promises)
      .then((res) => {
        toast.success("Changes saved successfully", { id: toastId });

        // Loop over each project in selectedProjectsList
        calculateProjectsList.forEach((projectID) => {
          // Find the project details based on the projectID
          const projectDetails = projectsList.find(
            (project) => project.projectID === projectID
          );

          // Create data object for backend request
          const updateBackEndData = {
            company: company_id,
            project: projectID,
            code,
            loginEmail,
            approvalTime: projectDetails.approvalTime,
            reviewTime: projectDetails.reviewTime,
          };

          // Send request for each project
          ProjectServices.getExportData(updateBackEndData);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong, please try again.", {
          id: toastId,
        });
      })
      .finally(() => {
        let promises = [];
      });
  };

  return (
    <>
      <CompanyAdmin pageType="company_schedule" />
      {(!isLoading && typeof accessLevelValue !== "number") ||
      accessLevelValue === 0 ? (
        <div className="mt-5 text-center">
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : (
        <div
          className={`${styles.container} mx-2`}
          style={accessLevelValue < 2 ? { pointerEvents: "none" } : null}
        >
          <Text className={`${styles.title} my-4`} dontWrap={false}>
            {/* Company Information */}
          </Text>
          <ContentContainer className="px-3 py-1 mt-4">
            {!isLoading ? (
              <div className="my-4">
                <div className="row">
                  <div className="col-8 col-xl-8 col-xxl-7 ">
                    <div>
                      <Text className={`${styles.title} mb-2`} dontWrap={false}>
                        <Tooltip title="Select working days" placement="right">
                          Company Working Days
                        </Tooltip>
                      </Text>
                      {
                        <div className="d-flex flex-wrap flex-row">
                          {Object.keys(selectedWeekdays).map((day, index) => (
                            <div
                              key={day}
                              className={`d-flex gap-2 align-items-center ${styles.checkboxContainer}`}
                            >
                              <Text className={`${styles.weekday}`}>
                                {WEEK_DAYS[index]}
                              </Text>
                              <input
                                label={day}
                                className={styles.checkbox}
                                type="checkbox"
                                checked={selectedWeekdays[day] === "1"}
                                onChange={() => handleWeekdayToggle(day)}
                              />
                            </div>
                          ))}
                          {weekendsError && (
                            <span className="text-danger">
                              You need to check at least one day
                            </span>
                          )}
                        </div>
                      }
                    </div>

                    <div>
                      <Text
                        className={`${styles.title} mt-4 mb-2`}
                        dontWrap={false}
                      >
                        <Tooltip title="Input Holidays" placement="right">
                          Company Non-Working Days
                        </Tooltip>
                      </Text>

                      {Object.keys(multiInputsData).length > 0 && (
                        <Controller
                          name="workingDays"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <MultiInputsProjectDates
                              {...field}
                              saveFlag={saveFlag}
                              setIsFieldsDirty={setIsFieldsDirty}
                              errors={errors.workingDays}
                              Data={multiInputsData}
                              onChange={handleMultiInputsChange}
                              accessLevel={accessLevelValue}
                            />
                          )}
                        />
                      )}

                      <div style={{ textAlign: "left", color: "red" }}>
                        {!saveFlag && !dateFlag && (
                          <p>Please fill in all fields</p>
                        )}
                        {!saveFlag && dateFlag && (
                          <p>Finish Date can't be before Start Date</p>
                        )}
                      </div>
                    </div>

                    <hr />

                    <Radio.Group
                      onChange={handleRadioChange}
                      value={radioValue}
                    >
                      <label className={`${styles.subTitleSmall} mb-2`}>
                        Apply changes to:
                      </label>
                      <div className=" d-flex flex-column gap-2">
                        <Radio value={1}>All Projects</Radio>
                        <Radio value={2}>Future Projects Only</Radio>
                        <Radio value={3}>Selected Projects Only</Radio>
                        <Select
                          mode="multiple"
                          showSearch={false}
                          allowClear
                          placeholder="Please select projects"
                          disabled={radioValue !== 3 || accessLevelValue < 2}
                          onChange={(value) => {
                            setSelectedProjectsList(value);
                          }}
                          style={{ maxWidth: "500px" }}
                          options={projectsList.map((item) => ({
                            value: item.projectID,
                            label: item.projectName,
                          }))}
                        />
                      </div>
                    </Radio.Group>
                    <Dropdown.Button
                      className={`${styles.dropdownList} mt-4 d-flex align-items-center`}
                      type="primary"
                      icon={
                        <DownOutlined
                          className={`${styles.dropdownIcon}`}
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingLeft:
                              !saveFlag ||
                              !isFieldsDirty ||
                              weekendsError ||
                              accessLevelValue < 2
                                ? "8px"
                                : "",
                          }}
                        />
                      }
                      disabled={
                        !saveFlag ||
                        !isFieldsDirty ||
                        weekendsError ||
                        accessLevelValue < 2
                      }
                      menu={{
                        items,
                        onClick: onMenuClick,
                      }}
                      placement="bottom"
                    >
                      <span
                        onClick={() => {
                          SaveChanges("saveAll");
                        }}
                      >
                        Save Changes
                      </span>
                    </Dropdown.Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="my-4">
                <LoadingComponent />
              </div>
            )}
          </ContentContainer>
        </div>
      )}
    </>
  );
}
