import React from "react";
import styles from "./code.module.css";
import AuthWrapper from "components/auth-page-wrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import ButtonComponent from "components/button-component";
import InputComponent from "components/input-component";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyCode = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search)


  let schema = yup.object().shape({
    code: yup.string().required("This field is required"),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      code: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (v) => {
    console.log(v);
    let email = params.get("email")
    navigate(`/reset-password?email=${email}&code=${v?.code}`);
  };

  return (
    <AuthWrapper title="Verify Code">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column mt-3">
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <InputComponent
                label="Verification Code"
                errors={errors.code}
                placeholder="Enter your verification code"
                {...field}
              />
            )}
          />

          <ButtonComponent title={"Submit"} className={`mt-3`} />
        </div>
      </form>
    </AuthWrapper>
  );
};

export default VerifyCode;
