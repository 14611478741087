export const ProgressArr = [
  { name: "Less Than or Equal 25%", slug: "Less Than or Equal 25%" },
  { name: "Less Than or Equal 50%", slug: "Less Than or Equal 50%" },
  { name: "Less Than or Equal 75%", slug: "Less Than or Equal 75%" },
  { name: "Less Than or Equal 100%", slug: "Less Than or Equal 100%" },
];

export const TypesArr = [
  { name: "Constructions", slug: "Constructions" },
  { name: "Commercial", slug: "Commercial" },
  { name: "Resedential", slug: "Resedential" },
  { name: "Public Entertainment", slug: "Public Entertainment" },
  { name: "Gardens", slug: "Gardens" },
];
