import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Button } from "antd";
import React from "react";
import Text from "components/text";
import styles from "./radio.module.css";

const RadioGroupComponent = ({
  name = "",
  data = [],
  selected = "",
  setSelected = () => {},
  header = "",
  className = "",
  valueKey = "", // Key for the value property
  labelKey = "", // Key for the label property
}) => {
  const uniqueValues = new Set();
  const filteredData = data.filter((item) => item[labelKey] !== "0%");

  return (
    <div className={`px-2 ${className}`}>
      <Text className={`${styles.header} my-2`}>{header}</Text>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label={name}
          name={name}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          {labelKey === "Progress" && !uniqueValues.has("0% Complete") && (
            <div className="d-flex flex-column">
              <FormControlLabel
                value="0% Complete"
                control={<Radio />}
                label={<Text className={styles.RadioText}>0% Complete</Text>}
              />
              <FormControlLabel
                value="less than 50% Complete"
                control={<Radio />}
                label={
                  <Text className={styles.RadioText}>
                    Less than 50% Complete
                  </Text>
                }
              />
              <FormControlLabel
                value="more than 50% Complete"
                control={<Radio />}
                label={
                  <Text className={styles.RadioText}>
                    More than 50% Complete
                  </Text>
                }
              />
              <FormControlLabel
                value="100% Complete"
                control={<Radio />}
                label={<Text className={styles.RadioText}>100% Complete</Text>}
              />
            </div>
          )}
          {filteredData.map((item, index) => {
            const value = item[valueKey];
            const label = item[labelKey];
            if (uniqueValues.has(value) || label === "") {
              return null;
            }
            uniqueValues.add(value);
            return labelKey === "Progress" ? null : (
              <div key={index} className="input-radio-container">
                <FormControlLabel
                  value={value}
                  control={<Radio />}
                  label={<Text className={styles.RadioText}>{label}</Text>}
                />
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioGroupComponent;
