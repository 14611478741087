import { Network } from "../Network";
import { CompanyAdminEndPoints } from "apis/EndPoints/CompanyAdminEndPoints";

export class CompanyAdminServices {
  static readRegisteredProjects(data = {}) {
    return Network.fetch(
      CompanyAdminEndPoints.readRegisteredProjects.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.readRegisteredProjects.method,
      }
    );
  }
  static readProcurementLogsViews(data = {}) {
    return Network.fetch(
      CompanyAdminEndPoints.readProcurementLogsViews.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.readProcurementLogsViews.method,
      }
    );
  }
  static getSampleProcurementLogsViews(data = {}) {
    return Network.fetch(
      CompanyAdminEndPoints.getSampleProcurementLogsViews.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.getSampleProcurementLogsViews.method,
        body: JSON.stringify(data),
      }
    );
  }
  static createProcurementLogsViews(data = {}) {
    return Network.fetch(
      CompanyAdminEndPoints.createProcurementLogsViews.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.createProcurementLogsViews.method,
        body: JSON.stringify(data),
      }
    );
  }
  static updateProcurementLogsViews(data = {}) {
    return Network.fetch(
      CompanyAdminEndPoints.updateProcurementLogsViews.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.updateProcurementLogsViews.method,
        body: JSON.stringify(data),
      }
    );
  }
  static deleteProcurementLogsViews(data = {}) {
    return Network.fetch(
      CompanyAdminEndPoints.deleteProcurementLogsViews.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.deleteProcurementLogsViews.method,
        body: JSON.stringify(data),
      }
    );
  }
  static companyAdminPage(data = {}) {
    return Network.fetch(
      CompanyAdminEndPoints.companyAdminPage.url(data.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.companyAdminPage.method,
      }
    );
  }
  static updateCompanyWorkingDays(data, company_id) {
    return Network.fetch(
      CompanyAdminEndPoints.updateCompanyWorkingDays.url(company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.updateCompanyWorkingDays.method,
        body: JSON.stringify(data),
      }
    );
  }
  static updateCompanyNonWorkingDays(data, company_id) {
    return Network.fetch(
      CompanyAdminEndPoints.updateCompanyNonWorkingDays.url(company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyAdminEndPoints.updateCompanyNonWorkingDays.method,
        body: JSON.stringify(data),
      }
    );
  }
}
