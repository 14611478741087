import { Button, Input, Modal, Select, Table } from "antd";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "components/button-component";
import CloseIcon from "@mui/icons-material/Close";
import Container from "components/container";
import ContentContainer from "components/content-container";
import { EditOutlined } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import InputComponent from "components/input-component";
import { PermissionServices } from "apis/Services/PermissionsService";
import SearchIcon from "assets/images/search-icon.svg";
import SeparateLine from "components/separate-line";
import Text from "components/text";
import styles from "./company-admin.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/loading-component";
import permissionsLevels from "constants/permissionsLevels";

const tabs = [
  {
    id: 1,
    text: "Overview",
    value: "company-overview",
    path: "company-overview",
  },
  {
    id: 2,
    text: "Schedule Management",
    value: "company-schedule",
    path: "company-schedule",
  },
  {
    id: 3,
    text: "Dashboard",
    value: "company-dashboard",
    path: "company-dashboard",
  },
];
const CompanyAdmin = ({ pageType }) => {
  const [selectedTab, setselectedTab] = useState(tabs[0]);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { company_id } = useParams();

  const projectPermissionsFunction = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");

    let data = {
      company_id,
      loginEmail,
      code,
    };
    console.log(data);
    PermissionServices.setProjectPermissionsTemplate(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {});
  };

  return (
    <>
      <div className={`d-flex flex-column nav-rest`}>
        <div className="d-flex justify-content-center px-3 ">
          <SeparateLine />
        </div>
        <Row
          className={`align-items-center justify-content-between gap-4 py-2 px-2`}
        >
          <Col>
            <div className={`d-flex align-items-center gap-4 py-2 px-2`}>
              <div className="d-flex gap-2 align-items-center py-1">
                <Text
                  className={`main-nav-title`}
                  fontFamily={"var(--ff-bold)"}
                >
                  Company Admin
                </Text>
              </div>
              <div className="d-flex align-items-center flex-wrap gap-3">
                {tabs.map((item, index) => (
                  <Link
                    key={index}
                    className="pointer"
                    to={`/main/company/${company_id}/${item.path}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Text
                      fontSize={14}
                      // color={selectedTab.id == item.id ? "#0196DB" : "black"}
                      color={
                        location.pathname.includes(item.path)
                          ? "#0196DB"
                          : "black"
                      }
                      fontFamily={"var(--ff-bold)"}
                    >
                      {item.text}
                    </Text>
                  </Link>
                ))}
              </div>
            </div>
          </Col>
          {/* {pageType == "company_overview" && <Col md={3}></Col>} */}
        </Row>
      </div>
      {/* <Container className="my-4">
        <ContentContainer className="p-1">
          <div className={`py-2 px-2`}>{selectedTab.id === 1 && <></>}</div>
        </ContentContainer>
      </Container> */}
    </>
  );
};

export default CompanyAdmin;
