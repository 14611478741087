import { React, useEffect } from "react";
import { Button, Popover, Modal, Alert } from "antd";
import { useState } from "react";
import { ProjectServices } from "apis/Services/ProjectService";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { reloadProjects } from "redux/project-reducer";
import {
  AppstoreAddOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import ProcoreLogo from "assets/images/Procore_Logo.svg";
import { useDispatch } from "react-redux";

function PostSubmittals({
  projectDetails,
  projectsAccessLevelValue,
  checkedRows,
  setCheckedRows,
}) {
  const { company_id, id } = useParams();
  const [isProcore, setIsProcore] = useState();
  const [hasSubmittalName, setHasSubmittalName] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const dispatch = useDispatch();

  const handlePosting = () => {
    if (projectsAccessLevelValue >= 2) {
      const selectedData = [];
      const divisionNames = [];

      const entryIDs = Object.keys(checkedRows).filter(
        (entryID) => checkedRows[entryID]
      );

      // Filtering data that matches entryID
      for (const key of Object.keys(projectDetails.content)) {
        const division = projectDetails.content[key];
        const subDivisions = Object.values(division);
        subDivisions.forEach((subDivision) => {
          subDivision.forEach((item) => {
            if (entryIDs.includes(item.entryID)) {
              if (
                (item.specNumber !== "" && item.entryOrigin === "Koncurent") ||
                (item.specRevision !== "" && item.entryOrigin === "Koncurent")
              ) {
                setHasSubmittalName(true);
              }
              if (item.entryOrigin === "Koncurent") {
                // Adding only koncurent entries to the request
                selectedData.push(item);
                if (!divisionNames.includes(key)) {
                  divisionNames.push(key);
                }
              } else {
                // Procore flag to show the warning
                setIsProcore("Some");
              }
            }
          });
        });
      }

      if (selectedData.length === 0) {
        setIsProcore("All");
      }

      setData({ selectedData, divisionNames });
      setIsModalOpen(true);
    }
  };

  function handleOk() {
    postData(data);
  }

  function handleIgnore() {
    // if submittal number is filleed or revision is filled then ignore.
    const selectedData = data.selectedData.filter(
      (item) => item.specNumber === "" && item.specRevision === ""
    );

    let divisionNames = data.divisionNames;
    let updatedData = { selectedData, divisionNames };

    if (selectedData.length > 0) {
      postData(updatedData);
    } else {
      handleCancel();
    }
  }

  function handleCancel() {
    setIsModalOpen(false);
    setIsProcore();
    setHasSubmittalName(false);
  }

  function handleCancelErrorModal() {
    setErrorModal(false);
  }

  function postData(data) {
    const toastId = toast.loading("Posting submittals in progress");
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let authData = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
    };
    setIsModalOpen(false);
    ProjectServices.procurementLogPostSubmittalToProcore(data, authData)
      .then((res) => {
        toast.success("Submittals posted successfully!", { id: toastId });
        if (res.length > 0) {
          setErrorModal(true);
          setErrorList(res);
        }
        setCheckedRows([]);
        dispatch(reloadProjects());
      })
      .catch((err) => {
        toast.error("Something went wrong, please try again.", {
          id: toastId,
        });
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsProcore();
        setHasSubmittalName(false);
      });
  }

  return (
    <div>
      <div
        onClick={handlePosting}
        className=" d-flex justify-content-left gap-2 align-items-center"
      >
        <img src={ProcoreLogo} alt="Procore" width={16} />
        Post to Procore
      </div>
      <Modal
        open={isModalOpen}
        width={700}
        onOk={isProcore !== "All" ? handleOk : null}
        onCancel={handleCancel}
        footer={
          hasSubmittalName && isProcore !== "All"
            ? [
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button key="ignore" onClick={handleIgnore}>
                  Skip These Items
                </Button>,
                <Button key="overwrite" type="primary" onClick={handleOk}>
                  Overwrite
                </Button>,
              ]
            : isProcore === "All"
            ? [
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button key="confirm" type="primary" disabled>
                  Confirm
                </Button>,
              ]
            : [
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button key="confirm" type="primary" onClick={handleOk}>
                  Confirm
                </Button>,
              ]
        }
      >
        <div className="">
          <div className="d-flex align-items-center gap-2">
            <h5 className="fw-bold pt-2">
              Selected submittals will be posted to Procore
            </h5>
          </div>
          <div className=" d-flex flex-column">
            <p>
              Submittal numbers and submittal revisions will be generated for
              all posted entries, to match Procore’s submittal requirements.
            </p>

            {isProcore === "Some" ? (
              <Alert
                message="Some of the selected items already exist in Procore, such items will not be posted."
                type="warning"
                showIcon
              />
            ) : isProcore === "All" ? (
              <Alert
                message="
                All selected items are already present in Procore."
                type="error"
                showIcon
              />
            ) : (
              <></>
            )}

            {hasSubmittalName && isProcore !== "All" ? (
              <Alert
                message="If some of the selected items have a submittal name and/or submittal revision, they may be overwritten to match Procore’s requirements."
                type="warning"
                className="mt-2"
                showIcon
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        open={errorModal}
        width={500}
        onCancel={handleCancelErrorModal}
        maskClosable={false}
        footer={[
          <Button key="close" onClick={handleCancelErrorModal}>
            Close
          </Button>,
        ]}
      >
        <div className="d-flex flex-column">
          <h5>Error Message</h5>
          {errorList && (
            <Alert
              style={{ maxHeight: "400px", overflowY: "auto" }}
              description={
                <div>
                  {errorList.map((error, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "8px 0px 8px 0px ",
                        borderBottom: "1px solid lightgray",
                      }}
                    >
                      <strong>Spec Section:</strong> {error["Spec Section"]}
                      <br />
                      <strong>Submittal Title:</strong> {error["Spec Title"]}
                      <br />
                      <strong>Submittal Number:</strong>{" "}
                      {error["Submittal Number"]}
                      <br />
                      <strong>Submittal Revision:</strong>
                      {error["Submittal Revision"]}
                      <br />
                      <strong>Column Name:</strong> {error["Column Name"]}
                      <br />
                      <strong>Column Value:</strong> {error["Column Value"]}
                      <br />
                      <strong>Error:</strong> {error["Error"]}
                      <br />
                    </div>
                  ))}
                </div>
              }
              type="error"
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default PostSubmittals;
