export const PermissionsEndPoints = {
  getProjectPermissions: {
    url: (company) => `/rest/v1/readProjectPermissionsTemplatesInfo/${company}`,
    method: "GET",
  },
  getCompanyPermissions: {
    url: (company) => `/rest/v1/readCompanyPermissionsTemplatesInfo/${company}`,
    method: "GET",
  },
  setCompanyPermissions: {
    url: (company) =>
      `/rest/v1/createCompanyPermissionsTemplatesInfo/${company}`,
    method: "POST",
  },
  deleteCompanyPermissions: {
    url: (company) =>
      `/rest/v1/deleteCompanyPermissionsTemplatesInfo/${company}`,
    method: "DELETE",
  },
  updateCompanyPermissions: {
    url: (company) =>
      `/rest/v1/updateCompanyPermissionsTemplatesInfo/${company}`,
    method: "PUT",
  },
  setProjectPermissions: {
    url: (company) =>
      `/rest/v1/createProjectPermissionsTemplatesInfo/${company}`,
    method: "POST",
  },
  deleteProjectPermissions: {
    url: (company) =>
      `/rest/v1/deleteProjectPermissionsTemplatesInfo/${company}`,
    method: "DELETE",
  },
  updateProjectPermissions: {
    url: (company) =>
      `/rest/v1/updateProjectPermissionsTemplatesInfo/${company}`,
    method: "PUT",
  },
  readCompanyContacts: {
    url: (company) => `/rest/v1/readCompanyContactsInfo/${company}`,
    method: "GET",
  },
  removeCompanyContacts: {
    url: (company) => `/rest/v1/removeCompanyContactsInfo/${company}`,
    method: "DELETE",
  },
  editUserPermission: {
    url: (company) => `/rest/v1/editContactsPermissionsCompanyWide/${company}`,
    method: "PUT",
  },
};
