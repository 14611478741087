import {
  Dropdown,
  Button,
  Tooltip,
  Radio,
  Select,
  Input,
  Table,
  Modal,
  Menu,
  Breadcrumb,
} from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Container from "components/container";
import ContentContainer from "components/content-container";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/loading-component";
import MultiInputsProjectDates from "components/multi-input-project-dates";
import { WEEK_DAYS } from "mockups/WEEK_DAYS";
import Text from "components/text";
import CompanyAdmin from "pages/company-admin";
import { CompanyAdminServices } from "apis/Services/CompanyAdminService";
import styles from "./company-dashboard.module.css";
import moment from "moment";
import { DeleteFilled } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import { ProjectServices } from "apis/Services/ProjectService";
import DashboardViews from "./dashboard-views";
import CreateView from "./create-View";

export default function CompanyDashboard({ type = "company_dashboard" }) {
  let code = localStorage.getItem("token");
  let loginEmail = localStorage.getItem("email");
  let Auth = {
    code,
    loginEmail,
  };

  const { company_id } = useParams();
  const [isLoading, setIsLoading] = useState();
  const [accessLevelValue, setAccessLevelValue] = useState();
  const [viewsList, setViewsList] = useState([]);
  const [selectedViewName, setSelectedViewName] = useState([]);
  const [projectsDropDown, setProjectsDropDown] = useState({});
  const [procoreColumnsInfo, setProcoreColumnsInfo] = useState({});
  const [undeletableColumns, setUndeletableColumns] = useState({});
  const [projectsInfoArray, setProjectsInfoArray] = useState([]);
  const [createFlag, setCreateFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [viewName, setViewName] = useState("");
  const [viewDescription, setViewDescription] = useState("");

  const readCompanySchedule = () => {
    setIsLoading(true);
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
    };
    CompanyAdminServices.readProcurementLogsViews(data)
      .then((res) => {
        setAccessLevelValue(parseInt(res.accessLevel));
        setViewsList(res.companyViewsTable);
        setProjectsDropDown(res.projectsDropDown);
        setProcoreColumnsInfo(res.procoreColumnsInfo);
        setUndeletableColumns(res.undeletableColumns);
        setProjectsInfoArray(res.projectsInfoArray);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateUndeleteableColumns = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
    };
    CompanyAdminServices.readProcurementLogsViews(data)
      .then((res) => {
        setUndeletableColumns(res.undeletableColumns);
        setViewsList(res.companyViewsTable);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    readCompanySchedule();
  }, []);

  const handleEdit = (item) => {
    const selectedView = viewsList.find(
      (view, index) => view.viewName === item.view
    );
    setSelectedViewName(selectedView);
    setViewName(selectedView.viewName);
    setViewDescription(selectedView.viewDescription);
    setCreateFlag(true);
    setEditFlag(true);
  };

  return (
    <>
      <CompanyAdmin pageType="company_schedule" />
      {(!isLoading && typeof accessLevelValue !== "number") ||
      accessLevelValue < 2 ? (
        <div className="mt-5 text-center">
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : (
        <>
          {createFlag && (
            <Breadcrumb
              className=" mt-4 ms-4"
              items={[
                {
                  title: (
                    <a
                      onClick={() => {
                        setViewName("");
                        setViewDescription("");
                        setCreateFlag(false);
                        setEditFlag(false);
                        setCreateFlag(false);
                      }}
                      href="##"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      Dashboard Views
                    </a>
                  ),
                },
                {
                  title: <>{viewName || "Untitled"}</>,
                },
              ]}
            />
          )}
          <Container>
            <ContentContainer className="px-3 py-1 my-4">
              {!isLoading ? (
                <div>
                  {!createFlag ? (
                    <DashboardViews
                      viewsList={viewsList}
                      selectedViewName={selectedViewName}
                      setSelectedViewName={setSelectedViewName}
                      setCreateFlag={setCreateFlag}
                      readCompanySchedule={readCompanySchedule}
                      handleEdit={handleEdit}
                    />
                  ) : (
                    <CreateView
                      selectedViewName={selectedViewName}
                      projectsDropDown={projectsDropDown}
                      viewName={viewName}
                      setViewName={setViewName}
                      viewDescription={viewDescription}
                      setViewDescription={setViewDescription}
                      setCreateFlag={setCreateFlag}
                      editFlag={editFlag}
                      setEditFlag={setEditFlag}
                      readCompanySchedule={readCompanySchedule}
                      procoreColumnsInfo={procoreColumnsInfo}
                      undeletableColumns={undeletableColumns}
                      projectsInfoArray={projectsInfoArray}
                      updateUndeleteableColumns={updateUndeleteableColumns}
                    />
                  )}
                </div>
              ) : (
                <div className="my-4">
                  <LoadingComponent />
                </div>
              )}
            </ContentContainer>
          </Container>
        </>
      )}
    </>
  );
}
