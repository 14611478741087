import React, { useEffect, useState } from "react";
import { Avatar, Button, Select, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { ContactServices } from "apis/Services/ContactService";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoIcon from "assets/images/InfoIcon.svg";
import InputComponent from "components/input-component";
import SearchIcon from "assets/images/search-icon.svg";
import SelectComponent from "components/select-component";
import { SideBarEndService } from "apis/Services/SideBarService";
import Text from "components/text";
import checklist from "assets/images/checklist.svg";
import increaseArrow from "assets/images/increaseArrow.svg";
import styles from "./ProcurementSideMenuContent.module.css";
import { useParams } from "react-router-dom";

function ProcurementSideMenuContent({ content, accessLevel }) {
  // console.log(content, "content");
  const { company_id, id } = useParams();

  const [contact, setContact] = useState(null);
  let code = localStorage.getItem("token");
  let email = localStorage.getItem("email");
  const [selectedItems, setSelectedItems] = useState([]);

  // Filter out contacts who are already assigned tasks
  const filteredValidContacts = Object.entries(
    contact?.validContacts || {}
  ).filter(
    ([email]) =>
      !contact?.tasksAssigned?.content.some(
        (item) => item["Assigned To Email"] === email
      )
  );

  const filteredOptions = filteredValidContacts.filter(
    ([email]) => !selectedItems.includes(email)
  );

  let TasksAssignedData = {
    code,
    loginEmail: email,
    company_id,
    project_id: id,
    id: content.id,
  };

  const fetchTasksAssignedInfo = () => {
    SideBarEndService.TasksAssignedInfo(TasksAssignedData)
      .then((res) => {
        // console.log(res, "res");
        setContact(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTasksAssignedInfo();
  }, []);

  useEffect(() => {
    let data = {
      code,
      loginEmail: email,
      company_id,
      project_id: id,
      id: content.id,
    };
    SideBarEndService.TasksAssignedInfo(data)
      .then((res) => {
        // console.log(res, "res");
        setContact(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [selectedManager, setSelectedManager] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState([]);
  const [clearSelectionValue, setClearSelectionValue] = useState(false);
  const [sendNotification, setSendNotification] = useState("sendNotification");

  // Function to handle selection change for the manager select component
  const handleManagerSelectChange = (selectedValue) => {
    const updatedSelectedItems = [...selectedItems];
    const updatedSelectedManager = selectedValue.map((value) => {
      const [email, fullName] = value.split(",");
      const [firstName, lastName] = fullName.split(" ");
      return { email, firstName, lastName };
    });

    selectedValue.forEach((value) => {
      const [email] = value.split(",");
      updatedSelectedItems.push(email);
    });

    setSelectedItems(updatedSelectedItems);
    setSelectedManager(updatedSelectedManager);
  };

  // Function to handle selection change for the contractor select component
  const handleContractorSelectChange = (selectedValue) => {
    const updatedSelectedItems = [...selectedItems];
    const updatedSelectedContractor = selectedValue.map((value) => {
      const [email, fullName] = value.split(",");
      const [firstName, lastName] = fullName.split(" ");
      return { email, firstName, lastName };
    });

    selectedValue.forEach((value) => {
      const [email] = value.split(",");
      updatedSelectedItems.push(email);
    });

    setSelectedItems(updatedSelectedItems);
    setSelectedContractor(updatedSelectedContractor);
  };

  useEffect(() => {
    // Combine the emails from selectedManager and selectedContractor
    const allSelectedEmails = [
      ...selectedManager.map((manager) => manager.email),
      ...selectedContractor.map((contractor) => contractor.email),
    ];

    // Filter emails from selectedItems that are included in either selectedManager or selectedContractor
    const updatedSelectedItems = selectedItems.filter((email) =>
      allSelectedEmails.includes(email)
    );

    // Update selectedItems with the filtered list
    setSelectedItems(updatedSelectedItems);
  }, [selectedManager, selectedContractor]);

  const isButtonVisible = selectedItems.length > 0;

  //checks notification switch if ON or OFF.
  const onChange = (checked) => {
    if (checked) {
      setSendNotification("sendNotification");
    } else {
      setSendNotification("");
    }
  };

  const handleSendInvitation = async () => {
    let managerEmails = [];
    let contractorEmails = [];

    if (selectedManager.length > 0) {
      selectedManager.forEach((manager) => {
        managerEmails.push(manager.email);
      });
    }
    if (selectedContractor.length > 0) {
      selectedContractor.forEach((contractor) => {
        contractorEmails.push(contractor.email);
      });
    }
    let Data = {
      code,
      loginEmail: email,
      company_id,
      project_id: id,
      managerEmails,
      contractorEmails,
      selectedItems: [
        {
          id: content.id,
          submittalTitle: content.specTitle,
          submittalNumber: content.specNumber,
          submittalRevision: content.specRevision,
        },
      ],
      action: sendNotification,
    };

    try {
      const res = await SideBarEndService.assignTask(Data);
      console.log("All invitations sent successfully.");
    } catch (err) {
      console.log("Error sending invitations:", err);
    } finally {
      fetchTasksAssignedInfo();
      // setSelectedManager(null);
      // setSelectedContractor(null);
      // setClearSelectionValue(true);
    }
  };
  const handleDelete = (task) => {
    let data = [];
    let assignedEmails = [];

    assignedEmails.push(task["Assigned To Email"]);

    let deletedData = {
      code,
      loginEmail: email,
      company_id,
      project_id: id,
      id: task["Task id"],
      assignedEmails,
    };

    data.push(deletedData);
    SideBarEndService.deleteTask(data, company_id)
      .then((res) => {
        console.log(res, "res");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        fetchTasksAssignedInfo();
      });
  };

  return (
    <>
      <div className={styles.ProcurementManagerSection}>
        <Text className={styles.ProcurementManager}>
          Assign Procurement Manager
        </Text>
        <div className="my-2">
          <strong>
            Manager(s) <span className=" text-danger">*</span>
          </strong>
        </div>
        <Select
          mode="multiple"
          placeholder="Select Manager(s)"
          value={selectedManager.firstName}
          onChange={handleManagerSelectChange}
          maxTagCount={3}
          style={{
            width: "100%",
          }}
          size="large"
          options={filteredOptions.map(([email, [firstName, lastName]]) => ({
            label: `${firstName} ${lastName}`,
            value: email + `,${firstName} ${lastName}`,
          }))}
        />

        <div style={{ overflowY: "scroll", maxHeight: "200px" }}>
          {contact?.tasksAssigned?.content?.map((item) => {
            if (item.Role === "Manager") {
              const assignedToName = item["Assigned To Name"];
              const firstTwoWords = assignedToName.split(" ").slice(0, 2);
              const initials = firstTwoWords
                .map((word) => word.charAt(0))
                .join("");
              return (
                <div
                  className="d-flex flex-row align-items-center my-2 mx-1"
                  key={item["Assigned To Email"]}
                >
                  <div className="d-flex flex-row align-items-center">
                    <Avatar
                      style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                    >
                      {initials}
                    </Avatar>
                    <h4 className={`my-0 mx-2 ${styles.name}`}>
                      {assignedToName}
                    </h4>
                  </div>
                  <span className="d-flex justify-content-center pointer">
                    <DeleteOutlineOutlinedIcon
                      className={`align-content-center ${
                        accessLevel < 3 ? styles.deleteButton : null
                      }`}
                      onClick={
                        accessLevel < 3 ? null : () => handleDelete(item)
                      }
                    />
                  </span>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>

      <div className={`${styles.responsibleContractorSection} mt-2`}>
        <Text className={styles.responsibleContractor}>
          Assign Responsible Contractor
        </Text>

        <div className="my-2">
          <strong>
            Contractor(s) <span className=" text-danger">*</span>
          </strong>
        </div>
        <Select
          mode="multiple"
          placeholder="Select Contractor(s)"
          value={selectedContractor.firstName}
          onChange={handleContractorSelectChange}
          maxTagCount={3}
          style={{
            width: "100%",
          }}
          size="large"
          options={filteredOptions.map(([email, [firstName, lastName]]) => ({
            label: `${firstName} ${lastName}`,
            value: email + `,${firstName} ${lastName}`,
          }))}
        />

        <div style={{ overflowY: "scroll", maxHeight: "200px" }}>
          {contact?.tasksAssigned?.content?.map((item) => {
            if (item.Role === "Contractor") {
              const assignedToName = item["Assigned To Name"];
              const firstTwoWords = assignedToName.split(" ").slice(0, 2);
              const initials = firstTwoWords
                .map((word) => word.charAt(0))
                .join("");

              return (
                <div
                  className="d-flex flex-row align-items-center my-2 mx-1"
                  key={item["Assigned To Email"]}
                >
                  <Avatar
                    style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                  >
                    {initials}
                  </Avatar>

                  <h4 className={`my-0 mx-2 ${styles.name}`}>
                    {assignedToName}
                  </h4>
                  <span className="d-flex justify-content-center pointer">
                    <DeleteOutlineOutlinedIcon
                      className={`align-content-center ${
                        accessLevel < 3 ? styles.deleteButton : null
                      }`}
                      onClick={
                        accessLevel < 3 ? null : () => handleDelete(item)
                      }
                    />
                  </span>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>

      <div className="d-flex gap-2 mt-3">
        <Switch defaultChecked onChange={onChange} />
        <p>Notify Assigned Users</p>
      </div>

      <Button
        disabled={!isButtonVisible}
        onClick={handleSendInvitation}
        type="primary"
      >
        Save
      </Button>

      <div className={`my-4 ${styles.activityTrackingContainer}`}>
        <div className="d-flex align-items-center">
          <img src={checklist} alt="checklist" />
          <h1 className={`my-1 mx-1 ${styles.activityTrackingHeader}`}>
            Item Activity Tracking
          </h1>
        </div>
        <div>
          <div className={styles.ScrollDiv}>
            {contact?.tasksAssigned?.contentHistory?.map((item) => {
              const assignmentDateTime = item["Assignment Date"];
              // item["Action"] === "Assigned"
              //   ? item["Assignment Date"]
              //   : item["Updated On"];
              const [date, time] = assignmentDateTime.split(" ");
              return (
                <div
                  className={`d-flex align-items-start justify-content-between my-3 ${styles.activityTracking}`}
                  key={item.id}
                >
                  <img src={InfoIcon} alt="InfoIcon" />
                  <div className="w-100 d-flex align-items-start flex-column">
                    <h3 className={`mx-3 ${styles.activityTrackingText}`}>
                      {item["Action"] === "Assigned" &&
                        `${item["Assigned By Name"]} ${item[
                          "Action"
                        ].toLowerCase()} ${item["Assigned To Name"]} as ${
                          item["Role"]
                        }`}

                      {item["Action"] === "Updated" &&
                        `${item["Assigned By Name"]} ${item[
                          "Action"
                        ].toLowerCase()} ${
                          item["Updated By Name"]
                        }'s task as completed`}

                      {item["Action"] === "Deleted" &&
                        `${item["Assigned By Name"]} ${item[
                          "Action"
                        ].toLowerCase()} ${item["Assigned To Name"]} as ${
                          item["Role"]
                        }`}

                      {item["Action"] !== "Assigned" &&
                        item["Action"] !== "Updated" &&
                        item["Action"] !== "Deleted" &&
                        `${item["Assigned By Name"]} ${item[
                          "Action"
                        ].toLowerCase()}`}
                    </h3>
                    <p
                      className={`mx-4 d-flex align-items-center ${styles.activityTrackingInfo}`}
                    >
                      {`Added ${date} at ${time}`}
                      <div className="mx-2">
                        {/* <span className={`${styles.actionBtn}`}>Comment</span> */}
                        {/* <span
                        onClick={() => handleCompleted(item)}
                        className={`${styles.actionBtn}`}
                      >
                        Mark as Completed
                      </span> */}
                        {/* <span className={`${styles.actionBtn}`}>Edit</span> */}
                      </div>
                    </p>
                  </div>
                  <img src={increaseArrow} alt="increaseArrow" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProcurementSideMenuContent;
