import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ProjectHeader from "components/project-header";
import styles from "./custom-reports-details.module.css";
import Container from "components/container";
import ButtonComponent from "components/button-component";
import { Table, Tooltip, DatePicker, Button } from "antd";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import { ReportServices } from "apis/Services/ReportService";
import ExportReports from "components/export-reports";
import dayjs from "dayjs";
import CustomReportFilters from "./custom-report-filters";

export default function CustomReportsDetails() {
  const { company_id, id, reportName } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [editedData, setEditedData] = useState([]);

  let reportPageTitle = reportName;

  let reportRequestName;
  const [data, setData] = useState([]);

  const [applyFilters, setApplyFilters] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [specifiedColumn, setSpecifiedColumn] = useState("");
  const [columnsUsed, setColumnsUsed] = useState([]);
  const [alignedData, setAlignedData] = useState([]);

  function getData() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName,
      minValue,
      maxValue,
      sortBy,
      specifiedColumn,
    };
    ReportServices.readCustomReport(ReqObj)
      .then((res) => {
        setData(res.report.customReportsInfo.data);
        setAccessLevel(parseInt(res.userData.accessLevel));
        setProjectName(res.projectData.projectName);
        setProjectAddress(res.projectData.projectAddress);
        setColumnsUsed(res.report.customReportsInfo.columnsUsed);
        setAlignedData(res.report.alignedData);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      })
      .finally(() => {
        setisLoading(false);
        setEditedData([]);
      });
  }

  useEffect(() => {
    getData();
  }, [applyFilters]);

  let columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      align: "left",
      width: 400,
      fixed: "left",
    },
    {
      title: "Submittal Number",
      dataIndex: "submittalNumber",
      key: "submittalNumber",
      align: "center",
      width: 150,
      fixed: "left",
    },
    {
      title: "Submittal Revison",
      dataIndex: "submittalRevison",
      key: "submittalRevison",
      align: "center",
      width: 150,
      fixed: "left",
    },
    {
      title: "Submittal Type",
      dataIndex: "submittalType",
      key: "submittalType",
      align: "center",
      width: 150,
    },
    ...columnsUsed.map((item) => ({
      title: alignedData[item].label || item,
      dataIndex: item,
      key: item,
      align: "center",
      width: 200,
    })),
  ];

  function saveChanges() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      editedData: editedData,
    };
    ReportServices.modifyIssueDateReport(ReqObj)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!isLoading) {
      const updatedDataSource = data.map((item) => ({
        key: `${item?.entryID}`,
        itemName: `${item?.specTitle}`,
        submittalNumber: `${item?.specNumber}`,
        submittalRevison: `${item?.specRevision}`,
        submittalType: `${item?.specType == null ? "" : item?.specType}`,
        ...columnsUsed.reduce((acc, column) => {
          acc[column] = `${item[column]}`;
          return acc;
        }, {}),
      }));
      setDataSource(updatedDataSource);
    }
  }, [data, isLoading, columnsUsed]);

  const returnedData = (data) => {
    setSpecifiedColumn(data?.specifiedColumn);
    setSortBy(data?.sortBy);
    setMaxValue(data?.maxValue);
    setMinValue(data?.minValue);

    console.log(data);

    setApplyFilters(!applyFilters);
  };

  return (
    <>
      <Container>
        {accessLevel > 1 && (
          <>
            <div className="row mt-4">
              <div className="col-md-6">
                <Text className={`${styles.header}`}>{reportPageTitle}</Text>
              </div>
              <div className="col-md-6 d-flex  justify-content-sm-center justify-content-md-end gap-3">
                {reportRequestName === "plannedIssueDateReport" && (
                  <div className="align-self-center">
                    <Button
                      size="default"
                      onClick={saveChanges}
                      disabled={editedData.length === 0 || isLoading}
                    >
                      Save Changes
                    </Button>
                  </div>
                )}
                <div className=" align-self-center">
                  <ExportReports
                    data={dataSource}
                    columns={columns}
                    accessLevel={accessLevel}
                    editedData={editedData}
                    isLoading={isLoading}
                    reportTitle={reportPageTitle}
                    projectName={projectName}
                    projAddress={projectAddress}
                  />
                </div>
              </div>
            </div>
            <CustomReportFilters
              returnedData={returnedData}
              columnsUsed={columnsUsed}
              alignedData={alignedData}
            />
          </>
        )}
        <div className="row mt-4">
          {isLoading ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <>
              {accessLevel < 2 ? (
                <div className="row">
                  <div className="text-center">
                    <Text>
                      You do not have the necessary permissions to access this
                      page.
                    </Text>
                  </div>
                </div>
              ) : (
                <>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ x: 500, y: "70vh" }}
                    bordered
                    pagination={{
                      position: ["bottomCenter"],
                      defaultPageSize: 20,
                    }}
                    size="small"
                  />
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
}
