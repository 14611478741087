import ActiveProfiles from "components/profiles";
import ActiveUsersAvatar from "components/profiles/ActiveUsersAvatar";
import React from "react";
import SeparateLine from "components/separate-line";
import Text from "components/text";
import { useSelector } from "react-redux";

const ProjectHeader = ({
  ActiveUsers,
  ActiveUsersIcon,
  isActiveShown = true,
}) => {
  const { projectDetails } = useSelector((state) => state.projectDetails);

  let projectName = projectDetails.projectInfo;
  return (
    <div className={`d-flex flex-column nav-rest`}>
      <div className="d-flex justify-content-center">
        <SeparateLine />
      </div>
      <div className={` d-flex justify-content-between py-2 px-2`}>
        <div className="d-flex gap-2 align-items-center flex-wrap flex-md-nowrap">
          <Text className={`main-nav-title`}>
            {projectName}
            {/* {projectName} */}
          </Text>
        </div>

        {/* <ActiveProfiles haveTitle /> */}
        {isActiveShown && (
          <ActiveUsersAvatar
            ActiveUsers={ActiveUsers}
            ActiveUsersIcon={ActiveUsersIcon}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectHeader;
