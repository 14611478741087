import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AuthService } from "apis/Services/AuthService";
import { Select } from "antd";
import { savecompaniesId } from "redux/auth-reducer";
import { useDispatch } from "react-redux";

const HandleAuthData = () => {
  const { access_token, company_id, company_name, refresh_token, email } =
    useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedCompanyIdIndex, setSelectedCompanyIdIndex] = useState(null);

  const handleCompanySelect = (selectedOption) => {
    setSelectedCompanyIdIndex(selectedOption);
  };

  const names = company_name.slice(2, -2).split("',");

  const companiesObject = {};

  for (let i = 0; i < names.length; i++) {
    companiesObject[names[i]] = JSON.parse(company_id)[i];
  }

  function sanitizeKeys(obj) {
    const sanitizedObject = {};
    for (const key in obj) {
      if (key.includes("'")) {
        const sanatize = key.replace("'", "");
        sanitizedObject[sanatize] = obj[key];
      }
      // if (key.startsWith("'") && key.endsWith("'")) {
      //   const sanitizedKey = key.slice(1, -1);
      //   sanitizedObject[sanitizedKey] = obj[key];
      // }
      else {
        sanitizedObject[key] = obj[key];
      }
    }
    return sanitizedObject;
  }

  const sanitizedObject = sanitizeKeys(companiesObject);

  useEffect(() => {
    if (email) {
      localStorage.setItem("email", email);
      localStorage.setItem("lastRequestTime", new Date().getTime());
    }

    if (refresh_token.length > 1 && access_token.length > 1) {
      localStorage.setItem("token", access_token);
      localStorage.setItem("refreshToken", refresh_token);
    }
    localStorage.setItem("companies_object", JSON.stringify(sanitizedObject));
    const pathname = location.pathname;
    let splitURL = pathname.split("/");
    // console.log(splitURL, "split");

    let slice = splitURL.slice(0, 4);
    // console.log(slice, "slice");
    const newURL = pathname.split("/").slice(0, 4).join("/") + "/j/r/e";
    navigate(newURL);
    // console.log(pathname);
  }, []);
  useEffect(() => {
    if (selectedCompanyIdIndex !== null) {
      console.log(selectedCompanyIdIndex, "selectedCompanyIdIndex");

      const selectedCompanyId = sanitizedObject[selectedCompanyIdIndex];

      dispatch(savecompaniesId(sanitizedObject));
      localStorage.setItem("company_id", selectedCompanyId);

      navigate(`/main/company/${selectedCompanyId}/projects`);
    }
  }, [selectedCompanyIdIndex]);

  const selectOptions = Object.keys(sanitizedObject).map((name) => ({
    value: name,
    label: name,
  }));

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "50vh",
      }}
    >
      <h3>Select a Company</h3>
      <Select
        style={{ width: "200px" }}
        options={selectOptions}
        value={selectOptions[selectedCompanyIdIndex]}
        onChange={handleCompanySelect}
      />
    </div>
  );
};

export default HandleAuthData;
