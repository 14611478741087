import { Button, Input, Modal, Select, Table } from "antd";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "components/button-component";
import CloseIcon from "@mui/icons-material/Close";
import Container from "components/container";
import ContentContainer from "components/content-container";
import { EditOutlined } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import InputComponent from "components/input-component";
import { PermissionServices } from "apis/Services/PermissionsService";
import SearchIcon from "assets/images/search-icon.svg";
import SeparateLine from "components/separate-line";
import Text from "components/text";
import styles from "./permission.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/loading-component";
import permissionsLevels from "constants/permissionsLevels";

const tabs = [
  {
    id: 1,
    text: "User Permissions",
    value: "user_permission",
    path: "user-permission",
  },
  {
    id: 2,
    text: "Company Permissions Templates",
    value: "company_permission",
    path: "company-permission",
  },
  {
    id: 3,
    text: "Project Permissions Templates",
    value: "company_permission",
    path: "Project-permission",
  },
];
const Permissions = ({
  pageType,
  templateName,
  templateNameFilter,
  setTemplateNameFilter,
  togglePermissionsTable,
  setNewProjectTemplateName,
  setNewCompanyTemplateName,
  assignedTemplates,
  setAssignedTemplates,
  permissionTemplatesNames,
  editingTemplateName,
  setEditingTemplateName,
}) => {
  const [selectedTab, setselectedTab] = useState(tabs[0]);
  const [showPermissionsTable, setshowPermissionsTable] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [modalInputText, setmodalInputText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [reload, setreload] = useState(0);
  const [showInput, setShowInput] = useState(false);
  const [loader, setloader] = useState(true);
  // const [editingTemplateName, setEditingTemplateName] = useState(false);
  const [editedTemplateName, setEditedTemplateName] = useState(templateName);
  const [previousTemplateName, setPreviousTemplateName] =
    useState(templateName);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [projectPermission, setprojectPermission] = useState("");
  const [companyPermission, setcompanyPermission] = useState("");
  const [companyAdminPermission, setCompanyAdminPermission] = useState("");
  const [companyReportsPermission, setCompanyReportsPermission] = useState("");
  const [reportsPermission, setReportsPermission] = useState("");
  const [ContactsPagePermission, setContactsPagePermission] = useState("");
  const [adminPagePermission, setadminPagePermission] = useState("");
  const [procurementlogpagePermission, setProcurementlogpagePermission] =
    useState("");
  const {
    companyPermissions,
    projectPermissions,
    companyAdminPermissions,
    companyReportsPermissions,
  } = useSelector((state) => state.permission);
  const [CheckedPermissions, setCheckedPermissions] = useState({
    subCommitedDate: "0",
    LTpermission: "0",
    viewTable: "0",
    SubRequiredpermission: "0",
  });
  const [isReadOnly, setIsReadOnly] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setloader(false);
    }, 1500);
  }, []);

  const loaderr = {
    spinning: loader,
    indicator: <LoadingComponent />,
  };

  // modal functions
  const showModal = (title) => {
    setisModalOpen(true);
    setModalTitle(title);
  };

  const editing = () => {
    setShowInput(true);
  };

  const handleCancel = () => {
    setisModalOpen(false);
    setmodalInputText("");
  };
  // end modal functions

  const navigate = useNavigate();
  const location = useLocation();

  const { company_id } = useParams();

  const handleClick = (item) => {
    navigate(`/main/company/${company_id}/${item.path}`);
  };

  const companyPermissionsFunction = () => {
    setisModalOpen(false);
    setshowPermissionsTable(true);
    let companyPermissionValue;
    let projectPermissionValue;
    let companyAdminPermissionValue;
    let companyReportsPermissionValue;

    switch (companyAdminPermission) {
      case "default":
        companyAdminPermissionValue = "0";
        break;
      case "readonly":
        companyAdminPermissionValue = "1";
        break;
      case "standard":
        companyAdminPermissionValue = "2";
        break;
      case "admin":
        companyAdminPermissionValue = "3";
        break;
      case "Admin":
        companyAdminPermissionValue = "3";
        break;
      default:
        companyAdminPermissionValue = "0";
        break;
    }

    switch (companyReportsPermission) {
      case "default":
        companyReportsPermissionValue = "0";
        break;
      case "readonly":
        companyReportsPermissionValue = "1";
        break;
      case "standard":
        companyReportsPermissionValue = "2";
        break;
      case "admin":
        companyReportsPermissionValue = "3";
        break;
      case "Admin":
        companyReportsPermissionValue = "3";
        break;
      default:
        companyReportsPermissionValue = "0";
        break;
    }
    switch (companyPermission) {
      case "default":
        companyPermissionValue = "0";
        break;
      case "readonly":
        companyPermissionValue = "1";
        break;
      case "standard":
        companyPermissionValue = "2";
        break;
      case "admin":
        companyPermissionValue = "3";
        break;
      case "Admin":
        companyPermissionValue = "3";
        break;
      default:
        companyPermissionValue = "0";
        break;
    }

    switch (projectPermission) {
      case "default":
        projectPermissionValue = "0";
        break;
      case "readonly":
        projectPermissionValue = "1";
        break;
      case "standard":
        projectPermissionValue = "2";
        break;
      case "admin":
        projectPermissionValue = "3";
        break;
      case "Admin":
        projectPermissionValue = "3";
        break;
      default:
        projectPermissionValue = "0";
        break;
    }
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      permissionTemplateName: modalInputText,
      permissionsPage: companyPermissionValue,
      projectsPage: projectPermissionValue,
      companyAdminPage: companyAdminPermissionValue,
      companyReportsPage: companyReportsPermissionValue,
      assignedTemplates: assignedTemplates,
    };
    // console.log(data, "Companydaataaaaaaaaa");
    PermissionServices.setCompanyPermissionsTemplate(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {
        setreload((prev) => prev++);
        setTimeout(() => {
          window.location.reload();
        }, 600);
      });
  };

  const projectPermissionsFunction = () => {
    setshowPermissionsTable(true);

    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let contactsPageValue = permissionsLevels[ContactsPagePermission];
    let procurementlogpageValue =
      permissionsLevels[procurementlogpagePermission];
    let adminPageValue = permissionsLevels[adminPagePermission];
    let reportsPageValue = permissionsLevels[reportsPermission];

    let data = {
      company_id,
      loginEmail,
      code,
      permissionTemplateName: modalInputText,
      contactsPage: contactsPageValue || "0",
      drawingsPage: "0",
      specificationsPage: "0",
      submittalsPage: "0",
      commitmentsPage: "0",
      procurementLogPage: procurementlogpageValue || "0",
      adminPage: adminPageValue || "0",
      reportsPage: reportsPageValue || "0",
      leadTimeGranularPermission: CheckedPermissions.LTpermission,
      subCommittedDeliveryDateGranularPermission:
        CheckedPermissions.subCommitedDate,
      viewOnlyAssignedEntriesGranularPermission:
        +CheckedPermissions.viewTable || "0",
      greyOutSubRequiredDateGranularPermissions:
        CheckedPermissions.SubRequiredpermission || "0",
      assignedTemplates: assignedTemplates,
    };
    console.log(data);
    PermissionServices.setProjectPermissionsTemplate(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {
        setisModalOpen(false);

        setreload((prev) => prev++);
        setTimeout(() => {
          window.location.reload();
        }, 600);
      });
  };

  const handleKeyPress = (event) => {
    console.log("event: " + event);
    if (event.key === "Enter") {
      pageType == "Company Permissions"
        ? companyPermissionsFunction()
        : projectPermissionsFunction();
    }
  };

  useEffect(() => {
    console.log(selectedPermissions, "useEffect");

    selectedPermissions.map((Permission) => {
      if (Permission.name === "Projects") {
        setprojectPermission(Permission.value);
      } else if (Permission.name === "Permissions") {
        setcompanyPermission(Permission.value);
      } else if (Permission.name === "Directory") {
        setContactsPagePermission(Permission.value);
      } else if (Permission.name === "Dashboard") {
        setProcurementlogpagePermission(Permission.value);
      } else if (Permission.name === "Admin") {
        setadminPagePermission(Permission.value);
      } else if (Permission.name === "Reports") {
        setReportsPermission(Permission.value);
      } else if (Permission.name === "Company Admin") {
        setCompanyAdminPermission(Permission.value);
      } else if (Permission.name === "Company Reports") {
        setCompanyReportsPermission(Permission.value);
      }
    });
  }, [selectedPermissions]);

  const startEditingTemplateName = () => {
    setEditingTemplateName(true);
    setPreviousTemplateName(editedTemplateName);
  };

  const handleColumnSelection = (column) => {
    const tempArr = [];
    if (column.dataIndex == "read_only") {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
    if (pageType === "Company Permissions") {
      permissionsCheck.permissions_collection_data.forEach((item) => {
        tempArr.push({
          name: item.name,
          value: column.dataIndex.toLowerCase().replace("_", ""),
        });
      });
    } else {
      permissionsCheck.project_permissions_collection_data.forEach((item) => {
        tempArr.push({
          name: item.name,
          value: column.dataIndex.toLowerCase().replace("_", ""),
        });
      });
    }
    // const revertedSelection = tempArr.reverse();
    //   setSelectedPermissions(revertedSelection);
    setSelectedPermissions(tempArr);
    // setSelectedProjectPermissions(tempArr);
  };
  console.log(selectedPermissions, "jj");
  const handleCheckboxChange = (e, record, value) => {
    console.log(record, "record");
    if (value == "readonly" && record.name == "Dashboard") setIsReadOnly(true);
    else if (value !== "readonly" && record.name == "Dashboard")
      setIsReadOnly(false);
    const tempObj = {
      name: record.name,
      value: value,
    };

    const updatedPermissions = selectedPermissions.map((permission) => {
      if (permission.name === tempObj.name) {
        return tempObj;
      }
      return permission;
    });

    // console.log("aaaaaaaaaaaaaaaa3", updatedPermissions);

    const isNewPermission = selectedPermissions.every(
      (permission) => permission.name !== tempObj.name
    );

    if (isNewPermission) {
      setSelectedPermissions([...updatedPermissions, tempObj]);
      // setSelectedProjectPermissions([...updatedProjectPermissions, tempObj]);
    } else {
      setSelectedPermissions(updatedPermissions);
      // setSelectedProjectPermissions(updatedProjectPermissions);
    }
  };

  const handleCheckedCells = (record, name) => {
    let selectedIndex = selectedPermissions.findIndex(
      (item) => item.name == record.name
    );
    return selectedPermissions[selectedIndex]?.value.toLowerCase() == name;
    // return selectedPermissions[record.key - 1]?.value.toLowerCase() == name;
  };

  const permissionsCheck = {
    permissions_collection_column: [
      {
        title: "Page Name",
        dataIndex: "name",
      },
      {
        title: "No Visibility",
        dataIndex: "Default",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.default}
            checked={handleCheckedCells(record, "default")}
            // defaultChecked={}
            onChange={(e) => handleCheckboxChange(e, record, "default")}
          />
        ),
      },
      {
        title: "Read Only",
        dataIndex: "read_only",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.readonly}
            checked={handleCheckedCells(record, "readonly")}
            onChange={(e) => handleCheckboxChange(e, record, "readonly")}
          />
        ),
      },
      {
        title: "Standard",
        dataIndex: "standard",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.standard}
            checked={handleCheckedCells(record, "standard")}
            onChange={(e) => handleCheckboxChange(e, record, "standard")}
          />
        ),
      },
      {
        title: "Admin",
        dataIndex: "Admin",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.admin}
            checked={handleCheckedCells(record, "admin")}
            onChange={(e) => handleCheckboxChange(e, record, "admin")}
          />
        ),
      },
    ],

    permissions_collection_data: [
      {
        key: 1,
        name: "Company Admin",
      },
      {
        key: 2,
        name: "Company Reports",
      },
      {
        key: 3,
        name: "Projects",
      },
      {
        key: 4,
        name: "Permissions",
      },
    ],
    project_permissions_collection_column: [
      {
        title: "Page Name",
        dataIndex: "name",
      },
      {
        title: "No Visibility",
        dataIndex: "Default",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            checked={handleCheckedCells(record, "default")}
            onChange={(e) => handleCheckboxChange(e, record, "default")}
          />
        ),
      },
      {
        title: "Read Only",
        dataIndex: "read_only",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.readonly}
            checked={handleCheckedCells(record, "readonly")}
            onChange={(e) => handleCheckboxChange(e, record, "readonly")}
          />
        ),
      },
      {
        title: "Standard",
        dataIndex: "standard",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.standard}
            checked={handleCheckedCells(record, "standard")}
            onChange={(e) => handleCheckboxChange(e, record, "standard")}
          />
        ),
      },
      {
        title: "Admin",
        dataIndex: "Admin",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            checked={handleCheckedCells(record, "admin")}
            onChange={(e) => handleCheckboxChange(e, record, "admin")}
          />
        ),
      },
    ],

    project_permissions_collection_data: [
      {
        key: 1,
        name: "Admin",
      },
      {
        key: 2,
        name: "Dashboard",
      },
      {
        key: 3,
        name: "Directory",
      },
      {
        key: 4,
        name: "Reports",
      },
    ],
  };

  let options = permissionTemplatesNames?.map((item) => ({
    label: item === "Default" ? "Apply Permission Template Later" : item,
    value: item,
  }));

  return (
    <>
      <div className={`d-flex flex-column nav-rest`}>
        <div className="d-flex justify-content-center px-3 ">
          <SeparateLine />
        </div>
        <Row
          className={`align-items-center justify-content-between gap-4 py-2 px-2`}
        >
          <Col>
            <div className={`d-flex align-items-center gap-4 py-2 px-2`}>
              <div className="d-flex gap-2 align-items-center py-1">
                <Text
                  className={`main-nav-title`}
                  fontFamily={"var(--ff-bold)"}
                >
                  Permissions
                </Text>
              </div>
              <div className="d-flex align-items-center flex-wrap gap-3">
                {tabs.map((item, index) => (
                  <div
                    key={index}
                    className="pointer"
                    // onClick={() => handleTabClick(item)}
                    onClick={() => handleClick(item)}
                  >
                    <Text
                      fontSize={14}
                      // color={selectedTab.id == item.id ? "#0196DB" : "black"}
                      color={
                        location.pathname.includes(item.path)
                          ? "#0196DB"
                          : "black"
                      }
                      fontFamily={"var(--ff-bold)"}
                    >
                      {item.text}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          {pageType == "Company Permissions" && (
            <Col md={3}>
              {togglePermissionsTable && (
                <ButtonComponent
                  disabled={companyPermissions.accessLevel < 2 ? true : false}
                  title="Create Company Permissions Template"
                  style={{ backgroundColor: "#0196DB" }}
                  // onClickAction={showModal}
                  onClickAction={() =>
                    companyPermissions.accessLevel < 2
                      ? null
                      : showModal("Create Company Permissions Template")
                  }
                  //   className={"bg-danger"}
                />
              )}
            </Col>
          )}
          {pageType == "Project Permissions" && (
            <Col md={3}>
              {togglePermissionsTable && (
                <ButtonComponent
                  disabled={projectPermissions.accessLevel < 2 ? true : false}
                  title="Create Project Permissions Template"
                  style={{ backgroundColor: "#0196DB" }}
                  // onClickAction={showModal }
                  onClickAction={() =>
                    projectPermissions.accessLevel < 2
                      ? null
                      : showModal("Create Project Permissions Template")
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </div>
      <Container className="my-4">
        <ContentContainer className="p-1">
          <div className={`py-2 px-2`}>
            {selectedTab.id === 1 && (
              <Row className="gap-5 align-items-center justify-content-between p-1">
                <Col md={3}>
                  <Row className="align-items-center">
                    <Col>
                      {togglePermissionsTable ||
                      pageType === "user_permission" ? (
                        <>
                          <InputComponent
                            placeholder="Search"
                            Icon={<img src={SearchIcon} alt="search icon" />}
                            // value={templateNameFilter}
                            onChange={(e) => setTemplateNameFilter(e)}
                          />
                        </>
                      ) : (
                        <div className=" d-flex justify-content-between">
                          {editingTemplateName ? (
                            <InputComponent
                              defaultValue={templateName}
                              onChange={(e) =>
                                pageType === "Project Permissions"
                                  ? setNewProjectTemplateName(e)
                                  : setNewCompanyTemplateName(e)
                              }
                            />
                          ) : (
                            <div className="d-flex align-items-center">
                              <Text className="px-2">{templateName}</Text>
                              <IconButton onClick={startEditingTemplateName}>
                                <EditOutlined />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  {!(
                    togglePermissionsTable || pageType === "user_permission"
                  ) && (
                    <div className=" d-flex flex-column gap-1">
                      {pageType === "Company Permissions" ? (
                        <span style={{ fontSize: "0.9rem" }}>
                          Manage Assignable Company Templates
                        </span>
                      ) : (
                        <span style={{ fontSize: "0.9rem" }}>
                          Manage Assignable Project Templates
                        </span>
                      )}

                      <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={2}
                        showSearch
                        // disabled={editingTemplateName}
                        value={assignedTemplates}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{
                          width: "100%",
                        }}
                        placeholder="0 Templates Assigned"
                        onChange={(value) => {
                          setAssignedTemplates(value);
                        }}
                        options={options}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </ContentContainer>
      </Container>

      <Modal
        centered
        footer={null}
        closable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        className={`w-50 ${styles.permissionModal}`}
      >
        <div className="d-flex flex-column justify-content-between p-3 h-100">
          <div className="d-flex align-items-center justify-content-between">
            {/* <Text dontWrap={false}>Create Company Permissions Template</Text> */}
            <Text dontWrap={false}>{modalTitle}</Text>
            <div className="pointer" onClick={handleCancel}>
              <CloseIcon />
            </div>
          </div>
          <div className=" d-flex justify-content-between align-items-end gap-5 mt-3 mb-3">
            <div className=" d-flex flex-column gap-1 w-75">
              <span className="fw-bold">
                Template Name <b className="text-danger">*</b>
              </span>
              <Input
                placeholder="Template Name"
                onChange={(e) => setmodalInputText(e.target.value)}
                onKeyDown={(e) =>
                  modalInputText && handleKeyPress(e.target.value)
                }
              />
            </div>

            <div className=" d-flex flex-column gap-1 w-100">
              {pageType === "Company Permissions" ? (
                <span className="fw-bold" style={{ fontSize: "0.9rem" }}>
                  Manage Assignable Company Templates
                </span>
              ) : (
                <span className="fw-bold" style={{ fontSize: "0.9rem" }}>
                  Manage Assignable Project Templates
                </span>
              )}
              <Select
                mode="multiple"
                allowClear
                maxTagCount={2}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{
                  width: "100%",
                }}
                placeholder="0 Templates Assigned"
                onChange={(value) => {
                  setAssignedTemplates(value);
                }}
                options={options}
              />
            </div>
          </div>

          <div className="mb-3">
            {pageType == "Project Permissions" ? (
              <Table
                columns={permissionsCheck.project_permissions_collection_column}
                dataSource={
                  permissionsCheck.project_permissions_collection_data
                }
                pagination={false}
                loading={loaderr}
                scroll={{
                  x: "max-content",
                }}
                expandable={
                  isReadOnly
                    ? {
                        expandedRowRender: (record) => (
                          <>
                            <div className="allowCon">
                              <input
                                type="checkbox"
                                name={record.key}
                                checked={+CheckedPermissions.subCommitedDate}
                                onChange={
                                  (e) =>
                                    setCheckedPermissions({
                                      ...CheckedPermissions,
                                      subCommitedDate: e.target.checked
                                        ? "3"
                                        : "0",
                                    })
                                  // handleCheckboxChange(e, record, "default")
                                }
                              />
                              <p
                                style={{
                                  margin: 0,
                                }}
                              >
                                Allow edit access to sub committed date cell
                              </p>
                            </div>
                            <div className="allowCon">
                              <input
                                type="checkbox"
                                name={record.key}
                                // checked={handleCheckedCells(record, "default")}
                                checked={+CheckedPermissions.LTpermission}
                                onChange={
                                  (e) =>
                                    setCheckedPermissions({
                                      ...CheckedPermissions,
                                      LTpermission: e.target.checked
                                        ? "3"
                                        : "0",
                                    })
                                  // handleCheckboxChange(e, record, "default")
                                }
                              />
                              <p
                                style={{
                                  margin: 0,
                                }}
                              >
                                Allow edit access to lead time cell
                              </p>
                            </div>
                            <div className="allowCon">
                              <input
                                type="checkbox"
                                name={record.key}
                                checked={+CheckedPermissions.viewTable}
                                onChange={
                                  (e) =>
                                    setCheckedPermissions({
                                      ...CheckedPermissions,
                                      viewTable: e.target.checked ? "3" : "0",
                                    })
                                  // handleCheckboxChange(e, record, "default")
                                }
                              />
                              <p
                                style={{
                                  margin: 0,
                                }}
                              >
                                ⁠Allow user to view only assigned line items
                              </p>
                            </div>
                            <div className="allowCon">
                              <input
                                type="checkbox"
                                name={record.key}
                                checked={
                                  +CheckedPermissions.SubRequiredpermission
                                }
                                onChange={(e) =>
                                  setCheckedPermissions({
                                    ...CheckedPermissions,
                                    SubRequiredpermission: e.target.checked
                                      ? "3"
                                      : "0",
                                  })
                                }
                              />
                              <p
                                style={{
                                  margin: 0,
                                }}
                              >
                                Hide submittal required date cell
                              </p>
                            </div>
                          </>
                        ),
                      }
                    : false
                }
              />
            ) : (
              <Table
                columns={permissionsCheck.permissions_collection_column}
                dataSource={permissionsCheck.permissions_collection_data}
                pagination={false}
                loading={loaderr}
                scroll={{
                  x: "max-content",
                }}
              />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <ButtonComponent
              title="Cancel"
              style={{ backgroundColor: "white", color: "black", flex: 0.2 }}
              onClickAction={handleCancel}
            />
            <ButtonComponent
              title="Create"
              style={{
                backgroundColor: modalInputText ? "#0196DB" : "grey",
                flex: 0.2,
              }}
              onClickAction={
                pageType == "Company Permissions"
                  ? companyPermissionsFunction
                  : projectPermissionsFunction
              }
              // onClickAction={companyPermissionsFunction}
              disabled={!modalInputText}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Permissions;
