import { React, useEffect } from "react";
import {
  Button,
  Popover,
  Modal,
  Alert,
  Input,
  DatePicker,
  Select,
  Tooltip,
} from "antd";
import { useState } from "react";
import { ProjectServices } from "apis/Services/ProjectService";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { reloadProjects } from "redux/project-reducer";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Styles from "./bulk.module.css";
import ButtonComponent from "components/button-component";
import { SideBarEndService } from "apis/Services/SideBarService";
import dayjs from "dayjs";

function BulkAssignEdit({
  projectDetails,
  projectsAccessLevelValue,
  checkedRows,
  setCheckedRows,
  showBulkForm,
  setShowBulkForm,
}) {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      submittalNumber: "",
      submittalRevision: "",
      submittalType: "",
      responsibleContractor: "",
      contractIssuedRelease: null,
      reviewTime: "",
      approvalTime: "",
      leadTime: "",
      dateRequiredOnSite: null,
      scheduleID: null,
      subCommittedDeliveryDate: null,
      actualDeliveryDate: null,
      assignManager: [],
      assignContractor: [],
    },
  });

  let code = localStorage.getItem("token");
  let email = localStorage.getItem("email");
  const dispatch = useDispatch();
  const { unsavedChanges } = useSelector((state) => state.projectDetails);
  const { company_id, id } = useParams();

  // Assign section variables
  const [contact, setContact] = useState(null);
  const [selectedManager, setSelectedManager] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState([]);
  const [selectedManagersOrContractors, setSelectedManagersOrContractors] =
    useState([]);

  const isAllCheckedRowsFalse = (checkedRows) => {
    for (const key in checkedRows) {
      if (checkedRows.hasOwnProperty(key) && checkedRows[key]) {
        return true;
      }
    }
    return false;
  };

  const alignedData = projectDetails?.viewsInfo?.alignedData;
  const selectedItems = [];

  let TasksAssignedData = {
    code,
    loginEmail: email,
    company_id,
    project_id: id,
    id: id,
  };

  const fetchTasksAssignedInfo = () => {
    SideBarEndService.TasksAssignedInfo(TasksAssignedData)
      .then((res) => {
        // console.log(res, "res");
        setContact(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTasksAssignedInfo();
  }, []);

  const filteredOptions = Object.entries(contact?.validContacts || {}).filter(
    ([email]) => !selectedManagersOrContractors.includes(email)
  );

  const handleManagerSelectChange = (selectedValue) => {
    const updatedSelectedItems = [...selectedManagersOrContractors];
    const updatedSelectedManager = selectedValue.map((value) => {
      const [email, fullName] = value.split(",");
      const [firstName, lastName] = fullName.split(" ");
      return { email, firstName, lastName };
    });

    selectedValue.forEach((value) => {
      const [email] = value.split(",");
      updatedSelectedItems.push(email);
    });

    setSelectedManagersOrContractors(updatedSelectedItems);
    setSelectedManager(updatedSelectedManager);
    setValue("assignManager", updatedSelectedManager);
  };

  // Function to handle selection change for the contractor select component
  const handleContractorSelectChange = (selectedValue) => {
    const updatedSelectedItems = [...selectedManagersOrContractors];
    const updatedSelectedContractor = selectedValue.map((value) => {
      const [email, fullName] = value.split(",");
      const [firstName, lastName] = fullName.split(" ");
      return { email, firstName, lastName };
    });

    selectedValue.forEach((value) => {
      const [email] = value.split(",");
      updatedSelectedItems.push(email);
    });

    setSelectedManagersOrContractors(updatedSelectedItems);
    setSelectedContractor(updatedSelectedContractor);
    setValue("assignContractor", updatedSelectedContractor);
  };

  useEffect(() => {
    // Combine the emails from selectedManager and selectedContractor
    const allSelectedEmails = [
      ...selectedManager.map((manager) => manager.email),
      ...selectedContractor.map((contractor) => contractor.email),
    ];

    // Filter emails from selectedManagersOrContractors that are included in either selectedManager or selectedContractor
    const updatedSelectedItems = selectedManagersOrContractors.filter((email) =>
      allSelectedEmails.includes(email)
    );

    // Update selectedManagersOrContractors with the filtered list
    setSelectedManagersOrContractors(updatedSelectedItems);
  }, [selectedManager, selectedContractor]);

  const nonWorkingDays = projectDetails.nonWorkingDays;

  const isDateDisabled = (
    date,
    isContractIssuedReleaseOrActualDeliveryDate
  ) => {
    // Handling Holidays
    const disabledDatesArray = [];
    Object.values(nonWorkingDays).forEach((holiday) => {
      var currDate = moment(holiday["startDate"], "MM/DD/YYYY").startOf("day");
      var lastDate = moment(holiday["finishDate"], "MM/DD/YYYY").startOf("day");
      disabledDatesArray.push(moment(currDate).format("YYYY-MM-DD"));
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        disabledDatesArray.push(moment(currDate).format("YYYY-MM-DD"));
      }
      disabledDatesArray.push(moment(lastDate).format("YYYY-MM-DD"));
    });

    // Function to check if a date is a weekend
    const isWeekend = (dateToCheck) => [0, 6].includes(dateToCheck.day());

    const isFutureDate = (dateToCheck) => dateToCheck.isAfter(moment(), "day");

    if (isContractIssuedReleaseOrActualDeliveryDate) {
      // Disable non-working days, weekends, and future dates for contractIssuedRelease and ActualDeliveryDate
      return (
        date &&
        (disabledDatesArray.includes(date.format("YYYY-MM-DD")) ||
          isWeekend(date) ||
          isFutureDate(date))
      );
    } else {
      return (
        date &&
        (disabledDatesArray.includes(date.format("YYYY-MM-DD")) ||
          isWeekend(date))
      );
    }
  };

  async function onSubmit(v) {
    const toastId = toast.loading("Saving changes");
    const selectedData = [];
    let managerEmails = [];
    let contractorEmails = [];

    const entryIDs = Object.keys(checkedRows).filter(
      (entryID) => checkedRows[entryID]
    );

    // Filtering data that matches entryID
    for (const key of Object.keys(projectDetails.content)) {
      const division = projectDetails.content[key];
      const subDivisions = Object.values(division);
      subDivisions.forEach((subDivision) => {
        subDivision.forEach((item) => {
          if (entryIDs.includes(item.entryID)) {
            //SELECTED DATA FOR BULK EDIT REQUEST
            selectedData.push(item);
            //SELECTED ITEMS FOR THE ASSIGN REQUEST
            selectedItems.push({
              id: item.entryID,
              submittalNumber: item.specNumber,
              submittalRevision: item.specRevision,
              submittalTitle: item.specTitle,
            });
          }
        });
      });
    }

    let data = selectedData.map((item) => {
      let modifiedItem = { ...item }; // Create a copy of the item to avoid changing the original data
      if (v.submittalNumber) {
        modifiedItem.specNumber = v.submittalNumber;
      }
      if (v.submittalRevision) {
        modifiedItem.specRevision = v.submittalRevision;
      }
      if (v.submittalType) {
        modifiedItem.specType = v.submittalType;
      }
      if (v.responsibleContractor) {
        modifiedItem.responsibleContractor = v.responsibleContractor;
      }
      if (v.contractIssuedRelease) {
        modifiedItem.contractIssuedRelease = moment(
          String(v.contractIssuedRelease)
        ).format("MM-DD-YYYY");
      }
      if (v.reviewTime) {
        modifiedItem.submittalPrepTime = v.reviewTime;
      }
      if (v.approvalTime) {
        modifiedItem.approvalTime = v.approvalTime;
      }
      if (v.leadTime) {
        modifiedItem.leadTime = v.leadTime;
      }
      if (v.dateRequiredOnSite) {
        if (!v.scheduleID) {
          modifiedItem.scheduleID = "";
        }
        modifiedItem.dateRequiredOnSite = moment(
          String(v.dateRequiredOnSite)
        ).format("MM-DD-YYYY");
      }
      if (v.subCommittedDeliveryDate) {
        modifiedItem.subCommittedDeliveryDate = moment(
          String(v.subCommittedDeliveryDate)
        ).format("MM-DD-YYYY");
      }
      if (v.scheduleID) {
        modifiedItem.scheduleID = String(v.scheduleID);
      }
      if (v.actualDeliveryDate) {
        modifiedItem.ActualDeliveryDate = moment(
          String(v.actualDeliveryDate)
        ).format("MM-DD-YYYY");
        delete modifiedItem.actualDeliveryDate; // Delete the old key if needed
      } else {
        modifiedItem.ActualDeliveryDate = modifiedItem.actualDeliveryDate;
        delete modifiedItem.actualDeliveryDate; // Delete the old key if needed
      }

      return modifiedItem; // Return the modified item
    });

    // ASSIGN SECTION

    const formData = new FormData(v.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    formObject.selectedManager = selectedManager;
    formObject.selectedContractor = selectedContractor;

    if (selectedManager.length > 0) {
      selectedManager.forEach((manager) => {
        managerEmails.push(manager.email);
      });
    }
    if (selectedContractor.length > 0) {
      selectedContractor.forEach((contractor) => {
        contractorEmails.push(contractor.email);
      });
    }

    let authData = {
      company_id: company_id,
      project_id: id,
      code,
      loginEmail: email,
    };

    let Data = {
      code,
      loginEmail: email,
      company_id,
      project_id: id,
      managerEmails,
      contractorEmails,
      selectedItems: selectedItems,
      action: "",
    };

    try {
      let successToast = true;
      const promises = [
        ProjectServices.EditRow(data, authData)
          .then(() => {
            // toast.success("Project data updated successfully");
            let updateBackEndData = {
              company: company_id,
              project: id,
              code,
              loginEmail: email,
              approvalTime: projectDetails.approvalTime,
              reviewTime: projectDetails.reviewTime,
            };
            ProjectServices.getExportData(updateBackEndData); // Call to update backend calculations
          })
          .catch((error) => {
            successToast = false;
            toast.error("Error updating data");
          }),
      ];

      if (Data.managerEmails.length > 0 || Data.contractorEmails.length > 0) {
        promises.push(
          SideBarEndService.assignTask(Data)
            .then(() => {
              // toast.success("Users Assigned Successfully");
            })
            .catch((error) => {
              successToast = false;
              toast.error("Error assigning Users");
            })
        );
      }
      await Promise.all(promises);

      if (successToast) {
        toast.success("Changes saved successfully", { id: toastId });
      }
      setShowBulkForm(false);
      dispatch(reloadProjects());
      setCheckedRows([]);
    } catch (err) {
      toast.error("Something went wrong, please try again.", {
        id: toastId,
      });
    }
  }

  const onChange = (value) => {
    let selectedDate = null;
    // Find the schedule item that matches the selected scheduleID
    let datesArr = projectDetails.schedule.find((x) => {
      return Object.keys(x)[0] == value;
    });

    if (datesArr) {
      // Extract the start date from the schedule item
      selectedDate = datesArr[value]?.["startDate"]?.split(" ");
      selectedDate =
        selectedDate?.length === 2 ? selectedDate[1] : selectedDate[0];
      // Format the selected date
      selectedDate = moment(selectedDate).format("MM-DD-YYYY");

      // console.log("Date selected:", selectedDate);
    }
    let isValidDate = moment(selectedDate).isValid();
    // Set the value of dateRequiredOnSite field in the form
    if (isValidDate) {
      setValue("dateRequiredOnSite", dayjs(selectedDate, "MM-DD-YYYY"));
    } else {
      setValue("dateRequiredOnSite", null);
    }

    if (value === null) {
      setValue("scheduleID", null);
    }
  };
  const onSearch = (value) => {
    // console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const sheduleOptions = () => {
    const options = projectDetails.schedule
      .map((scheduleItem) => {
        const key = Object.keys(scheduleItem)[0];
        const value = Object.values(scheduleItem)[0].taskName;
        return {
          label: `${key} - ${value}`,
          value: key,
        };
      })
      .sort((a, b) => +a.value - +b.value);

    return options;
  };

  return (
    <div className={`row mb-4 mt-4 p-3 bg-white rounded-3 ${Styles.container}`}>
      <div className="col-12 py-3">
        <form className="col-12 w-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="row justify-content-start">
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="submittalNumber" className="form-label">
                  Submittal Number
                </label>
                <Controller
                  name="submittalNumber"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      style={{ width: "100%" }}
                      disabled={
                        projectsAccessLevelValue < 2 ||
                        alignedData["specType"].hidden === "true"
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="submittalRevision" className="form-label">
                  Submittal Revision
                </label>
                <Controller
                  name="submittalRevision"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      style={{ width: "100%" }}
                      disabled={
                        projectsAccessLevelValue < 2 ||
                        alignedData["specType"].hidden === "true"
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="submittalType" className="form-label">
                  Submittal Type
                </label>
                <Controller
                  name="submittalType"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      // id="submittalType"
                      // name="submittalType"
                      style={{ width: "100%" }}
                      disabled={
                        projectsAccessLevelValue < 2 ||
                        alignedData["specType"].hidden === "true"
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="responsibleContractor" className="form-label">
                  Responsible Contractor
                </label>
                <Controller
                  name="responsibleContractor"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      // id="responsibleContractor"
                      // name="responsibleContractor"
                      style={{ width: "100%" }}
                      disabled={
                        projectsAccessLevelValue < 2 ||
                        alignedData["responsibleContractor"].hidden === "true"
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="contractIssuedRelease" className="form-label">
                  Contract Issued Release
                </label>
                <div style={{ display: "inline-block" }}>
                  <Controller
                    name="contractIssuedRelease"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        // name="contractIssuedRelease"
                        format="MM-DD-YYYY"
                        placeholder="Select Date"
                        className={`${Styles.antPickerStyle} ${
                          projectsAccessLevelValue < 2 ||
                          alignedData["contractIssuedRelease"].hidden === "true"
                            ? Styles.antPickerDisabled
                            : ""
                        }`}
                        style={{
                          width: "100%",
                        }}
                        disabled={
                          projectsAccessLevelValue < 2 ||
                          alignedData["contractIssuedRelease"].hidden === "true"
                        }
                        disabledDate={(date) => isDateDisabled(date, true)} // Pass true for contractIssuedRelease
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="reviewTime" className="form-label">
                  <Tooltip title="Internal submittal review time">
                    Review Time (weeks)
                  </Tooltip>
                </label>
                <Controller
                  name="reviewTime"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Enter Review Time"
                      onKeyDown={(event) => {
                        //TO REMOVE ANYTHING BUT NUMBERS FROM THE FIELD
                        if (
                          !(
                            event.key === "Backspace" ||
                            event.key === "Tab" ||
                            /[0-9]/.test(event.key)
                          )
                        ) {
                          event.preventDefault();
                        }
                      }}
                      min={0}
                      style={{
                        width: "100%",
                      }}
                      disabled={projectsAccessLevelValue < 2}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="approvalTime" className="form-label">
                  <Tooltip title="Design team review time">
                    Approval Time (weeks)
                  </Tooltip>
                </label>
                <Controller
                  name="approvalTime"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Enter Approval Time"
                      onKeyDown={(event) => {
                        //TO REMOVE ANYTHING BUT NUMBERS FROM THE FIELD
                        if (
                          !(
                            event.key === "Backspace" ||
                            event.key === "Tab" ||
                            /[0-9]/.test(event.key)
                          )
                        ) {
                          event.preventDefault();
                        }
                      }}
                      min={0}
                      style={{
                        width: "100%",
                      }}
                      disabled={projectsAccessLevelValue < 2}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="leadTime" className="form-label">
                  Lead Time (weeks)
                </label>
                <Controller
                  name="leadTime"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Enter Lead Time"
                      onKeyDown={(event) => {
                        //TO REMOVE ANYTHING BUT NUMBERS FROM THE FIELD
                        if (
                          !(
                            event.key === "Backspace" ||
                            event.key === "Tab" ||
                            /[0-9]/.test(event.key)
                          )
                        ) {
                          event.preventDefault();
                        }
                      }}
                      min={0}
                      style={{
                        width: "100%",
                      }}
                      disabled={
                        projectsAccessLevelValue < 2 &&
                        projectDetails.usersInfo?.[0][
                          "leadTimeGranularPermission"
                        ] !== "3"
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label className="form-label">Schedule ID</label>
                <div
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <Controller
                    name="scheduleID"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        style={{
                          width: "100%",
                        }}
                        showSearch
                        allowClear
                        placeholder="Select a Schedule"
                        optionFilterProp="children"
                        onChange={(value) => {
                          field.onChange(value);
                          onChange(value);
                        }}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={sheduleOptions()}
                        disabled={
                          projectsAccessLevelValue < 2 ||
                          alignedData["scheduleID"].hidden === "true"
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label className="form-label">Date Required on Site</label>
                <div
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <Controller
                    name="dateRequiredOnSite"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        // name="dateRequiredOnSite"
                        format="MM-DD-YYYY"
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("scheduleID", null);
                        }}
                        className={`${Styles.antPickerStyle} ${
                          projectsAccessLevelValue < 2 ||
                          alignedData["dateRequiredOnSite"].hidden === "true"
                            ? Styles.antPickerDisabled
                            : ""
                        }`}
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Date"
                        disabledDate={(date) => isDateDisabled(date, false)} // Pass true for contractIssuedRelease
                        disabled={
                          projectsAccessLevelValue < 2 ||
                          alignedData["dateRequiredOnSite"].hidden === "true"
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="subCommittedDeliveryDate"
                  className="form-label"
                >
                  Sub Committed Delivery Date
                </label>
                <div style={{ display: "inline-block" }}>
                  <Controller
                    name="subCommittedDeliveryDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        // name="subCommittedDeliveryDate"
                        format="MM-DD-YYYY"
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Date"
                        disabledDate={(date) => isDateDisabled(date, false)} // Pass true for contractIssuedRelease
                        className={`${Styles.antPickerStyle} ${
                          (projectsAccessLevelValue < 2 &&
                            projectDetails.usersInfo?.[0][
                              "subCommittedDeliveryDateGranularPermission"
                            ] !== "3") ||
                          alignedData["subCommittedDeliveryDate"].hidden ===
                            "true"
                            ? Styles.antPickerDisabled
                            : ""
                        }`}
                        disabled={
                          (projectsAccessLevelValue < 2 &&
                            projectDetails.usersInfo?.[0][
                              "subCommittedDeliveryDateGranularPermission"
                            ] !== "3") ||
                          alignedData["subCommittedDeliveryDate"].hidden ===
                            "true"
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="actualDeliveryDate" className="form-label">
                  Actual Delivery Date
                </label>
                <div style={{ display: "inline-block" }}>
                  <Controller
                    name="actualDeliveryDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        // name="actualDeliveryDate"
                        format="MM-DD-YYYY"
                        style={{
                          width: "100%",
                        }}
                        className={`${Styles.antPickerStyle} ${
                          projectsAccessLevelValue < 2 ||
                          alignedData["actualDeliveryDate"].hidden === "true"
                            ? Styles.antPickerDisabled
                            : ""
                        }`}
                        placeholder="Select Date"
                        disabledDate={(date) => isDateDisabled(date, true)} // Pass true for contractIssuedRelease
                        disabled={
                          projectsAccessLevelValue < 2 ||
                          alignedData["actualDeliveryDate"].hidden === "true"
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-start">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="assignManager" className="form-label">
                  Assign Manager(s)
                </label>
                <Controller
                  name="assignManager"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      mode="multiple"
                      placeholder="Select Manager(s)"
                      value={selectedManager.firstName}
                      onChange={handleManagerSelectChange}
                      style={{
                        width: "100%",
                      }}
                      disabled={projectsAccessLevelValue < 2}
                      options={
                        contact &&
                        filteredOptions.map(
                          ([email, [firstName, lastName]]) => ({
                            label: `${firstName} ${lastName}`,
                            value: email + `,${firstName} ${lastName}`,
                          })
                        )
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="assignContractor" className="form-label">
                  Assign Contractor(s)
                </label>
                <Controller
                  name="assignContractor"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      mode="multiple"
                      placeholder="Select Contractor(s)"
                      value={selectedContractor.firstName}
                      onChange={handleContractorSelectChange}
                      style={{
                        width: "100%",
                      }}
                      disabled={projectsAccessLevelValue < 2}
                      options={
                        contact &&
                        filteredOptions.map(
                          ([email, [firstName, lastName]]) => ({
                            label: `${firstName} ${lastName}`,
                            value: email + `,${firstName} ${lastName}`,
                          })
                        )
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className=" d-flex gap-2 justify-content-end align-items-center">
            <Button
              size="medium"
              type="text"
              onClick={() => {
                setShowBulkForm(false);
              }}
            >
              Cancel
            </Button>
            {unsavedChanges ? (
              <Tooltip
                placement="topRight"
                title="You have unsaved changes in the Dashboard"
              >
                <div>
                  <ButtonComponent
                    type="submit"
                    disabled={
                      unsavedChanges ||
                      !isAllCheckedRowsFalse(checkedRows) ||
                      (!isDirty &&
                        getValues("assignManager").length === 0 &&
                        getValues("assignContractor").length === 0)
                    }
                    title={
                      projectsAccessLevelValue != 1
                        ? "Save Changes"
                        : "Submit For Approval"
                    }
                    className={"w-md-100 px-3 w-auto"}
                    style={{
                      userSelect: "none",
                    }}
                  />
                </div>
              </Tooltip>
            ) : (
              <ButtonComponent
                type="submit"
                disabled={
                  unsavedChanges ||
                  !isAllCheckedRowsFalse(checkedRows) ||
                  (!isDirty &&
                    getValues("assignManager").length === 0 &&
                    getValues("assignContractor").length === 0)
                }
                title={
                  projectsAccessLevelValue != 1
                    ? "Save Changes"
                    : "Submit For Approval"
                }
                className={"w-md-100 px-3 w-auto"}
                style={{
                  userSelect: "none",
                }}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default BulkAssignEdit;
