import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import ButtonComponent from "components/button-component";
import { CREATE_PROJECT_SCHEMA } from "schemas/createProjectSchema";
import Container from "components/container";
import FileInput from "components/file-input";
import InputComponent from "components/input-component";
import LoadingComponent from "components/loading-component";
import MultiInputsProjectDates from "components/multi-input-project-dates";
import { ProjectServices } from "apis/Services/ProjectService";
import { Spin } from "antd";
import Text from "components/text";
import { WEEK_DAYS } from "mockups/WEEK_DAYS";
import styles from "./create.module.css";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function CreateProject() {
  const {
    control,
    formState: { errors, isValid, isDirty },

    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      "Project Number": "",
      Name: "",
      "Total Value": "",
      "Start Date": "",
      "Projected Finish Date": "",
      Stage: "",
      Type: "",
      "Square Feet": "",
      Country: "",
      Address: "",
      City: "",
      State: "",
      Country: "",
      Latitude: "",
      Longitude: "",
      Department: "",
      ReviewTime: "",
      approvalTime: "",
    },
  });

  let code = localStorage.getItem("token");
  let loginEmail = localStorage.getItem("email");
  let Auth = {
    code,
    loginEmail,
  };

  const [selectedWeekdays, setSelectedWeekdays] = useState({});
  const [multiInputsData, setMultiInputsData] = useState({});
  const [accessLevelValue, setAccessLevelValue] = useState("");
  const { company_id, id } = useParams();
  const [loader, setloader] = useState(true);
  const [saveFlag, setSaveFlag] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [isFieldsDirty, setIsFieldsDirty] = useState(false);

  const loaderr = {
    spinning: loader,
    indicator: <LoadingComponent />,
  };

  const handleMultiInputsChange = (data) => {
    setMultiInputsData(data);
  };
  useEffect(() => {
    let isValid = true;

    // Iterate over the keys of the multiInputsData object
    for (const key in multiInputsData) {
      if (multiInputsData.hasOwnProperty(key)) {
        const { holidayName, startDate, finishDate } = multiInputsData[key];
        if (!holidayName || !startDate || !finishDate) {
          isValid = false;
          break;
        }

        const startDateMoment = moment(startDate, "MM-DD-YYYY");
        const endDateMoment = moment(finishDate, "MM-DD-YYYY");

        if (
          !startDateMoment.isValid() ||
          !endDateMoment.isValid() ||
          endDateMoment.isBefore(startDateMoment)
        ) {
          isValid = false;
          break;
        }
      }
    }

    setSaveFlag(isValid);
    setDateFlag(!isValid);
  }, [multiInputsData]);

  const handleWeekdayToggle = (day) => {
    const updatedWeekdays = { ...selectedWeekdays };
    updatedWeekdays[day] = updatedWeekdays[day] === "1" ? "0" : "1";

    setIsFieldsDirty(true);
    setSelectedWeekdays(updatedWeekdays);
  };

  const onCreateProjectHandler = (values) => {
    const formData = {
      ...getValues(),
      workingDays: selectedWeekdays,
      nonWorkingDays: multiInputsData,
    };

    const WorkingDaysData = {
      ...Auth,
      saturday: selectedWeekdays["Saturday"],
      sunday: selectedWeekdays["Sunday"],
      monday: selectedWeekdays["Monday"],
      tuesday: selectedWeekdays["Tuesday"],
      wednesday: selectedWeekdays["Wednesday"],
      thursday: selectedWeekdays["Thursday"],
      friday: selectedWeekdays["Friday"],
      reviewTime: values.ReviewTime,
      approvalTime: values.approvalTime,
    };

    const holidayName = [];
    const start = [];
    const end = [];

    for (const key in multiInputsData) {
      if (multiInputsData.hasOwnProperty(key)) {
        const item = multiInputsData[key];

        //To prevent saving empty data
        if (
          item.holidayName !== "" &&
          item.startDate !== "" &&
          item.finishDate !== ""
        ) {
          holidayName.push(item.holidayName);
          start.push(item.startDate);
          end.push(item.finishDate);
        }
      }
    }

    const NonWorkingDaysData = {
      ...Auth,
      holidayName,
      start,
      end,
    };

    const workingDaysPromise = ProjectServices.WorkingDays(
      WorkingDaysData,
      company_id,
      id
    );

    const nonWorkingDaysPromise = ProjectServices.NonWorkingDays(
      NonWorkingDaysData,
      company_id,
      id
    );

    Promise.all([workingDaysPromise, nonWorkingDaysPromise])
      .then((results) => {
        const workingDaysResult = results[0];
        const nonWorkingDaysResult = results[1];

        let updateBackEndData = {
          company: company_id,
          project: id,
          code,
          loginEmail: loginEmail,
          approvalTime: values.approvalTime,
          reviewTime: values.ReviewTime,
        };
        ProjectServices.getExportData(updateBackEndData); // Call to update backend calculations
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          window.location.reload();
        }, 600);
      });
  };

  useEffect(() => {
    ProjectServices.procurementLogAdminPage(Auth, company_id, id)
      .then((res) => {
        setAccessLevelValue(res.adminInfo.accessLevel);
        const updatedDefaultValues = {
          "Project Number": res.adminInfo["Project Number"],
          Name: res.adminInfo["Name"],
          "Total Value": `$${res.adminInfo["Total Value"]}`,
          "Start Date": res.adminInfo["Actual Start Date"],
          "Projected Finish Date": res.adminInfo["Completion Date"],
          Stage: res.adminInfo["Stage"],
          Type: res.adminInfo["Type"],
          "Square Feet": res.adminInfo["Square Feet"],
          Country: res.adminInfo["Country"],
          Address: res.adminInfo["Address"],
          City: res.adminInfo["City"],
          State: res.adminInfo["State"],
          County: res.adminInfo["County"],
          Latitude: res.adminInfo["Latitude"],
          Longitude: res.adminInfo["Longitude"],
          Department: res.adminInfo["Department"],
        };
        const nonWorkingDaysData = Object.values(res.nonWorkingDays).map(
          (day) => ({
            holidayName: day["holidayName"],
            startDate: day["startDate"],
            finishDate: day["finishDate"],
          })
        );
        setMultiInputsData(res.nonWorkingDays);
        setSelectedWeekdays(res.workingDays);
        handleMultiInputsChange(nonWorkingDaysData);
        reset({
          ...updatedDefaultValues,
          nonWorkingDays: nonWorkingDaysData,
          workingDays: res.workingDays,
          ReviewTime: res.reviewTime,
          approvalTime: res.approvalTime,
        });
      })
      .finally(() => {
        setloader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const isDividedBy7 = (value) => {
    if (!value) {
      return "false";
    } else {
      return value % 7 === 0;
    }
  };

  const ProjectInformationInputsMarkup = (
    <div className={styles.inputContainer}>
      <Controller
        name="Project Number"
        control={control}
        render={({ field }) => (
          <InputComponent disabled={true} label="Project Number" {...field} />
        )}
      />

      <Controller
        name="Name"
        control={control}
        render={({ field }) => (
          <InputComponent label="Project Name" disabled={true} {...field} />
        )}
      />

      <Controller
        name="Total Value"
        control={control}
        render={({ field }) => (
          <InputComponent label="Total Value" disabled={true} {...field} />
        )}
      />

      <Controller
        name="Start Date"
        control={control}
        render={({ field }) => (
          <InputComponent
            label="Project Start Date"
            disabled={true}
            {...field}
          />
        )}
      />

      <Controller
        name="Projected Finish Date"
        control={control}
        render={({ field }) => (
          <InputComponent label="Project End Date" disabled={true} {...field} />
        )}
      />

      <Controller
        name="Stage"
        control={control}
        render={({ field }) => (
          <InputComponent
            disabled={true}
            label="Stage"
            errors={errors.stage}
            {...field}
          />
        )}
      />

      <Controller
        name="Type"
        control={control}
        render={({ field }) => (
          <InputComponent
            disabled={true}
            label="Type"
            errors={errors.type}
            {...field}
          />
        )}
      />

      <Controller
        name="Square Feet"
        control={control}
        render={({ field }) => (
          <InputComponent label="Square feet" disabled={true} {...field} />
        )}
      />
    </div>
  );

  const ProjectLocationInputsMarkup = (
    <div>
      <Controller
        name="County"
        control={control}
        render={({ field }) => (
          <InputComponent
            label="Country"
            disabled={true}
            errors={errors.County}
            {...field}
          />
        )}
      />

      <Controller
        name="Address"
        control={control}
        render={({ field }) => (
          <InputComponent
            label="Address"
            disabled={true}
            errors={errors.Address}
            {...field}
          />
        )}
      />

      <Controller
        name="City"
        control={control}
        render={({ field }) => (
          <InputComponent
            label="City"
            disabled={true}
            errors={errors.City}
            {...field}
          />
        )}
      />

      <Controller
        name="State"
        control={control}
        render={({ field }) => (
          <InputComponent
            label="State"
            disabled={true}
            errors={errors.State}
            {...field}
          />
        )}
      />

      <Controller
        name="Country"
        control={control}
        render={({ field }) => (
          <InputComponent
            label="Country"
            disabled={true}
            errors={errors.Country}
            {...field}
          />
        )}
      />

      <Controller
        name="Latitude"
        control={control}
        render={({ field }) => (
          <InputComponent
            label="Latitude"
            disabled={true}
            errors={errors.lat}
            {...field}
          />
        )}
      />

      <Controller
        name="Longitude"
        control={control}
        render={({ field }) => (
          <InputComponent
            label="Longitude"
            disabled={true}
            errors={errors.long}
            {...field}
          />
        )}
      />

      <Controller
        name="Department"
        control={control}
        render={({ field }) => (
          <InputComponent label="Department" disabled={true} {...field} />
        )}
      />
    </div>
  );

  return (
    <Spin spinning={loader} indicator={<LoadingComponent />}>
      {loader ? null : accessLevelValue == 0 ? (
        <div className="text-center mt-5">
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onCreateProjectHandler)}
          className="bg-white pt-4 pb-5"
          style={{ overflowX: "hidden" }}
        >
          <Container
            style={accessLevelValue == 1 ? { pointerEvents: "none" } : null}
          >
            <div className="row justify-content-end">
              <div className="col-2">
                <ButtonComponent
                  disabled={
                    isValid &&
                    (isDirty || isFieldsDirty) &&
                    saveFlag &&
                    accessLevelValue > 1
                      ? false
                      : true
                  }
                  title={"Save"}
                  onClickAction={handleSubmit(onCreateProjectHandler)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Text className={`${styles.title} mb-2`} dontWrap={false}>
                  Project Information
                </Text>

                {ProjectInformationInputsMarkup}

                <Text className={`${styles.title} mt-5 mb-2`} dontWrap={false}>
                  Project Location
                </Text>

                {ProjectLocationInputsMarkup}
              </div>

              <div className="col-md-6">
                <div>
                  <Text
                    className={`${styles.title} mt-5 mb-2`}
                    dontWrap={false}
                  >
                    Project Working Days
                  </Text>
                  {
                    <div className="d-flex flex-wrap flex-row">
                      {Object.keys(selectedWeekdays).map((day, index) => (
                        <div
                          key={day}
                          className={`d-flex gap-2 align-items-center ${styles.checkboxContainer}`}
                        >
                          <Text className={`${styles.weekday}`}>
                            {WEEK_DAYS[index]}
                          </Text>
                          <input
                            label={day}
                            className={styles.checkbox}
                            type="checkbox"
                            checked={selectedWeekdays[day] === "1"}
                            onChange={() => handleWeekdayToggle(day)}
                          />
                        </div>
                      ))}
                    </div>
                  }
                </div>

                <div>
                  <Text
                    className={`${styles.title} mt-5 mb-2`}
                    dontWrap={false}
                  >
                    Project Non-Working Days
                  </Text>

                  {Object.keys(multiInputsData).length > 0 && (
                    <Controller
                      name="workingDays"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MultiInputsProjectDates
                          {...field}
                          saveFlag={saveFlag}
                          setIsFieldsDirty={setIsFieldsDirty}
                          errors={errors.workingDays}
                          Data={multiInputsData}
                          onChange={handleMultiInputsChange}
                          accessLevel={accessLevelValue}
                        />
                      )}
                    />
                  )}

                  <div style={{ textAlign: "left", color: "red" }}>
                    {!saveFlag && !dateFlag && <p>Please fill in all fields</p>}
                    {!saveFlag && dateFlag && (
                      <p>Finish Date can't be before Start Date</p>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center mt-4 w-75">
                  <div className="w-100">
                    <h3>Review Time</h3>
                  </div>
                  <Controller
                    name="ReviewTime"
                    rules={{
                      validate: { isDividedBy7 },
                    }}
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputComponent className="h-25 mx-3" {...field} />
                      </>
                    )}
                  />
                  <p className="p-0 m-0">Days</p>
                </div>
                <div style={{ textAlign: "center", color: "red" }}>
                  {errors?.ReviewTime?.ref?.name ? (
                    <p>Must be an increment of 7</p>
                  ) : null}
                </div>
                <div className="d-flex align-items-center mt-4 w-75">
                  <div className="w-100">
                    <h3>Approval Time</h3>
                  </div>
                  <Controller
                    name="approvalTime"
                    control={control}
                    rules={{
                      validate: { isDividedBy7 },
                    }}
                    render={({ field }) => (
                      <InputComponent className="h-25 mx-3" {...field} />
                    )}
                  />
                  <p className="p-0 m-0">Days</p>
                </div>
                <div style={{ textAlign: "center", color: "red" }}>
                  {errors?.approvalTime?.ref?.name ? (
                    <p>Must be an increment of 7</p>
                  ) : null}
                </div>
              </div>
            </div>
          </Container>
        </form>
      )}
    </Spin>
  );
}
