export const ContactsEndPoints = {
  getProjectsContactPage: {
    url: (company, project) =>
      `/rest/v1/readContactsInfo/${company}/${project}`,
    method: `GET`,
  },

  AddContact: {
    url: (company, project) => `/rest/v1/addContactsInfo/${company}/${project}`,
    method: "POST",
  },

  EditContact: {
    url: (company, project) =>
      `/rest/v1/editContactsInfo/${company}/${project}`,
    method: "PUT",
  },

  removeContact: {
    url: (compay_id, project_id) =>
      `/rest/v1/removeContactsInfo/${compay_id}/${project_id}`,
    method: "DELETE",
  },

  resendInvitation: {
    url: (company, project) =>
      `/rest/v1/resendInvitationContactsInfo/${company}/${project}`,
    method: "POST",
  },

  resyncContacts: {
    url: (company, project) =>
      `/rest/v1/resyncContactsInfo/${company}/${project}`,
    method: "GET",
  },

  readUserSpecificCompanyAndProjectAssignablePermissionsTemplates: {
    url: (company, project) =>
      `/rest/v1/readUserSpecificCompanyAndProjectAssignablePermissionsTemplates/${company}/${project}`,
    method: "GET",
  },
};
