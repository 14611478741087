import { React } from "react";
import { Button, Modal, Alert } from "antd";
import { useState } from "react";
import { ProjectServices } from "apis/Services/ProjectService";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { reloadProjects } from "redux/project-reducer";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

function BulkDelete({
  projectDetails,
  projectsAccessLevelValue,
  checkedRows,
  setCheckedRows,
}) {
  const { company_id, id } = useParams();
  const [isProcore, setIsProcore] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const dispatch = useDispatch();

  const handleDeleting = () => {
    if (projectsAccessLevelValue > 2) {
      const selectedData = [];

      const entryIDs = Object.keys(checkedRows).filter(
        (entryID) => checkedRows[entryID]
      );

      // Filtering data that matches entryID
      for (const key of Object.keys(projectDetails.content)) {
        const division = projectDetails.content[key];
        const subDivisions = Object.values(division);
        subDivisions.forEach((subDivision) => {
          subDivision.forEach((item) => {
            if (entryIDs.includes(item.entryID)) {
              if (item.specNumber === "") {
                // Adding only koncurent entries to the request
                selectedData.push(item);
              } else {
                // Procore flag to show the warning
                setIsProcore("Some");
              }
            }
          });
        });
      }

      if (selectedData.length === 0) {
        setIsProcore("All");
      }

      setFilteredData(selectedData);
      setIsModalOpen(true);
    }
  };

  function handleOk() {
    let data = {
      entryID: filteredData.map((item) => item.entryID),
    };
    const toastId = toast.loading("Deleting submittals in progress");
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let authData = {
      company_id: company_id,
      project_id: id,
      code,
      loginEmail: email,
    };
    setIsModalOpen(false);
    ProjectServices.deleteRow(data, authData)
      .then((res) => {
        toast.success("Submittals deleted successfully!", { id: toastId });
        setCheckedRows([]);
        dispatch(reloadProjects());
      })
      .catch((err) => {
        toast.error("Something went wrong, please try again.", { id: toastId });
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsProcore();
      });
  }

  function handleCancel() {
    setIsModalOpen(false);
    setIsProcore();
  }

  return (
    <div>
      <div
        onClick={handleDeleting}
        className=" d-flex justify-content-left gap-2 align-items-center"
      >
        <DeleteOutlined style={{ fontSize: "15px" }} />
        Bulk Delete
      </div>
      <Modal
        open={isModalOpen}
        width={700}
        onOk={isProcore !== "All" ? handleOk : null}
        onCancel={handleCancel}
        footer={
          isProcore === "All"
            ? [
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button key="delete" type="primary" danger disabled>
                  Delete
                </Button>,
              ]
            : [
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button key="delete" type="primary" onClick={handleOk} danger>
                  Delete
                </Button>,
              ]
        }
      >
        <div className="">
          <div className="d-flex align-items-center gap-2">
            <h5 className="fw-bold pt-2">
              Are you sure you want to delete all selected items?
            </h5>
          </div>
          <div className=" d-flex flex-column">
            <p>
              You can still recover deleted items from the deleted items page.
            </p>

            {isProcore === "Some" ? (
              <Alert
                // message="Some of the selected items have a submittal number, those items will not be deleted."
                message="Items with a submittal number will not be deleted."
                type="warning"
                showIcon
              />
            ) : isProcore === "All" ? (
              <Alert
                message="All selected items have a submittal number, you need to remove the submittal number from these items before proceeding."
                type="error"
                showIcon
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BulkDelete;
