import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOneOutlined,
} from "@material-ui/icons";
import { Button, Input, Modal, Select, Space, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Container from "components/container";
import ContentContainer from "components/content-container";
import LoadingComponent from "components/loading-component";
import { PermissionServices } from "apis/Services/PermissionsService";
import Permission_table from "components/permission-table";
import Permissions from "pages/permissions";
import UseGetCompanyPermissions from "hooks/request-hooks/use-get-company-permissions";
import { saveCompanyAccessLevel } from "redux/permission-reducer";
import tableData from "../../components/permission-table/tableData";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Text from "components/text";

const CompanyPermissions = () => {
  const { company_id } = useParams();

  const company_permissions = UseGetCompanyPermissions({ company_id });
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedRecordEdit, setSelectedRecordEdit] = useState(null);
  const [togglePermissions, settogglePermissions] = useState(true);
  const [templateName, settemplateName] = useState("");
  const [assignedTemplates, setAssignedTemplates] = useState([]);
  const [permissionTemplatesNames, setPermissionTemplatesNames] = useState([]);
  const [editingTemplateName, setEditingTemplateName] = useState(false);

  const [projectPermission, setprojectPermission] = useState("");
  const [companyPermission, setcompanyPermission] = useState("");
  const [companyAdminPermission, setCompanyAdminPermission] = useState("");
  const [companyReportsPermission, setCompanyReportsPermission] = useState("");
  const [defaultPermission, setDefaultPermission] = useState({});

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [templateNameFilter, setTemplateNameFilter] = useState("");
  const dispatch = useDispatch();
  const [NewCompanyTemplateName, setNewCompanyTemplateName] = useState("");

  const [loader, setloader] = useState(true);
  const { companyAccessLevel } = useSelector((state) => state.permission);
  useEffect(() => {
    setTimeout(() => {
      setloader(false);
    }, 1500);
  }, []);
  const loaderr = {
    spinning: loader,
    indicator: <LoadingComponent />,
  };
  useEffect(() => {
    console.log(company_permissions, "company_permissions");
    dispatch(saveCompanyAccessLevel(company_permissions.accessLevel));
    setPermissionTemplatesNames(company_permissions?.permissionTemplatesNames);
  }, [company_permissions]);

  const handleCheckboxChange = (e, record, value) => {
    console.log(record, "record");
    const tempObj = {
      name: record.name,
      value: value,
    };

    const updatedPermissions = selectedPermissions.map((permission) => {
      if (permission.name === tempObj.name) {
        return tempObj;
      }
      return permission;
    });

    const isNewPermission = selectedPermissions.every(
      (permission) => permission.name !== tempObj.name
    );

    if (isNewPermission) {
      setSelectedPermissions([...updatedPermissions, tempObj]);
    } else {
      setSelectedPermissions(updatedPermissions);
    }
  };

  useEffect(() => {
    console.log(selectedPermissions, "useEffect");

    selectedPermissions.map((Permission) => {
      if (Permission.name === "Projects") {
        setprojectPermission(Permission.value);
      } else if (Permission.name === "Company Admin") {
        setCompanyAdminPermission(Permission.value);
      } else if (Permission.name === "Company Reports") {
        setCompanyReportsPermission(Permission.value);
      } else {
        setcompanyPermission(Permission.value);
      }
    });
  }, [selectedPermissions]);

  const TableData = {
    BasicData: company_permissions?.content
      ?.map((item, index) => {
        let permissionsPageValue;
        let projectPageValue;
        let companyAdminPageValue;
        let companyReportsPageValue;

        switch (item["Company Admin page"]) {
          case "0":
            companyAdminPageValue = "default";
            break;
          case "1":
            companyAdminPageValue = "readonly";
            break;
          case "2":
            companyAdminPageValue = "standard";
            break;
          case "3":
            companyAdminPageValue = "Admin";
            break;
          default:
            companyAdminPageValue = "0";
            break;
        }

        switch (item["Company Reports page"]) {
          case "0":
            companyReportsPageValue = "default";
            break;
          case "1":
            companyReportsPageValue = "readonly";
            break;
          case "2":
            companyReportsPageValue = "standard";
            break;
          case "3":
            companyReportsPageValue = "Admin";
            break;
          default:
            companyReportsPageValue = "0";
            break;
        }

        switch (item["Permissions page"]) {
          case "0":
            permissionsPageValue = "default";
            break;
          case "1":
            permissionsPageValue = "readonly";
            break;
          case "2":
            permissionsPageValue = "standard";
            break;
          case "3":
            permissionsPageValue = "Admin";
            break;
          default:
            permissionsPageValue = "0";
            break;
        }

        switch (item["Projects page"]) {
          case "0":
            projectPageValue = "default";
            break;
          case "1":
            projectPageValue = "readonly";
            break;
          case "2":
            projectPageValue = "standard";
            break;
          case "3":
            projectPageValue = "Admin";
            break;
          default:
            projectPageValue = "0";
            break;
        }
        return {
          key: item["Template Name"],
          templateName: item["Template Name"],
          permissionsPage: permissionsPageValue || "default",
          ProjectsPage: projectPageValue || "default",
          companyAdminPage: companyAdminPageValue || "default",
          companyReportsPage: companyReportsPageValue || "default",
          assignedTemplates: item?.["Assigned Templates"],
          index: index,
        };
      })
      .filter((item) => item.templateName !== "Default"),

    BasicColumns: [
      {
        title: "Actions",
        dataIndex: "actions",
        render: (_, record) => {
          if (companyAccessLevel == 1) {
            return null;
          } else if (companyAccessLevel == 2) {
            return (
              <Space size="small">
                <Button
                  type="link"
                  onClick={() => handleEdit(record)}
                  icon={<EditOutlined />}
                />
              </Space>
            );
          } else {
            return (
              <Space size="small">
                <Button
                  type="link"
                  onClick={() => handleEdit(record)}
                  icon={<EditOutlined />}
                />
                <Button
                  type="link"
                  onClick={() => {
                    handleDelete(record);
                  }}
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                />
              </Space>
            );
          }
        },
        width: 50,
      },
      {
        title: "Template Name",
        dataIndex: "templateName",
      },
      // {
      //   title: "permissionsPage",
      //   dataIndex: "permissionsPage",
      // },
      // {
      //   title: "ProjectsPage",
      //   dataIndex: "ProjectsPage",
      // },
    ],
  };

  const permissionsCheck = {
    permissions_collection_column: [
      {
        title: "Page Name",
        dataIndex: "name",
      },
      {
        title: "No Visibility",
        dataIndex: "Default",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.default}
            checked={handleCheckedCells(record, "default")}
            // defaultChecked={}
            onChange={(e) => handleCheckboxChange(e, record, "default")}
          />
        ),
      },
      {
        title: "Read Only",
        dataIndex: "read_only",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.readonly}
            checked={handleCheckedCells(record, "readonly")}
            onChange={(e) => handleCheckboxChange(e, record, "readonly")}
          />
        ),
      },
      {
        title: "Standard",
        dataIndex: "standard",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.standard}
            checked={handleCheckedCells(record, "standard")}
            onChange={(e) => handleCheckboxChange(e, record, "standard")}
          />
        ),
      },
      {
        title: "Admin",
        dataIndex: "Admin",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              handleColumnSelection(column);
            },
          };
        },
        render: (_, record) => (
          <input
            type="radio"
            name={record.key}
            // checked={selectedPermissions[record.key]?.admin}
            checked={handleCheckedCells(record, "admin")}
            onChange={(e) => handleCheckboxChange(e, record, "admin")}
          />
        ),
      },
    ],

    permissions_collection_data: [
      {
        key: 1,
        name: "Company Admin",
      },
      {
        key: 2,
        name: "Company Reports",
      },
      {
        key: 3,
        name: "Projects",
      },
      {
        key: 4,
        name: "Permissions",
      },
    ],
  };

  const handleColumnSelection = (column) => {
    const tempArr = [];
    permissionsCheck.permissions_collection_data.forEach((item) => {
      tempArr.push({
        name: item.name,
        value: column.dataIndex.toLowerCase().replace("_", ""),
      });
    });
    setSelectedPermissions(tempArr);
  };

  const handleCheckedCells = (record, name) => {
    console.log(selectedPermissions[record.key - 1]);
    return selectedPermissions[record.key - 1]?.value.toLowerCase() == name;
  };

  const handleEditModalOk = () => {
    setEditModalVisible(false);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
  };

  const handleEdit = (key) => {
    console.log(key, "key");
    setSelectedRecordEdit(key);
    const initialValues = [
      { name: "Company Admin", value: key.companyAdminPage },
      { name: "Company Reports", value: key.companyReportsPage },
      { name: "Projects", value: key.ProjectsPage },
      { name: "Permissions", value: key.permissionsPage },
    ];
    setSelectedPermissions(initialValues);
    settogglePermissions(false);
    settemplateName(key.templateName);
    setAssignedTemplates(key.assignedTemplates);
    setDefaultPermission(key);
  };

  const saveEditedPermissions = () => {
    let companyPermissionValue;
    let projectPermissionValue;
    let companyAdminPermissionValue;
    let companyReportsPermissionValue;

    switch (companyAdminPermission) {
      case "default":
        companyAdminPermissionValue = "0";
        break;
      case "readonly":
        companyAdminPermissionValue = "1";
        break;
      case "standard":
        companyAdminPermissionValue = "2";
        break;
      case "admin":
        companyAdminPermissionValue = "3";
        break;
      case "Admin":
        companyAdminPermissionValue = "3";
        break;
      default:
        companyAdminPermissionValue = "0";
        break;
    }

    switch (companyReportsPermission) {
      case "default":
        companyReportsPermissionValue = "0";
        break;
      case "readonly":
        companyReportsPermissionValue = "1";
        break;
      case "standard":
        companyReportsPermissionValue = "2";
        break;
      case "admin":
        companyReportsPermissionValue = "3";
        break;
      case "Admin":
        companyReportsPermissionValue = "3";
        break;
      default:
        companyReportsPermissionValue = "0";
        break;
    }

    switch (companyPermission) {
      case "default":
        companyPermissionValue = "0";
        break;
      case "readonly":
        companyPermissionValue = "1";
        break;
      case "standard":
        companyPermissionValue = "2";
        break;
      case "admin":
        companyPermissionValue = "3";
        break;
      case "Admin":
        companyPermissionValue = "3";
        break;
      default:
        companyPermissionValue = "0";
        break;
    }

    switch (projectPermission) {
      case "default":
        projectPermissionValue = "0";
        break;
      case "readonly":
        projectPermissionValue = "1";
        break;
      case "standard":
        projectPermissionValue = "2";
        break;
      case "admin":
        projectPermissionValue = "3";
        break;
      case "Admin":
        projectPermissionValue = "3";
        break;
      default:
        projectPermissionValue = "0";
        break;
    }

    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      permissionsPageToEdit: companyPermissionValue,
      projectsPageToEdit: projectPermissionValue,
      companyAdminPageToEdit: companyAdminPermissionValue,
      companyReportsPageToEdit: companyReportsPermissionValue,
      permissionTemplateNameToEdit: templateName,
      newPermissionTemplateNameToEdit: NewCompanyTemplateName || templateName,
      assignedTemplates: assignedTemplates,
    };

    PermissionServices.updateCompanyPermissionsTemplate(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() =>
        setTimeout(() => {
          window.location.reload();
        }, 600)
      );
  };

  const handleDelete = (key) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this item?",
      onOk() {
        console.log("Delete key:", key);
        console.log("inputValue", inputValue);
        let code = localStorage.getItem("token");
        let loginEmail = localStorage.getItem("email");
        let data = {
          company_id,
          loginEmail,
          code,
          permissionTemplateNameToDelete: key.templateName,
        };
        console.log(data);
        PermissionServices.deleteCompanyPermissionsTemplate(data)
          .then((res) => {
            console.log(res);

            setTimeout(() => {
              window.location.reload();
            }, 600);
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              "User(s) already assigned to this permission template!"
            );
          });
      },
    });
  };

  const filteredData = TableData?.BasicData?.filter((item) =>
    item.templateName.toLowerCase().includes(templateNameFilter.toLowerCase())
  );

  return (
    <>
      <Permissions
        togglePermissionsTable={togglePermissions}
        pageType="Company Permissions"
        templateName={templateName}
        templateNameFilter={templateNameFilter}
        setTemplateNameFilter={setTemplateNameFilter}
        setNewCompanyTemplateName={setNewCompanyTemplateName}
        assignedTemplates={assignedTemplates}
        setAssignedTemplates={setAssignedTemplates}
        permissionTemplatesNames={permissionTemplatesNames}
        editingTemplateName={editingTemplateName}
        setEditingTemplateName={setEditingTemplateName}
      />

      {companyAccessLevel == 0 ? (
        <div className="pt-5 text-center">
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : (
        <Container>
          <ContentContainer className="P-3">
            <div className="my-2">
              {togglePermissions ? (
                <>
                  <Modal
                    title="Edit Value"
                    open={editModalVisible}
                    onOk={handleEditModalOk}
                    onCancel={handleEditModalCancel}
                  >
                    <Input
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </Modal>
                  <Table
                    // columns={TableData.BasicColumns}
                    // dataSource={TableData.BasicData}

                    columns={TableData.BasicColumns}
                    dataSource={filteredData}
                    bordered
                    loading={loaderr}
                    pagination={{
                      pageSize: 10,
                      // current: currentPage,
                      // showSizeChanger: true,
                      // position: ["bottomLeft"],
                    }}
                    scroll={{
                      x: "max-content",
                    }}
                  />
                </>
              ) : (
                <>
                  <Table
                    columns={permissionsCheck.permissions_collection_column}
                    dataSource={permissionsCheck.permissions_collection_data}
                    pagination={false}
                    loading={loaderr}
                    scroll={{
                      x: "max-content",
                    }}
                  />

                  <Button
                    type="primary"
                    onClick={() => {
                      settogglePermissions(true);
                      saveEditedPermissions();
                    }}
                    style={{ margin: "10px" }}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      settogglePermissions(true);
                      setEditingTemplateName(false);
                    }}
                    style={{ margin: "10px" }}
                  >
                    Discard
                  </Button>
                </>
              )}
            </div>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default CompanyPermissions;
