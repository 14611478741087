import React, { useEffect, useState } from "react";

import { Collapse } from "antd";
import Container from "components/container";
import ContentContainer from "components/content-container";
import DivisionItemHeader from "./sub-division-item-header";
import GrowingAlert from "components/growing-alert";
import SubDivisionTable from "./sub-division-table";
import Text from "components/text";
import styles from "./div.module.css";

const SubDiv = ({
  divName,
  name,
  data,
  setcollapsed,
  collapsed,
  handleCollapse,
  index,
  checkedRows,
  setCheckedRows,
}) => {
  return (
    <>
      <DivisionItemHeader
        collapsed={collapsed}
        setcollapsed={setcollapsed}
        name={name}
        length={data.length}
        handleCollapse={(x) => handleCollapse(x)}
        checkedRows={checkedRows}
        setCheckedRows={setCheckedRows}
      />
      {collapsed.includes(name) && (
        <SubDivisionTable
          collapsed={collapsed}
          data={data}
          setcollapsed={setcollapsed}
          divName={divName}
          subDivisionName={name}
          name={name}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
        />
      )}
    </>
  );
};

const PanelContent = ({
  divName,
  subDivData,
  collapsed,
  setcollapsed,
  handleCollapse,
  checkedRows,
  setCheckedRows,
}) => {
  return (
    <>
      {Object.keys(subDivData)?.map((subDivisionName, index) => {
        return (
          <div
            className="my-3"
            key={
              "subDivisionTable" +
              index +
              JSON.stringify(subDivData[subDivisionName])
            }
          >
            <SubDiv
              collapsed={collapsed}
              setcollapsed={setcollapsed}
              name={subDivisionName}
              data={subDivData[subDivisionName]}
              divName={divName}
              handleCollapse={(x) => handleCollapse(x)}
              checkedRows={checkedRows}
              setCheckedRows={setCheckedRows}
            />
          </div>
        );
      })}
    </>
  );
};

const ProjectDivision = ({
  name,
  projectSubDivision = {},
  setcollapsed,
  collapsed,
  handleCollapse,
  index,
  collapsedDiv,
  checkedRows,
  setCheckedRows,
}) => {
  return (
    <PanelContent
      setcollapsed={setcollapsed}
      collapsed={collapsed}
      divName={name}
      subDivData={projectSubDivision}
      handleCollapse={(x) => handleCollapse(x)}
      checkedRows={checkedRows}
      setCheckedRows={setCheckedRows}
    />
  );
};

export default ProjectDivision;
