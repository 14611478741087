const ModalBackdrop = ({
  handleDismiss,
  children,
  customClassName,
  isCenterStyle,
}) => {
  const positionStyle = isCenterStyle
    ? { top: "50%", left: "50%", transform: "translate(-50%,-50%)" }
    : { top: "60%", right: "0px" };
  return (
    <section>
      <div
        className="position-fixed"
        style={{
          backgroundColor: "black",
          opacity: 0.4,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 88,
        }}
        onClick={handleDismiss}
      />
      <div
        className={`position-absolute ${
          isCenterStyle && "position-fixed"
        } ${customClassName}`}
        style={{
          transform: "translate(-50%, -50%)",
          left: "50%",
          top: "50%",
          backgroundColor: "white",
          zIndex: 99,
          borderRadius: "0 0 10px 10px",
          ...positionStyle,
        }}
      >
        {children}
      </div>
    </section>
  );
};

export default ModalBackdrop;
