import { Alert, Button, Input, Modal, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

function CreateColumn({
  createColumnType,
  saveCreateColumn,
  saveUpdateColumn,
  deleteColumn,
  columnName,
  setColumnName,
  columnDescription,
  setColumnDescription,
  columnType,
  setColumnType,
  columnkey,
  procoreColumnsInfo,
  undeletableColumns,
  source,
  setSource,
  columnsInfo,
  columnFormula,
  setColumnFormula,
  formulaRows,
  setFormulaRows,
  createColumnFlag,
  formula,
  setFormula,
  setShouldRecalculate,
}) {
  const { Option } = Select;
  const { confirm } = Modal;

  let saveAsNew = false;

  const getOptions = () => {
    if (createColumnType === "Koncurent Hard coded") {
      return [
        { value: "string", label: "Text" },
        { value: "date", label: "Date" },
        { value: "int", label: "Number" },
      ];
    } else if (columnkey && createColumnType === "Procore Source") {
      return Object.entries(procoreColumnsInfo)
        .map(([key, value]) => ({
          value: value.procoreKey,
          label: key,
          type: value.type,
        }))
        .filter((option) => option.type === columnsInfo[columnkey].type);
    } else {
      return Object.entries(procoreColumnsInfo).map(([key, value]) => ({
        value: value.procoreKey,
        label: key,
        type: value.type,
      }));
    }
  };

  const calculatedFirstOptions = () => {
    return Object.entries(columnsInfo)
      .filter(([key, value]) => {
        // If columnkey is not provided, perform the standard filter
        if (!columnkey) {
          return (
            value.usedStatus !== "unused" &&
            key !== "specRevision" &&
            value.type !== "string"
          );
        }
        // If columnkey is provided, filter based on the specified criteria
        return (
          value.usedStatus !== "unused" &&
          value.type !== "string" &&
          key !== "specRevision" &&
          key !== columnkey // Remove the column itself from the list
          // value.type === columnsInfo[columnkey].type &&
        );
      })
      .map(([key, value]) => ({
        value: key,
        label: value.label,
        type: value.type,
      }));
  };

  const calculatedOtherOptions = () => {
    return Object.entries(columnsInfo)
      .filter(
        ([key, value]) =>
          value.usedStatus !== "unused" &&
          value.type !== "string" &&
          value.type !== "date" &&
          key !== "specRevision" &&
          key !== columnkey
      )
      .map(([key, value]) => ({
        value: key,
        label: value.label,
        type: value.type,
      }));
  };

  const handleKoncurentHardCodedSave = () => {
    if (createColumnType === "Procore Source") {
      setShouldRecalculate(true);
    }

    let columnDetails = {
      label: columnName,
      description: columnDescription,
      type: columnType,
      hidden: columnkey ? columnsInfo[columnkey].hidden : "false",
      editable: createColumnType === "Procore Source" ? "false" : "true",
      usedStatus: "used",
      formula: "",
      source: createColumnType === "Procore Source" ? source : "koncurent",
    };

    setSource("");

    if (columnkey.length === 0 || saveAsNew) {
      saveCreateColumn(columnDetails);
      saveAsNew = false;
    } else {
      saveUpdateColumn(columnDetails, columnkey);
    }
  };

  const handleCalculatedSave = () => {
    const updatedRows = [...formulaRows];

    setShouldRecalculate(true);

    if (!updatedRows[updatedRows.length - 1].columnKey) {
      console.log(updatedRows[updatedRows.length - 1].columnKey);
      updatedRows.pop();
      setFormulaRows([...updatedRows]);
    }

    const formula = updatedRows.reduce((acc, row, idx) => {
      if (idx === 0) return row.columnKey;
      return `${acc}${row.operator}${row.columnKey}`;
    }, "");

    let columnDetails = {
      label: columnName,
      description: columnDescription,
      type: columnType,
      hidden: columnkey ? columnsInfo[columnkey].hidden : "false",
      editable: "false",
      usedStatus: "used",
      formula: formula,
      source: "koncurent",
    };

    if (columnkey.length === 0 || saveAsNew) {
      saveCreateColumn(columnDetails);
      saveAsNew = false;
      setColumnFormula("");
      setFormulaRows([]);
      resetFormula();
    } else {
      saveUpdateColumn(columnDetails, columnkey);
      setFormulaRows([]);
      setColumnFormula("");
      resetFormula();
    }
  };

  const deleteColumns = () => {
    let columnDetails = {
      label: "",
      description: "",
      hidden: "true",
      type: "",
      editable: "",
      usedStatus: "unused",
      formula: "",
      source: "",
    };

    deleteColumn(columnDetails, columnkey);
  };

  const handleFirstColumnSelect = (value, option) => {
    setColumnType(option?.type);

    let updatedRows;
    if (formulaRows.length === 0) {
      // If this is the first time selecting a column, create initial rows
      updatedRows = [
        { operator: "", columnKey: option?.value },
        { operator: "+", columnKey: "" },
      ];
    } else {
      updatedRows = [...formulaRows];
      updatedRows[0] = { ...updatedRows[0], columnKey: option?.value };
    }

    setFormulaRows(updatedRows);

    const newFormula = updatedRows.reduce((acc, row, idx) => {
      if (idx === 0) return row.columnKey;
      return row.columnKey ? `${acc} ${row.operator} ${row.columnKey}` : acc;
    }, "");

    setFormula(newFormula);
  };

  const resetFormula = () => {
    setColumnType("");
    // setSource("koncurent");

    setFormulaRows([]);

    // Update formula string
    setFormula("");
  };

  const handleFormulaChange = (index, type, value, option) => {
    const updatedRows = [...formulaRows];

    if (type === "columnKey") {
      updatedRows[index] = {
        ...updatedRows[index],
        columnKey: option?.value || "",
      };

      // Automatically add a new row if this is the last row and both operator and columnKey are filled
      if (
        index === updatedRows.length - 1 &&
        option?.value &&
        updatedRows[index].operator
      ) {
        updatedRows.push({ operator: "+", columnKey: "" });
      }
    } else {
      updatedRows[index] = {
        ...updatedRows[index],
        [type]: value,
      };

      // Automatically add a new row if this is the last row and both operator and columnKey are filled
      if (
        index === updatedRows.length - 1 &&
        value &&
        updatedRows[index].columnKey
      ) {
        updatedRows.push({ operator: "+", columnKey: "" });
      }
    }

    setFormulaRows(updatedRows);

    // Update formula string
    const newFormula = updatedRows.reduce((acc, row, idx) => {
      if (idx === 0) return row.columnKey;
      return row.columnKey && row.operator
        ? `${acc} ${row.operator} ${row.columnKey}`
        : acc;
    }, "");

    setFormula(newFormula);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...formulaRows];
    updatedRows.splice(index, 1);
    setFormulaRows(updatedRows);

    // Update formula string
    const newFormula = updatedRows.reduce((acc, row, idx) => {
      if (idx === 0) return row.columnKey;
      return row.columnKey && row.operator
        ? `${acc} ${row.operator} ${row.columnKey}`
        : acc;
    }, "");

    setFormula(newFormula);
  };

  const showDeleteConfirm = (columnkey, deleteColumns) => {
    confirm({
      title: "Are you sure you want to delete this column?",
      content: (
        <div>
          <p>
            You are about to delete the column: <strong>{columnName}</strong>.
            <br />
            This action is permanent and will delete all data for that column.
            <br />
            The action will be done after saving the view.
          </p>
        </div>
      ),
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      width: 600,
      onOk() {
        deleteColumns(columnkey);
      },
      onCancel() {},
    });
  };

  const unEditableColumns = [
    "specType",
    "specResponse",
    "responsibleContractor",
    "issueContract",
    "contractIssuedRelease",
    "submittalPrepTime",
    "submittalRequired",
    "submittalIssued",
    "approvalTime",
    "submittalApprovalDue",
    "submittalReturned",
    "leadTime",
    "scheduleID",
    "dateRequiredOnSite",
    "projectedDeliveryDate",
    "subCommittedDeliveryDate",
    "actualDeliveryDate",
  ];

  const unDeletableColumns = Object.keys(undeletableColumns);

  const getReasonForColumn = (columnName) => {
    const reason = undeletableColumns[columnName];

    if (Array.isArray(reason)) {
      if (reason.includes("Koncurent original column")) {
        return "Original Koncurent columns cannot be deleted.";
      } else {
        return "Used in other formulas";
      }
    }
  };

  return (
    <div className=" d-flex flex-column gap-2">
      {columnkey && createColumnType === "Procore Source" && (
        <Alert
          className=" my-2"
          message="You can only change the column type to another column with the same data type."
          type="warning"
        />
      )}
      <div className=" d-flex justify-content-center gap-2 w-100">
        <div>
          <label htmlFor="columnName" className=" me-1">
            Label{" "}
          </label>
          <strong style={{ color: "red" }}>*</strong>
          <Input
            value={columnName}
            placeholder="Label"
            // showCount
            maxLength={30}
            onChange={(e) => setColumnName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="columnDescription">Tooltip</label>
          <Input
            value={columnDescription}
            placeholder={
              createColumnType === "Calculated Column"
                ? "Write out your calculation"
                : "Tooltip"
            }
            // showCount
            maxLength={100}
            onChange={(e) => setColumnDescription(e.target.value)}
          />
        </div>
      </div>
      <div className=" d-flex justify-content-center">
        {createColumnType !== "Calculated Column" ? (
          <div className=" d-flex flex-column justify-content-center">
            <div>
              <label htmlFor="columnTypeSelect" className=" me-1">
                Type
              </label>
              <strong style={{ color: "red" }}>*</strong>
            </div>

            <Select
              id="columnTypeSelect"
              style={{ width: 450 }}
              placeholder="Select a type"
              showSearch
              disabled={
                (columnkey && createColumnType === "Koncurent Hard coded") ||
                unEditableColumns.includes(columnkey)
              }
              onChange={(value, option) => {
                if (createColumnType === "Koncurent Hard coded") {
                  setColumnType(value);
                } else {
                  setColumnType(option?.type);
                  setSource(
                    createColumnType === "Procore Source" ? value : "koncurent"
                  );
                }
              }}
              value={
                createColumnType === "Procore Source" ? source : columnType
              }
              options={getOptions()}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            ></Select>

            <div className=" d-flex mt-3 justify-content-between gap-2">
              <div>
                {columnkey && (
                  <Tooltip title={getReasonForColumn(columnkey)}>
                    <Button
                      type="primary"
                      disabled={unDeletableColumns.includes(columnkey)}
                      onClick={() =>
                        showDeleteConfirm(columnkey, deleteColumns)
                      }
                      danger
                    >
                      Delete
                    </Button>
                  </Tooltip>
                )}
              </div>

              <div className=" d-flex justify-content-end gap-2">
                {columnkey && (
                  <Button
                    onClick={() => {
                      saveAsNew = true;
                      handleKoncurentHardCodedSave();
                    }}
                    disabled={createColumnFlag}
                  >
                    Save as New
                  </Button>
                )}

                <Button
                  type="primary"
                  onClick={handleKoncurentHardCodedSave}
                  disabled={!columnName || !columnType}
                >
                  {columnkey ? "Update" : "Save"}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column w-100 justify-content-center gap-2">
            <div>
              <label htmlFor="columnTypeSelect" className=" me-1">
                Create Formula
              </label>
              <strong style={{ color: "red" }}>*</strong>
            </div>

            <Select
              id="columnTypeSelect"
              placeholder="Select first column"
              showSearch
              onChange={handleFirstColumnSelect}
              value={formulaRows[0]?.columnKey}
              disabled={unEditableColumns.includes(columnkey)}
              options={calculatedFirstOptions()}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            ></Select>
            {formulaRows.map(
              (row, index) =>
                index > 0 && (
                  <div key={index} className="d-flex gap-2">
                    <Select
                      placeholder="+/-"
                      value={row?.operator}
                      onChange={(value) =>
                        handleFormulaChange(index, "operator", value)
                      }
                      disabled={unEditableColumns.includes(columnkey)}
                    >
                      <Option value="+">+</Option>
                      <Option value="-">-</Option>
                    </Select>
                    <Select
                      className="flex-grow-1"
                      placeholder="Select another column"
                      value={formulaRows[index]?.columnKey}
                      onChange={(value, option) =>
                        handleFormulaChange(index, "columnKey", value, option)
                      }
                      disabled={unEditableColumns.includes(columnkey)}
                      options={calculatedOtherOptions()}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    ></Select>

                    {index !== formulaRows.length && (
                      <Button
                        onClick={() => handleRemoveRow(index)}
                        disabled={unEditableColumns.includes(columnkey)}
                        type="ghost"
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                )
            )}
            {/* FOR TESTING PURPOSES */}
            {/* {formula && (
              <div>
                <label>Current Formula:</label>
                <div>{formula}</div>
              </div>
            )} */}

            <div className=" d-flex justify-content-between gap-2 mt-2">
              <div>
                {columnkey && (
                  <Tooltip title={getReasonForColumn(columnkey)}>
                    <Button
                      type="primary"
                      disabled={unDeletableColumns.includes(columnkey)}
                      onClick={() =>
                        showDeleteConfirm(columnkey, deleteColumns)
                      }
                      danger
                    >
                      Delete
                    </Button>
                  </Tooltip>
                )}
              </div>

              <div className=" d-flex justify-content-between gap-2">
                <div>
                  {formulaRows.length > 1 && (
                    <Button
                      onClick={resetFormula}
                      disabled={unEditableColumns.includes(columnkey)}
                    >
                      Clear Formula
                    </Button>
                  )}
                </div>
                <div className=" d-flex gap-2 justify-content-end">
                  {columnkey && (
                    <Button
                      onClick={() => {
                        saveAsNew = true;
                        handleCalculatedSave();
                      }}
                      disabled={createColumnFlag}
                    >
                      Save as New
                    </Button>
                  )}
                  <Button
                    type="primary"
                    onClick={handleCalculatedSave}
                    disabled={
                      !columnName || formulaRows.length < 2
                      // ||
                      // (formulaRows.length > 1 &&
                      //   !formulaRows[formulaRows.length - 1].columnKey)
                    }
                  >
                    {columnkey ? "Update" : "Save"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateColumn;
