export const SideBarEndPoints = {
  TasksAssigned: {
    url: (company_id, project_id) =>
      `/rest/v1/procurementLogTasksAssignedInfo/${company_id}/${project_id}`,
    method: "GET",
  },

  assignTasks: {
    url: (company_id, project_id) =>
      `/rest/v1/assignTasks/${company_id}/${project_id}`,
    method: "POST",
  },

  deleteTasks: {
    url: (company_id, project_id) =>
      `/rest/v1/deleteTasks/${company_id}/${project_id}`,
    method: "DELETE",
  },
  completeTasks: {
    url: (company_id, project_id) =>
      `/rest/v1/completeTasks/${company_id}/${project_id}`,
    method: "POST",
  },
  procurementLogNotificationCenter: {
    url: (company_id, project_id) =>
      `/rest/v1/procurementLogNotificationCenter/${company_id}/${project_id}`,
    method: "GET",
  },
};
