import { Radio, Select } from "antd";

import React from "react";

const tableData = (type, onChange = () => {}) => {
  const menuOptions = [
    {
      label: "Action1",
      value: "Action1",
    },
    {
      label: "Action2",
      value: "Action2",
    },
    {
      label: "Action3",
      value: "Action3",
    },
  ];
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const tablesData = {
    user_permission_columns: [
      {
        title: "",
        dataIndex: "action",
        //   width: '15%',
        render: (text, record) => (
          <Select
            // mode=""
            // allowClear
            style={{
              width: "100%",
            }}
            placeholder="Actions"
            onChange={handleChange}
            options={menuOptions}
          />
        ),
      },
      {
        title: "User",
        dataIndex: "user",
      },
      {
        title: "Company",
        dataIndex: "company",
      },
      {
        title: "Company Permissions",
        dataIndex: "company_permissions",
      },
      {
        title: "Default Project Permission",
        dataIndex: "default_project_permission",
      },
    ],
    user_permission_data: [
      {
        key: 1,
        user: "Mohamed test1",
        company: "Food Services",
        company_permissions: "None",
        default_project_permission: "Project Manager",
      },
      {
        key: 2,
        user: "ahmed test1",
        company: "Food Services",
        company_permissions: "None",
        default_project_permission: "None",
      },
      {
        key: 3,
        user: "tarek test1",
        company: "Food Services",
        company_permissions: "None",
        default_project_permission: "Project Manager",
      },
      {
        key: 4,
        user: "omar test1",
        company: "Food Services",
        company_permissions: "None",
        default_project_permission: "Project Manager",
      },
      {
        key: 5,
        user: "omar test2",
        company: "Food Services",
        company_permissions: "None",
        default_project_permission: "None",
      },
    ],
    company_permission_columns: [
      {
        title: "",
        dataIndex: "action",
        //   width: '15%',
        render: (text, record) => (
          <Select
            style={{
              width: "100%",
            }}
            placeholder="Actions"
            // onChange={handleChange}
            options={menuOptions}
          />
        ),
      },
      {
        title: "Company Permissions Templates",
        dataIndex: "company_permissions_templates",
      },
      {
        title: "Active Users",
        dataIndex: "active_users",
      },
      {
        title: "Inactive Users",
        dataIndex: "inactive_users",
      },
    ],
    company_permission_data: [
      {
        key: 1,
        company_permissions_templates: "Architect",
        active_users: "10 Users",
        inactive_users: "2 Users",
      },
      {
        key: 2,
        company_permissions_templates: "Architect",
        active_users: "8 Users",
        inactive_users: "0 Users",
      },
    ],

    permissions_collection_column: [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "None",
        dataIndex: "none",
        render: (text, record) => <input type="radio" name={record.name} />,
      },
      {
        title: "Read Only",
        dataIndex: "read_only",
        render: (text, record) => <input type="radio" name={record.name} />,
      },
      {
        title: "Standard",
        dataIndex: "standard",
        render: (text, record) => <input type="radio" name={record.name} />,
      },
      {
        title: "Admin",
        dataIndex: "project_admin",
        render: (text, record) => <input type="radio" name={record.name} />,
      },
    ],
    permissions_collection_data: [
      {
        key: 1,
        name: "Projects",
        none: "",
        read_only: "",
        standard: "",
        project_admin: "",
        company_admin: "",
      },
      {
        key: 2,
        name: "Permissions",
        none: "",
        read_only: "",
        standard: "",
        project_admin: "",
        company_admin: "",
      },
    ],
  };

  return tablesData[type];
};

export default tableData;
