import React, { useEffect, useState } from "react";

import { DatePicker } from "antd";
import Styles from "./input.module.css";
import Text from "components/text";
import dayjs from "dayjs";
import errorIcon from "assets/images/error-icon.svg";
import moment, { weekdays } from "moment";
import { useSelector } from "react-redux";
const InputComponent = ({
  defaultValue = "",
  id = "",
  className = "",
  inputClassName = "",
  inputIconContainerClass = "",
  style,
  type = "",
  label = "",
  required = false,
  placeholder = "",
  handleChange = (e) => {},
  handleBlur = () => {},
  value,
  onChange,
  errors = "",
  hasIcon = false,
  Icon = () => {},
  ischecked = false,
  textarea = false,
  isDate = false,
  havemargin = true,
  normalError = false,
  disabled = false,
  disabledDate = false,
  customDisabledDates = false,
  ...prop
}) => {
  const dateFormat = "MM-DD-YYYY";
  const [selectedDate, setSelectedDate] = useState(
    isDate && value ? dayjs(value, dateFormat) : null
  );

  const { projectDetails } = useSelector((state) => state.projectDetails);

  const WorkingDays = projectDetails.workingDays;
  const nonWorkingDays = projectDetails.nonWorkingDays;

  useEffect(() => {
    if (isDate && value) {
      const parsedDate = dayjs(value, dateFormat);
      // console.log(parsedDate, "parsedDate");
      setSelectedDate(parsedDate);
    } else {
      setSelectedDate(null);
    }
  }, [isDate, value]);

  const defaultPickerValue = selectedDate;

  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
    onChange(dateString);
  };

  const isDateDisabled = (date) => {
    // Handling Holidays
    const disabledDatesArray = [];
    Object.values(nonWorkingDays).forEach((holiday) => {
      var currDate = moment(holiday["startDate"], "MM/DD/YYYY").startOf("day");
      var lastDate = moment(holiday["finishDate"], "MM/DD/YYYY").startOf("day");
      disabledDatesArray.push(moment(currDate).format("YYYY-MM-DD"));
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        disabledDatesArray.push(moment(currDate).format("YYYY-MM-DD"));
      }
      disabledDatesArray.push(moment(lastDate).format("YYYY-MM-DD"));
    });

    // Handling Weekends
    const disabledWorkingdays = Object.values(WorkingDays);
    const adjustingDayOffs = disabledWorkingdays.shift();
    disabledWorkingdays.push(adjustingDayOffs);
    const weeklyDayOffindexs = [];
    const dayofWeek = date.day();

    disabledWorkingdays.forEach((item, index) => {
      if (item === "0") {
        weeklyDayOffindexs.push(index);
      }
    });

    const formattedDate = date.format("YYYY-MM-DD");
    return (
      ((disabledDatesArray.includes(formattedDate) ||
        weeklyDayOffindexs.includes(dayofWeek)) &&
        customDisabledDates) ||
      (date && date > moment().endOf("day") && disabledDate)
    );
  };

  return (
    <>
      {label && (
        <div className="d-flex align-items-start">
          <label htmlFor={id} className={`${Styles.label}`}>
            {label}
          </label>
          {required && (
            <label htmlFor={id} className={`${Styles.star} mx-1`}>
              *
            </label>
          )}
        </div>
      )}

      {!textarea && isDate ? (
        <div className={`${Styles.dateContainer} ${className}`}>
          {Icon}
          <DatePicker
            defaultValue={defaultPickerValue}
            format={dateFormat}
            onChange={handleDateChange}
            value={selectedDate}
            placeholder=""
            className="antPickerStyle"
            style={{
              border: "none",
              backgroundColor: "transparent",
              ...style,
            }}
            disabled={disabled}
            disabledDate={customDisabledDates ? isDateDisabled : null}
          />
        </div>
      ) : (
        <div
          className={` ${Styles.container} w-100 ${
            havemargin ? "mb-3" : ""
          }position-relative  ${className}`}
        >
          {textarea ? (
            <>
              {Icon}

              <textarea
                onChange={(e) => {
                  const newValue = e.target.value;
                  onChange(newValue);
                }}
                onBlur={() => {
                  handleBlur();
                }}
                placeholder={placeholder}
                type={type}
                value={value}
                className={`${Styles.input} ${Styles.textarea} ${inputClassName} `}
                style={{
                  ...style,
                  borderColor: errors ? "#e93c3c" : "#C8C8C8",
                }}
                {...prop}
              ></textarea>
            </>
          ) : (
            <>
              <input
                on
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                onBlur={() => {
                  handleBlur();
                }}
                id={id}
                defaultValue={defaultValue}
                placeholder={placeholder}
                type={type}
                value={value}
                disabled={disabled}
                className={`${Styles.input} ${inputClassName} `}
                style={{
                  ...style,
                  borderColor: errors ? "#e93c3c" : "#C8C8C8",
                }}
                {...prop}
              />
              {Icon}
            </>
          )}

          {errors && !normalError && (
            <div
              className={`d-flex align-items-center ${Styles.errorContainer}`}
            >
              <img
                src={errorIcon}
                alt="errorIcon"
                className={`${Styles.errorIcon}`}
              />
              <Text className={`${Styles.error_msg}`}>
                {errors?.message || errors}
              </Text>
            </div>
          )}
        </div>
      )}

      {errors && normalError && (
        <Text className={`${Styles.error_msg} mt-1`}>
          {errors?.message || errors}
        </Text>
      )}
    </>
  );
};

export default InputComponent;
