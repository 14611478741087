import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const Edit_Contact_Schema = yupResolver(
  yup.object().shape({
    "name": yup.string().required("Name Required"),
    // "Email Address": yup.string().required("Email Address Required"),
    "Phone": yup.string().required("Phone Required"),
    // "Project Permissions": yup.string().required("Project Permission Required"),
    // "Company Permissions": yup.string().required("Company Permission Required"),

  })
);

