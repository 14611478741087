import { HiddenItemsEndPoints } from "apis/EndPoints/HiddenItemsEndPoints";
import { Network } from "../Network";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class HiddenItemsService {
  static getProcurementLogHiddenEntries(data = {}) {
    return Network.fetch(
      HiddenItemsEndPoints.getProcurementLogHiddenEntries.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: HiddenItemsEndPoints.getProcurementLogHiddenEntries.method,
      }
    );
  }

  static restoreProcurementLogHiddenEntries(data = {}) {
    return Network.fetch(
      HiddenItemsEndPoints.restoreProcurementLogHiddenEntries.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: HiddenItemsEndPoints.restoreProcurementLogHiddenEntries.method,
        body: JSON.stringify(data),
      }
    );
  }

  static procurementLogHideEntry(data = {}) {
    return Network.fetch(
      HiddenItemsEndPoints.procurementLogHideEntry.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: HiddenItemsEndPoints.procurementLogHideEntry.method,
        body: JSON.stringify(data),
      }
    );
  }
}
