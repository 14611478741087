import React, { useState } from "react";
import styles from "./home.module.css";
import Container from "components/container";
import HomeSlider from "components/home-slider";
import Text from "components/text";
import ContentContainer from "components/content-container";
import OwnnYourData from "components/own-your-data";
import ButtonComponent from "components/button-component";
import OurPlatform from "components/our-platform";
import ownDataImg from "assets/images/ownData.png";
import procoreFounderImg from "assets/images/procore-founder.png";
import ContainerLanding from "components/container-landing";
import logo from "assets/images/Koncurent-03.png";
import testimonialImage from "assets/images/Testimonial-Amanda.jpg";
import instagramLogo from "assets/images/instagram-logo.svg";
import linkedInLogo from "assets/images/linkedin-logo.svg";
import youtubeLogo from "assets/images/youtube-icon.svg";
import procurementImage2 from "assets/images/procurement-image-2.svg";
import procurementImage1 from "assets/images/procurement-image-1.svg";
import communicate from "assets/images/communicate.webp";
import procure from "assets/images/procure.webp";
import report from "assets/images/report.webp";
import innovationImage from "assets/images/innovation-image-1.svg";
import HeroImage from "assets/images/Hero-Image.svg";
import FooterImage from "assets/images/Footer-Image.svg";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

const Home = () => {
  const currentYear = new Date().getFullYear();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const handleClosePrivacyModal = () => setShowPrivacyModal(false);

  return (
    <div>
      {/* <HomeSlider /> */}

      <div className="bg-black text-white p-5">
        <ContainerLanding>
          <div className="d-flex flex-lg-row flex-column-reverse gap-4 p-0 p-md-5">
            <div className=" d-flex flex-column align-items-center align-items-md-start gap-0">
              <div>
                <p className={styles.headerTitle}>Koncurent</p>
              </div>
              <div>
                <p className={styles.headerSubTitle}>Procurement Matters</p>
              </div>
              <Link
                className={`${styles.bookDemoBtn} px-5 py-3`}
                to={"/contact-us"}
              >
                BOOK A DEMO
              </Link>
            </div>
            <div className=" d-flex justify-content-center align-items-center">
              <img
                src={HeroImage}
                className={styles.responsiveHero}
                alt="koncurent-logo"
              />
            </div>
          </div>
        </ContainerLanding>
      </div>

      <div className="bg-white py-5">
        <ContainerLanding>
          <div
            className={`${styles.sectionBorder} d-flex flex-lg-row flex-column-reverse gap-5 p-5`}
          >
            <div className=" d-flex flex-column align-items-center align-items-md-start gap-0">
              <div className=" d-flex align-items-center">
                <img
                  src={procurementImage1}
                  width={400}
                  alt="built easy"
                  className={styles.responsiveHero}
                />
              </div>
            </div>
            <div className=" d-flex flex-column gap-3 align-items-md-start align-items-center justify-content-end">
              <div>
                <h1 className=" display-2 fw-bold text-center text-md-start">
                  Procurement doesn't have to be painful
                </h1>
              </div>
              <div>
                <div className=" d-flex flex-column align-items-md-start align-items-center">
                  <h4 className="text-black fw-normal text-center text-md-start">
                    We built Koncurent to provide your team with procurement
                    logs that work, saving you time & money
                  </h4>
                  <Link
                    className={`${styles.learnMoreBtn} px-5 py-3`}
                    to={"/contact-us"}
                  >
                    LEARN MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ContainerLanding>
      </div>

      <div className={`${styles.testimonial} text-white p-3 py-5 p-md-5`}>
        <ContainerLanding>
          <div className="d-flex flex-md-row flex-column gap-4 p-0 p-md-5 ">
            <div className=" d-flex flex-column gap-3 justify-content-center">
              <h6 className=" display-6 fw-bold">
                "Koncurent provides us with an elegant and collaborative
                solution that will help standardize the way we do procurement."
              </h6>

              <p className=" fw-bold">
                Amanda Finnerty, Director of Technology & Process at Commodore
                Builders
              </p>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <img
                src={testimonialImage}
                width={300}
                height={300}
                className={`styles.responsiveHero rounded-circle`}
                alt="koncurent-logo"
              />
            </div>
          </div>
        </ContainerLanding>
      </div>

      <div className="bg-black text-white p-3 py-5 p-md-5">
        <ContainerLanding>
          <div className="d-flex flex-column align-items-center gap-5 p-0 p-md-5">
            <div className=" d-flex flex-column flex-md-row gap-5 p-5">
              <div className=" d-flex flex-column justify-content-start align-items-center gap-3 text-center w-100">
                <div className="">
                  <img src={procure} width={120} alt="procure" />
                </div>
                <div>
                  <h1 className=" display-5 fw-normal">Procure</h1>
                  <p className={`${styles.letterSpacing}`}>
                    Live procurement tracking log
                  </p>
                </div>
              </div>
              <div className=" d-flex flex-column justify-content-start align-items-center gap-4 text-center w-100">
                <div className="">
                  <img src={communicate} width={120} alt="communicate" />
                </div>
                <div>
                  <h1 className=" display-5 fw-normal">Communicate</h1>
                  <p className={`${styles.letterSpacing}`}>
                    Communicate & invite all project team members
                  </p>
                </div>
              </div>
              <div className=" d-flex flex-column justify-content-start align-items-center gap-4 text-center w-100">
                <div>
                  <img src={report} width={120} alt="report" />
                </div>

                <div>
                  <h1 className=" display-5 fw-normal">Report</h1>
                  <p className={`${styles.letterSpacing}`}>
                    Report on all company procurement metrics
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Link
                className={`${styles.bookDemoBtn} px-5 py-3`}
                to={"/contact-us"}
              >
                EXPLORE
              </Link>
            </div>
          </div>
        </ContainerLanding>
      </div>

      <div className="bg-white p-3 py-5 p-md-5">
        <ContainerLanding>
          <div className=" d-flex flex-column flex-md-row gap-5 p-3 p-md-5 w-100">
            <div className=" d-flex flex-column w-100 w-md-auto">
              <div>
                <h1 className=" display-2 text-center text-md-start fw-bold">
                  Innovation <br /> that’s <br /> Koncurent
                </h1>
              </div>
              <div>
                <img
                  src={innovationImage}
                  // width={450}
                  // className= "mt-5"
                  className={`styles.responsiveHero mt-5 `}
                  alt="koncurent-logo"
                />
              </div>
            </div>
            <div className=" d-flex flex-column text-center text-md-start w-100 gap-3">
              <div>
                <h1 className=" display-5 fw-bold">Seamless integrations</h1>
                <p className=" opacity-75">
                  The integration of Koncurent into Procore empowers
                  construction managers with a centralized procurement log that
                  seamlessly synchronizes with Procore submittals and the
                  current imported construction schedule.
                </p>
              </div>
              <hr className="border border-1 border-dark opacity-100 w-100" />
              <div>
                <h1 className=" display-5 fw-bold">Intuitive user interface</h1>
                <p className=" opacity-75">
                  Our simple interface makes every feature a joy to use. Elevate
                  your procurement logs with innovation and elegance.
                </p>
              </div>
              <hr className="border border-1 border-dark opacity-100 w-100" />
              <div>
                <h1 className=" display-5 fw-bold">
                  Excellent customer support
                </h1>
                <p className=" opacity-75">
                  Koncurent offers exceptional support through strong
                  partnerships and proactive assistance. Enjoy personalized
                  onboarding, continuous training, and tailored services. With
                  Koncurent, you're gaining a committed partner in your success.
                </p>
              </div>
            </div>
          </div>
        </ContainerLanding>
      </div>

      <div className="bg-black text-white p-3 py-5 p-md-5">
        <ContainerLanding>
          <div className="d-flex flex-md-row flex-column gap-5 p-3 p-md-5">
            <div className=" d-flex flex-column align-items-center align-items-md-start gap-0 w-100">
              <div className=" mb-5">
                <img
                  src={logo}
                  width={180}
                  className=" mb-5"
                  alt="koncurent-logo"
                />
              </div>
              <div>
                <p className={styles.footerTitle}>Procure with us</p>
              </div>
              <div>
                <p className={styles.footerSubTitle}>
                  Automate and simplify your procurement operations with an easy
                  and reliable solution
                </p>
              </div>
              <Link
                className={`${styles.bookDemoBtn} px-5 py-3`}
                to={"/contact-us"}
              >
                START TODAY
              </Link>
            </div>
            <div className=" w-100 d-flex flex-column justify-content-center align-items-center align-items-md-start gap-3">
              <div>
                <img
                  src={FooterImage}
                  className={styles.responsiveFooterImage}
                  alt="Footer-img"
                />
              </div>
              <div className=" d-flex flex-column flex-md-row w-100">
                <div className=" d-flex justify-content-center align-items-md-end me-4">
                  <h5 className=" fw-bold">GET SOCIAL</h5>
                </div>
                <div className=" d-flex justify-content-center align-items-center gap-2">
                  <a
                    href="https://www.linkedin.com/company/koncurent"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedInLogo} width={50} alt="Linkedin-logo" />
                  </a>

                  <a
                    href="https://www.instagram.com/koncurenttech/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagramLogo} width={35} alt="Instagram-logo" />
                  </a>

                  <a
                    href="https://www.youtube.com/watch?v=FcNVZG3kpbc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={youtubeLogo}
                      width={50}
                      className=" ms-2"
                      alt="Youtube-logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ContainerLanding>
      </div>

      {/* Privacy Notice Modal */}
      <Modal show={showPrivacyModal} onHide={handleClosePrivacyModal}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This Privacy Policy explains our practices regarding the collection,
          use, and disclosure of information that we receive from users of our
          website and services.
          <br /> 1. Information We Collect We collect information that you
          provide to us, such as your name, email address, postal address, and
          phone number when you register for our services, make a purchase, or
          otherwise communicate with us. We may also collect information about
          your use of our website and services, such as your IP address, browser
          type, and device ID.
          <br /> 2. How We Use Your Information We use your information to
          provide our services to you, to communicate with you about our
          services, and to improve our website and services. We may also use
          your information to send you marketing communications about our
          services, unless you opt out of such communications.
          <br /> 3. Sharing Your Information We do not sell or rent your
          personal information to third parties. We may share your information
          with our third-party service providers who help us to provide our
          services or process payments. We may also share your information if
          required by law or to protect our rights or the rights of others.
          <br /> 4. Security We take reasonable measures to protect your
          information from unauthorized access, use, or disclosure. However, no
          method of transmission over the Internet or electronic storage is 100%
          secure, and we cannot guarantee the absolute security of your
          information.
          <br /> 5. Your Choices You may choose to opt out of receiving
          marketing communications from us at any time by following the
          instructions in the communications. You may also access, correct, or
          delete your personal information by contacting us.
          <br /> 6. Children's Privacy Our website and services are not intended
          for use by children under the age of 13. We do not knowingly collect
          personal information from children under 13. If we become aware that
          we have collected personal information from a child under 13, we will
          take steps to delete the information as soon as possible.
          <br /> 7. Changes to This Privacy Policy We may update this Privacy
          Policy from time to time. If we make material changes to this Privacy
          Policy, we will notify you by email or by posting a notice on our
          website.
          <br /> 8. Contact Us If you have any questions or concerns about our
          Privacy Policy, please contact us.
        </Modal.Body>
      </Modal>

      <div className=" d-flex justify-content-center justify-content-md-start px-0 px-md-5 bg-black text-white">
        <footer className=" d-flex gap-1 px-0 px-md-5">
          <p
            className="opacity-75 pointer"
            onClick={() => setShowPrivacyModal(true)}
          >
            Privacy Notice
          </p>

          <span className="opacity-50"> | </span>
          <p className="opacity-50">© {currentYear} All rights reserved.</p>
        </footer>
      </div>
      {/* <OurPlatform /> */}
    </div>
  );
};

export default Home;
