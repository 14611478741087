import { DeletedItemsEndPoints } from "apis/EndPoints/DeletedItemsEndPoints";
import { Network } from "../Network";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class DeletedItemsService {
  static getProcurementLogDeletedEntries(data = {}) {
    return Network.fetch(
      DeletedItemsEndPoints.getProcurementLogDeletedEntries.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DeletedItemsEndPoints.getProcurementLogDeletedEntries.method,
      }
    );
  }

  static restoreProcurementLogDeletedEntries(data = {}) {
    return Network.fetch(
      DeletedItemsEndPoints.restoreProcurementLogDeletedEntries.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method:
          DeletedItemsEndPoints.restoreProcurementLogDeletedEntries.method,
        body: JSON.stringify(data),
      }
    );
  }

  static deleteProcurementLogDeletedEntries(data = {}) {
    return Network.fetch(
      DeletedItemsEndPoints.deleteProcurementLogDeletedEntries.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DeletedItemsEndPoints.deleteProcurementLogDeletedEntries.method,
        body: JSON.stringify(data),
      }
    );
  }
}
