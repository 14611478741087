import React, { useEffect, useState } from "react";
import Container from "components/container";
import ContentContainer from "components/content-container";
import { Link, useParams } from "react-router-dom";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import CompanyAdmin from "pages/company-admin";
import { CompanyAdminServices } from "apis/Services/CompanyAdminService";
import styles from "./company-overview.module.css";
import { CaretRightOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Collapse, Result, Tooltip } from "antd";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

const { Panel } = Collapse;

const CompanyOverview = ({
  type = "company_overview",
  showPermissionsTable,
}) => {
  const { company_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [accessLevelValue, setAccessLevelValue] = useState();
  const [companyName, setCompanyName] = useState();

  // contacts
  const [totalContacts, setTotalContacts] = useState(0);
  const [activeContacts, setActiveContacts] = useState(0);

  //
  const [totalProjects, setTotalProjects] = useState(0);
  const [activeProjects, setActiveProjects] = useState(0);

  const [filter, setFilter] = useState("All");

  const readCompanyOverview = async () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let requestData = {
      company_id,
      loginEmail,
      code,
    };

    try {
      const res = await CompanyAdminServices.readRegisteredProjects(
        requestData
      );
      setAccessLevelValue(parseInt(res?.accessLevel));
      setData(res.content);
      setFilteredData(res.content);
      setCompanyName(res?.companyName);

      // console.log(res);

      // Calculate totals here
      let totalCon = 0; // total contacts
      let activeCon = 0; // active contacts
      let totalProj = 0; // total projects
      let activeProj = 0; // active projects

      res.content.forEach((project) => {
        totalProj += 1;
        if (project.registrationInfo.registerationStatus === "Active") {
          activeProj += 1;
        }
        totalCon += project.directoryInfo.totalContacts || 0;
        activeCon += project.directoryInfo.activatedContacts || 0;
      });

      setTotalContacts(totalCon);
      setActiveContacts(activeCon);
      setTotalProjects(totalProj);
      setActiveProjects(activeProj);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    if (newFilter === "All") {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter(
          (item) => item.registrationInfo.registerationStatus === newFilter
        )
      );
    }
  };

  useEffect(() => {
    readCompanyOverview();
  }, []);

  return (
    <>
      <CompanyAdmin pageType="company_overview" />
      {(!isLoading && typeof accessLevelValue !== "number") ||
      accessLevelValue === 0 ? (
        <div className="mt-5 text-center">
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : (
        <Container>
          <ContentContainer className="px-3 py-1 my-4">
            {!isLoading ? (
              <div className="my-4">
                <Text className={`${styles.title} mb-2`} dontWrap={false}>
                  {companyName}
                </Text>
                <div className="d-flex gap-3 justify-content-left align-items-center mt-3">
                  <Card size="small" className={`${styles.mainCard}`}>
                    <div className="p-2">
                      <div className="d-flex flex-column mt-2">
                        <Text className=" fs-6 opacity-75">
                          Active Projects
                        </Text>
                        <Text className=" fs-5">
                          {activeProjects} / {totalProjects}
                        </Text>
                      </div>
                    </div>
                  </Card>
                  <Card size="small" className={`${styles.mainCard}`}>
                    <div className="p-2">
                      <div className="d-flex flex-column mt-2">
                        <Text className="fs-6 opacity-75">Active Contacts</Text>
                        <Text className="fs-5">
                          {activeContacts} / {totalContacts}
                        </Text>
                      </div>
                    </div>
                  </Card>
                </div>

                {/* Filter Toggles */}
                <div className="d-flex gap-2 my-4">
                  <Button
                    className=" rounded-5"
                    type={filter === "All" ? "primary" : "default"}
                    onClick={() => handleFilterChange("All")}
                  >
                    All
                  </Button>
                  <Button
                    className=" rounded-5"
                    type={filter === "Active" ? "primary" : "default"}
                    onClick={() => handleFilterChange("Active")}
                  >
                    Active
                  </Button>
                  <Button
                    className=" rounded-5"
                    type={filter === "Expiring soon" ? "primary" : "default"}
                    onClick={() => handleFilterChange("Expiring soon")}
                  >
                    Expiring Soon
                  </Button>
                  <Button
                    className=" rounded-5"
                    type={filter === "Expired" ? "primary" : "default"}
                    onClick={() => handleFilterChange("Expired")}
                  >
                    Expired
                  </Button>
                </div>

                {filteredData.length > 0 ? (
                  <div className="mt-4">
                    {filteredData.map((item) => (
                      <Collapse
                        className={`my-2 ${styles.panelStyling}`}
                        key={item.projectInfo.projectID}
                        ghost
                        expandIconPosition={"start"}
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                      >
                        <Panel
                          header={item.projectInfo?.["Project Name"]}
                          key="1"
                        >
                          <div className=" d-flex flex-column flex-wrap gap-4">
                            <div className=" d-flex flex-row flex-wrap gap-4">
                              <div>
                                <div className=" d-flex gap-2 align-items-center">
                                  <Text className="fw-bold fs-5">
                                    Directory Info
                                  </Text>
                                  {(item.registrationInfo
                                    ?.registerationStatus === "Active" ||
                                    "Expiring soon") && (
                                    <Tooltip title="Open in Directory Page">
                                      <Link
                                        to={`/main/company/${company_id}/projects/${item.projectInfo?.projectID}/directory`}
                                        target="_blank"
                                        replace
                                        style={{ color: "#8C8C8C" }}
                                      >
                                        <OpenInNewIcon fontSize="14" />
                                      </Link>
                                    </Tooltip>
                                  )}
                                </div>
                                <div className=" d-flex flex-wrap gap-2 ">
                                  <div className="mt-2">
                                    <Card
                                      size="small"
                                      className={`${styles.detailsCard}`}
                                    >
                                      <div className="p-2">
                                        <div className="d-flex flex-column mt-2">
                                          <Text
                                            className={`opacity-50 ${styles.cardTitle}`}
                                          >
                                            Activated Contacts
                                          </Text>
                                          <Text className=" fs-5">
                                            {
                                              item.directoryInfo
                                                ?.activatedContacts
                                            }{" "}
                                            /{" "}
                                            {item.directoryInfo?.totalContacts}
                                          </Text>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                  <div className="mt-2">
                                    <Card
                                      size="small"
                                      className={`${styles.detailsCard}`}
                                    >
                                      <div className="p-2">
                                        <div className="d-flex flex-column mt-2">
                                          <Text
                                            className={`opacity-50 ${styles.cardTitle}`}
                                          >
                                            Not Invited Contacts
                                          </Text>
                                          <Text className=" fs-5">
                                            {
                                              item.directoryInfo
                                                ?.notInvitedContacts
                                            }{" "}
                                            /{" "}
                                            {item.directoryInfo?.totalContacts}
                                          </Text>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                  <div className="mt-2">
                                    <Card
                                      size="small"
                                      className={`${styles.detailsCard}`}
                                    >
                                      <div className="p-2">
                                        <div className="d-flex flex-column mt-2">
                                          <Text
                                            className={`opacity-50 ${styles.cardTitle}`}
                                          >
                                            Pending Contacts
                                          </Text>
                                          <Text className=" fs-5">
                                            {
                                              item.directoryInfo
                                                ?.pendingContacts
                                            }{" "}
                                            /{" "}
                                            {item.directoryInfo?.totalContacts}
                                          </Text>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="d-flex gap-2">
                                  <Text className="fw-bold fs-5">
                                    Project Info
                                  </Text>
                                  {(item.registrationInfo
                                    ?.registerationStatus === "Active" ||
                                    "Expiring soon") && (
                                    <Tooltip title="More Details">
                                      <Link
                                        to={`/main/company/${company_id}/create-project/${item.projectInfo?.projectID}`}
                                        target="_blank"
                                        replace
                                        style={{ color: "#8C8C8C" }}
                                      >
                                        <OpenInNewIcon fontSize="14" />
                                      </Link>
                                    </Tooltip>
                                  )}
                                </div>
                                <div className=" d-flex flex-wrap gap-2 ">
                                  <div className="mt-2">
                                    <Card
                                      size="small"
                                      className={`${styles.detailsCard}`}
                                      style={{
                                        width: "auto",
                                      }}
                                    >
                                      <div className="p-2">
                                        <div className="d-flex flex-column mt-2">
                                          <Text
                                            className={`opacity-50 ${styles.cardTitle}`}
                                          >
                                            Project Name
                                          </Text>
                                          <Text className=" fs-5">
                                            {item.projectInfo?.["Project Name"]}
                                          </Text>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                  <div className="mt-2">
                                    <Card
                                      size="small"
                                      className={`${styles.detailsCard}`}
                                    >
                                      <div className="p-2">
                                        <div className="d-flex flex-column mt-2">
                                          <Text
                                            className={`opacity-50 ${styles.cardTitle}`}
                                          >
                                            Project Number
                                          </Text>
                                          <Text className=" fs-5">
                                            {item.projectInfo?.[
                                              "Project Number"
                                            ] || "N/A"}
                                          </Text>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                  <div className="mt-2">
                                    <Card
                                      size="small"
                                      className={`${styles.detailsCard}`}
                                    >
                                      <div className="p-2">
                                        <div className="d-flex flex-column mt-2">
                                          <Text
                                            className={`opacity-50 ${styles.cardTitle}`}
                                          >
                                            Project ID
                                          </Text>
                                          <Text className=" fs-5">
                                            {item.projectInfo?.projectID}
                                          </Text>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className=" d-flex gap-2 align-items-center">
                                <Text className="fw-bold fs-5">
                                  Registration Info
                                </Text>
                                <Badge
                                  count={
                                    item.registrationInfo?.registerationStatus
                                  }
                                  style={{
                                    backgroundColor:
                                      item.registrationInfo
                                        ?.registerationStatus === "Active"
                                        ? "#1CBDF1"
                                        : item.registrationInfo
                                            ?.registerationStatus ===
                                          "Expiring soon"
                                        ? "#F65C14"
                                        : "#f11e1e",
                                  }}
                                />
                              </div>
                              <div className=" d-flex flex-wrap gap-2 ">
                                <div className="mt-2">
                                  <Card
                                    size="small"
                                    className={`${styles.detailsCard}`}
                                  >
                                    <div className="p-2">
                                      <div className="d-flex flex-column mt-2">
                                        <Text
                                          className={`opacity-50 ${styles.cardTitle}`}
                                        >
                                          Registration Date
                                        </Text>
                                        <Text className=" fs-5">
                                          {
                                            item.registrationInfo
                                              ?.dateRegistered
                                          }
                                        </Text>
                                      </div>
                                    </div>
                                  </Card>
                                </div>
                                <div className="mt-2">
                                  <Card
                                    size="small"
                                    className={`${styles.detailsCard}`}
                                  >
                                    <div className="p-2">
                                      <div className="d-flex flex-column mt-2">
                                        <Text
                                          className={`opacity-50 ${styles.cardTitle}`}
                                        >
                                          Expiry Date
                                        </Text>
                                        <Text className=" fs-5">
                                          {item.registrationInfo?.expiryDate}
                                        </Text>
                                      </div>
                                    </div>
                                  </Card>
                                </div>
                                <div className="mt-2">
                                  <Card
                                    size="small"
                                    className={`${styles.detailsCard}`}
                                  >
                                    <div className="p-2">
                                      <div className="d-flex flex-column mt-2">
                                        <Text
                                          className={`opacity-50 ${styles.cardTitle}`}
                                        >
                                          Days Till Expiry
                                        </Text>
                                        <Text
                                          className="fs-5"
                                          style={{
                                            color:
                                              item.registrationInfo
                                                ?.daysTillExpiry < 1
                                                ? "#F11F1D"
                                                : item.registrationInfo
                                                    ?.daysTillExpiry <= 30
                                                ? "#F65C14"
                                                : "inherit",
                                          }}
                                        >
                                          {item.registrationInfo
                                            ?.registerationStatus !=
                                            "Not registered" &&
                                            (item.registrationInfo
                                              ?.daysTillExpiry < 1
                                              ? "Expired"
                                              : item.registrationInfo
                                                  ?.daysTillExpiry)}
                                        </Text>
                                      </div>
                                    </div>
                                  </Card>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    ))}
                  </div>
                ) : (
                  <div className="mt-4 text-center d-flex justify-content-center align-items-center gap-2">
                    {filteredData.length < 1 && filter === "Expired" ? (
                      <div>
                        <Result
                          icon={
                            <RocketLaunchIcon
                              style={{ fontSize: "94px", color: "#1777FF" }}
                            />
                          }
                          title={"Great job!"}
                          subTitle="There are no expired projects in your list. Keep it up!"
                        />
                      </div>
                    ) : (
                      <div>
                        <Result
                          icon={
                            <RocketLaunchIcon
                              style={{ fontSize: "94px", color: "#1777FF" }}
                            />
                          }
                          title={"Great job!"}
                          subTitle="There are no expiring soon projects in your list. Keep it up!"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="my-4">
                <LoadingComponent />
              </div>
            )}
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default CompanyOverview;
