export const ActiveUsersEndPoints = {
  getActiveUsers: {
    url: (company, project) =>
      `/rest/v1/procurementLogActiveUsers/${company}/${project}`,
    method: "GET",
  },
  addActiveUsers: {
    url: (company, project) =>
      `/rest/v1/addToProcurementLogActiveUsers/${company}/${project}`,
    method: "POST",
  },
  removeActiveUsers: {
    url: (company, project) =>
      `/rest/v1/removeFromProcurementLogActiveUsers/${company}/${project}`,
    method: "DELETE",
  },

  getUsersInfo: {
    url: (company) => `/rest/v1/getUsersInfo/${company}`,
    method: "GET",
  },
};
