export const CompanyAdminEndPoints = {
  readRegisteredProjects: {
    url: (company) => `/rest/v1/readRegisteredProjects/${company}`,
    method: "GET",
  },
  readProcurementLogsViews: {
    url: (company) => `/rest/v1/readProcurementLogsViews/${company}`,
    method: "GET",
  },
  getSampleProcurementLogsViews: {
    url: (company) => `/rest/v1/getSampleProcurementLogsViews/${company}`,
    method: "POST",
  },
  createProcurementLogsViews: {
    url: (company) => `/rest/v1/createProcurementLogsViews/${company}`,
    method: "POST",
  },
  updateProcurementLogsViews: {
    url: (company) => `/rest/v1/updateProcurementLogsViews/${company}`,
    method: "PUT",
  },
  deleteProcurementLogsViews: {
    url: (company) => `/rest/v1/deleteProcurementLogsViews/${company}`,
    method: "DELETE",
  },
  companyAdminPage: {
    url: (company) => `/rest/v1/companyAdminPage/${company}`,
    method: "GET",
  },
  updateCompanyWorkingDays: {
    url: (company) => `/rest/v1/updateCompanyWorkingDays/${company}`,
    method: "PUT",
  },
  updateCompanyNonWorkingDays: {
    url: (company) => `/rest/v1/updateCompanyNonWorkingDays/${company}`,
    method: "PUT",
  },
};
