import Router from "router";
import "App.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Provider } from "react-redux";
import { store } from "./redux";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="App">
          <Router />
          <Toaster />
        </div>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
