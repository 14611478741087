import React from "react";
import styles from "./forget.module.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import AuthWrapper from "components/auth-page-wrapper";
import ButtonComponent from "components/button-component";
import InputComponent from "components/input-component";
import { useNavigate } from "react-router-dom";
import { AuthService } from "apis/Services/AuthService";
import { toast } from "react-hot-toast";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loader, setloader] = React.useState(false);
  let schema = yup.object().shape({
    loginEmail: yup
      .string()
      .required("This field is required")
      .email("Please enter a valid email"),
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      loginEmail: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    AuthService.sendVerificationCodeLink(values)
      .then((res) => {
        toast.success("An email has been sent successfully!");
        navigate("/login");
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Email not registered with Koncurent.");
      });
  };

  return (
    <AuthWrapper title="Forgot Password">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column mt-3">
          <Controller
            name="loginEmail"
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            }}
            control={control}
            render={({ field }) => (
              <InputComponent
                label="Email"
                errors={errors.loginEmail}
                placeholder="Enter your Email"
                {...field}
              />
            )}
          />

          <ButtonComponent
            title={"Send Code"}
            className={`mt-3`}
            disabled={!isValid}
          />
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ForgetPassword;
