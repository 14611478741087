import React from "react";

const SeparateLine = ({ width = "100%", className = "" }) => {
  return (
    <div
      className={`${className}`}
      style={{ width: width, height: 1, backgroundColor: "var(--clr-silver)" }}
    ></div>
  );
};

export default SeparateLine;
