import { Button, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import AuthSlider from "components/auth-slider";
import Text from "components/text";
import logo from "assets/images/logo.svg";
import styles from "./wrapper.module.css";

const AuthWrapper = ({ title = "", hasWelcome = false, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isResetPasswordPath = location.pathname.includes("resetPassword");
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const handleClosePrivacyModal = () => setShowPrivacyModal(false);
  const handleCloseTermsModal = () => setShowTermsModal(false);

  return (
    <div className={`container-fluid bg-white ${styles.page} min-vh-100`}>
      <div className="row py-0 min-vh-100">
        <div className="col-md-6 d-flex flex-column justify-content-between px-4 py-3">
          {isResetPasswordPath ? null : ( // Render nothing if the condition is met (hiding the image)
            <img
              src={logo}
              alt="konkurent-logo"
              className={`${styles.logo} pointer`}
              onClick={() => navigate("/")}
            />
          )}
          {/* <img
            src={logo}
            alt="koncurent-logo"
            className={`${styles.logo} pointer`}
            onClick={() => navigate("/")}
          /> */}
          <div className="d-flex flex-column mt-4 h-100 justify-content-center">
            <Text className={`${styles.title}`} dontWrap={false}>
              {title}
            </Text>
            {hasWelcome ? (
              <Text className={`${styles.welcome}`} dontWrap={false}>
                Welcome Back!
              </Text>
            ) : null}

            {children}
          </div>

          <div className="d-flex gap-4 my-3">
            <Text className={`${styles.copyRight}`}>
              © {new Date().getFullYear()} KONCURENT. Technologies, Inc.
            </Text>
            {/* <Text className={`${styles.privacy}`}>
                Privacy Notice | Terms of Service
            </Text> */}
            {/*  */}

            <div>
              <Text className={`${styles.privacy}`}>
                <span
                  onClick={() => setShowPrivacyModal(true)}
                  style={{ cursor: "pointer" }}
                >
                  Privacy Notice
                </span>{" "}
                |{" "}
                <span
                  onClick={() => setShowTermsModal(true)}
                  style={{ cursor: "pointer" }}
                >
                  Terms of Service
                </span>
              </Text>

              {/* Privacy Notice Modal */}
              <Modal show={showPrivacyModal} onHide={handleClosePrivacyModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Privacy Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Add your Privacy Notice content here */}
                  Privacy Policy At Koncurent, we take your privacy seriously.
                  This Privacy Policy explains our practices regarding the
                  collection, use, and disclosure of information that we receive
                  from users of our website and services.
                  <br /> 1. Information We Collect We collect information that
                  you provide to us, such as your name, email address, postal
                  address, and phone number when you register for our services,
                  make a purchase, or otherwise communicate with us. We may also
                  collect information about your use of our website and
                  services, such as your IP address, browser type, and device
                  ID.
                  <br /> 2. How We Use Your Information We use your information
                  to provide our services to you, to communicate with you about
                  our services, and to improve our website and services. We may
                  also use your information to send you marketing communications
                  about our services, unless you opt out of such communications.
                  <br /> 3. Sharing Your Information We do not sell or rent your
                  personal information to third parties. We may share your
                  information with our third-party service providers who help us
                  to provide our services or process payments. We may also share
                  your information if required by law or to protect our rights
                  or the rights of others.
                  <br /> 4. Security We take reasonable measures to protect your
                  information from unauthorized access, use, or disclosure.
                  However, no method of transmission over the Internet or
                  electronic storage is 100% secure, and we cannot guarantee the
                  absolute security of your information.
                  <br /> 5. Your Choices You may choose to opt out of receiving
                  marketing communications from us at any time by following the
                  instructions in the communications. You may also access,
                  correct, or delete your personal information by contacting us.
                  <br /> 6. Children's Privacy Our website and services are not
                  intended for use by children under the age of 13. We do not
                  knowingly collect personal information from children under 13.
                  If we become aware that we have collected personal information
                  from a child under 13, we will take steps to delete the
                  information as soon as possible.
                  <br /> 7. Changes to This Privacy Policy We may update this
                  Privacy Policy from time to time. If we make material changes
                  to this Privacy Policy, we will notify you by email or by
                  posting a notice on our website.
                  <br /> 8. Contact Us If you have any questions or concerns
                  about our Privacy Policy, please contact us.
                </Modal.Body>
                {/* <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePrivacyModal}>
                    Close
                  </Button>
                </Modal.Footer> */}
              </Modal>

              {/* Terms of Service Modal */}
              <Modal show={showTermsModal} onHide={handleCloseTermsModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Terms of Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Add your Terms of Service content here */}
                  These Terms of Service ("Terms") govern your use of Koncurent
                  website and services. Please read these Terms carefully before
                  using our website or services. By using our website or
                  services, you agree to be bound by these Terms. <br />
                  1. Use of Our Website and Services You may use our website and
                  services only for lawful purposes and in accordance with these
                  Terms. You may not use our website or services in any way that
                  violates any applicable federal, state, local, or
                  international law or regulation.
                  <br /> 2. User Accounts You may need to create an account to
                  use certain features of our website and services. You are
                  responsible for maintaining the confidentiality of your
                  account and password and for restricting access to your
                  computer or mobile device. You agree to accept responsibility
                  for all activities that occur under your account or password.
                  <br />
                  3. Intellectual Property Our website and services, including
                  all content, logos, and trademarks, are owned by Koncurent and
                  are protected by copyright, trademark, and other intellectual
                  property laws. You may not use our website or services in any
                  way that infringes on our intellectual property rights or the
                  rights of any third party.
                  <br /> 4. User Content You may be able to submit content, such
                  as comments or reviews, to our website or services. You retain
                  ownership of any content that you submit, but you grant us a
                  non-exclusive, worldwide, royalty-free, perpetual, and
                  irrevocable license to use, reproduce, modify, publish,
                  translate, distribute, and display such content in any media
                  now known or hereafter developed.
                  <br /> 5. Prohibited Activities You may not use our website or
                  services to: - Engage in any activity that violates any
                  applicable law or regulation. - Transmit any viruses, worms,
                  or other harmful code. - Interfere with the security or
                  functionality of our website or services. - Gain unauthorized
                  access to any account, computer system, or network. - Use our
                  website or services to send unsolicited commercial messages.
                  <br /> 6. Termination We may terminate or suspend your access
                  to our website and services at any time, without notice and
                  for any reason, including if you violate these Terms.
                  <br /> 7. Disclaimer of Warranties Our website and services
                  are provided "as is" and without warranties of any kind,
                  whether express or implied. We do not warrant that our website
                  or services will be uninterrupted or error-free, or that any
                  defects will be corrected.
                  <br /> 8. Limitation of Liability We are not liable for any
                  damages, whether direct, indirect, incidental, or
                  consequential, arising out of your use of our website or
                  services, even if we have been advised of the possibility of
                  such damages.
                  <br /> 9. Governing Law These Terms and your use of our
                  website and services are governed by and construed in
                  accordance with the laws of Delaware, USA, without giving
                  effect to any principles of conflicts of law.
                  <br /> 10. Changes to These Terms We may update these Terms
                  from time to time by posting the updated Terms on our website.
                  Your continued use of our website or services after the
                  posting of the updated Terms constitutes your acceptance of
                  the updated Terms.
                  <br /> 11. Contact Us If you have any questions or concerns
                  about these Terms, please contact us.
                </Modal.Body>
              </Modal>
            </div>

            {/*  */}
          </div>

          {/*  */}

          {/*  */}
        </div>

        <div className={`d-none p-0 d-md-block ${styles.backgroundContainer}`}>
          <AuthSlider />
        </div>

        {/* <div className="col-md-6 p-0 m-0">
          <AuthSlider />
        </div> */}
      </div>
    </div>
  );
};

export default AuthWrapper;
