export const HiddenItemsEndPoints = {
  getProcurementLogHiddenEntries: {
    url: (company, project) =>
      `/rest/v1/getProcurementLogHiddenEntries/${company}/${project}`,
    method: `GET`,
  },

  restoreProcurementLogHiddenEntries: {
    url: (company, project) =>
      `/rest/v1/restoreProcurementLogHiddenEntries/${company}/${project}`,
    method: "POST",
  },

  procurementLogHideEntry: {
    url: (company, project) =>
      `/rest/v1/procurementLogHideEntry/${company}/${project}`,
    method: "POST",
  },
};
