import React from "react";
import Styles from "./components.module.css";

const ContainerLanding = ({ children, style = {}, className = "" }) => {
  return (
    <div style={style} className={`${className} ${Styles.containerLanding}`}>
      {children}
    </div>
  );
};

export default ContainerLanding;
