import { Network } from "../Network";
import { CompanyReportsEndPoints } from "apis/EndPoints/CompanyReportsEndPoints";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class CompanyReportServices {
  static getReportDetails(data = {}) {
    return Network.fetch(
      CompanyReportsEndPoints.getReportDetails.url(
        data?.company,
        data?.firstColumnName,
        data?.secondColumnName,
        data?.minValue,
        data?.maxValue,
        data?.sortBy,
        data?.status,
        data?.filteredColumns,
        data?.filterCombination,
        data?.subcontractorFilter,
        data?.reportRequestName,
        data?.responseFilter
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyReportsEndPoints.getReportDetails.method,
        body: JSON.stringify(data),
      }
    );
  }
  static getCompanyReportAccessLevel(data = {}) {
    return Network.fetch(
      CompanyReportsEndPoints.getCompanyReportAccessLevel.url(data?.company),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyReportsEndPoints.getCompanyReportAccessLevel.method,
      }
    );
  }

  static modifyIssueDateReport(data = {}) {
    return Network.fetch(
      CompanyReportsEndPoints.modifyIssueDateReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: CompanyReportsEndPoints.modifyIssueDateReport.method,
        body: JSON.stringify(data?.editedData),
      }
    );
  }
}
