import { useEffect, useState } from "react";

import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ButtonComponent from "components/button-component";
import CrossIcon from "assets/images/cross-icon.svg";
import InputComponent from "components/input-component";
import ModalBackdrop from "components/modal-backdrop";
import PersonImg from "assets/images/person-image.png";
import SearchIcon from "assets/images/search-icon.svg";
import TwoMembersIcon from "assets/images/two-members-icon.svg";
import styles from "../PersonsModal/PersonsModal.module.css";
import { useParams } from "react-router-dom";

// import styles from "./PersonsModal.module.css";

const PersonsModal = ({
  isVisible,
  closeModal,

  ActiveUsers,
}) => {
  const [filterdMembers, setFilteredMembers] = useState([]);
  const handleDismiss = () => {
    closeModal();
  };

  const handleFilterMembers = (member) => {
    setFilteredMembers((prev) => [...prev, member]);
  };

  const handleApplyFitlerStyle = (member) => {
    if (filterdMembers.includes(member)) return styles.chosenFilteredMember;
  };

  const clearFilter = () => {
    setFilteredMembers([]);
  };
  const { company_id, id } = useParams();
  // const [ActiveUsers, setActiveUsers] = useState([]);

  // useEffect(() => {
  //   let code = localStorage.getItem("token");
  //   let loginEmail = localStorage.getItem("email");
  //   let data = {
  //     company_id,
  //     loginEmail,
  //     code,
  //     project_id: id,
  //   };
  //   ActiveUsersService.getUsers(data)
  //     .then((res) => {
  //       console.log(res, "ActiveUsers");
  //       setActiveUsers(res);
  //       setActiveUsersIcon(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <>
      {!!isVisible && (
        <ModalBackdrop handleDismiss={handleDismiss} customClassName={""}>
          <div className="px-3 py-4" style={{ minWidth: "300px" }}>
            <div
              className="d-flex justify-content-between pb-1 mb-3"
              style={{ borderBottom: "1px solid green" }}
            >
              <div className="d-flex align-items-center gap-2">
                <img src={TwoMembersIcon} alt="two-members-icon" />
                <span>Members</span>
              </div>
              <img
                src={CrossIcon}
                alt="cross-icon"
                style={{ cursor: "pointer" }}
                onClick={handleDismiss}
              />
            </div>
            <div className="mb-3">
              <InputComponent
                className={`w-auto mb-0`}
                placeholder="Search"
                Icon={() => <img src={SearchIcon} alt="search icon" />}
              />
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                {/* <p>Add to Team</p> */}
                {/* <p
                  className={`${styles.clearBtn}`}
                  style={{ cursor: "pointer" }}
                  onClick={clearFilter}
                >
                  Clear
                </p> */}
              </div>
              <ul className={`list-unstyled p-0 m-0 ${styles.membersList}`}>
                {
                  ActiveUsers?.map((item, index) => (
                    <li
                      className={`d-flex gap-2 align-items-center mb-2 ${
                        styles.memberItem
                      } ${handleApplyFitlerStyle(index)}`}
                      key={index}
                    >{`${item[0]} ${item[1]}`}</li>
                  ))

                  // [...Array(10).keys()].map((ele, index) => (
                  //     <li
                  //         key={index}
                  //         className={`d-flex gap-2 align-items-center mb-2 ${styles.memberItem} ${handleApplyFitlerStyle(index)}`}
                  //         style={{ cursor: 'pointer' }}
                  //         onClick={() => handleFilterMembers(index)}
                  //     >
                  //         <img src={PersonImg} alt='team-member' />
                  //         <p className="m-0">NAME: {index}</p>
                  //     </li>
                  // ))
                }
              </ul>
            </div>
            {/* 
            <div>
              <ButtonComponent
                title={"Show more members"}
                className="w-full px-3"
              />
            </div> */}
          </div>
        </ModalBackdrop>
      )}
    </>
  );
};

export default PersonsModal;
