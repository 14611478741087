export const ProjectsEndPoints = {
  getProjects: {
    url: (company_id) => `/rest/v1/projectsInfo/${company_id}`,
    method: "GET",
  },
  getProjectDetails: {
    url: (company, project) =>
      `/rest/v1/procurementLogLoadFromDB/${company}/${project}`,
    method: `GET`,
  },
  getExportData: {
    url: (company, project) =>
      `/rest/v1/recalculateDatesForExport/${company}/${project}`,
    method: `POST`,
  },
  webhooks: {
    url: (company, project) => `/rest/v1/webhooks/${company}/${project}`,
    method: `POST`,
  },
  setLastUsedView: {
    url: (company) => `/rest/v1/setLastUsedView/${company}`,
    method: `POST`,
  },
  getProjectDetailsFromSubmittals: {
    url: (company, project) =>
      `/rest/v1/procurementLogBuildFromSubmittals/${company}/${project}`,
    method: `GET`,
  },
  getProjectsContactPage: {
    url: (company, project) =>
      `/rest/v1/readContactsInfo/${company}/${project}`,
    method: `POST`,
  },

  getProjectsInfo: {
    url: (company, project) => `/rest/v1/adminInfo/${company}/${project}`,
    method: `POST`,
  },

  getItemsPendingApprovals: {
    url: (company, project) =>
      `/rest/v1/procurementLogPendingApprovals/${company}/${project}`,
    method: `Get`,
  },

  sendItemsPendingApprovals: {
    url: (company, project) =>
      `/rest/v1/procurementLogPendingApprovalsAction/${company}/${project}`,
    method: `POST`,
  },

  getItemsPendingApprovalsHistory: {
    url: (company, project) =>
      `/rest/v1/procurementLogApprovalsHistory/${company}/${project}`,
    method: `Get`,
  },

  postSubmittaltoProcore: {
    url: (company, project) =>
      `/rest/v1/procurementLogPostSubmittalToProcore/${company}/${project}`,
    method: `POST`,
  },

  saveRow: {
    url: (company, project) =>
      `/rest/v1/procurementLogInsertEntry/${company}/${project}`,
    method: `POST`,
  },

  EditRow: {
    url: (company, project) =>
      `/rest/v1/procurementLogEditEntry/${company}/${project}`,
    method: `PUT`,
  },

  deleteRow: {
    url: (company, project) =>
      `/rest/v1/procurementLogDeleteEntry/${company}/${project}`,
    method: "DELETE",
  },
  UploadedSchedule: {
    url: (company, project) =>
      `/rest/v1/saveUploadedScheduleAPI/${company}/${project}`,
    method: "POST",
  },
  UploadedProcurementLogSchedule: {
    url: (company, project) =>
      `/rest/v1/saveUploadedProcurementLog/${company}/${project}`,
    method: "POST",
  },

  AdminPage: {
    url: (company, project) =>
      `/rest/v1/procurementLogAdminPage/${company}/${project}`,
    method: "GET",
  },

  insertEditDeleteNonWorkingDays: {
    url: (company, project) =>
      `/rest/v1/insertEditDeleteNonWorkingDays/${company}/${project}`,
    method: "POST",
  },

  updateWorkingDays: {
    url: (company, project) =>
      `/rest/v1/updateWorkingDays/${company}/${project}`,
    method: "POST",
  },
};
