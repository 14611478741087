import { ActiveUsersEndPoints } from "apis/EndPoints/ActiveUsersEndPoints";
import { Network } from "../Network";

export class ActiveUsersService {
  static getUsers(data = {}) {
    return Network.fetch(
      ActiveUsersEndPoints.getActiveUsers.url(
        data?.company_id,
        data?.project_id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ActiveUsersEndPoints.getActiveUsers.method,
        // body: JSON.stringify(data),
      }
    );
  }

  static AddUser(data = {}) {
    return Network.fetch(
      ActiveUsersEndPoints.addActiveUsers.url(
        data?.company_id,
        data?.project_id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ActiveUsersEndPoints.addActiveUsers.method,
        body: JSON.stringify(data),
      }
    );
  }

  static RemoveUser(data = {}) {
    return Network.fetch(
      ActiveUsersEndPoints.removeActiveUsers.url(
        data?.company_id,
        data?.project_id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ActiveUsersEndPoints.removeActiveUsers.method,
        body: JSON.stringify(data),
      }
    );
  }

  static UserInfo(data = {}) {
    return Network.fetch(
      ActiveUsersEndPoints.getUsersInfo.url(data?.company_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ActiveUsersEndPoints.getUsersInfo.method,
        // body: JSON.stringify(data),
      }
    );
  }
}
