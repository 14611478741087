import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companiesId: {},
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    savecompaniesId: (state, action) => {
      state.companiesId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { savecompaniesId } = AuthSlice.actions;

export default AuthSlice.reducer;
