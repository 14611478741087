import React, { useEffect } from "react";

import { Collapse } from "react-bootstrap";
import { SideBarEndService } from "apis/Services/SideBarService";
import Text from "components/text";
import closeIcon from "assets/images/close-icon.png";
import styles from "./menu.module.css";
import { useParams } from "react-router-dom";

const SideMenu = ({
  children,
  show = false,
  setShow,
  title,
  subTitle,
  isRight,
  isSmall = true,
  taskId,
}) => {
  const hideSideNav = () => {
    setShow(false);
  };

  // const { company_id, id } = useParams();

  // useEffect(() => {
  //   let code = localStorage.getItem("token");
  //   let email = localStorage.getItem("email");

  //   let data = {
  //     code,
  //     loginEmail: email,
  //     company_id,
  //     project_id: id,
  //     id: taskId,
  //     // id: "1",
  //   };
  //   SideBarEndService.TasksAssignedInfo(data)
  //     .then((res) => {
  //       console.log(res, "res");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [show]);

  useEffect(() => {
    const handleScroll = () => {
      if (show) {
        document.documentElement.style.overflowY = "hidden";
      } else {
        document.documentElement.style.overflowY = "auto";
      }
    };

    handleScroll(); // Call it immediately to set the initial state

    return () => {
      document.documentElement.style.overflowY = "auto";
    };
  }, [show]);
  return (
    <>
      {show ? (
        <div className={`${styles.layer}`} onClick={hideSideNav}></div>
      ) : null}
      <Collapse in={show} dimension="width">
        <div
          className={`${styles.sidemenu} ${
            isRight && styles.rightStyle
          } px-2 py-2`}
          style={{
            maxWidth: isSmall ? "350px" : "50%",
          }}
        >
          <div
            className={`d-flex justify-content-between align-items-center ${styles.titleSection}`}
          >
            <div className="d-flex flex-column">
              <Text className={`${styles.title}`}>{title}</Text>
              <Text className={`${styles.subTitle}`}>{subTitle}</Text>
            </div>

            <img
              src={closeIcon}
              alt={"close icon"}
              className={`${styles.close}`}
              onClick={hideSideNav}
            />
          </div>
          {children}
        </div>
      </Collapse>
    </>
  );
};

export default SideMenu;
