import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import ModalBackdrop from "components/modal-backdrop";

const DatePicker = ({
  isVisible,
  closeModal,
  isCenterStyle,
  handleGettingDateVal,
  dateVal,
}) => {
  const handleDismiss = () => {
    closeModal();
    handleGettingDateVal(null); // Set the dateVal state to null (or any other appropriate value to represent an empty date)
  };
  return (
    <>
      {!!isVisible && (
        <ModalBackdrop
          handleDismiss={handleDismiss}
          isCenterStyle={isCenterStyle}
        >
          <DateCalendar onChange={handleGettingDateVal} />
          {/* format="DD-MM-YYYY"  inputFormat="DD/MM/YYYY" views={["day", "month", "year"] */}
        </ModalBackdrop>
      )}
    </>
  );
};

export default DatePicker;
