export const RegisterEndPoints = {
  getRegisteredClients: {
    url: () => `/rest/v1/getRegisteredClients`,
    method: `GET`,
  },

  registerNewClient: {
    url: () => `/rest/v1/registerNewClient`,
    method: `POST`,
  },

  updateRegisteredClient: {
    url: () => `/rest/v1/updateRegisteredClient`,
    method: `PUT`,
  },

  deleteRegisteredClient: {
    url: () => `/rest/v1/deleteRegisteredClient`,
    method: `DELETE`,
  },
};
